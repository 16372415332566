import { SIGNER_STATUS } from 'features/dashboard/know-your-customer/kyc-dashboard.constants';
import type { SignerStatusSelect } from './signer-detail.interfaces';

export const ALL_SIGNER_STATUS = [
	SIGNER_STATUS.success,
	SIGNER_STATUS.pending,
	SIGNER_STATUS.failed,
];

export const SIGNER_STATUS_OPTIONS: SignerStatusSelect[] = [
	{
		title: 'Pending',
		value: SIGNER_STATUS.pending,
	},
	{
		title: 'Berhasil',
		value: SIGNER_STATUS.success,
	},
	{
		title: 'Gagal',
		value: SIGNER_STATUS.failed,
	},
];
