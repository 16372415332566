import { Typography } from '@squantumengine/horizon';
import type { FieldColumnProps } from './field-column.interfaces';

const { Paragraph } = Typography;

const FieldColumn = ({ description = '', title }: FieldColumnProps) => {
	return (
		<div className="flex flex-col">
			<Paragraph weight="regular" size="r">
				{title}
			</Paragraph>
			<Paragraph className="mt-1 text-secondary" weight="regular" size="s">
				{description}
			</Paragraph>
		</div>
	);
};

export default FieldColumn;
