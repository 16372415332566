import { useMutation } from 'react-query';
import { useMasterStore } from 'common/stores/master';
import ToastMessage from 'common/utils/toast-message';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { generateDocumentActivity } from 'services/usage';

const useGenerateContractActivity = () => {
	const setIsLoading = useMasterStore((action) => action.setIsLoading);

	const generateDocumentActivityMutation = useMutation(
		generateDocumentActivity,
		{
			onSuccess: (response) => {
				const pdfUrl = URL.createObjectURL(response);
				window.open(pdfUrl, '_blank');
			},
			onError: () => {
				ToastMessage({
					type: 'error',
					label: GENERAL_ERROR_MESSAGE,
				});
			},
			onSettled: () => {
				setIsLoading(false);
			},
		}
	);

	const generateContractActivity = (id: string) => {
		setIsLoading(true);
		generateDocumentActivityMutation.mutate(id);
	};

	return {
		generateContractActivity,
	};
};

export default useGenerateContractActivity;
