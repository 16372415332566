import { useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Outlet, useLocation, useNavigate, matchPath } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { Typography } from '@squantumengine/horizon';
import { Button, Layout, Popover } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import type { AxiosError } from 'axios';
import classNames from 'classnames';
import {
	INVITATION_STATUS,
	USER_STATUS,
} from 'features/admin-dashboard/admin-dashboard.constants';
import { useMasterStore } from 'common/stores/master';
import { useProfileStore } from 'common/stores/profile';
import ProfileIcon from 'common/components/icons/profile';
import Loader from 'common/components/loader';
import { goToLogin } from 'common/utils/auth';
import { generateInitial } from 'common/utils/initial';
import { local } from 'common/utils/storage';
import ToastMessage from 'common/utils/toast-message';
import { camelize } from 'common/utils/transformer';
import useDidMount from 'common/hooks/use-did-mount';
import type { ProfileOrganisations, FeatureFlags } from 'common/types/auth';
import { GENERAL_ERROR_MESSAGE, ROLES } from 'common/constants/index';
import { getInfo } from 'services/auth';
import { getPermission } from 'services/permission';
import { getQueueStatus } from 'services/queue';
import { ROLE_NAVIGATION, ROLE_ALLOWED_PATHS } from './dashboard.constants';
import NotificationCenter from './notification-center';
import ProfilePopover from './profile-popover';
import SharedModal from './shared-modal';
import Sidebar from './sidebar';

const { Paragraph } = Typography;

const DashboardLayout = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const queryClient = useQueryClient();

	const role = useProfileStore((state) => state.role);
	const isAuthenticated = useProfileStore((state) => state.isAuthenticated);
	const name = useProfileStore((state) => state.name);
	const isLoading = useMasterStore((state) => state.isLoading);
	const setIsInActiveUser = useProfileStore((state) => state.setIsInActiveUser);
	const setIsUnregisteredUser = useProfileStore(
		(state) => state.setIsUnregisteredUser
	);
	const setName = useProfileStore((state) => state.setName);
	const setRole = useProfileStore((state) => state.setRole);
	const setPhone = useProfileStore((state) => state.setPhone);
	const setFeatureFlags = useProfileStore((action) => action.setFeatureFlags);
	const setEmail = useProfileStore((state) => state.setEmail);
	const setId = useProfileStore((state) => state.setId);
	const setOrganisations = useProfileStore((state) => state.setOrganisations);
	const setPermissions = useProfileStore((state) => state.setPermissions);
	const setIsAuthenticated = useProfileStore(
		(state) => state.setIsAuthenticated
	);
	const setNavigationMenu = useMasterStore(
		(action) => action.setNavigationMenu
	);
	const setSelectedMenu = useMasterStore((action) => action.setSelectedMenu);
	const { isPollingQueue, setIsPollingQueue } = useMasterStore(
		(state) => state
	);
	const [isChecked, setIsChecked] = useState(isAuthenticated);

	const [fibonacciInterval, setFibonacciInterval] = useState({
		interval: 0,
		index: 0,
	});

	const onSetPolling = (value: boolean) => {
		setIsPollingQueue(value);
	};

	const goToProductDashboard = () => {
		role === ROLES.ADMIN.toLowerCase() ? navigate('/admin') : navigate('/');
	};

	const setupSidebar = (role: string, featureFlags: FeatureFlags | null) => {
		let roleNavigation = ROLE_NAVIGATION[role] || [];

		if (
			ROLES.PENGELOLA.toLowerCase() === role &&
			((featureFlags && !featureFlags['ekyc_dashboard_enabled']) ||
				!featureFlags)
		) {
			roleNavigation = roleNavigation.filter(
				(nav) => nav.url !== '/dashboard/know-your-customer'
			);
		}
		if (
			ROLES.MANAGER.toLowerCase() === role &&
			((featureFlags &&
				/* istanbul ignore next */ !featureFlags[
					'approval_dashboard_enabled'
				]) ||
				!featureFlags)
		) {
			roleNavigation = roleNavigation.map((navigation) => {
				if (!navigation.children) return navigation;

				return {
					...navigation,
					children: navigation.children.filter(
						(nav) => nav.url !== '/invoice-management/approval'
					),
				};
			});
		}

		setNavigationMenu(roleNavigation);
		setActiveSidebar(role);
	};

	const setActiveSidebar = (role: string) => {
		const paths = ROLE_ALLOWED_PATHS[role] ?? [];
		let selectedMenu = '';

		for (const routeInfo of paths) {
			if (matchPath(routeInfo.path, location.pathname)) {
				selectedMenu = routeInfo.id;
				break;
			}
		}

		selectedMenu && setSelectedMenu(selectedMenu);
	};

	const { refetch: callProfile } = useQuery({
		queryKey: ['profileMiddleware'],
		queryFn: getInfo,
		enabled: false,
		onSuccess: (response) => {
			const userData = response?.data?.user;

			const userStatus = userData?.user_status ?? '';
			const invitationStatus = userData?.invitation_status ?? '';

			const inActiveUser = userStatus === USER_STATUS.INACTIVE;
			const verifiedUser = invitationStatus === INVITATION_STATUS.VERIFIED;

			const isInactiveUser =
				(inActiveUser && verifiedUser) || !userStatus || !invitationStatus;
			const isUnregisteredUser = inActiveUser && !verifiedUser;

			setIsInActiveUser(isInactiveUser);
			setIsUnregisteredUser(isUnregisteredUser);

			const name = response?.data?.given_name ?? '';
			const role = userData?.user_role?.toLowerCase() ?? '';
			const email = userData?.user_email ?? '';
			const phone = userData?.user_phone || '-';
			const identifier = userData?.user_id || '';
			const featureFlags = response?.data?.ff ?? null;

			const organisations = response?.data?.user?.organisations ?? [];
			const sanitizedResponse = camelize(
				organisations
			) as ProfileOrganisations[];

			setOrganisations(sanitizedResponse);
			setName(name);
			setRole(role);
			setFeatureFlags(featureFlags);
			setEmail(email);
			setId(identifier);
			setPhone(phone);
			setupSidebar(role, featureFlags);

			datadogRum.setUser({
				id: identifier,
			});

			callPermission();
		},
		onError: (error: AxiosError) => {
			const status = error.response?.status;

			if (status === 404) {
				setIsChecked(true);
				return navigate('/unregistered-account');
			}

			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const { refetch: callPermission } = useQuery({
		queryKey: ['permissions'],
		queryFn: getPermission,
		enabled: false,
		onSuccess: (response) => {
			const permissions = response?.data ?? [];

			setPermissions(permissions);
			setIsAuthenticated(true);
			setIsChecked(true);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const fibonacciUpTo = (max: number) => {
		const fib = [0, 1];
		let nextFib = 0;

		while (nextFib <= max) {
			nextFib = fib[fib.length - 1] + fib[fib.length - 2];
			if (nextFib > max) {
				break;
			}
			fib.push(nextFib);
		}
		fib.shift();
		fib.push(max);
		return fib;
	};

	const fibonacci = useMemo(() => {
		return fibonacciUpTo(60);
	}, []);

	useQuery({
		queryKey: ['getQueueStatus'],
		queryFn: () => getQueueStatus('IN_PROGRESS'),
		retry: (failureCount) => {
			const isRetry = failureCount <= 3;
			onSetPolling(isRetry);
			return isRetry;
		},
		refetchInterval: (response) => {
			const items = response?.data?.items ?? [];
			const isInProgress = items.length > 0;
			/* istanbul ignore next */
			if (isInProgress) return fibonacciInterval.interval * 1000;

			return false;
		},
		enabled: isPollingQueue,
		refetchIntervalInBackground: true,
		onSettled: () => {
			/* istanbul ignore next */
			if (fibonacciInterval.index <= fibonacci.length - 1) {
				setFibonacciInterval({
					interval: fibonacci[fibonacciInterval.index],
					index: fibonacciInterval.index + 1,
				});
			}
		},
		onSuccess: (response) => {
			const items = response?.data?.items ?? [];
			const isInProgress = items.length > 0;
			onSetPolling(isInProgress);

			/* istanbul ignore next */
			if (!isInProgress) {
				queryClient.invalidateQueries('getNotifications');
				queryClient.invalidateQueries('getNotificationCounter');
			}
		},
	});

	const isInvoicePreview = location.pathname.startsWith(
		'/invoice-management/preview'
	);
	const contentClassName = classNames('bg-background overflow-auto ', {
		'bg-neutral-0': isInvoicePreview,
	});
	const isShowSidebar =
		!['/unauthorized', '/unregistered-account', '/404', '/inactive'].includes(
			location.pathname
		) && !isInvoicePreview;

	useDidMount(() => {
		setActiveSidebar(role);
	}, [location.pathname]);

	useEffect(() => {
		if (isChecked) return;

		const hasToken = local.getItem('access_token');
		hasToken ? callProfile() : goToLogin();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!isChecked) {
		return (
			<div className="fixed bg-neutral-100 w-full h-full top-0 left-0 z-20">
				<Loader loading={true} overlay={false} />
			</div>
		);
	}

	return (
		<Layout className="h-full layout">
			{!isInvoicePreview && (
				<Header className="bg-white drop-shadow-sm flex justify-between py-2 px-[60px] z-[21]">
					<div className="h-full w-[144px] flex items-center justify-center">
						<Button
							className={classNames('h-full p-0 hover:!bg-transparent', {
								'cursor-pointer': isAuthenticated,
							})}
							type="text"
							onClick={goToProductDashboard}
						>
							<img
								src="/assets/illustrations/sqeportal-logo.png"
								alt="Sqeportal logo"
								className="h-[48px] w-auto"
							/>
						</Button>
					</div>
					<div className="flex items-center">
						<div className="mr-4">
							<NotificationCenter />
						</div>
						<Popover
							content={<ProfilePopover />}
							trigger="click"
							placement="bottomRight"
						>
							<div className="flex items-center h-full p-1 cursor-pointer">
								<div className="w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center text-neutral-0">
									<Paragraph weight="bold" size="l">
										{generateInitial(name) || <ProfileIcon />}
									</Paragraph>
								</div>
							</div>
						</Popover>
					</div>
				</Header>
			)}
			<Layout hasSider className="relative">
				{isAuthenticated && isShowSidebar ? <Sidebar /> : null}
				<Content className={contentClassName}>
					<div className="px-8 w-full mx-auto h-full">
						<Outlet />
					</div>
				</Content>
			</Layout>
			<Loader loading={isLoading} />
			<SharedModal />
		</Layout>
	);
};

export default DashboardLayout;
