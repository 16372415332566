import { useState, Suspense, lazy } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Label } from '@squantumengine/horizon';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useBillingStore } from 'features/billing-approval/billing-approval.store';
import { generateStatus } from 'features/billing-approval/billing-approval.utils';
import { useProfileStore } from 'common/stores/profile';
import SectionHeader from 'common/components/section-header';
import SpPagination from 'common/components/sp-pagination';
import SpTable from 'common/components/sp-table';
import ToastMessage from 'common/utils/toast-message';
import { camelize } from 'common/utils/transformer';
import useModal from 'common/hooks/use-modal';
import usePagination from 'common/hooks/use-pagination';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { getLogs } from 'services/billing-approval';
import style from './index.module.scss';
import type { Log, LogFile, LogProps } from './log.interfaces';

const FileListModal = lazy(
	() =>
		import(/* webpackChunkName: "approvalFileListModal" */ './file-list-modal')
);

const { Paragraph } = Typography;

const ReportLog = ({ backUrl }: LogProps) => {
	const { reportId } = useParams<{ reportId: string }>();
	const {
		currentPage,
		pageSize,
		totalCount,
		setTotalCount,
		onChangeCurrentPage,
		onChangePageSize,
	} = usePagination();
	const fileListModal = useModal();
	const navigate = useNavigate();
	const role = useProfileStore((state) => state.role);
	const isReportDetailLoading = useBillingStore(
		(state) => state.isReportDetailLoading
	);

	const [logs, setLogs] = useState<Log[]>([]);
	const [organisationName, setOrganisationName] = useState('');
	const [period, setPeriod] = useState('');
	const [files, setFiles] = useState<LogFile[]>([]);

	const { isFetching } = useQuery({
		queryKey: ['getLogs', currentPage, pageSize],
		queryFn: () =>
			getLogs({
				report_id: reportId as string,
				page: currentPage,
				limit: pageSize,
			}),
		onSuccess: (response) => {
			const totalItems = response?.data?.pagination?.totalItems ?? 0;
			const organisationName = response?.data?.report?.organisation_name;
			const period = response?.data?.report?.period;
			const sanitizedResponse = response?.data?.items ?? [];
			let sanitizedLogs = camelize(sanitizedResponse) as Log[];
			sanitizedLogs = sanitizedLogs.map((log) => {
				return {
					...log,
					key: log.id,
				};
			});

			setTotalCount(totalItems);
			setLogs(sanitizedLogs);
			setOrganisationName(organisationName);
			setPeriod(period);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const openFileListModal = (fileList: LogFile[]) => {
		setFiles(fileList);
		fileListModal.openModal();
	};

	const columns: ColumnsType<Log> = [
		{
			title: 'Tanggal & waktu',
			dataIndex: 'eventTime',
			key: 'eventTime',
			width: 160,
			className: 'min-w-[160px]',
			render: (_, { eventTime }) => {
				return (
					<Paragraph className="text-primary">
						{dayjs(eventTime).format('D/MM/YYYY - HH:mm')}
					</Paragraph>
				);
			},
		},
		{
			title: 'Status',
			dataIndex: 'state',
			key: 'state',
			width: 220,
			className: 'min-w-[220px]',
			render: (_, { state }) => {
				const { type, label } = generateStatus(state, role);

				return type ? <Label label={label} border type={type} /> : label;
			},
		},
		{
			title: 'Dokumen',
			dataIndex: 'files',
			key: 'files',
			width: 140,
			className: 'min-w-[140px]',
			render: (_, { files }) => {
				if (!files.length) return '-';

				return (
					<div
						className="flex items-center cursor-pointer"
						onClick={() => openFileListModal(files)}
					>
						<Paragraph
							className="text-blue-500 text-ellipsis overflow-hidden whitespace-nowrap"
							title={files[0].name}
						>
							{files[0].name}
						</Paragraph>
						{files.length > 1 && (
							<div className="py-1 px-2 border border-stroke-primary rounded-lg shrink-0 text-secondary ml-2">
								+{files.length - 1}
							</div>
						)}
					</div>
				);
			},
		},
		{
			title: 'Manager/Penandatangan',
			dataIndex: 'actorName',
			key: 'actorName',
			width: 180,
			className: 'min-w-[180px]',
			render: (_, { actorName }) => {
				return <Paragraph className="text-primary">{actorName}</Paragraph>;
			},
		},
		{
			title: 'Pembaca',
			dataIndex: 'readersName',
			key: 'readersName',
			width: 180,
			className: 'min-w-[180px]',
			render: (_, { readersName }) => {
				return readersName.length ? (
					<div className="flex flex-col">
						{readersName.map((reader) => (
							<Paragraph key={reader} className="text-primary">
								{reader}
							</Paragraph>
						))}
					</div>
				) : (
					'-'
				);
			},
		},
		{
			title: 'Catatan',
			dataIndex: 'notes',
			key: 'notes',
			render: (_, { notes }) => {
				return <Paragraph className="text-primary">{notes}</Paragraph>;
			},
		},
	];

	const title =
		organisationName && period
			? `Laporan ${organisationName} - ${dayjs(period).format('MMMM YYYY')}`
			: 'Laporan';

	return (
		<div className="py-8">
			<SectionHeader
				isLoading={isReportDetailLoading}
				backHandler={() => navigate(backUrl)}
				title={title}
			/>
			<div className="mt-6 bg-neutral-0 rounded-lg shadow-base p-6">
				<SpTable
					columns={columns}
					dataSource={logs}
					className={style.table}
					scroll={{ x: 1400 }}
					pagination={false}
					defaultTable
					bordered
					isLoading={isFetching || isReportDetailLoading}
				/>
				{logs.length && !isFetching ? (
					<SpPagination
						currentPage={currentPage}
						pageSize={pageSize}
						totalCount={totalCount}
						onChangePageSize={onChangePageSize}
						onChangeCurrentPage={onChangeCurrentPage}
					/>
				) : null}
			</div>
			<Suspense>
				{fileListModal.visible && (
					<FileListModal
						isVisible={fileListModal.visible}
						closeModal={fileListModal.closeModal}
						files={files}
					/>
				)}
			</Suspense>
		</div>
	);
};

export default ReportLog;
