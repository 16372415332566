import { isValidElement } from 'react';
import { Typography, Icon } from '@squantumengine/horizon';
import classNames from 'classnames';
import { MESSAGE_VARIANTS } from 'layouts/dashboard/notification-center/notification-center.constants';
import type { NotificationIcon } from 'layouts/dashboard/notification-center/notification-center.interfaces';
import type { CardProps } from './card.interfaces';

const { Paragraph } = Typography;

const Card = <T,>({
	id,
	action,
	subject,
	messages,
	eventTime,
	isSeen,
	isActive,
	badge,
	onClick,
}: CardProps<T>) => {
	const isContainAction = Boolean(action);

	const iconClassName = classNames('w-8 h-8 shrink-0 mr-3', {
		'text-neutral-500': isSeen,
		'text-blue-500': !isSeen,
	});
	const cardClassName = classNames('py-2 px-4 flex relative', {
		'bg-neutral-50': !isSeen && !isActive,
		'bg-blue-50': isActive,
		'cursor-pointer': isContainAction,
	});

	const onRedirectNotification = () => {
		if (onClick && isContainAction)
			onClick({
				id,
				action: action as T,
			});
	};

	return (
		<li className={cardClassName} onClick={onRedirectNotification}>
			{isValidElement(badge) && badge}
			{!isValidElement(badge) && badge && (
				<div className={`${iconClassName} w-8 h-8`}>
					<Icon
						name={badge as NotificationIcon}
						size="lg"
						color="currentColor"
					/>
				</div>
			)}
			<div className="flex flex-col w-full">
				<Paragraph size="r" weight={isSeen ? 'semibold' : 'bold'}>
					{subject}
				</Paragraph>
				{messages.map((message, index) => {
					const textClassName = classNames('mt-1', {
						'text-primary': message.variant === MESSAGE_VARIANTS.default,
						'text-secondary': message.variant === MESSAGE_VARIANTS.muted,
						'text-blue-500': message.variant === MESSAGE_VARIANTS.link,
						'text-red-500': message.variant === MESSAGE_VARIANTS.alert,
					});

					return (
						<Paragraph
							size="s"
							weight="regular"
							className={textClassName}
							key={message.id}
							title={message.details?.join(', ')}
						>
							{messages.length - 1 === index ? (
								<span className="flex justify-between">
									<span className="truncate w-40">{message.text}</span>
									<span className="text-xs text-disable whitespace-nowrap">
										{eventTime}
									</span>
								</span>
							) : (
								message.text
							)}
						</Paragraph>
					);
				})}
			</div>
			{!isSeen && (
				<div className="absolute left-0 top-[10px] w-1 h-5 bg-blue-500 rounded-r-lg" />
			)}
		</li>
	);
};

export default Card;
