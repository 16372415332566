import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.5999 11.9999C21.5999 17.3018 17.3019 21.5999 11.9999 21.5999C6.69798 21.5999 2.3999 17.3018 2.3999 11.9999C2.3999 6.69797 6.69798 2.3999 11.9999 2.3999C17.3019 2.3999 21.5999 6.69797 21.5999 11.9999ZM13.1999 7.1999C13.1999 7.86264 12.6627 8.3999 11.9999 8.3999C11.3372 8.3999 10.7999 7.86264 10.7999 7.1999C10.7999 6.53716 11.3372 5.9999 11.9999 5.9999C12.6627 5.9999 13.1999 6.53716 13.1999 7.1999ZM10.7999 10.7999C10.1372 10.7999 9.59991 11.3372 9.59991 11.9999C9.59991 12.6626 10.1372 13.1999 10.7999 13.1999V16.7999C10.7999 17.4626 11.3372 17.9999 11.9999 17.9999H13.1999C13.8627 17.9999 14.3999 17.4626 14.3999 16.7999C14.3999 16.1372 13.8627 15.5999 13.1999 15.5999V11.9999C13.1999 11.3372 12.6627 10.7999 11.9999 10.7999H10.7999Z"
			fill="currentColor"
		/>
	</svg>
);

const InfoIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default InfoIcon;
