import { Label, Typography } from '@squantumengine/horizon';
import type { ColumnsType } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import FieldColumn from 'features/dashboard/know-your-customer/field-column';
import {
	DOCUMENT_STATUS_PROPS,
	DOCUMENT_STATUS,
	SIGNER_STATUS,
	DOCUMENT_SORT_KEYS,
} from 'features/dashboard/know-your-customer/kyc-dashboard.constants';
import type {
	DocumentHistory,
	DocumentSortKeys,
} from 'features/dashboard/know-your-customer/kyc-dashboard.interfaces';
import { CheckmarkIcon, UserIcon } from 'common/components/icons';
import SpPagination from 'common/components/sp-pagination';
import SpProgress from 'common/components/sp-progress';
import SpTable from 'common/components/sp-table';
import type { SortBy } from 'common/types/global';
import style from './index.module.scss';
import type { TableProps } from './table.interfaces';

const { Paragraph } = Typography;

const Table = ({
	data,
	isLoading,
	limit,
	page,
	totalCount,
	onChangeCurrentPage,
	onChangePageSize,
	onSort,
	onOpenSignersModal,
}: TableProps) => {
	const onTableChange = (sorter: SorterResult<DocumentHistory>) => {
		const by =
			sorter.order && sorter.columnKey
				? (sorter.columnKey as DocumentSortKeys)
				: ('created_at' as DocumentSortKeys);
		let order = 'desc' as SortBy;

		if (sorter.order) order = sorter.order === 'ascend' ? 'asc' : order;

		onSort({
			by,
			order,
		});
	};

	const showSigners = (id: string) => {
		if (isLoading) return;

		onOpenSignersModal(id);
	};

	const columns: ColumnsType<DocumentHistory> = [
		{
			title: 'Tanggal diunggah',
			dataIndex: 'createdAt',
			key: DOCUMENT_SORT_KEYS.createdAt,
			sorter: true,
			width: 200,
			className: 'min-w-[200px]',
			render: (_, { createdAt }) => (
				<FieldColumn
					title={dayjs(createdAt).format('DD MMM YYYY')}
					description={dayjs(createdAt).format('HH:mm:ss')}
				/>
			),
		},
		{
			title: 'Terakhir diperbarui',
			dataIndex: 'updatedAt',
			key: DOCUMENT_SORT_KEYS.updatedAt,
			sorter: true,
			width: 200,
			className: 'min-w-[200px]',
			render: (_, { updatedAt }) => (
				<FieldColumn
					title={dayjs(updatedAt).format('DD MMM YYYY')}
					description={dayjs(updatedAt).format('HH:mm:ss')}
				/>
			),
		},
		{
			title: 'Kode Nasabah',
			dataIndex: 'customerId',
			width: 140,
			className: 'min-w-[140px]',
			render: (_, { customerId }) => <FieldColumn title={customerId || '-'} />,
		},
		{
			title: 'Nama dokumen',
			dataIndex: 'name',
			width: 240,
			className: 'min-w-[240px]',
			render: (_, { name, refContractId }) => (
				<FieldColumn title={name} description={`ID: ${refContractId}`} />
			),
		},
		{
			title: 'E-materai',
			width: 100,
			className: 'min-w-[100px]',
			dataIndex: 'useStamp',
			render: (_, { useStamp }) => (
				<div className="flex items-center justify-center w-full h-5">
					{useStamp ? <CheckmarkIcon /> : '-'}
				</div>
			),
		},
		{
			title: 'Penandatangan',
			dataIndex: 'signersStatus',
			width: 140,
			className: 'min-w-[140px]',
			render: (_, { status, signersStatus }) => {
				const totalSigners = signersStatus.length;
				const signed = signersStatus.filter(
					(status) => status === SIGNER_STATUS.success
				);
				const percentage = (signed.length * 100) / totalSigners;
				const isShowProgress =
					signed.length > 0 &&
					signed.length < totalSigners &&
					status !== DOCUMENT_STATUS.success &&
					status !== DOCUMENT_STATUS.canceled;

				return (
					<div className="flex flex-col items-center">
						<div className="flex items-center">
							<UserIcon />
							<Paragraph className="-ml-[2px]" weight="semibold" size="s">
								{totalSigners}
							</Paragraph>
						</div>
						{isShowProgress ? (
							<div className="mt-1">
								<SpProgress
									progress={percentage}
									width={72}
									progressColor="#006CEB"
								/>
							</div>
						) : null}
					</div>
				);
			},
		},
		{
			title: 'Status dokumen',
			dataIndex: 'status',
			width: 150,
			className: 'min-w-[150px]',
			render: (_, { status }) => {
				const documentProps =
					DOCUMENT_STATUS_PROPS[status] ?? DOCUMENT_STATUS_PROPS.DEFAULT;

				return (
					<div className="flex items-center justify-between">
						<Label
							label={documentProps.label}
							type={documentProps.type}
							border
						/>
					</div>
				);
			},
		},
	];

	return (
		<div className="mt-4 flex flex-col">
			<SpTable
				columns={columns}
				dataSource={data}
				bordered
				defaultTable
				scroll={{
					x: 900,
				}}
				rowClassName="cursor-pointer"
				onRow={(record) => {
					return {
						onClick: () => showSigners(record.contractId),
					};
				}}
				className={style.table}
				isLoading={isLoading}
				pagination={false}
				onChange={
					/* istanbul ignore next */
					(_pagination, _filters, sorter) =>
						onTableChange(sorter as SorterResult<DocumentHistory>)
				}
			/>
			{data.length && !isLoading ? (
				<div className="px-6">
					<SpPagination
						currentPage={page}
						pageSize={limit}
						totalCount={totalCount}
						onChangePageSize={onChangePageSize}
						onChangeCurrentPage={onChangeCurrentPage}
					/>
				</div>
			) : null}
		</div>
	);
};

export default Table;
