import classNames from 'classnames';
import type { OptionProps } from './option.interfaces';

const Option = <TValue,>({
	value,
	disabled,
	isActive,
	title,
	suffix,
	onClick,
}: OptionProps<TValue>) => {
	const className = classNames(
		'flex items-center h-8 px-4 text-body-r-regular justify-between',
		{
			'bg-blue-50': isActive,
			'hover:bg-blue-50': !disabled,
			'cursor-pointer': !disabled,
			'cursor-not-allowed text-disable': disabled,
		}
	);

	const onClickOption = () => {
		!disabled && onClick(value);
	};

	return (
		<li className={className} title={title as string} onClick={onClickOption}>
			<span className="text-ellipsis overflow-hidden whitespace-nowrap">
				{title}
			</span>
			{suffix ? <span className="ml-2">{suffix}</span> : null}
		</li>
	);
};

export default Option;
