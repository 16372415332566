export const CALCULATION_TYPES = {
	MULTIPLIER: 'Multiplier',
	UNIT: 'Satuan',
	TIER: 'Tier',
	FREE: 'Gratis',
};
export const CALCULATION_TYPES_KEY = {
	MULTIPLIER: 'MULTIPLIER',
	UNIT: 'UNIT',
	TIER: 'TIER',
	FREE: 'FREE',
} as const;
