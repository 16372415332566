import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.2501 4.75831C14.9251 4.43331 14.4001 4.43331 14.0751 4.75831L10.0001 8.82498L5.9251 4.74998C5.6001 4.42498 5.0751 4.42498 4.7501 4.74998C4.4251 5.07498 4.4251 5.59998 4.7501 5.92498L8.8251 9.99998L4.7501 14.075C4.4251 14.4 4.4251 14.925 4.7501 15.25C5.0751 15.575 5.6001 15.575 5.9251 15.25L10.0001 11.175L14.0751 15.25C14.4001 15.575 14.9251 15.575 15.2501 15.25C15.5751 14.925 15.5751 14.4 15.2501 14.075L11.1751 9.99998L15.2501 5.92498C15.5668 5.60831 15.5668 5.07498 15.2501 4.75831Z"
			fill="currentColor"
		/>
	</svg>
);

const CloseNewIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default CloseNewIcon;
