import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13.4583 18.6668H12.5C12.25 18.6668 12 18.9585 12 19.2085V22.1668C12 22.4168 12.25 22.6668 12.5 22.6668H13.4583C13.7083 22.6668 14 22.4168 14 22.1668V19.2085C14 18.9585 13.7083 18.6668 13.4583 18.6668ZM16.4583 16.0002H15.5C15.25 16.0002 15 16.2918 15 16.5418V22.1668C15 22.4168 15.25 22.6668 15.5 22.6668H16.4583C16.7083 22.6668 17 22.4168 17 22.1668V16.5418C17 16.2918 16.7083 16.0002 16.4583 16.0002ZM18.5 22.6668H19.4583C19.7083 22.6668 20 22.4168 20 22.1668V17.8752C20 17.6252 19.7083 17.3335 19.4583 17.3335H18.5C18.25 17.3335 18 17.6252 18 17.8752V22.1668C18 22.4168 18.25 22.6668 18.5 22.6668ZM23.375 9.41683L19.875 5.9585C19.5 5.5835 19 5.3335 18.5 5.3335H9.95833C8.875 5.37516 8 6.25016 8 7.37516V24.7085C8 25.7918 8.875 26.6668 9.95833 26.6668H21.9583C23.0833 26.6668 24 25.7918 24 24.7085V10.8335C24 10.3335 23.75 9.79183 23.375 9.41683ZM18.625 7.50016L21.8333 10.7085H18.625V7.50016ZM22 24.7085H9.95833V7.37516H16.625V11.7085C16.625 12.2502 17.0833 12.7085 17.625 12.7085H22V24.7085Z"
			fill="currentColor"
		/>
	</svg>
);

const DocumentIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default DocumentIcon;
