import { SearchBar } from '@squantumengine/horizon';
import StatusFilter from 'features/billing-approval/status-filter';
import SpMonthPicker from 'common/components/sp-month-picker';
import type { FilterProps } from './filter.interfaces';

const Filter = ({
	status,
	search,
	selectedMonth,
	onMonthChange,
	onSearch,
	onSetStatus,
}: FilterProps) => {
	return (
		<div className="flex items-center">
			<SearchBar
				className="w-[328px]"
				placeholder="Cari nama business unit"
				value={search}
				debounceDuration={300}
				size="compact"
				aria-label="Search business unit"
				onChange={onSearch}
			/>
			<div className="ml-4">
				<SpMonthPicker
					value={selectedMonth}
					size="lg"
					onMonthChange={onMonthChange}
				/>
			</div>
			<div className="ml-4">
				<StatusFilter status={status} onSetStatus={onSetStatus} />
			</div>
		</div>
	);
};

export default Filter;
