export const STEPPER_INFO = [
	{
		title: 'Informasi Business Unit',
		step: 1,
		description: 'Business unit yang menggunakan service SQE.',
	},
	{
		title: 'Daftar PIC',
		step: 2,
		description: 'Kontak perwakilan business unit.',
	},
	{
		title: 'Daftar Service',
		step: 3,
		description: 'Pilih service yang akan digunakan.',
	},
	{
		title: 'Pengaturan Komponen Tagihan',
		step: 4,
		description: 'Penambahan potongan diskon serta biaya lain seperti pajak.',
	},
];
