import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import ToastMessage from 'common/utils/toast-message';
import { camelize } from 'common/utils/transformer';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { getDetail } from 'services/billing-approval';
import type {
	BillingMiddlewareProps,
	ReportAccess,
} from './billing-approval.interfaces';
import { useBillingStore } from './billing-approval.store';

const BillingApprovalMiddleware = ({
	component: Component,
}: BillingMiddlewareProps) => {
	const { reportId } = useParams<{ reportId: string }>();
	const location = useLocation();
	const setReportAccess = useBillingStore((action) => action.setReportAccess);
	const setIsReportDetailLoading = useBillingStore(
		(action) => action.setIsReportDetailLoading
	);

	useQuery({
		queryKey: ['getDetail', reportId, location.pathname],
		queryFn: () => getDetail(reportId as string),
		onSuccess: (response) => {
			const sanitizedResponse = camelize(response.data) as ReportAccess;
			setReportAccess(sanitizedResponse);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
		onSettled: () => {
			setIsReportDetailLoading(false);
		},
	});

	return Component;
};

export default BillingApprovalMiddleware;
