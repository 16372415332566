import { Token } from '@squantumengine/horizon';

export const COLORS = {
	...Token.COLORS,
	primary: '#1b232d',
	'stroke-primary': '#bac3d1',
	secondary: '#67727e',
	'stroke-secondary': '#edf0f5',
	disable: '#a4aebd',
	background: '#f9fbff',
};
export const FONT_SIZE = {
	...Token.TYPOGRAPHY,
};
export const SHADOW = {
	base: '0px 4px 16px 0px #0000000a',
	'light-lg': '10px 10px 30px 0px #aeaec026',
	'option-box': '0px 0.5px 2px 0px #00000029, 0px 0px 1px 0px #00000014',
};
export const DEFAULT_TITLE_FONT_WEIGHT = 700;
export const DEFAULT_TITLE_MARGIN_BOTTOM = 0;
export const TITLE_H1_STYLE = {
	fontSize: 32,
	lineHeight: 1.25,
};
export const TITLE_H2_STYLE = {
	fontSize: 28,
	lineHeight: 1.29,
};
export const TITLE_H3_STYLE = {
	fontSize: 24,
	lineHeight: 1.34,
};
export const TITLE_H4_STYLE = {
	fontSize: 20,
	lineHeight: 1.6,
};
export const TITLE_H5_STYLE = {
	fontSize: 16,
	lineHeight: 1.5,
};
