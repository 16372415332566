import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.7188 3.1875H14.0625C13.8985 3.1875 13.7813 3.32812 13.7813 3.49219L13.8282 5.78906C12.7969 4.21875 10.9922 3.1875 8.9766 3.21094C5.76566 3.21094 3.1641 5.83594 3.18754 9.02344C3.18754 12.2344 5.7891 14.8125 9.00003 14.8125C10.4766 14.8125 11.8594 14.25 12.8907 13.3359C13.0078 13.2188 13.0078 13.0312 12.8907 12.9141L12.4219 12.4453C12.3282 12.3516 12.1641 12.3516 12.0469 12.4453C11.2266 13.1719 10.1719 13.5938 9.00003 13.5938C6.44535 13.5938 4.40628 11.5547 4.40628 9C4.40628 6.46875 6.44535 4.40625 9.00003 4.40625C10.8516 4.40625 12.4453 5.53125 13.1719 7.125L10.2188 7.05469C10.0547 7.05469 9.93753 7.17188 9.93753 7.33594V7.96875C9.93753 8.13281 10.0547 8.25 10.2188 8.25H14.7188C14.8594 8.25 15 8.13281 15 7.96875V3.46875C15 3.32812 14.8594 3.1875 14.7188 3.1875Z"
			fill="currentColor"
		/>
	</svg>
);

const InfoIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default InfoIcon;
