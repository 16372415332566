import { Typography } from '@squantumengine/horizon';
import type { EmptyDataProps } from './empty-data.interfaces';

const { Paragraph } = Typography;

const EmptyData = ({
	text = 'Belum ada data yang dapat ditampilkan.',
}: EmptyDataProps) => {
	return (
		<div className="flex flex-col items-center">
			<div className="h-[64px]">
				<img
					src="/assets/illustrations/empty-data.svg"
					alt="Empty data"
					className="h-full w-auto"
				/>
			</div>
			<Paragraph className="mt-2 text-secondary" size="r" weight="regular">
				{text}
			</Paragraph>
		</div>
	);
};

export default EmptyData;
