import SpSkeleton from 'common/components/sp-skeleton';

const Skeleton = () => {
	return (
		<div className="relative p-4 bg-neutral-0 rounded-lg shadow-base h-[146px]">
			<div className="flex flex-col h-full">
				<SpSkeleton className="w-8 h-8 mb-1" />

				<SpSkeleton className="h-5 w-[200px] my-2" />

				<SpSkeleton className="h-8 w-8 mt-6" />
			</div>
		</div>
	);
};

export default Skeleton;
