import SpSkeleton from 'common/components/sp-skeleton';

const Skeleton = () => {
	return (
		<div className="flex mt-6">
			<SpSkeleton className="w-[352px] h-[260px] shrink-0" />
			<SpSkeleton className="w-[calc(100%-352px)] h-[580px] ml-6" />
		</div>
	);
};

export default Skeleton;
