import type { SidebarItemProps } from '@squantumengine/horizon';
import type {
	MasterSliceState,
	MasterSliceActions,
	Organisation,
} from 'common/types';
import { create } from 'zustand';

const initialState: MasterSliceState = {
	isLoading: false,
	navigationMenu: [],
	organisations: [],
	selectedMenu: '',
	isPollingQueue: true,
};

export const useMasterStore = create<MasterSliceState & MasterSliceActions>()(
	(set, get) => ({
		...initialState,
		setOrganisations: (organisations: Organisation[]) =>
			set(() => ({
				organisations,
			})),
		setSelectedMenu: (selectedMenu: string) =>
			set(() => ({
				selectedMenu,
			})),
		setIsLoading: (isLoading: boolean) =>
			set(() => ({
				isLoading,
			})),
		setNavigationMenu: (navigationMenu: SidebarItemProps[]) =>
			set(() => ({
				navigationMenu,
			})),
		setIsPollingQueue: (isPollingQueue: boolean) =>
			set(() => ({
				isPollingQueue,
			})),
	})
);
