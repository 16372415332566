import { Typography } from '@squantumengine/horizon';
import { Divider } from 'antd';
import type { RowTitleProps } from './row-title.interfaces';

const { Paragraph } = Typography;

const RowTitle = ({
	title,
	useDivider = false,
	rowClassName,
	value,
	bold = true,
}: RowTitleProps) => {
	return (
		<>
			<div className={`${rowClassName} flex justify-between`}>
				<Paragraph
					className="text-primary"
					size="r"
					weight={bold ? 'semibold' : 'regular'}
				>
					{title}
				</Paragraph>
				{value && (
					<Paragraph
						className="text-primary"
						size="r"
						weight={bold ? 'semibold' : 'regular'}
					>
						{value}
					</Paragraph>
				)}
			</div>
			{useDivider && <Divider className="m-0 border-stroke-secondary" />}
		</>
	);
};

export default RowTitle;
