import { Button, Typography } from '@squantumengine/horizon';
import type { ErrorProps } from './error.interfaces';

const { Paragraph } = Typography;

const ErrorState = ({ refreshNotifications }: ErrorProps) => {
	return (
		<div className="flex flex-col items-center justify-center h-[394px] bg-[#fafafb] px-8">
			<Paragraph weight="semibold" size="r" className="text-primary">
				Oops, terjadi kesalahan
			</Paragraph>
			<Paragraph
				weight="regular"
				size="r"
				className="mt-2 text-secondary text-center"
			>
				Notifikasi gagal di update, silahkan refresh atau kontak admin
			</Paragraph>
			<Button
				variant="secondary"
				size="sm"
				className="mt-4"
				onClick={refreshNotifications}
			>
				Refresh
			</Button>
		</div>
	);
};

export default ErrorState;
