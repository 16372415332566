import InfoPanel from 'common/components/info-panel';

const Inactive = () => {
	return (
		<div className="flex flex-col bg-background h-full">
			<div className="w-full items-center justify-center h-full flex">
				<InfoPanel
					description="Silakan kontak admin agar Anda dapat mengakses akun."
					imgAlt="Inactive user"
					imgSource="/assets/illustrations/inactive.svg"
					title="Anda tidak memiliki akses ke SQE Portal"
				></InfoPanel>
			</div>
		</div>
	);
};

export default Inactive;
