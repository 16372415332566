import dayjs from 'dayjs';
import type { ReportAccess } from 'features/billing-approval/billing-approval.interfaces';
import { useBillingStore } from 'features/billing-approval/billing-approval.store';
import { generateProgress } from 'features/billing-approval/billing-approval.utils';
import { useProfileStore } from 'common/stores/profile';

const useBillingDetailPageHelper = () => {
	const reportAccess = useBillingStore(
		(state) => state.reportAccess
	) as ReportAccess;
	const isReportDetailLoading = useBillingStore(
		(state) => state.isReportDetailLoading
	);
	const setIsReportDetailLoading = useBillingStore(
		(action) => action.setIsReportDetailLoading
	);
	const role = useProfileStore((state) => state.role);

	const currentState = reportAccess?.currentState ?? '';
	const step = generateProgress(currentState, role)?.step ?? 1;
	const title = reportAccess
		? `Laporan ${reportAccess?.organisationName} - ${dayjs(
				reportAccess?.period
		  ).format('MMMM YYYY')}`
		: 'Laporan';

	return {
		currentState,
		reportAccess,
		isReportDetailLoading,
		step,
		title,
		setIsReportDetailLoading,
	};
};

export default useBillingDetailPageHelper;
