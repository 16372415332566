import type { Dayjs } from 'dayjs';
import { getValidMonthYear } from 'common/utils/date';
import { generateQueryString } from 'common/utils/url';
import useDidMount from 'common/hooks/use-did-mount';

const useUpdateDefaultParams = ({
	selectedMonth,
	currentPage,
	pageSize,
	search,
	status,
	setSearchParams,
}: {
	selectedMonth: Dayjs | null;
	currentPage: number;
	pageSize: number;
	search: string;
	status: string;
	setSearchParams: (
		params: URLSearchParams | string,
		options?: { replace?: boolean }
	) => void;
}) => {
	useDidMount(() => {
		let monthQuery = selectedMonth?.month() as number;
		monthQuery = monthQuery + 1;
		const yearQuery = selectedMonth?.year() as number;
		const validDate = getValidMonthYear(monthQuery, yearQuery);

		const params = {
			page: currentPage,
			size: pageSize,
			search,
			month: validDate.month,
			year: validDate.year,
			status,
		};

		const sanitizedParams = generateQueryString(params) as URLSearchParams;
		setSearchParams(sanitizedParams, { replace: true });
	}, [selectedMonth, currentPage, search, pageSize, status]);
};

export default useUpdateDefaultParams;
