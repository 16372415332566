import classNames from 'classnames';
import type { StatusTogglerProps } from './account-category-switcher.interfaces';

const AccountCategorySwitcher = ({
	isUserStatusActive,
	setUserFilter,
}: StatusTogglerProps) => {
	const useActiveUserStatus = () => {
		setUserFilter({
			key: 'userStatus',
			value: true,
		});
	};

	const useInactiveUserStatus = () => {
		setUserFilter({
			key: 'userStatus',
			value: false,
		});
	};

	const defaultStyles =
		'flex items-center px-3 h-[40px] text-body-r-semibold cursor-pointer rounded-lg border';
	const activeStyles = classNames(defaultStyles, {
		'border-blue-500 text-blue-500 bg-blue-50': isUserStatusActive,
		'bg-white text-secondary': !isUserStatusActive,
	});
	const inactiveStyles = classNames(defaultStyles, {
		'border-blue-500 text-blue-500 bg-blue-50': !isUserStatusActive,
		'bg-white text-secondary': isUserStatusActive,
	});

	return (
		<div className="flex mt-6 gap-4">
			<div className={activeStyles} onClick={useActiveUserStatus}>
				Akun Aktif
			</div>
			<div className={inactiveStyles} onClick={useInactiveUserStatus}>
				Akun Tidak Aktif
			</div>
		</div>
	);
};

export default AccountCategorySwitcher;
