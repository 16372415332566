import { DOCUMENT_STATUS } from 'features/dashboard/know-your-customer/kyc-dashboard.constants';

export const STAMP_STATUS_OPTIONS = [
	{
		title: 'Pending',
		value: DOCUMENT_STATUS.pending,
	},
	{
		title: 'Berhasil',
		value: DOCUMENT_STATUS.success,
	},
	{
		title: 'Gagal',
		value: DOCUMENT_STATUS.failed,
	},
];
