import { Button, SearchBar } from '@squantumengine/horizon';
import { Checkbox } from 'antd';
import {
	ALL_SIGNER_STATUS,
	SIGNER_STATUS_OPTIONS,
} from 'features/dashboard/know-your-customer/detail/signer-detail.constants';
import type { SignerFilterProps } from 'features/dashboard/know-your-customer/detail/signer-detail.interfaces';
import type { SignerStatus } from 'features/dashboard/know-your-customer/kyc-dashboard.interfaces';
import { SortIcon } from 'common/components/icons';
import SpSelect from 'common/components/sp-select';
import style from './index.module.scss';

const Filter = ({
	onSearch,
	searchValue,
	status,
	onChangeStatus,
	onApplyFilter,
	searchPlaceholder,
}: SignerFilterProps) => {
	const searchDocument = (value: string) => {
		onSearch(value);
	};

	const onSelectStatus = (value: SignerStatus) => {
		const updatedStatus = status.includes(value)
			? status.filter((option) => option !== value)
			: [...status, value];

		onChangeStatus(updatedStatus);
	};

	const onSelectAll = () => {
		const updatedStatus =
			status.length === ALL_SIGNER_STATUS.length ? [] : ALL_SIGNER_STATUS;

		onChangeStatus(updatedStatus);
	};

	const statusFilterContent = () => (
		<ul
			className={`${style['status-filter']} rounded w-[200px] py-1`}
			data-testid="statusFilter"
		>
			<li className="flex px-4 h-7 items-center">
				<Checkbox
					checked={isSelectAll}
					aria-label="Select all status"
					indeterminate={!isSelectAll && status.length > 0}
					onChange={onSelectAll}
				>
					Semua status
				</Checkbox>
			</li>
			{SIGNER_STATUS_OPTIONS.map((option) => (
				<li className="flex px-4 h-7 items-center" key={option.value}>
					<Checkbox
						checked={status.includes(option.value)}
						onChange={() => onSelectStatus(option.value)}
					>
						{option.title}
					</Checkbox>
				</li>
			))}
			<li className="py-3 px-4">
				<Button
					size="sm"
					full
					onClick={onApplyFilter}
					disabled={status.length === 0}
				>
					Terapkan
				</Button>
			</li>
		</ul>
	);

	const isSelectAll = status.length === ALL_SIGNER_STATUS.length;
	const statusValue =
		status.length === ALL_SIGNER_STATUS.length || status.length === 0
			? 'Semua status'
			: `Status (${status.length})`;

	return (
		<div className="flex items-center">
			<SearchBar
				className="w-[300px] mr-4"
				placeholder={searchPlaceholder}
				value={searchValue}
				aria-label="Search non weblink"
				onChange={searchDocument}
				size="compact"
				debounceDuration={300}
			/>
			<SpSelect
				size="lg"
				placement="bottomLeft"
				placeholder="Status tandatangan"
				value={statusValue}
				prefix={<SortIcon />}
				content={statusFilterContent}
				popoverClassName="z-[18]"
			/>
		</div>
	);
};

export default Filter;
