import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="40"
		height="40"
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M35.0007 24.166H31.6673V11.2493C31.6673 8.74935 29.584 6.66602 27.084 6.66602H7.50065C5.1569 6.66602 3.33398 8.54102 3.33398 10.8327V14.166C3.33398 15.1035 4.06315 15.8327 5.00065 15.8327H9.16732V27.7077C9.16732 30.5723 11.3027 33.1764 14.2715 33.3327H30.5215C33.9069 33.3327 36.6673 30.6243 36.6673 27.2389V25.8327C36.6673 24.9473 35.8861 24.166 35.0007 24.166ZM5.83398 13.3327V10.8327C5.83398 9.94727 6.56315 9.16602 7.50065 9.16602C8.38607 9.16602 9.16732 9.94727 9.16732 10.8327V13.3327H5.83398ZM11.6673 27.7077V10.8327C11.6673 10.2598 11.5111 9.68685 11.3027 9.16602H27.084C28.2298 9.16602 29.1673 10.1035 29.1673 11.2493V24.166H19.1673C18.2298 24.166 17.5007 24.9473 17.5007 25.8327V27.916C17.5007 31.666 11.6673 32.0306 11.6673 27.7077ZM34.1673 27.2389C34.1673 29.2181 32.5007 30.8327 30.5215 30.8327H19.1152C19.6361 29.9993 20.0007 29.0098 20.0007 27.916V26.666H34.1673V27.2389Z"
			fill="currentColor"
		/>
	</svg>
);

const Scroll = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default Scroll;
