import { Button, SearchBar } from '@squantumengine/horizon';
import type { Dayjs } from 'dayjs';
import type { FilterProps } from 'features/dashboard/know-your-customer/signer-activity-log/filter/filter.interfaces';
import { DownloadIcon } from 'common/components/icons';
import SpRangePicker from 'common/components/sp-range-picker';

export default function SignerActivityLogFilter({
	filter,
	searchValue,
	onSetFilter,
	onSearchInput,
	onSearch,
	onDownload,
}: FilterProps) {
	const onSetDates = (dateRange: [Dayjs, Dayjs]) => {
		onSetFilter({
			...filter,
			dates: dateRange,
		});
	};

	return (
		<div className="flex justify-between mb-6">
			<div className="flex">
				<SearchBar
					className="w-[300px] mr-4"
					size="compact"
					aria-label="Search user id"
					placeholder="Cari User ID"
					debounceDuration={300}
					value={searchValue}
					onChange={onSearch}
					onInput={onSearchInput}
				/>
				<SpRangePicker
					setDates={onSetDates}
					dates={filter.dates}
					maxRangeInDays={31}
				/>
			</div>
			<Button variant="secondary" onClick={onDownload}>
				<span className="mr-2">
					<DownloadIcon />
				</span>
				Unduh data
			</Button>
		</div>
	);
}
