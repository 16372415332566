import type { ChangeEvent, ReactNode } from 'react';
import { CaretDownFilled, SearchOutlined } from '@ant-design/icons';
import { Cascader, Input } from 'antd';
import type { SelectProps } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';
import { useMasterStore } from 'common/stores/master';
import type { FilterProps } from './filter.interfaces';
import style from './index.module.scss';

const Filter = ({
	isUserStatusActive,
	organisationIds,
	search,
	setUserFilter,
	searchUsers,
}: FilterProps) => {
	const organisations = useMasterStore((state) => state.organisations);

	const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
		searchUsers(event.target.value);
	};

	const onSelectOrganisations = (payload: unknown) => {
		const value = payload as string[];

		setUserFilter({
			key: 'organisationIds',
			value,
		});
	};

	/* istanbul ignore next */
	const tagRender = ({ label }: { label: ReactNode }) => {
		return <span>{label},</span>;
	};

	const organisationOptions = organisations.length
		? organisations.reduce((prev, current) => {
				const previousValue = prev as DefaultOptionType[];

				return [
					...previousValue,
					{
						value: current.organisationId,
						label: current.organisationName,
					},
				];
		  }, [] as SelectProps['options'])
		: [];

	return (
		<div className="flex">
			<Input
				className="w-[326px] rounded-lg border-neutral-200 mr-4"
				placeholder="Cari nama atau email"
				aria-label="Search user"
				prefix={<SearchOutlined className="mr-1" />}
				onChange={onSearch}
				value={search}
			/>
			{isUserStatusActive && (
				<Cascader
					options={organisationOptions}
					className={`${style['business-unit-filter']}`}
					placeholder="Business Unit"
					maxTagCount="responsive"
					maxTagTextLength={13}
					multiple
					tagRender={tagRender}
					onChange={onSelectOrganisations}
					value={organisationIds}
					aria-label="Business unit filter"
					suffixIcon={<CaretDownFilled className="text-black" />}
					getPopupContainer={(trigger) => {
						return trigger.parentElement;
					}}
					popupClassName="w-[313px] h-[232px] overflow-y-auto"
				/>
			)}
		</div>
	);
};

export default Filter;
