import { forwardRef, useImperativeHandle, useState } from 'react';
import type { ForwardedRef } from 'react';
import { Popover } from 'antd';
import classNames from 'classnames';
import type { SpSelectProps } from './sp-select.interfaces';

declare module 'react' {
	// eslint-disable-next-line @typescript-eslint/ban-types
	function forwardRef<T, P = {}>(
		render: (props: P, ref: React.Ref<T>) => JSX.Element | null
	): (props: P & React.RefAttributes<T>) => JSX.Element | null;
}

// eslint-disable-next-line react/display-name
const SpSelect = forwardRef(
	<TValue,>(
		props: SpSelectProps<TValue>,
		ref: ForwardedRef<{
			closePopover: () => void;
		} | null>
	) => {
		const {
			prefix,
			suffix,
			className,
			contentClassName,
			popoverClassName,
			disabled,
			error,
			variant = 'default',
			options = [],
			placement = 'bottomLeft',
			size = 'md',
			value,
			placeholder,
			content,
			onChange,
		} = props;
		const [open, setOpen] = useState(false);

		const closePopover = () => {
			setOpen(false);
		};

		const onOpenPopover = (newOpen: boolean) => {
			if (disabled) return;

			setOpen(newOpen);
		};

		const emitOption = (value: string | number) => {
			const selectValue = value as TValue;

			onChange?.(selectValue);
			closePopover();
		};

		useImperativeHandle(
			ref,
			() => {
				return {
					closePopover,
				};
			},
			[]
		);

		const textValue = (options.find((option) => option.value === value)
			?.title ?? value) as string;

		const textClassName = classNames(
			`flex px-3 rounded-lg text-body-r-regular justify-between items-center ${className}`,
			{
				border: variant === 'default',
				'text-primary bg-neutral-0': Boolean(textValue) && !disabled,
				'text-neutral-300': !textValue && Boolean(placeholder),
				'border-red-300': error && variant === 'default',
				'border-stroke-primary': !error && variant === 'default',
				'cursor-pointer': !disabled,
				'cursor-not-allowed text-neutral-300 bg-neutral-50': disabled,
				'h-8': size === 'md',
				'h-10': size === 'lg',
			}
		);

		return (
			<Popover
				overlayClassName={popoverClassName}
				content={
					content?.(closePopover) ?? (
						<ul
							className={`max-h-[128px] ${contentClassName} rounded overflow-hidden py-1 overflow-y-auto`}
							role="listbox"
						>
							{options.map((option) => {
								const optionClassName = classNames(
									'flex items-center h-8 px-4 cursor-pointer hover:bg-blue-50 text-body-r-regular relative',
									{
										"before:content-[''] before:w-1 before:h-6 before:bg-blue-500 before:rounded-r-full before:absolute before:top-1/2 before:left-[0px] before:translate-x-[0%] before:translate-y-[-50%]":
											option.value === value,
									}
								);

								return (
									<li
										key={option.value}
										className={optionClassName}
										role="option"
										aria-selected={option.value === value}
										onClick={() => emitOption(option.value)}
									>
										{option.title}
									</li>
								);
							})}
						</ul>
					)
				}
				trigger="click"
				placement={placement}
				open={open}
				onOpenChange={onOpenPopover}
			>
				<div className={textClassName} role="button">
					{prefix ? <span className="mr-1 flex">{prefix}</span> : null}
					<span className="overflow-hidden whitespace-nowrap text-ellipsis">
						{textValue || placeholder}
					</span>
					{suffix ? <span className="ml-1 flex">{suffix}</span> : null}
				</div>
			</Popover>
		);
	}
);

export default SpSelect;
