import { lazy, Suspense, useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Button as HzButton, Icon } from '@squantumengine/horizon';
import { Button, Modal, Popover } from 'antd';
import dayjs from 'dayjs';
import {
	SETTINGS_TYPE,
	SETTINGS_FORMAT,
	SETTINGS_CONFIG,
} from 'features/business-unit/form/invoice-setting/invoice-setting.constants';
import Option from 'common/components/option';
import SectionHeader from 'common/components/section-header';
import SpSkeleton from 'common/components/sp-skeleton';
import ToastMessage from 'common/utils/toast-message';
import { camelize, convertToRpPrice } from 'common/utils/transformer';
import useModal from 'common/hooks/use-modal';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import {
	getOrganisationDetails,
	updateOrganisationStatus,
} from 'services/organisation';
import type { OrganisationDetails } from './business-unit.interfaces';
import { useBusinessUnitStore } from './business-unit.store';
import Info from './detail/info';
import InvoiceSetting from './detail/invoice-setting';
import Pic from './detail/pic';
import Services from './detail/services';
import Skeleton from './detail/skeleton';

const DeactivateModal = lazy(
	() =>
		import(
			/* webpackChunkName: "deactivateBusinessUnitModal" */ 'features/business-unit/deactivate-modal'
		)
);
// SQEPORT-575: will be commented out for now
// const VerifyPasswordModal = lazy(
// 	() =>
// 		import(
// 			/* webpackChunkName: "verifyPasswordModal" */ 'components/BusinessUnit/Detail/VerifyPasswordModal'
// 		)
// );

const DetailBusinessUnit = () => {
	const { organisationId } = useParams<{ organisationId: string }>();
	const navigate = useNavigate();
	const [modal, contextHolder] = Modal.useModal();
	const deactivateBusinessUnitModal = useModal();
	// SQEPORT-575: will be commented out for now
	// const verifyPasswordModal = useModal();

	const setIsLoadingDetails = useBusinessUnitStore(
		(action) => action.setIsLoadingDetails
	);

	const [details, setDetails] = useState<OrganisationDetails | null>(null);

	const deactivateBusinessUnit = (reason: string) => {
		updateOrganisationStatusMutation.mutate(
			{
				id: organisationId as string,
				payload: {
					is_active: false,
					inactive_reason: reason,
				},
			},
			{
				onSuccess: () => {
					ToastMessage({
						type: 'success',
						label: 'Pembaruan status berhasil.',
					});
					deactivateBusinessUnitModal.closeModal();
					getDetails();
				},
				onError: () => {
					ToastMessage({
						type: 'error',
						label: 'Pembaruan status gagal.',
					});
				},
			}
		);
	};

	const goToEditPage = () => {
		setIsLoadingDetails(true);
		navigate(`/business-unit/${organisationId}/edit`);
	};

	const goToCustomPricePage = () => {
		navigate(`/price/custom/${organisationId}`);
	};

	const openDeactivateOrganisationModal = () => {
		deactivateBusinessUnitModal.openModal();
	};

	// SQEPORT-575: will be commented out for now
	// const openVerifyModal = () => {
	// 	verifyPasswordModal.openModal();
	// 	deactivateBusinessUnitModal.closeModal();
	// };

	const openReactivateConfirmation = () => {
		modal.confirm({
			title: 'Reaktivasi business unit?',
			cancelText: 'Kembali',
			okText: 'Ya, reaktivasi',
			className: 'confirm-modal',
			centered: true,
			zIndex: 1041,
			onOk: reactivateBusinessUnit,
		});
	};

	const reactivateBusinessUnit = () => {
		updateOrganisationStatusMutation.mutate(
			{
				id: organisationId as string,
				payload: {
					is_active: true,
				},
			},
			{
				onSuccess: () => {
					ToastMessage({
						type: 'success',
						label: 'Pembaruan status berhasil.',
					});
					getDetails();
				},
				onError: () => {
					ToastMessage({
						type: 'error',
						label: 'Pembaruan status gagal.',
					});
				},
			}
		);
	};

	const updateOrganisationStatusMutation = useMutation(
		updateOrganisationStatus
	);

	const { isFetching, refetch: getDetails } = useQuery({
		queryKey: 'getOrganisationDetails',
		queryFn: () => getOrganisationDetails(organisationId as string),
		enabled: false,
		onSuccess: (data) => {
			const details = data?.data ?? null;

			if (!details) {
				navigate('/maintenance', { replace: true });
				return;
			}

			const sanitizedResponse = camelize(details) as OrganisationDetails;
			const organisationDetails = {
				...sanitizedResponse,
				invoiceComponentSettings:
					sanitizedResponse.invoiceComponentSettings.map((component) => {
						const method =
							component.format === SETTINGS_FORMAT.PERCENT
								? 'Persen'
								: 'Nominal';
						const realValue =
							component.format === SETTINGS_FORMAT.PERCENT
								? `${component.value}%`
								: convertToRpPrice(component.value);
						const type =
							component.componentType === SETTINGS_TYPE.ADDITION
								? 'Tambah (+)'
								: 'Kurang (-)';
						let period = 'Hanya bulan ini';
						period =
							component.config === SETTINGS_CONFIG.THIS_MONTH_ONWARDS
								? 'Bulan ini dan seterusnya'
								: period;

						if (component.config === SETTINGS_CONFIG.NEXT_MONTH_ONWARDS) {
							const isStarted = dayjs().isAfter(dayjs(component.startAt));
							period = isStarted
								? 'Bulan ini dan seterusnya'
								: 'Bulan depan dan seterusnya';
						}

						return {
							...component,
							method,
							realValue,
							type,
							period,
						};
					}),
				organisationServices: sanitizedResponse.organisationServices.filter(
					(service) => service.isActive
				),
			};

			setDetails(organisationDetails);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});

			navigate('/maintenance', { replace: true });
		},
	});

	const isOrganisationActive = details?.organisation?.isActive ?? false;

	useEffect(() => {
		if (organisationId) {
			getDetails();
			return;
		}

		navigate('/404', { replace: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<SectionHeader backHandler={() => navigate(-1)} title="Detail">
				{isFetching && (
					<div className="flex">
						<SpSkeleton className="w-[200px] h-8"></SpSkeleton>
						<SpSkeleton className="w-[56px] h-8 ml-4"></SpSkeleton>
					</div>
				)}
				{!isFetching && isOrganisationActive && (
					<div className="flex">
						<Button
							className="text-red-500 border-red-500 rounded-lg hover:!border-red-300 hover:!text-red-300"
							onClick={openDeactivateOrganisationModal}
						>
							Nonaktifkan Business Unit
						</Button>
						<span className="ml-4">
							<Popover
								content={
									<ul className="w-[148px] py-1">
										<Option
											title="Kustom harga"
											isActive={false}
											value="custom"
											onClick={goToCustomPricePage}
										/>
										<Option
											title="Business unit"
											isActive={false}
											value="edit"
											onClick={goToEditPage}
										/>
									</ul>
								}
								trigger="click"
								placement="bottomRight"
							>
								<HzButton size="sm">
									Edit
									<span className="ml-2">
										<Icon name="chevron-large-down" color="currentColor" />
									</span>
								</HzButton>
							</Popover>
						</span>
					</div>
				)}
				{!isFetching && !isOrganisationActive && (
					<HzButton
						variant="secondary"
						size="sm"
						onClick={openReactivateConfirmation}
					>
						Reaktivasi Business Unit
					</HzButton>
				)}
			</SectionHeader>
			<div className="mt-6 shadow-base py-6 px-4 rounded-lg bg-neutral-0">
				{isFetching && <Skeleton />}
				{!isFetching && details && (
					<>
						<Info organisation={details.organisation} />
						<Pic pics={details.pics} />
						<Services services={details.organisationServices} />
						<InvoiceSetting settings={details.invoiceComponentSettings} />
					</>
				)}
			</div>
			<Suspense>
				{deactivateBusinessUnitModal.visible && (
					<DeactivateModal
						isVisible={deactivateBusinessUnitModal.visible}
						isLoading={updateOrganisationStatusMutation.isLoading}
						closeModal={deactivateBusinessUnitModal.closeModal}
						// SQEPORT-575: will be commented out for now
						// openVerifyPasswordModal={openVerifyModal}
						deactivate={deactivateBusinessUnit}
					></DeactivateModal>
				)}
			</Suspense>
			{/* SQEPORT-575: will be commented out for now */}
			{/* <Suspense>
				{verifyPasswordModal.visible && (
					<VerifyPasswordModal
						isVisible={verifyPasswordModal.visible}
						closeModal={verifyPasswordModal.closeModal}
						deactivate={deactivateBusinessUnit}
					></VerifyPasswordModal>
				)}
			</Suspense> */}
			{contextHolder}
		</div>
	);
};

export default DetailBusinessUnit;
