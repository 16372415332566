import { SearchBar } from '@squantumengine/horizon';

const SignerFilter = ({
	onSearch,
	value,
}: {
	onSearch: (value: string) => void;
	value: string;
}) => {
	const searchSigner = (value: string) => {
		onSearch(value);
	};

	return (
		<SearchBar
			className="w-[300px]"
			size="compact"
			value={value}
			placeholder="Cari user id"
			aria-label="Search signer"
			onChange={searchSigner}
			debounceDuration={300}
		/>
	);
};

export default SignerFilter;
