import { useEffect, useRef } from 'react';
import type { DependencyList } from 'react';

const useDidMount = (effect: () => void, dependencies: DependencyList) => {
	const isMounted = useRef(false);

	useEffect(() => {
		if (isMounted.current) {
			effect();
		} else {
			isMounted.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencies);
};

export default useDidMount;
