import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import type { ChartProps } from './chart.interfaces';
import CustomTooltip from './custom-tooltip';

const Chart = ({
	data,
	colors,
	setHoveredColor,
	hoveredColor,
	width,
	height,
	innerRadius,
	outerRadius,
}: ChartProps) => {
	return (
		<PieChart width={width} height={height}>
			<Pie
				data={data}
				dataKey="value"
				nameKey="name"
				cx="50%"
				cy="50%"
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				fill="#8884d8"
				paddingAngle={2}
				isAnimationActive={false}
				startAngle={90}
				endAngle={-270}
			>
				{colors.map((color) => (
					<Cell
						opacity={hoveredColor && hoveredColor !== color ? 0.3 : 1}
						fill={color}
						key={color}
						onMouseEnter={() => setHoveredColor(color)}
						onMouseLeave={() => setHoveredColor('')}
					/>
				))}
			</Pie>
			<Tooltip content={<CustomTooltip />} wrapperStyle={{ zIndex: 9999 }} />
		</PieChart>
	);
};

export default Chart;
