import { DOCUMENT_STATUS } from 'features/dashboard/know-your-customer/kyc-dashboard.constants';
import type { DocumentStatusSelect } from './document-filter.interfaces';

export const DOCUMENT_STATUS_OPTIONS: DocumentStatusSelect[] = [
	{
		title: 'Pending',
		value: DOCUMENT_STATUS.pending,
	},
	{
		title: 'Berhasil',
		value: DOCUMENT_STATUS.success,
	},
	{
		title: 'Gagal',
		value: DOCUMENT_STATUS.failed,
	},
	{
		title: 'Dibatalkan',
		value: DOCUMENT_STATUS.canceled,
	},
];
