import type { ObjectWithStringValue } from 'common/types/global';

export const generateQueryString = <T>(inputObject: T) => {
	const result: Partial<ObjectWithStringValue<T>> = {};

	for (const key in inputObject) {
		const value = inputObject[key];

		if (value !== undefined && value !== null && value !== '') {
			result[key] = value.toString();
		}
	}

	return result;
};
