import { useState } from 'react';
import { useQuery } from 'react-query';
import ToastMessage from 'common/utils/toast-message';
import { camelize } from 'common/utils/transformer';
import useQueryUrl from 'common/hooks/use-query-url';
import type { Service } from 'common/types/service';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { getServices } from 'services/service';
import PriceDashboard from './dashboard';
import type { PriceServices } from './price.interfaces';

const GeneralPrice = () => {
	const { searchParams, setUrl } = useQueryUrl();

	const [services, setServices] = useState<PriceServices[]>([]);

	const onChipClick = (serviceId: string) => {
		const currentService = services.find(
			(service) => service.sqeserviceId === serviceId
		);
		const currentServiceName = currentService?.sqeserviceName as string;

		setUrl({
			keyPairs: {
				sid: serviceId,
				sname: currentServiceName,
			},
		});
	};

	const { isFetching } = useQuery({
		queryKey: 'getServices',
		queryFn: getServices,
		onSuccess: (response) => {
			const services = response?.data?.items ?? [];
			const sanitizedResponse = camelize(services) as Service[];
			const serviceIdQuery = searchParams.get('sid');

			if (services.length) {
				const service = sanitizedResponse.find(
					(service) => service.sqeserviceId === serviceIdQuery
				);

				const currentServiceId =
					service?.sqeserviceId ?? sanitizedResponse?.[0]?.sqeserviceId;
				const currentServiceName =
					service?.sqeserviceName ?? sanitizedResponse?.[0]?.sqeserviceName;

				setServices(sanitizedResponse);
				setUrl({
					keyPairs: {
						sid: currentServiceId,
						sname: currentServiceName,
					},
				});
			}
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	return (
		<PriceDashboard
			isServicesLoading={isFetching}
			serviceList={services}
			title="Pengaturan harga standar"
			onChipClick={onChipClick}
		></PriceDashboard>
	);
};

export default GeneralPrice;
