import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import {
	BILLING_STATUS,
	MANAGER_STATUS_FOR_SKIP_REPORT,
} from 'features/billing-approval/billing-approval.constants';
import type {
	ApprovalList,
	BillingSort,
	BillingStatus,
} from 'features/billing-approval/billing-approval.interfaces';
import { useBillingStore } from 'features/billing-approval/billing-approval.store';
import { getInitialParams } from 'features/billing-approval/billing-approval.utils';
import Table from 'features/billing-approval/table';
import SectionHeader from 'common/components/section-header';
import ToastMessage from 'common/utils/toast-message';
import { camelize } from 'common/utils/transformer';
import usePagination from 'common/hooks/use-pagination';
import useSort from 'common/hooks/use-sort';
import useUpdateDefaultParams from 'common/hooks/use-update-default-params';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { getApprovalList } from 'services/billing-approval';
import Filter from './filter';

const ManagerApproval = () => {
	const {
		currentPage,
		pageSize,
		totalCount,
		setTotalCount,
		setCurrentPage,
		onChangeCurrentPage,
		onChangePageSize,
	} = usePagination();
	const navigate = useNavigate();
	const { sort, setSort } = useSort({
		by: 'organisation_name',
		order: 'desc',
	});
	const [searchParams, setSearchParams] = useSearchParams();
	const setIsReportDetailLoading = useBillingStore(
		(action) => action.setIsReportDetailLoading
	);

	const [search, setSearch] = useState('');
	const [selectedMonth, setSelectedMonth] = useState<Dayjs | null>(dayjs());
	const [status, setStatus] = useState<BillingStatus[]>(
		Object.values(BILLING_STATUS)
	);
	const [isQueryEnabled, setIsQueryEnabled] = useState(false);
	const [approvals, setApprovals] = useState<ApprovalList[]>([]);

	const { isFetching } = useQuery({
		queryKey: [
			'getApprovalList',
			sort,
			search,
			selectedMonth,
			currentPage,
			pageSize,
			status,
		],
		queryFn: () =>
			getApprovalList({
				organisation_name: search,
				sort_by: `${sort.by}:${sort.order}`,
				period: (selectedMonth as Dayjs).format('YYYY-MM'),
				state: status.join(),
				page: currentPage,
				limit: pageSize,
			}),
		enabled: isQueryEnabled,
		onSuccess: (response) => {
			const totalItems = response?.data?.pagination?.totalItems ?? 0;
			const managerApprovalList = response?.data?.items ?? [];
			let sanitizedManagerApprovalList = camelize(
				managerApprovalList
			) as ApprovalList[];
			sanitizedManagerApprovalList = sanitizedManagerApprovalList.map(
				(approval) => {
					return {
						...approval,
						key: approval.reportId,
					};
				}
			);

			setTotalCount(totalItems);
			setApprovals(sanitizedManagerApprovalList);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const onMonthChange = (date: Dayjs | null) => {
		setSelectedMonth(date);
		setCurrentPage(1);
	};

	const onSearch = (value: string) => {
		setSearch(value);
	};

	const onSetStatus = (value: BillingStatus[]) => {
		setStatus(value);
		setCurrentPage(1);
	};

	const onGoToDetail = (reportId: string, status: BillingStatus) => {
		setIsReportDetailLoading(true);
		MANAGER_STATUS_FOR_SKIP_REPORT.includes(status)
			? navigate(`/invoice-management/approval/log/${reportId}`)
			: navigate(`/invoice-management/approval/${reportId}`);
	};

	const onSort = (sort: BillingSort) => {
		setSort(sort);
	};

	useEffect(() => {
		const { page, size, validDate, search, status } =
			getInitialParams<BillingStatus>(
				searchParams,
				Object.values(BILLING_STATUS)
			);

		onChangeCurrentPage(page);
		onChangePageSize(size);
		setStatus(status);
		setSelectedMonth(dayjs(`${validDate.year}-${validDate.month}-01`));
		setIsQueryEnabled(true);
		setSearch(search);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useUpdateDefaultParams({
		selectedMonth,
		currentPage,
		pageSize,
		search,
		status: status.join(),
		setSearchParams,
	});

	return (
		<div className="py-8">
			<SectionHeader containerClass="mb-6" title="Report Management" />
			<div className="p-6 bg-white rounded-lg shadow-base">
				<Filter
					selectedMonth={selectedMonth}
					search={search}
					status={status}
					onMonthChange={onMonthChange}
					onSearch={onSearch}
					onSetStatus={onSetStatus}
				/>
				<div className="mt-6">
					<Table
						isLoading={isFetching}
						data={approvals}
						totalCount={totalCount}
						currentPage={currentPage}
						pageSize={pageSize}
						onChangeCurrentPage={onChangeCurrentPage}
						onChangePageSize={onChangePageSize}
						onGoToDetail={onGoToDetail}
						onSort={onSort}
					/>
				</div>
			</div>
		</div>
	);
};

export default ManagerApproval;
