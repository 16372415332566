import { useState } from 'react';

const usePagination = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalCount, setTotalCount] = useState(20);

	const onChangePageSize = (size: number) => {
		size !== pageSize && setPageSize(size);
		currentPage !== 1 && setCurrentPage(1);
	};

	const onChangeCurrentPage = (page: number) => {
		currentPage !== page && setCurrentPage(page);
	};

	return {
		currentPage,
		pageSize,
		totalCount,
		onChangeCurrentPage,
		onChangePageSize,
		setTotalCount,
		setCurrentPage,
	};
};

export default usePagination;
