import { useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@squantumengine/horizon';
import dayjs from 'dayjs';
import { useProfileStore } from 'common/stores/profile';
import {
	ArrowRightIcon,
	DownloadIcon,
	ChartIcon,
} from 'common/components/icons';
import Option from 'common/components/option';
import SpMonthPicker from 'common/components/sp-month-picker';
import SpSelect from 'common/components/sp-select';
import useAnalytic from 'common/hooks/use-analytic';
import type { FilterProps, ServiceSelectorRef } from './filter.interfaces';

const { Paragraph } = Typography;

const Filter = ({
	selectedBusinessUnit,
	selectedMonth,
	downloadDisable,
	downloadLoading,
	path,
	activeService,
	updatedAt,
	onMonthChange,
	onDownload,
}: FilterProps) => {
	const navigate = useNavigate();
	const { syncFilterToUrl } = useAnalytic();

	const organisations = useProfileStore((state) =>
		state.organisations.filter(
			(organisation) => organisation.usedSqeservices.length > 0
		)
	);
	const role = useProfileStore((state) => state.role);

	const serviceSelectorsRef = useRef<ServiceSelectorRef>(null);

	const [currentBusinessUnit, setCurrentBusinessUnit] =
		useState(selectedBusinessUnit);

	const onChangeService = (currentPath: string) => {
		if (currentPath === path) {
			syncFilterToUrl({
				organisationId: currentBusinessUnit,
				selectedMonth,
			});
			serviceSelectorsRef.current?.closePopover();
		} else {
			const month = dayjs(selectedMonth).month() + 1;
			const year = dayjs(selectedMonth).year();

			navigate({
				pathname: currentPath,
				search: `?month=${month}&year=${year}&oid=${currentBusinessUnit}`,
			});
		}
	};

	const onChangeBusinessUnit = (value: string) => {
		setCurrentBusinessUnit(value);
	};

	const serviceSelectorContent = () => (
		<div className="rounded">
			<div className="h-[42px] flex items-center px-4 border-b border-neutral-50">
				<Paragraph className="text-primary" weight="semibold" size="r">
					Pilih dashboard yang ingin ditampilkan
				</Paragraph>
			</div>
			<div className="pb-2 flex">
				<ul className="w-[240px] max-h-[180px] overflow-auto">
					{serviceSelectors.map((option) => {
						return (
							<Option
								key={option.id}
								isActive={currentBusinessUnit === option.id}
								value={option.id}
								disabled={!option.services.length}
								title={option.name}
								suffix={<ArrowRightIcon />}
								onClick={onChangeBusinessUnit}
							/>
						);
					})}
				</ul>
				{services.length ? (
					<ul className="w-[280px] border-l border-neutral-50">
						{services
							.filter((service) => service.name.toLowerCase() !== 'sqeportal')
							.map((option) => {
								return (
									<Option
										key={option.id}
										isActive={
											option.path === path &&
											selectedBusinessUnit === currentBusinessUnit
										}
										value={option.id}
										title={option.name}
										onClick={() => onChangeService(option.path)}
									/>
								);
							})}
					</ul>
				) : null}
			</div>
		</div>
	);

	const serviceSelectors = useMemo(() => {
		return organisations.map((organisation) => {
			return {
				id: organisation.organisationId,
				name: organisation.organisationName,
				services: organisation.usedSqeservices.map((service) => {
					return {
						id: service.sqeserviceId,
						name: service.sqeserviceName,
						path: service.sqeservicePath,
					};
				}),
			};
		});
	}, [organisations]);
	const services =
		serviceSelectors.find(
			(organisation) => organisation.id === currentBusinessUnit
		)?.services ?? [];
	const serviceOptions = services
		.filter((service) => service.name.toLowerCase() !== 'sqeportal')
		.map((service) => {
			return {
				title: service.name,
				value: service.path,
			};
		});
	const organisationName = serviceSelectors.find(
		(organisation) => organisation.id === selectedBusinessUnit
	)?.name;
	const value =
		role === 'pengelola' ? path : `${organisationName} - ${activeService}`;
	const latestUpdatedAt =
		updatedAt && dayjs(updatedAt).isValid()
			? `Terakhir diperbarui: ${dayjs(updatedAt).format('DD MMM YYYY, HH:mm')}`
			: '';

	useEffect(() => {
		setCurrentBusinessUnit(selectedBusinessUnit);
	}, [selectedBusinessUnit]);

	return (
		<div className="flex justify-between">
			<div className="flex items-center">
				{role === 'pengelola' ? (
					<SpSelect
						value={value}
						size="lg"
						className="max-w-[300px]"
						prefix={<ChartIcon />}
						options={serviceOptions}
						onChange={onChangeService}
					/>
				) : (
					<SpSelect
						ref={serviceSelectorsRef}
						size="lg"
						value={value}
						className="max-w-[300px]"
						prefix={<ChartIcon />}
						content={serviceSelectorContent}
					/>
				)}
				<div className="ml-4">
					<SpMonthPicker
						value={selectedMonth}
						onMonthChange={onMonthChange}
						size="lg"
					/>
				</div>
				<Paragraph className="text-disable ml-4" weight="regular" size="r">
					{latestUpdatedAt}
				</Paragraph>
			</div>
			<Button
				variant="secondary"
				disabled={downloadDisable}
				loading={downloadLoading}
				onClick={onDownload}
			>
				<span className="mr-2">
					<DownloadIcon />
				</span>
				Unduh data
			</Button>
		</div>
	);
};

export default Filter;
