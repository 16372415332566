import type { CreateQueueRequestPayload } from 'layouts/dashboard/shared-modal/download-evidences-modal/download-modal.interfaces';
import axios from './axios-instance';

const baseUrl = '/v1/queue';

export const createQueue = async ({
	action_id,
	payload,
}: CreateQueueRequestPayload) => {
	const response = await axios.post(baseUrl, {
		action_id,
		payload,
	});

	return response.data;
};

export const getQueueStatus = async (statuses: string) => {
	const response = await axios.get(baseUrl, {
		params: {
			statuses,
		},
	});

	return response.data;
};
