import { Paragraph } from '@squantumengine/horizon';
import type { TooltipProps } from 'recharts';
import type {
	ValueType,
	NameType,
} from 'recharts/types/component/DefaultTooltipContent';

const CustomTooltip = ({
	active,
	payload,
}: TooltipProps<ValueType, NameType>) => {
	if (active && payload?.[0]?.payload?.detail) {
		const data = payload[0].payload;
		const draft = data.detail?.draft;
		const signing = data.detail?.signing;
		const stamp = data.detail?.stamp;

		return (
			<div
				className="bg-neutral-950 p-4 rounded-2xl text-white w-[311px]"
				data-testid="widgetTooltip"
			>
				<Paragraph size="l" weight="bold">{`${data.name}: ${
					data.value
				} (${data.percentage.toFixed(2)}%)`}</Paragraph>
				<ul className="list-disc ml-6 mt-2">
					{data.detail.draft >= 0 && (
						<li>
							<div className="flex justify-between">
								<Paragraph className="w-36">Belum dikirim :</Paragraph>
								<Paragraph>{draft}</Paragraph>
							</div>
						</li>
					)}
					<li>
						<div className="flex justify-between">
							<Paragraph className="w-36">Signing :</Paragraph>
							<Paragraph>{signing}</Paragraph>
						</div>
					</li>
					<li>
						<div className="flex justify-between">
							<Paragraph className="w-36">E-materai :</Paragraph>
							<Paragraph>{stamp}</Paragraph>
						</div>
					</li>
				</ul>
			</div>
		);
	}
	return null;
};

export default CustomTooltip;
