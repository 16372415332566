import { useMemo } from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
} from 'recharts';
import type { ChartProps } from './chart.interfaces';

const Chart = <T,>({ data, renderTooltip }: ChartProps<T>) => {
	const longestLabelLength = useMemo(() => {
		return data
			.map((chartData) => (chartData as { value: number }).value.toString())
			.reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0);
	}, [data]);

	return (
		<div className="w-full mt-6 h-[196px]">
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					data={data}
					barSize={8}
					barCategoryGap={4}
					margin={{ left: -32 }}
				>
					<CartesianGrid
						vertical={false}
						horizontal={true}
						strokeDasharray="6"
						stroke="#BDC4CF"
					/>
					<XAxis dataKey="name" />
					<YAxis
						tickCount={5}
						axisLine={false}
						tickLine={false}
						width={longestLabelLength * 7 + 50}
						domain={[0, 'dataMax']}
					/>
					<Tooltip
						content={(payload) => renderTooltip(payload)}
						cursor={false}
						position={{ y: 200 }}
						wrapperStyle={{
							boxShadow: '0px 4px 16px 6px #00000029',
							borderRadius: '8px',
							zIndex: 1,
						}}
					/>
					<Bar dataKey="value" fill="#006CEB" />
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

export default Chart;
