import { USER_STATUS, USER_ACTIONS } from './list.constants';
import type { UserStatus, UserActions } from './list.interfaces';

export const getUserStatus = (
	userStatus: string,
	invitationStatus: string
): UserStatus => {
	const tag = `${userStatus.toLowerCase()}-${invitationStatus.toLowerCase()}`;
	const status = USER_STATUS[tag] || USER_STATUS['default'];

	return status;
};
export const getUserActions = (
	userStatus: string,
	invitationStatus: string
): UserActions[] => {
	if (!userStatus || !invitationStatus) return USER_ACTIONS['default'];

	const tag = `${userStatus.toLowerCase()}-${invitationStatus.toLowerCase()}`;

	return USER_ACTIONS[tag] || USER_ACTIONS['default'];
};
