import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import type { TooltipProps } from 'recharts';
import type {
	ValueType,
	NameType,
} from 'recharts/types/component/DefaultTooltipContent';
import { CALCULATION_TYPES_KEY } from 'features/price/price.constants';
import { useProfileStore } from 'common/stores/profile';
import type {
	ChartData,
	GetAnalyticApiUsageResponse,
	GetAnalyticApiUsagesResponse,
} from 'common/components/analytic/analytic.interfaces';
import Tooltip from 'common/components/analytic/tooltip';
import { getValidMonthYear } from 'common/utils/date';
import {
	convertToNumericOnly,
	convertToThousandsSeparator,
} from 'common/utils/transformer';
import { generateQueryString } from 'common/utils/url';

const useAnalytic = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const organisations = useProfileStore((state) =>
		state.organisations.filter(
			(organisation) => organisation.usedSqeservices.length > 0
		)
	);

	const generateChartData = (usages: GetAnalyticApiUsageResponse[]) => {
		return usages.map((usage) => {
			return {
				name: dayjs(usage.date).format('D MMM'),
				value: usage.value,
				unitType: usage.unitType,
				additionalData: {
					dayUsages: usage.usagesPerDay.map((dayUsage) => {
						return {
							date: dayjs(dayUsage.date).format('D MMM YYYY'),
							value: convertToThousandsSeparator(dayUsage.value.toString()),
							unitType: dayUsage.unitType,
						};
					}),
				},
			};
		});
	};

	const generateUsages = (usages: GetAnalyticApiUsagesResponse[]) => {
		return usages.map((usage) => {
			let additionalTotalUsage = '';

			if (usage.isFree) {
				additionalTotalUsage = '(Gratis)';
			} else if (usage.calculationType === CALCULATION_TYPES_KEY.MULTIPLIER) {
				const rawPackage = usage.totalUsage / usage.divider;
				const packageCount = Math.ceil(rawPackage);

				additionalTotalUsage = `(${packageCount} paket)`;
			}

			return {
				...usage,
				totalUsage: convertToThousandsSeparator(usage.totalUsage.toString()),
				additionalTotalUsage,
				chartData: usage.isFree ? [] : generateChartData(usage.usagesPerWeek),
				unitType: usage.isFree ? '' : usage.unitType,
			};
		});
	};

	const renderTooltip = ({
		active,
		payload,
	}: TooltipProps<ValueType, NameType>) => {
		if (!active) return null;

		const data = (payload?.[0].payload as ChartData) ?? null;

		if (!data) return null;

		const usages = data.additionalData?.dayUsages ?? [];

		return (
			<Tooltip
				usages={usages}
				unit={data.unitType}
				totalText={`Total ${data.unitType}`}
				total={convertToThousandsSeparator(data.value.toString())}
			/>
		);
	};

	const syncFilterToUrl = ({
		selectedMonth,
		organisationId,
	}: {
		selectedMonth?: Dayjs | null;
		organisationId?: string;
	} = {}) => {
		const currentMonth = dayjs(selectedMonth).month() + 1;
		const currentYear = dayjs(selectedMonth).year();
		const monthQuery = parseInt(
			convertToNumericOnly(
				searchParams.get('month') ?? `${dayjs().month() + 1}`
			)
		);
		const yearQuery = parseInt(
			convertToNumericOnly(searchParams.get('year') ?? `${dayjs().year()}`)
		);

		const monthDate = isNaN(currentMonth) ? monthQuery : currentMonth;
		const yearDate = isNaN(currentYear) ? yearQuery : currentYear;
		const oid = organisationId ?? searchParams.get('oid');

		const { month, year } = getValidMonthYear(monthDate, yearDate);

		const params = {
			month,
			year,
			oid,
		};
		const sanitizedParams = generateQueryString(params);
		setSearchParams(sanitizedParams, { replace: true });
	};

	const selectedBusinessUnit = searchParams.get('oid') ?? '';
	const selectedBusinessUnitName =
		organisations.find(
			(organisation) => organisation.organisationId === selectedBusinessUnit
		)?.organisationName ?? '';

	return {
		selectedBusinessUnit,
		selectedBusinessUnitName,
		generateChartData,
		generateUsages,
		syncFilterToUrl,
		renderTooltip,
	};
};

export default useAnalytic;
