import { useState, useEffect } from 'react';
import { Icon, Typography, Button } from '@squantumengine/horizon';
import { Checkbox } from 'antd';
import { BILLING_STATUS } from 'features/billing-approval/billing-approval.constants';
import type { BillingStatus } from 'features/billing-approval/billing-approval.interfaces';
import SpSelect from 'common/components/sp-select';
import style from './index.module.scss';
import { BILLING_STATUS_OPTIONS } from './status-filter.constants';
import type { StatusFilterProps } from './status-filter.interfaces';

const { Paragraph } = Typography;

const StatusFilter = ({ status, onSetStatus }: StatusFilterProps) => {
	const [selectedStatus, setSelectedStatus] = useState<BillingStatus[]>([]);

	const applyStatus = () => {
		onSetStatus(selectedStatus);
	};

	const onSelectAll = () => {
		if (isSelectAll) return setSelectedStatus([]);

		setSelectedStatus(allStatus);
	};

	const onSelectStatus = (value: BillingStatus) => {
		const updatedStatus = selectedStatus.includes(value)
			? selectedStatus.filter((option) => option !== value)
			: [...selectedStatus, value];

		setSelectedStatus(updatedStatus);
	};

	const allStatus = Object.values(BILLING_STATUS);
	const isSelectAll = allStatus.length === selectedStatus.length;
	const statusValue =
		selectedStatus.length === allStatus.length
			? 'Semua status'
			: `Status (${selectedStatus.length})`;

	const statusFilterContent = () => (
		<ul className={`${style['billing-status-filter']} py-1 w-[235px]`}>
			<li className="flex px-4 h-8 items-center">
				<Checkbox
					checked={isSelectAll}
					aria-label="Select all status"
					indeterminate={!isSelectAll && selectedStatus.length > 0}
					onChange={onSelectAll}
				>
					<Paragraph>Semua status</Paragraph>
				</Checkbox>
			</li>
			{BILLING_STATUS_OPTIONS.map((option) => (
				<li className="flex px-4 h-8 items-center" key={option.value}>
					<Checkbox
						checked={selectedStatus.includes(option.value)}
						onChange={() => onSelectStatus(option.value)}
					>
						<Paragraph>{option.title}</Paragraph>
					</Checkbox>
				</li>
			))}
			<li className="py-3 px-4">
				<Button
					disabled={selectedStatus.length === 0}
					full
					size="sm"
					onClick={applyStatus}
				>
					Terapkan
				</Button>
			</li>
		</ul>
	);

	useEffect(() => {
		setSelectedStatus(status);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	return (
		<SpSelect
			placement="bottomLeft"
			size="lg"
			value={statusValue}
			suffix={<Icon name="chevron-large-down" color="currentColor" />}
			content={statusFilterContent}
		/>
	);
};

export default StatusFilter;
