import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PrinterOutlined } from '@ant-design/icons';
import { Button, Typography } from '@squantumengine/horizon';
import { Descriptions, Divider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import DownloadDropdown from 'features/invoice-management/download-dropdown';
import ToastMessage from 'common/utils/toast-message';
import {
	camelize,
	capitalizeEachWord,
	convertToThousandsSeparator,
} from 'common/utils/transformer';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { getDetail } from 'services/invoice';
import './index.scss';
import AdditionalCost from './additional-cost';
import type { InvoiceDetail } from './invoice-preview.interfaces';
import Product from './product';
import RowTitle from './row-title';
import SkeletonLoader from './skeleton';

dayjs.locale('en');

const { Paragraph, Title } = Typography;

const InvoicePreview = () => {
	const { organisationId } = useParams<{ organisationId: string }>();
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const usagePeriod = searchParams.get('usage_period');
	const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetail>();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!organisationId || !usagePeriod) {
			navigate('/404', { replace: true });
		}
		getInvoiceDetail();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { refetch: getInvoiceDetail } = useQuery({
		queryKey: ['getInvoiceDetail', organisationId, usagePeriod],
		queryFn: () =>
			getDetail({
				organisation_id: organisationId as string,
				usage_period: usagePeriod as string,
			}),
		onSuccess: (data) => {
			const details = data?.data ?? null;

			if (!details) {
				navigate('/maintenance', { replace: true });
				return;
			}

			const sanitizedResponse = camelize(details) as InvoiceDetail;
			setInvoiceDetails(sanitizedResponse);
			setIsLoading(false);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});

			navigate('/maintenance', { replace: true });
		},
	});

	const additions = invoiceDetails?.summary?.additions ?? [];
	const hasNonTaxableProduct = invoiceDetails?.containNonTaxableGoods ?? false;
	const invoiceTitle =
		invoiceDetails?.usageStartAt && invoiceDetails?.usageEndAt
			? `Invoice ${dayjs(invoiceDetails?.usageStartAt).format('D')} - ${dayjs(
					invoiceDetails?.usageEndAt
			  ).format('D MMMM YYYY')}`
			: `Invoice ${dayjs(usagePeriod).format('MMMM YYYY')}`;
	const reductions = invoiceDetails?.summary?.reductions ?? [];
	const title = `${invoiceDetails?.buyerName ?? 'Preview Invoice'} ${dayjs(
		usagePeriod
	).format('MM/YY')}`;
	const taxSummary = `FINAL BILL AMOUNT (${
		hasNonTaxableProduct ? 'Non-Taxable Product + ' : ''
	}Tax Base + Tax)`;
	const billingTo = invoiceDetails?.companyName
		? `${invoiceDetails.companyName} (${invoiceDetails?.buyerName})`
		: invoiceDetails?.buyerName;

	return (
		<>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			{isLoading && <SkeletonLoader />}
			{!isLoading && (
				<div className="pb-[80px] pt-[34px]">
					<div className="mx-auto w-[1080px]" id="print">
						<div className="flex justify-end mb-10" id="not-print">
							<div className="flex gap-3">
								<Button variant="secondary" onClick={() => window.print()}>
									<span className="mr-2">
										<PrinterOutlined />
									</span>
									Cetak
								</Button>
								<DownloadDropdown
									ids={[organisationId as string]}
									date={dayjs(usagePeriod)}
									selectedName={invoiceDetails?.buyerName ?? ''}
								/>
							</div>
						</div>
						<div className="flex justify-between items-center">
							<img src="/assets/icons/sqe-logo.svg" alt="logo" />
							<Paragraph
								className="w-[218px] text-right"
								size="r"
								weight="regular"
							>
								{invoiceDetails?.issuerAddress}
							</Paragraph>
						</div>
						<Divider className="my-6 border-stroke-primary" />
						<Title level={4} className="mb-6">
							{invoiceTitle}
						</Title>
						<Descriptions
							labelStyle={{
								color: '#677485',
								fontSize: '14px',
							}}
							className="w-[452px] mb-6"
							size="small"
							column={1}
							colon={false}
						>
							<Descriptions.Item
								label={
									<>
										<div className="w-[160px]">Invoice number</div>
										<div>:</div>
									</>
								}
							>
								{invoiceDetails?.invoiceNo ?? ''}
							</Descriptions.Item>
							<Descriptions.Item
								label={
									<>
										<div className="w-[160px]">Invoice date</div>
										<div>:</div>
									</>
								}
							>
								{dayjs(invoiceDetails?.issueDate).format('MMMM Do YYYY')}
							</Descriptions.Item>
							<Descriptions.Item
								label={
									<>
										<div className="w-[160px]">Billing to</div>
										<div>:</div>
									</>
								}
							>
								{billingTo}
							</Descriptions.Item>
							<Descriptions.Item
								label={
									<>
										<div className="w-[160px]">Billing address</div>
										<div>:</div>
									</>
								}
							>
								{invoiceDetails?.buyerAddress}
							</Descriptions.Item>
							<Descriptions.Item
								label={
									<>
										<div className="w-[160px]">NPWP</div>
										<div>:</div>
									</>
								}
							>
								{invoiceDetails?.buyerNpwp ?? ''}
							</Descriptions.Item>
						</Descriptions>
						<Product
							services={invoiceDetails?.details ?? []}
							gross={invoiceDetails?.summary?.totalGross ?? 0}
						/>
						{hasNonTaxableProduct && (
							<div className="mt-4">
								<Paragraph
									className="text-neutral-500"
									size="r"
									weight="regular"
								>
									* Non-taxable product
								</Paragraph>
							</div>
						)}
						<div className="mt-6">
							<AdditionalCost
								title="Adjustment"
								titleValue="Amount IDR"
								additionalCosts={reductions}
								summaryTitle="TOTAL AFTER ADJUSTMENT"
								summaryValue={
									convertToThousandsSeparator(
										(
											invoiceDetails?.summary?.totalAfterReductions ?? 0
										).toString()
									) || '0'
								}
							>
								<>
									{hasNonTaxableProduct && (
										<RowTitle
											title="Non-taxable product (include adjustment)"
											rowClassName="p-4"
											bold={false}
											useDivider
											value={
												convertToThousandsSeparator(
													(
														invoiceDetails?.summary?.totalNonTaxableGoods ?? 0
													).toString()
												) || '0'
											}
										/>
									)}
									<RowTitle
										title="TAX BASE"
										rowClassName="p-4 bg-neutral-50"
										value={
											convertToThousandsSeparator(
												(invoiceDetails?.summary?.totalTaxBase ?? 0).toString()
											) || '0'
										}
									/>
								</>
							</AdditionalCost>
						</div>
						<div className="mt-6">
							<AdditionalCost
								title="Tax"
								titleValue="Amount IDR"
								additionalCosts={additions}
								summaryTitle={taxSummary}
								summaryValue={
									convertToThousandsSeparator(
										(invoiceDetails?.totalPrice ?? 0).toString()
									) || '0'
								}
							>
								{additions.length === 0 && (
									<RowTitle
										title={taxSummary}
										rowClassName="p-4 bg-neutral-50"
										value={
											convertToThousandsSeparator(
												(invoiceDetails?.totalPrice ?? 0).toString()
											) || '0'
										}
									/>
								)}
							</AdditionalCost>
						</div>
						<Descriptions
							labelStyle={{
								color: '#677485',
								fontSize: '14px',
							}}
							className="my-6"
							size="small"
							column={1}
							colon={false}
						>
							<Descriptions.Item
								label={
									<>
										<Paragraph
											className="w-[160px] text-primary"
											size="r"
											weight="bold"
										>
											In Words
										</Paragraph>
										<div>:</div>
									</>
								}
							>
								<Paragraph className="text-primary" size="r" weight="bold">
									{invoiceDetails?.totalPriceInWords || '-'}
								</Paragraph>
							</Descriptions.Item>
							<Descriptions.Item
								label={
									<>
										<Paragraph
											className="w-[160px] text-primary"
											size="r"
											weight="bold"
										>
											Due Date
										</Paragraph>
										<div>:</div>
									</>
								}
								className="font-bold"
							>
								{dayjs(invoiceDetails?.dueDate).format('MMMM Do YYYY')}
							</Descriptions.Item>
							<Descriptions.Item
								label={
									<>
										<Paragraph className="w-[160px]" size="r" weight="regular">
											Account Name
										</Paragraph>
										<div>:</div>
									</>
								}
							>
								{invoiceDetails?.issuerName}
							</Descriptions.Item>
							<Descriptions.Item
								label={
									<>
										<Paragraph className="w-[160px]" size="r" weight="regular">
											Bank Name / Branch
										</Paragraph>
										<div>:</div>
									</>
								}
							>
								{invoiceDetails?.paymentChannelName}
							</Descriptions.Item>
							<Descriptions.Item
								label={
									<>
										<Paragraph className="w-[160px]" size="r" weight="regular">
											Account Number
										</Paragraph>
										<div>:</div>
									</>
								}
							>
								{invoiceDetails?.paymentAccountNo}
							</Descriptions.Item>
						</Descriptions>
						<Paragraph size="r" weight="regular">
							PT Semesta Quantum Eterniti reserves the right to hold service of
							any unpaid invoice beyound the terms of payment*
						</Paragraph>
						<div className="mb-24 mt-6 flex h-[200px] justify-end">
							<div className="flex flex-col justify-between h-full">
								<Paragraph size="r" weight="regular">
									Jakarta, {dayjs().format('MMMM Do YYYY')}
								</Paragraph>
								<div className="flex flex-col">
									<Paragraph size="l" weight="bold">
										{capitalizeEachWord(
											invoiceDetails?.businessOpsPicName ?? ''
										)}
									</Paragraph>
									<Paragraph size="r" weight="regular">
										Business Operation
									</Paragraph>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default InvoicePreview;
