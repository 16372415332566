import { Typography } from '@squantumengine/horizon';
import classNames from 'classnames';
import { IMAGE_SIZES } from './info-panel.constants';
import type { InfoPanelProps } from './info-panel.interfaces';

const { Title, Paragraph } = Typography;

const InfoPanel = ({
	description,
	imgSource,
	imgAlt,
	title,
	imgSize = IMAGE_SIZES.NORMAL,
}: InfoPanelProps) => {
	const imgClass = classNames('w-auto', {
		'h-[340px]': imgSize === IMAGE_SIZES.NORMAL,
		'h-[150px]': imgSize === IMAGE_SIZES.SMALL,
	});

	return (
		<div className="flex flex-col items-center">
			<img src={imgSource} className={imgClass} alt={imgAlt}></img>
			<div className="max-w-[504px] mt-11 flex flex-col items-center info-panel">
				<Title level={4} className="text-center">
					{title}
				</Title>
				<Paragraph
					className="text-center mt-4 text-secondary"
					size="r"
					weight="regular"
				>
					{description}
				</Paragraph>
			</div>
		</div>
	);
};

export default InfoPanel;
