import { Icon } from '@squantumengine/horizon';
import { DatePicker } from 'antd';
import local from 'antd/es/date-picker/locale/id_ID';
import classNames from 'classnames';
import dayjs from 'dayjs';
import type { SpMonthPickerProps } from './sp-month-picker.interfaces';

const SpMonthPicker = ({
	value,
	size,
	onMonthChange,
	full,
	placeholder,
}: SpMonthPickerProps) => {
	const containerClassName = classNames(
		'custom-monthpicker pl-3 text-body-r-regular border-stroke-primary rounded-lg hover:border-stroke-primary',
		{
			'h-8': size === 'md',
			'h-10': size === 'lg',
			'h-12': size === 'xl',
			'w-full': full,
			'max-w-[172px] w-[172px]': !full,
		}
	);

	return (
		<DatePicker
			placeholder={placeholder ?? 'Pilih tanggal'}
			suffixIcon={<Icon name="calendar" />}
			allowClear={false}
			superNextIcon={<Icon name="chevron-small-right" />}
			superPrevIcon={<Icon name="chevron-small-left" />}
			picker="month"
			format="MMMM YYYY"
			onChange={onMonthChange}
			value={value}
			locale={local}
			disabledDate={(current) => {
				return current && current > dayjs().endOf('month');
			}}
			className={containerClassName}
			getPopupContainer={(triggerNode) =>
				triggerNode.parentElement as HTMLElement
			}
			popupClassName="shadow-base rounded-lg"
		/>
	);
};

export default SpMonthPicker;
