import type { AxiosError } from 'axios';
import ToastMessage from 'common/utils/toast-message';
import type { ErrorResponse } from 'common/types/global';
import {
	ERROR_CODE_MESSAGE,
	GENERAL_ERROR_MESSAGE,
} from 'common/constants/errors';

export const generateErrorMessage = (
	error: AxiosError<ErrorResponse>,
	defaultMessage: string
) => {
	const errorCode = error?.response?.data?.code ?? '';
	let message = '';

	if (errorCode) {
		message = ERROR_CODE_MESSAGE[errorCode] ?? GENERAL_ERROR_MESSAGE;
	} else {
		message = defaultMessage;
	}

	ToastMessage({
		type: 'error',
		label: message,
	});
};
