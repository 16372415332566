import { Typography } from '@squantumengine/horizon';
import { Pagination, Select } from 'antd';
import style from './index.module.scss';
import { PAGE_OPTIONS } from './sp-pagination.constants';
import type { PaginationProps } from './sp-pagination.interfaces';

const { Paragraph } = Typography;

const SpPagination = ({
	currentPage,
	pageSize,
	leftSection,
	totalCount,
	onChangePageSize,
	onChangeCurrentPage,
}: PaginationProps) => {
	return (
		<div className="relative flex w-full justify-center items-center py-4">
			<div className="absolute left-0">{leftSection ?? null}</div>
			<Pagination
				className={style.pagination}
				current={currentPage}
				pageSize={pageSize}
				total={totalCount}
				showSizeChanger={false}
				showQuickJumper={false}
				onChange={onChangeCurrentPage}
			/>
			<div className="flex items-center absolute right-0">
				<Paragraph weight="regular" size="r">
					Tampilan per halaman
				</Paragraph>
				<Select
					aria-label="Pagination limit"
					className={`ml-3 ${style['pagination-select']}`}
					onChange={onChangePageSize}
					value={pageSize}
					options={PAGE_OPTIONS}
				/>
			</div>
		</div>
	);
};

export default SpPagination;
