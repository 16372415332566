import { create } from 'zustand';
import type {
	BusinessUnitSliceActions,
	BusinessUnitSliceState,
} from './business-unit.interfaces';

const initialState: BusinessUnitSliceState = {
	formStep: 1,
	isUseStepperGuard: true,
	isLoadingDetails: true,
	isDataChanged: false,
	organisationForm: null,
};

export const useBusinessUnitStore = create<
	BusinessUnitSliceState & BusinessUnitSliceActions
>()((set, get) => ({
	...initialState,
	setOrganisationForm: (organisationForm) => {
		set({
			organisationForm,
		});
	},
	setFormStep: (value: number) => {
		set({
			formStep: value,
		});
	},
	setIsDataChanged: (value: boolean) => {
		set({
			isDataChanged: value,
		});
	},
	setIsLoadingDetails: (value: boolean) => {
		set({
			isLoadingDetails: value,
		});
	},
	setIsUseStepperGuard: (value: boolean) => {
		set({
			isUseStepperGuard: value,
		});
	},
}));
