import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.3904 3.3335H4.58312C3.46333 3.3335 2.89041 4.68766 3.6977 5.46891L7.91645 9.68766V14.1668C7.91645 14.5835 8.09874 14.9481 8.41124 15.2085L10.0779 16.4585C10.8852 17.0054 12.0831 16.4845 12.0831 15.4429V9.68766L16.2758 5.46891C17.0831 4.68766 16.5102 3.3335 15.3904 3.3335ZM10.8331 9.16683V15.4168L9.16645 14.1668V9.16683L4.58312 4.5835H15.4165L10.8331 9.16683Z"
			fill="#1E252E"
		/>
	</svg>
);

const SortIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default SortIcon;
