import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<img src="/assets/icons/ellipsis.svg" alt="Ellipsis icon" />
);

const EllipsisIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default EllipsisIcon;
