import { Fragment } from 'react';
import { Divider } from '@squantumengine/horizon';
import Card from 'layouts/dashboard/notification-center/card';
import CardSkeleton from 'layouts/dashboard/notification-center/card/skeleton';
import Empty from 'layouts/dashboard/notification-center/empty';
import Error from 'layouts/dashboard/notification-center/error';
import Header from 'layouts/dashboard/notification-center/header';
import type { MainProps } from './main.interfaces';
import Skeleton from './skeleton';

const Main = <T,>(props: MainProps<T>) => {
	const isContainHeaderContent = 'headerContent' in props;
	const isContainSkeletonContent = 'skeletonContent' in props;
	const isContainErrorContent = 'errorContent' in props;
	const isContainEmptyContent = 'emptyContent' in props;
	const isContainFilterContent = 'filterContent' in props;
	const isContainNotificationContent = 'notificationContent' in props;
	const isShowEmptyElement =
		!props.isError && props.notifications.length === 0 && !props.isShowSkeleton;
	const isShowErrorElement = props.isError && !props.isShowSkeleton;
	const isShowNotificationsElement =
		!props.isError && props.notifications.length > 0 && !props.isShowSkeleton;

	return (
		<div className="flex flex-col w-[388px]">
			{props.isShowSkeleton &&
				isContainSkeletonContent &&
				props.skeletonContent}
			{props.isShowSkeleton && !isContainSkeletonContent && <Skeleton />}
			{isContainHeaderContent && !props.isShowSkeleton && props.headerContent}
			{!isContainHeaderContent && !props.isShowSkeleton && (
				<Header
					notifCount={props.notifications.length}
					unreadCount={props.unreadCount}
					isError={props.isError}
					isHeaderActionOpen={props.isHeaderActionOpen}
					showDeleteAction={props.showDeleteAction}
					onToggleHeaderAction={props.onToggleHeaderAction}
					onReadNotifications={props.onReadNotifications}
					onDeleteAction={props.onDeleteAction}
				/>
			)}
			{isContainFilterContent && props.filterContent}
			{isContainNotificationContent &&
				isShowNotificationsElement &&
				props.notificationContent}
			{!isContainNotificationContent && isShowNotificationsElement && (
				<ul
					className="max-h-[300px] overflow-y-auto"
					aria-label="Notification list"
					onScroll={props.onScrollNotifications}
				>
					{props.notifications.map((notification, index) => (
						<Fragment key={notification.id}>
							<Card
								id={notification.id}
								badge={notification.icon}
								isSeen={notification.isSeen}
								subject={notification.subject}
								messages={notification.messages}
								eventTime={notification.eventTime}
							/>
							{index + 1 < props.notifications.length && (
								<Divider className="m-0 border-stroke-secondary" />
							)}
						</Fragment>
					))}
					{props.isShowLoadMoreSkeleton && <CardSkeleton />}
				</ul>
			)}
			{isContainEmptyContent && isShowEmptyElement && props.emptyContent}
			{!isContainEmptyContent && isShowEmptyElement && <Empty />}
			{isContainErrorContent && isShowErrorElement && props.errorContent}
			{!isContainErrorContent && isShowErrorElement && (
				<Error refreshNotifications={props.refreshNotifications} />
			)}
		</div>
	);
};

Main.Card = Card;

export default Main;
