import type {
	PostOrganisationApiPayload,
	PostOrganisationStatusApiPayload,
} from 'features/business-unit/business-unit.interfaces';
import type {
	GetOrganisationParams,
	GetOrganisationServiceParams,
} from 'common/types/organisation';
import axios from './axios-instance';

const baseUrl = '/v1/organisation';

export const getOrganisation = async (params: GetOrganisationParams) => {
	const response = await axios.get(baseUrl, {
		params,
	});

	return response.data;
};
export const getOrganisationServices = async (
	id: string,
	params: GetOrganisationServiceParams
) => {
	const response = await axios.get(`/v1/organisation-service/${id}`, {
		params,
	});

	return response.data;
};
export const getOrganisationDetails = async (id: string) => {
	const response = await axios.get(`${baseUrl}/${id}/detail`);
	return response.data;
};
export const updateOrganisationStatus = async ({
	id,
	payload,
}: {
	id: string;
	payload: PostOrganisationStatusApiPayload;
}) => {
	const response = await axios.post(`${baseUrl}/${id}`, {
		data: {
			...payload,
		},
	});
	return response.data;
};
export const upsertOrganisation = async (
	payload: PostOrganisationApiPayload
) => {
	const response = await axios.post(`${baseUrl}/upsert`, payload);
	return response.data;
};
