import { Label, Typography } from '@squantumengine/horizon';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import EmptyData from 'common/components/empty-data';
import type { ServicesProps } from './services.interfaces';

const { Paragraph } = Typography;

const Services = ({ services }: ServicesProps) => {
	return (
		<div className="mt-8">
			<Paragraph weight="bold" size="l" className="mb-6">
				Daftar Service
			</Paragraph>
			<Row className="border-b border-stroke-secondary">
				<Col span={16} className="px-4 py-3 text-body-r-semibold">
					Nama service
				</Col>
				<Col span={4} className="px-4 py-3 text-body-r-semibold">
					Update terakhir
				</Col>
				<Col span={4} className="px-4 py-3 text-body-r-semibold">
					Tipe harga
				</Col>
			</Row>
			{services.length > 0 ? (
				services.map((service) => (
					<Row
						className="border-b border-stroke-secondary"
						key={service.sqeserviceId}
					>
						<Col
							span={16}
							className="p-4 text-body-r-regular overflow-hidden text-ellipsis"
						>
							{service.sqeserviceName}
						</Col>
						<Col
							span={4}
							className="p-4 text-body-r-regular overflow-hidden text-ellipsis"
						>
							{service.servicePriceUpdatedAt
								? dayjs(service.servicePriceUpdatedAt).format('D/MM/YYYY')
								: '-'}
						</Col>
						<Col
							span={4}
							className="p-4 text-body-r-regular overflow-hidden text-ellipsis"
						>
							<Label
								label={
									service.useCustomPrice ? 'Harga Kustom' : 'Harga Standar'
								}
								type={service.useCustomPrice ? 'warning' : 'success'}
								border
							/>
						</Col>
					</Row>
				))
			) : (
				<div className="py-8 bg-neutral-50">
					<EmptyData text="Belum ada service yang ditampilkan." />
				</div>
			)}
		</div>
	);
};

export default Services;
