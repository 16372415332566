import { Typography } from '@squantumengine/horizon';
import dayjs from 'dayjs';
import RedoIcon from 'common/components/icons/redo';

const { Paragraph } = Typography;

const EmeteraiWidgetRecap = ({
	getQuota,
	dateTime,
}: {
	getQuota: () => void;
	dateTime: string;
}) => {
	return (
		<div className="flex flex-col h-full">
			<div className="flex justify-between">
				<div className="flex flex-col">
					<Paragraph weight="bold" size="r">
						Sisa kuota e-materai
					</Paragraph>
					<div className=" flex items-center">
						<Paragraph
							weight="regular"
							size="s"
							className="text-secondary text-[10px] leading-[14px]"
						>
							Terakhir diperbarui:
						</Paragraph>

						<Paragraph
							weight="regular"
							size="s"
							className="text-secondary text-[10px] leading-[16px] ml-1"
						>
							{dayjs(dateTime).isValid()
								? dayjs(dateTime).format('DD/MM/YYYY HH:mm')
								: '-'}
						</Paragraph>
					</div>
				</div>

				<div
					className="w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-neutral-0 cursor-pointer"
					aria-label="Redo icon"
					onClick={getQuota}
				>
					<RedoIcon />
				</div>
			</div>
		</div>
	);
};

export default EmeteraiWidgetRecap;
