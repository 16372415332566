import { Typography } from '@squantumengine/horizon';

const { Title } = Typography;

const Version = () => {
	const packageData = require('../../package.json');
	const version = packageData?.version;

	return (
		<div className="flex items-center justify-center h-full w-full">
			<div className="bg-blue-500 w-full flex items-center justify-center py-4">
				<Title level={4} className="text-neutral-0">
					Client version: {version}
				</Title>
			</div>
		</div>
	);
};

export default Version;
