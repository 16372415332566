import { lazy, Suspense } from 'react';
import { useBusinessUnitStore } from 'features/business-unit/business-unit.store';
import SectionHeader from 'common/components/section-header';
import SpSkeleton from 'common/components/sp-skeleton';
import { getRegistrationState } from './form.utils';
import InfoSkeleton from './info/skeleton';
import InvoiceSettingSkeleton from './invoice-setting/skeleton';
import PICSkeleton from './pic/skeleton';
import ServiceSkeleton from './service/skeleton';
import Stepper from './stepper';
import useBusinessUnitForm from './use-business-unit-form';

const Info = lazy(
	() => import(/* webpackChunkName: "businessUnitInfoStep" */ './info')
);
const Pic = lazy(
	() => import(/* webpackChunkName: "businessUnitPicStep" */ './pic')
);
const Service = lazy(
	() => import(/* webpackChunkName: "businessUnitServiceStep" */ './service')
);
const InvoiceSetting = lazy(
	() =>
		import(
			/* webpackChunkName: "businessUnitInvoiceSettingStep" */ './invoice-setting'
		)
);

const Form = () => {
	const {
		contextHolder,
		formStep,
		isUseStepperGuard,
		isDataChanged,
		backToPreviousPage,
		showConfirmationModal,
	} = useBusinessUnitForm();

	const isLoadingDetails = useBusinessUnitStore(
		(state) => state.isLoadingDetails
	);

	const onBackToPreviousPage = () => {
		const organisationDraft = getRegistrationState();

		const organisationName = organisationDraft?.organisation?.name ?? '';
		const organisationCompanyName =
			organisationDraft?.organisation?.companyName ?? '';
		const organisationNpwp = organisationDraft?.organisation?.npwp ?? '';
		const organisationAddress = organisationDraft?.organisation?.address ?? '';
		const organisationInvoiceAddress =
			organisationDraft?.organisation?.invoiceAddress ?? '';
		const pics = organisationDraft?.pics ?? [];
		const additionSettings =
			organisationDraft?.invoice_component_settings?.additions ?? [];
		const substractionSettings =
			organisationDraft?.invoice_component_settings?.substractions ?? [];

		const isDataExists = Boolean(
			organisationName ||
				organisationCompanyName ||
				organisationNpwp ||
				organisationAddress ||
				organisationInvoiceAddress ||
				pics.length ||
				additionSettings.length ||
				substractionSettings.length
		);
		const isStorageExists = isDataExists && isUseStepperGuard;

		if (isDataChanged || isStorageExists) {
			return showConfirmationModal(() =>
				backToPreviousPage({
					deleteStorage: isStorageExists,
				})
			);
		}

		backToPreviousPage();
	};

	const sectionTitle = `${isUseStepperGuard ? 'Tambah' : 'Edit'} business unit`;

	return (
		<>
			<SectionHeader backHandler={onBackToPreviousPage} title={sectionTitle} />
			<div className="flex mt-6">
				<div className="w-[360px] shrink-0 ">
					<div className="p-6 bg-neutral-0 rounded-lg shadow-base flex">
						{isLoadingDetails ? (
							<SpSkeleton className="h-[420px] w-full"></SpSkeleton>
						) : (
							<Stepper></Stepper>
						)}
					</div>
				</div>
				<div className="w-[calc(100%-384px)] ml-6">
					<Suspense fallback={<InfoSkeleton />}>
						{formStep === 1 && <Info />}
					</Suspense>
					<Suspense fallback={<PICSkeleton />}>
						{formStep === 2 && <Pic />}
					</Suspense>
					<Suspense fallback={<ServiceSkeleton />}>
						{formStep === 3 && <Service />}
					</Suspense>
					<Suspense fallback={<InvoiceSettingSkeleton />}>
						{formStep === 4 && <InvoiceSetting />}
					</Suspense>
				</div>
			</div>
			{contextHolder}
		</>
	);
};

export default Form;
