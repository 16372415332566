import { Label } from '@squantumengine/horizon';
import type { ColumnsType } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import { useModalContext } from 'common/context/shared-modal';
import type { SortBy } from 'common/types';
import dayjs from 'dayjs';
import type { WeblinkSortKeys } from 'features/dashboard/know-your-customer/detail/signer-detail.interfaces';
import FieldColumn from 'features/dashboard/know-your-customer/field-column';
import { EMATERAI_STATUS_PROPS } from 'features/dashboard/know-your-customer/kyc-dashboard.constants';
import style from 'features/dashboard/know-your-customer/table/index.module.scss';
import SpPagination from 'common/components/sp-pagination';
import SpTable from 'common/components/sp-table';
import type {
	NonWeblinkList,
	NonWeblinkTableProps,
} from './non-weblink.interfaces';

const NonWeblinkTable = ({
	data,
	isLoading,
	onChangeCurrentPage,
	onChangePageSize,
	onSort,
	limit,
	refUserId,
	page,
	totalCount,
}: NonWeblinkTableProps) => {
	const columns: ColumnsType<NonWeblinkList> = [
		{
			title: 'Tanggal diunggah',
			dataIndex: 'contractCreatedAt',
			key: 'contract_created_at',
			sorter: true,
			width: 200,
			className: 'min-w-[200px]',
			render: (_, { contractCreatedAt }) => (
				<FieldColumn
					title={dayjs(contractCreatedAt).format('DD MMM YYYY')}
					description={dayjs(contractCreatedAt).format('HH:mm:ss')}
				/>
			),
		},
		{
			title: 'Tanggal diperbarui',
			dataIndex: 'contractUpdatedAt',
			key: 'contract_updated_at',
			sorter: true,
			width: 200,
			className: 'min-w-[200px]',
			render: (_, { contractUpdatedAt }) => (
				<FieldColumn
					title={dayjs(contractUpdatedAt).format('DD MMM YYYY')}
					description={dayjs(contractUpdatedAt).format('HH:mm:ss')}
				/>
			),
		},
		{
			title: 'Kode Nasabah',
			dataIndex: 'customerCode',
			width: 200,
			className: 'min-w-[200px]',
			render: (_, { customerCode }) => <FieldColumn title={customerCode} />,
		},
		{
			title: 'Nama dokumen',
			dataIndex: 'customerName',
			width: 200,
			className: 'min-w-[200px]',
			render: (_, { contractName, refContractId }) => (
				<FieldColumn
					title={contractName}
					description={`ID: ${refContractId}`}
				/>
			),
		},
		{
			title: 'Status Tanda Tangan',
			dataIndex: 'signingStatus',
			width: 200,
			className: 'min-w-[200px]',
			render: (_, { signingStatus }) => {
				const signerProps =
					EMATERAI_STATUS_PROPS[signingStatus] ?? EMATERAI_STATUS_PROPS.DEFAULT;

				return (
					<div className="flex items-center justify-between">
						<Label label={signerProps.label} type={signerProps.type} border />
					</div>
				);
			},
		},
	];

	const onTableChange = (sorter: SorterResult<NonWeblinkList>) => {
		const by =
			sorter.order && sorter.columnKey
				? (sorter.columnKey as WeblinkSortKeys)
				: ('contract_created_at' as WeblinkSortKeys);

		let order = 'desc' as SortBy;

		if (sorter.order) order = sorter.order === 'ascend' ? 'asc' : order;

		onSort({
			by,
			order,
		});
	};

	const { toggleSharedModal } = useModalContext();

	const openStatusModal = (contractId: string, refUserId: string) => {
		toggleSharedModal({
			name: 'signerStatus',
			payload: {
				isOpen: true,
				data: {
					weblinkId: '',
					contractId,
					refUserId,
				},
			},
		});
	};

	return (
		<>
			<SpTable
				isLoading={isLoading}
				className={style.table}
				columns={columns}
				dataSource={data}
				bordered
				defaultTable
				scroll={{
					x: 900,
				}}
				rowClassName="cursor-pointer"
				onRow={({ contractId }) => {
					return {
						onClick: () => openStatusModal(contractId, refUserId),
					};
				}}
				onChange={
					/* istanbul ignore next */
					(_pagination, _filters, sorter) =>
						onTableChange(sorter as SorterResult<NonWeblinkList>)
				}
				pagination={false}
			/>
			{data.length && !isLoading ? (
				<div className="px-6">
					<SpPagination
						currentPage={page}
						pageSize={limit}
						totalCount={totalCount}
						onChangePageSize={onChangePageSize}
						onChangeCurrentPage={onChangeCurrentPage}
					/>
				</div>
			) : null}
		</>
	);
};

export default NonWeblinkTable;
