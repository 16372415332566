import SpChip from 'common/components/sp-chip';
import type { ServiceChipProps } from './service-chip.interfaces';

const ServiceChip = ({
	className = '',
	services,
	activeService,
	setActiveService,
}: ServiceChipProps) => {
	return (
		<div className={`flex gap-3 ${className}`}>
			{services.map((service) => (
				<SpChip
					key={service.sqeserviceId}
					active={activeService === service.sqeserviceId}
					label={service.sqeserviceName}
					onClick={() => setActiveService(service.sqeserviceId)}
				/>
			))}
		</div>
	);
};

export default ServiceChip;
