import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M24.9585 20.8284C24.1668 19.9534 22.6252 18.6617 22.6252 14.37C22.6252 11.1617 20.3752 8.57837 17.2918 7.9117V7.0367C17.2918 6.32837 16.7085 5.70337 16.0002 5.70337C15.2502 5.70337 14.6668 6.32837 14.6668 7.0367V7.9117C11.5835 8.57837 9.3335 11.1617 9.3335 14.37C9.3335 18.6617 7.79183 19.9534 7.00016 20.8284C6.75016 21.0784 6.62516 21.4117 6.66683 21.7034C6.66683 22.4117 7.16683 23.0367 8.00016 23.0367H23.9585C24.7918 23.0367 25.2918 22.4117 25.3335 21.7034C25.3335 21.4117 25.2085 21.0784 24.9585 20.8284ZM9.4585 21.0367C10.3335 19.9117 11.2918 17.9534 11.3335 14.4117C11.3335 14.4117 11.3335 14.4117 11.3335 14.37C11.3335 11.8284 13.4168 9.70337 16.0002 9.70337C18.5418 9.70337 20.6668 11.8284 20.6668 14.37C20.6668 14.4117 20.6252 14.4117 20.6252 14.4117C20.6668 17.9534 21.6252 19.9117 22.5002 21.0367H9.4585ZM16.0002 27.0367C17.4585 27.0367 18.6252 25.87 18.6252 24.37H13.3335C13.3335 25.87 14.5002 27.0367 16.0002 27.0367Z"
			fill="#29313B"
		/>
	</svg>
);

const BellIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} aria-label="Bell icon" />
);

export default BellIcon;
