import classNames from 'classnames';
import SpSkeleton from 'common/components/sp-skeleton';

const Skeleton = ({ isVertical }: { isVertical?: boolean }) => {
	const verticalClass = classNames('flex items-center', {
		'flex-col mt-6': isVertical,
		'flex-row mt-10': !isVertical,
	});
	const chartClass = classNames('rounded-full shrink-0', {
		'ml-4': !isVertical,
		'w-[150px] h-[150px]': isVertical,
		'w-[222px] h-[222px]': !isVertical,
	});
	const skeletonClass = classNames('h-5 ', {
		'mt-1': isVertical,
		'first:mt-0 mt-1 w-[200px]': !isVertical,
	});
	const skeletonContainerClass = classNames('flex flex-col w-full', {
		'ml-6': !isVertical,
		'mt-6': isVertical,
	});
	const titleSkeletonClass = classNames('mt-1 w-full', {
		'h-8': isVertical,
		'h-4': !isVertical,
	});

	return (
		<div className="w-full p-4 flex flex-col bg-neutral-0 rounded-lg shadow-base h-full">
			<SpSkeleton className="h-5 w-[100px]" />
			<SpSkeleton className={titleSkeletonClass} />
			<div className={verticalClass}>
				<SpSkeleton className={chartClass} />
				<div className={skeletonContainerClass}>
					<SpSkeleton className={skeletonClass} />
					<SpSkeleton className={skeletonClass} />
					<SpSkeleton className={skeletonClass} />
					{isVertical ? null : <SpSkeleton className={skeletonClass} />}
				</div>
			</div>
		</div>
	);
};

export default Skeleton;
