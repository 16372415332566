import { Typography } from '@squantumengine/horizon';
import { Checkbox } from 'antd';
import style from './index.module.scss';
import type { SpCheckboxProps } from './sp-checkbox.interfaces';

const { Paragraph } = Typography;

const SpCheckbox = ({
	indeterminate = false,
	children,
	label,
	checked,
	onChange,
}: SpCheckboxProps) => {
	return (
		<Checkbox
			checked={checked}
			indeterminate={indeterminate}
			onChange={onChange}
			onClick={(e) => e.stopPropagation()}
			className={style['sp-checkbox']}
		>
			{label && (
				<Paragraph className="ml-1 text-blue-500 pt-[2px]" weight="semibold">
					{label}
				</Paragraph>
			)}
			{children}
		</Checkbox>
	);
};

export default SpCheckbox;
