import { Fragment } from 'react';
import { Divider } from 'antd';
import CardSkeleton from 'layouts/dashboard/notification-center/card/skeleton';
import SpSkeleton from 'common/components/sp-skeleton';

const Skeleton = () => {
	const skeletonArray = new Array(4).fill(null).map((_, index) => index);

	return (
		<div className="flex flex-col">
			<div className="flex p-4 h-[56px] justify-between">
				<SpSkeleton className="h-6 w-[80px]" />
				<SpSkeleton className="h-6 w-6" />
			</div>
			<div className="w-full h-10 border-b border-stroke-secondary px-4 gap-6 flex items-center">
				<SpSkeleton className="h-5 w-13" />
				<SpSkeleton className="h-5 w-9" />
			</div>
			<div className="h-[64px] px-4 flex items-center">
				<SpSkeleton className="h-10 w-[72px] mr-2" />
				<SpSkeleton className="h-10 w-[120px]" />
			</div>
			{skeletonArray.map((index) => (
				<Fragment key={index}>
					<CardSkeleton />
					{index + 1 < skeletonArray.length && (
						<Divider className="m-0 border-stroke-secondary" />
					)}
				</Fragment>
			))}
		</div>
	);
};

export default Skeleton;
