import { Typography } from '@squantumengine/horizon';
import classNames from 'classnames';
import type { CardProps } from './card.interfaces';
import style from './index.module.scss';

const { Paragraph } = Typography;

const Card = ({
	description,
	isDisabled,
	isDisableClick,
	isActive,
	step,
	title,
	onClick,
}: CardProps) => {
	const containerClass = classNames('p-4', {
		'cursor-pointer': !isDisabled,
		'cursor-not-allowed': isDisabled,
		'bg-blue-50': isActive,
		'hover:bg-neutral-100': !isActive && !isDisabled,
		[style.card]: !isActive && !isDisabled,
	});
	const titleClass = classNames({
		'!text-disable': isDisabled,
	});
	const descriptionClass = classNames('mt-2 flex', {
		'text-disable': isDisabled && !isActive,
		'text-secondary': !isDisabled && !isActive,
		'text-primary': !isDisabled && isActive,
		'business-unit-form-stepper-card__description': !isActive && !isDisabled,
		[style.card__description]: !isActive && !isDisabled,
	});

	const updateStep = () => {
		if (isDisabled || isDisableClick) return;

		onClick(step);
	};

	return (
		<div className={containerClass} onClick={updateStep}>
			<Paragraph className={titleClass} weight="bold" size="l">
				{title}
			</Paragraph>
			<Paragraph className={descriptionClass} weight="regular" size="r">
				{description}
			</Paragraph>
		</div>
	);
};

export default Card;
