import type { StatusSelect } from './organisation-service-table.interfaces';

export const ACTION_MENU_KEY = {
	PREVIEW: 'preview',
	CUSTOM: 'custom',
	EDIT: 'edit',
} as const;
export const STATUS_OPTIONS_VALUES = {
	all: '',
	active: 'active',
	nonActive: 'nonactive',
} as const;
export const ACTION_MENU = [
	{
		text: 'Preview & unduh invoice',
		href: '/invoice',
		key: ACTION_MENU_KEY.PREVIEW,
	},
	{
		text: 'Kustom harga',
		href: '/price/custom',
		key: ACTION_MENU_KEY.CUSTOM,
	},
	{
		text: 'Edit business unit',
		href: '',
		key: ACTION_MENU_KEY.EDIT,
	},
];
export const STATUS_OPTIONS: StatusSelect[] = [
	{
		title: 'Semua',
		value: STATUS_OPTIONS_VALUES.all,
	},
	{
		title: 'Aktif',
		value: STATUS_OPTIONS_VALUES.active,
	},
	{
		title: 'Non Aktif',
		value: STATUS_OPTIONS_VALUES.nonActive,
	},
];
