import { getValidMonthYear } from 'common/utils/date';
import { convertToNumericOnly } from 'common/utils/transformer';
import type { Roles } from 'common/types/global';
import { PAGINATION_SIZE } from 'common/constants/global';
import {
	MANAGER_STATUS_POV,
	MANAGER_STATUS_TO_PROGRESS,
	PENGELOLA_STATUS_POV,
	PENGELOLA_STATUS_TO_PROGRESS,
} from './billing-approval.constants';
import type {
	BillingStatus,
	StatusGenerator,
	StatusToProgress,
} from './billing-approval.interfaces';

export const generateStatus = (
	status: BillingStatus,
	role: Roles
): StatusGenerator => {
	let pov: StatusGenerator = {
		type: '',
		label: '',
	};

	if (role === 'manager') pov = MANAGER_STATUS_POV[status] ?? pov;
	else if (role === 'pengelola') pov = PENGELOLA_STATUS_POV[status] ?? pov;

	return pov;
};
export const generateProgress = (
	status: BillingStatus,
	role: Roles
): StatusToProgress => {
	let progress: StatusToProgress = {
		step: 1,
		label: '',
	};

	if (role === 'manager') {
		progress = MANAGER_STATUS_TO_PROGRESS[status];
	} else if (role === 'pengelola') {
		progress = PENGELOLA_STATUS_TO_PROGRESS[status];
	}

	return progress;
};
export const getInitialParams = <T>(
	searchParams: URLSearchParams,
	allStatus: T[]
) => {
	const pageQuery = parseInt(
		convertToNumericOnly(searchParams.get('page') as string) || '1'
	);
	let sizeQuery = parseInt(
		convertToNumericOnly(searchParams.get('size') as string) || '10'
	);
	sizeQuery = PAGINATION_SIZE.includes(sizeQuery) ? sizeQuery : 10;

	const month = searchParams.get('month') ?? '';
	const year = searchParams.get('year') ?? '';
	const search = searchParams.get('search') ?? '';
	const statusQuery = searchParams.get('status') ?? '';
	const arrQueryStatus = statusQuery ? statusQuery.split(',') : [];
	const arrStatus =
		arrQueryStatus.length > 0
			? arrQueryStatus.filter((status) => allStatus.includes(status as T))
			: arrQueryStatus;
	const status = arrStatus.length > 0 ? (arrStatus as T[]) : allStatus;

	const validDate = getValidMonthYear(parseInt(month), parseInt(year));

	return {
		page: pageQuery,
		size: sizeQuery,
		validDate,
		search,
		status,
	};
};
