import { Fragment } from 'react';
import { Divider, Row, Col } from 'antd';
import SpSkeleton from 'common/components/sp-skeleton';

const Skeleton = () => {
	const skeletonArray = new Array(4).fill(null).map((_, index) => index);

	return (
		<>
			<div className="p-4 bg-neutral-0 rounded-lg">
				<Row gutter={16} className="mb-4">
					<Col span={6} className="h-[44px] flex items-center">
						<SpSkeleton className="w-3/4 h-5" />
					</Col>
					<Col span={4} className="h-[44px] flex items-center">
						<SpSkeleton className="w-5/6 h-5" />
					</Col>
					<Col span={14}>
						<Row gutter={16}>
							<Col span={5} className="h-[44px] flex items-center">
								<SpSkeleton className="w-1/2 h-5" />
							</Col>
							<Col span={7} className="h-[44px] flex items-center">
								<SpSkeleton className="w-1/2 h-5" />
							</Col>
							<Col span={12} className="h-[44px] flex items-center">
								<SpSkeleton className="w-1/5 h-5" />
							</Col>
						</Row>
					</Col>
				</Row>
				{skeletonArray.map((index) => (
					<Fragment key={index}>
						<Row gutter={16}>
							<Col span={6}>
								<SpSkeleton className="w-full h-full" />
							</Col>
							<Col span={4}>
								<SpSkeleton className="w-full h-full" />
							</Col>
							<Col span={14}>
								<Row gutter={16}>
									<Col span={5}>
										<SpSkeleton className="w-full h-full" />
									</Col>
									<Col span={7}>
										<SpSkeleton className="w-full h-full" />
									</Col>
									<Col span={10}>
										<SpSkeleton className="w-full h-full" />
									</Col>
									<Col span={2} className="mt-[12px]">
										<SpSkeleton className="w-full h-full" />
									</Col>
								</Row>
							</Col>
						</Row>
						{index < skeletonArray.length - 1 ? (
							<Divider className="my-4"></Divider>
						) : null}
					</Fragment>
				))}
			</div>
			<div className="flex w-full justify-end mt-6">
				<SpSkeleton className="h-[32px] w-[64px] mr-4" />
				<SpSkeleton className="h-[32px w-[80px]" />
			</div>
		</>
	);
};

export default Skeleton;
