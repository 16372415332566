import type { ReactNode } from 'react';
import { Alert, message } from 'antd';
import type { AlertProps } from 'antd';
import classNames from 'classnames';
import { ALERT_TYPES } from './toast-message.constants';

const ToastMessage = ({
	type,
	label,
}: {
	type: AlertProps['type'];
	label: string | ReactNode;
}) => {
	const toastClassName = classNames('w-[500px] text-left', {
		'bg-red-50 border-red-400': type === ALERT_TYPES.ERROR,
		'bg-green-50 border-green-400': type === ALERT_TYPES.SUCCESS,
	});

	message.open({
		content: (
			<Alert message={label} type={type} showIcon className={toastClassName} />
		),
		duration: 3,
	});

	message.config({
		prefixCls: 'notif-toast',
	});
};

export default ToastMessage;
