import { BILLING_STATUS } from 'features/billing-approval/billing-approval.constants';
import type { BillingStatus } from 'features/billing-approval/billing-approval.interfaces';

export const BILLING_STATUS_OPTIONS: {
	title: string;
	value: BillingStatus;
}[] = [
	{
		title: 'Selesai',
		value: BILLING_STATUS.paymentConfirmed,
	},
	{
		title: 'Konfirmasi pembayaran',
		value: BILLING_STATUS.paymentUploaded,
	},
	{
		title: 'Menunggu pembayaran',
		value: BILLING_STATUS.invoiceUploaded,
	},
	{
		title: 'Unggah invoice',
		value: BILLING_STATUS.reportApproved,
	},
	{
		title: 'Revisi laporan',
		value: BILLING_STATUS.reportRejected,
	},
	{
		title: 'Menunggu persetujuan',
		value: BILLING_STATUS.reportUploaded,
	},
	{
		title: 'Unggah laporan',
		value: BILLING_STATUS.waitingInitialization,
	},
];
