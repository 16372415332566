import { Typography } from '@squantumengine/horizon';
import Chart from './chart';
import type { WidgetProps, HeaderObject } from './widget.interfaces';

const { Paragraph, Title } = Typography;

const Widget = <T,>({
	data,
	header,
	footer,
	renderTooltip,
}: WidgetProps<T>) => {
	const isHeaderObject = (content: unknown): content is HeaderObject => {
		const contentSource = content as HeaderObject;
		return (
			typeof contentSource === 'object' &&
			'title' in contentSource &&
			'subtitle' in contentSource
		);
	};

	return (
		<div className="flex flex-col bg-neutral-0 shadow-base rounded-lg p-4">
			<div className="flex">
				{header.map((content) => {
					if (isHeaderObject(content)) {
						return (
							<div key={content.title} className="flex flex-col w-full">
								<Paragraph className="text-secondary" weight="regular" size="r">
									{content.title}
								</Paragraph>
								<Title className="!mt-2 text-primary" level={5}>
									{content.subtitle} {content.additionalTotalUsage}
								</Title>
							</div>
						);
					}

					return content;
				})}
			</div>
			{data?.length ? (
				<Chart data={data} renderTooltip={renderTooltip} />
			) : null}
			{footer && typeof footer === 'string' && (
				<div className="flex justify-end items-center mt-5">
					<div className="mr-2 w-3 h-3 bg-blue-500" />
					<Paragraph className="text-secondary" weight="regular" size="s">
						{footer}
					</Paragraph>
				</div>
			)}
			{footer && typeof footer !== 'string' && footer}
		</div>
	);
};

export default Widget;
