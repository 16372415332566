import { Typography } from '@squantumengine/horizon';

const { Paragraph } = Typography;

const PrefixCurrency = () => (
	<Paragraph weight="semibold" size="r">
		Rp
	</Paragraph>
);

export default PrefixCurrency;
