import { useCallback } from 'react';
import type { ValueProps } from './value.interfaces';

const Value = ({ title, oldValue, newValue }: ValueProps) => {
	const generateValue = useCallback(
		(logValue: string) => {
			let value = '';
			const isValueExist = Boolean(logValue);

			value = isValueExist && title ? `${title} :` : title;
			value = `${value} ${logValue}`;

			return value;
		},
		[title]
	);

	const oldTextValue = generateValue(oldValue);
	const newTextValue = generateValue(newValue);

	return (
		<div className="flex">
			<div
				className="py-4 border-b border-stroke-secondary w-1/2 border-r"
				style={{
					paddingLeft: `12px`,
				}}
			>
				{oldTextValue}
			</div>
			<div
				className="py-4 border-b border-stroke-secondary w-1/2"
				style={{
					paddingLeft: `12px`,
				}}
			>
				{newTextValue}
			</div>
		</div>
	);
};

export default Value;
