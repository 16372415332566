import type {
	GetNotificationsParams,
	ReadNotificationsPayload,
} from 'layouts/dashboard/notification-center/notification-center.interfaces';
import axios from './axios-instance';

const baseUrl = '/v1/notification';

export const getNotificationCounter = async () => {
	const response = await axios.get(`${baseUrl}/count`);

	return response.data;
};
export const getNotifications = async (params: GetNotificationsParams) => {
	const response = await axios.get(baseUrl, {
		params,
	});

	return response.data;
};
export const readNotifications = async (payload: ReadNotificationsPayload) => {
	const response = await axios.post(`${baseUrl}/read`, payload);

	return response.data;
};
export const deleteNotifications = async (payload: string[]) => {
	const response = await axios.delete(baseUrl, {
		data: {
			ids: payload,
		},
	});

	return response.data;
};
