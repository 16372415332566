import CardSkeleton from 'features/main-dashboard/product-card/skeleton';
import SpSkeleton from 'common/components/sp-skeleton';

const PengelolaSkeleton = () => {
	const skeletonArray = new Array(4).fill(null).map((_, index) => index);

	return (
		<>
			<SpSkeleton className="w-[132px] h-[32px]" />
			<SpSkeleton className="w-[450px] h-5 mt-6" />
			<div className="grid grid-cols-4 gap-6 mt-6">
				{skeletonArray.map((column) => (
					<CardSkeleton key={column} />
				))}
			</div>
		</>
	);
};

export default PengelolaSkeleton;
