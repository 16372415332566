export const ACTIVITY_OPTIONS_VALUES = {
	all: '',
	create: 'CREATE',
	modified: 'EDIT',
	enable: 'ENABLE',
	disable: 'DISABLE',
	delete: 'DELETE',
} as const;
export const ACTIVITY_TYPE = {
	create: 'CREATE',
	edit: 'EDIT',
	disable: 'DISABLE',
	enable: 'ENABLE',
	delete: 'DELETE',
} as const;
export const SUBJECT_LIST_VALUES = {
	all: '',
	general: 'GENERAL',
	businessUnit: 'BUSINESS_UNIT',
} as const;
export const ACTIVITY_LOG_SORT_KEYS = {
	createdAt: 'created_at',
	actorEmail: 'actor_email',
} as const;
