import SpSkeleton from 'common/components/sp-skeleton';

const Skeleton = () => {
	const skeletonArray = new Array(2).fill(null).map((_, index) => index);

	return (
		<>
			<div className="p-6 bg-neutral-0 shadow-base rounded-lg">
				<SpSkeleton className="h-[20px] mb-8 w-[180px]" />
				{skeletonArray.map((index) => (
					<div key={index}>
						<SpSkeleton className="h-[14px] w-[120px] mb-2" />
						<SpSkeleton className="h-[47px] mb-5" />
					</div>
				))}
				<SpSkeleton className="h-[14px] w-[120px] mb-2" />
				<SpSkeleton className="h-[90px] mb-5" />
				<SpSkeleton className="h-[32px] w-[120px] mb-5" />
				<SpSkeleton className="h-[14px] w-[120px] mb-2" />
				<SpSkeleton className="h-[47px] mb-5" />
			</div>
			<div className="flex justify-end bg-white shadow-base py-4 px-6 mt-6 rounded-lg">
				<SpSkeleton className="h-[32px] w-[47px] mr-4" />
				<SpSkeleton className="h-[32px] w-[47px]" />
			</div>
		</>
	);
};

export default Skeleton;
