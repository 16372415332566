import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.25518 9.51143L9.41352 11.6698C9.73852 11.9948 10.2635 11.9948 10.5885 11.6698L12.7468 9.51143C13.2718 8.98643 12.8968 8.08643 12.1552 8.08643H7.83852C7.09685 8.08643 6.73018 8.98643 7.25518 9.51143Z"
			fill="currentColor"
		/>
	</svg>
);

const DropdownArrow = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default DropdownArrow;
