export const SETTINGS_MAP_VALUE: { [key: string]: string } = {
	ONLY_THIS_MONTH: 'Hanya bulan ini',
	THIS_MONTH_ONWARDS: 'Bulan ini dan seterusnya',
	NEXT_MONTH_ONWARDS: 'Bulan depan dan seterusnya',
};
export const SETTINGS_MODE = {
	ADD: 'ADD',
	SUBSTRACT: 'SUBSTRACT',
} as const;
export const SETTINGS_TYPE = {
	ADDITION: 'ADDITION',
	REDUCTION: 'REDUCTION',
} as const;
export const SETTINGS_FORMAT = {
	NUMERIC: 'NUMERIC',
	PERCENT: 'PERCENT',
} as const;
export const SETTINGS_CONFIG = {
	THIS_MONTH_ONWARDS: 'THIS_MONTH_ONWARDS',
	NEXT_MONTH_ONWARDS: 'NEXT_MONTH_ONWARDS',
} as const;
