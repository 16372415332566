import { create } from 'zustand';
import type {
	ProfileSliceState,
	ProfileSliceActions,
	FeatureFlags,
} from 'common/types/auth';

const initialState: ProfileSliceState = {
	email: '',
	isAuthenticated: false,
	isInActiveUser: false,
	isUnregisteredUser: false,
	name: '',
	id: '',
	featureFlags: null,
	role: '',
	phone: '',
	organisations: [],
	permissions: [],
};

export const useProfileStore = create<
	ProfileSliceState & ProfileSliceActions
>()((set, get) => ({
	...initialState,
	checkPermission: (resource, type): boolean => {
		const currentPermissions = useProfileStore.getState().permissions;
		const rule = currentPermissions.find(
			(permission) => permission.rsname === `res:${resource.toLowerCase()}`
		);

		if (!rule) return false;

		const isAllowed = rule.scopes.find((scope) =>
			scope.includes(`scope:${type.toLowerCase()}`)
		);

		return Boolean(isAllowed);
	},
	setIsAuthenticated: (isAuthenticated) =>
		set(() => ({
			isAuthenticated,
		})),
	setRole: (role) =>
		set(() => ({
			role,
		})),
	setName: (name: string) =>
		set(() => ({
			name,
		})),
	setEmail: (email: string) =>
		set(() => ({
			email,
		})),
	setId: (id: string) =>
		set(() => ({
			id,
		})),
	setFeatureFlags: (featureFlags: FeatureFlags) =>
		set(() => ({
			featureFlags,
		})),
	setPhone: (phone: string) =>
		set(() => ({
			phone,
		})),
	setOrganisations: (organisationServices) =>
		set(() => ({
			organisations: organisationServices,
		})),
	setPermissions: (permissions) =>
		set(() => ({
			permissions,
		})),
	setIsInActiveUser: (isInActiveUser) =>
		set(() => ({
			isInActiveUser,
		})),
	setIsUnregisteredUser: (isUnregisteredUser) =>
		set(() => ({
			isUnregisteredUser,
		})),
}));
