import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { useProfileStore } from 'common/stores/profile';
import { getValidMonthYear } from 'common/utils/date';
import useAnalytic from 'common/hooks/use-analytic';
import useDidMount from 'common/hooks/use-did-mount';

const useInitiateAnalytic = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { syncFilterToUrl } = useAnalytic();

	const [isQueryEnabled, setIsQueryEnabled] = useState(false);
	const [selectedMonth, setSelectedMonth] = useState<Dayjs | null>(null);

	const organisations = useProfileStore((state) => state.organisations);

	const organisationId = searchParams.get('oid') ?? '';

	useEffect(() => {
		if (!organisationId) return navigate('/', { replace: true });

		const isOrganisationExist = organisations.find(
			(organisation) => organisation.organisationId === organisationId
		);

		if (!isOrganisationExist) return navigate('/', { replace: true });

		const month = searchParams.get('month') ?? '';
		const year = searchParams.get('year') ?? '';

		const validDate = getValidMonthYear(parseInt(month), parseInt(year));
		const initialDate = dayjs(`${validDate.year}-${validDate.month}-01`);

		setSelectedMonth(initialDate);
		setIsQueryEnabled(true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useDidMount(() => {
		syncFilterToUrl({
			selectedMonth,
		});
	}, [selectedMonth]);

	return {
		selectedMonth,
		isQueryEnabled,
		setSelectedMonth,
	};
};

export default useInitiateAnalytic;
