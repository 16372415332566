import type {
	GetPriceApiParams,
	PatchUpsertPriceApiPayload,
} from 'features/price/price.interfaces';
import axios from './axios-instance';

const baseUrl = '/v1/price';

export const getPrices = async (params: GetPriceApiParams) => {
	const queryParams = params;

	if (!queryParams.organisation_id) delete queryParams.organisation_id;

	const response = await axios.get(baseUrl, {
		params: queryParams,
	});

	return response.data;
};
export const updatePrices = async (payload: PatchUpsertPriceApiPayload) => {
	const response = await axios.patch(baseUrl, {
		data: payload,
	});

	return response.data;
};
