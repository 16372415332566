import { Fragment, useState, useRef, useEffect } from 'react';
import type { UIEvent, MouseEvent } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { Divider, Typography, Tabs } from '@squantumengine/horizon';
import { Popover } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import classNames from 'classnames';
import { useModalContext } from 'common/context/shared-modal';
import dayjs from 'dayjs';
import relative from 'dayjs/plugin/relativeTime';
import { useProfileStore } from 'common/stores/profile';
import { BellIcon } from 'common/components/icons';
import SpCheckbox from 'common/components/sp-checkbox';
import ToastMessage from 'common/utils/toast-message';
import { camelize } from 'common/utils/transformer';
import useDidMount from 'common/hooks/use-did-mount';
import useFileDownload from 'common/hooks/use-file-download';
import useGenerateContractActivity from 'common/hooks/use-generate-contract-activity';
import usePageVisibility from 'common/hooks/use-page-visibility';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { FILE_ACTION } from 'common/constants/global';
import {
	getNotificationCounter,
	getNotifications,
	readNotifications,
	deleteNotifications,
} from 'services/notification';
import CardSkeleton from './card/skeleton';
import DeleteAction from './delete-action';
import Filter from './filter';
import Main from './main';
import {
	NOTIFICATION_ACTION_ID,
	EVENT_TYPES,
	NOTIFICATION_TYPE_MAP,
	NOTIFICATION_CATEGORY,
	NOTIFICATION_STATUS,
} from './notification-center.constants';
import type {
	GetNotificationsParams,
	Notification,
	NotificationStatus,
	NotificationAction,
	NotificationCategory,
} from './notification-center.interfaces';
import 'dayjs/locale/id';
import Skeleton from './skeleton';

const { Paragraph } = Typography;
dayjs.extend(relative);
dayjs.locale('id');

const NotificationCenter = () => {
	const location = useLocation();
	const { setDownloadFile } = useFileDownload();
	const { generateContractActivity } = useGenerateContractActivity();
	const { toggleSharedModal } = useModalContext();
	const notificationsRef = useRef<HTMLUListElement>(null);

	const [counter, setCounter] = useState(0);
	const [deleteState, setDeleteState] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isNotificationsLoading, setIsNotificationsLoading] = useState(true);
	const [params, setParams] = useState<GetNotificationsParams>({
		limit: 4,
		status: '',
		cursor: '',
		category: NOTIFICATION_CATEGORY.general,
	});
	const [isLoadMore, setIsLoadMore] = useState(false);
	const [hasNext, setHasNext] = useState(false);
	const [isUtilitiesOpen, setIsUtilitiesOpen] = useState(false);
	const [isNotificationOpen, setIsNotificationOpen] = useState(false);
	const [groupedNotifications, setGroupedNotifications] = useState<
		Record<string, Notification[]>
	>({
		today: [],
		yesterday: [],
		lastWeek: [],
		lastMonth: [],
		older: [],
	});
	const [selectedIds, setSelectedIds] = useState<string[]>([]);
	const organisations = useProfileStore((state) => {
		return state.organisations.filter(
			(organisation) => organisation.usedSqeservices.length > 0
		);
	});

	const { refetch: fetchNotificationCounter } = useQuery({
		queryKey: ['getNotificationCounter'],
		queryFn: getNotificationCounter,
		onSuccess: (response) => {
			const counter = response?.data?.count ?? 0;

			setCounter(counter);
			setIsError(false);
		},
		onError: () => {
			setIsError(true);
		},
	});

	const { refetch: fetchNotifications } = useQuery({
		queryKey: ['getNotifications'],
		queryFn: () => getNotifications(params),
		onSuccess: (response) => {
			const data = response?.data?.items ?? [];

			const hasNext = response?.data?.pagination?.has_next ?? false;
			const nextCursor = response?.data?.pagination?.next_cursor ?? '';
			const sanitizedNoficationList = camelize(data) as Notification[];

			const newGroupedNotifications: Record<string, Notification[]> = {
				today: [],
				yesterday: [],
				lastWeek: [],
				lastMonth: [],
				older: [],
			};

			const today = dayjs().startOf('day');
			const yesterday = today.subtract(1, 'day');
			const lastWeek = today.subtract(7, 'day');
			const lastMonth = today.subtract(1, 'month');

			sanitizedNoficationList.forEach((notification) => {
				const eventTime = dayjs(notification.eventTime);
				const icon = NOTIFICATION_TYPE_MAP[notification.type] ?? '';

				if (eventTime.isSame(today, 'day')) {
					newGroupedNotifications.today.push({
						...notification,
						icon,
						eventTime: eventTime.locale('id').fromNow(),
					});
				} else if (eventTime.isSame(yesterday, 'day')) {
					newGroupedNotifications.yesterday.push({
						...notification,
						icon,
						eventTime: `${eventTime.format('HH:mm')} WIB`,
					});
				} else if (eventTime.isAfter(lastWeek)) {
					const daysAgo = Math.floor(today.diff(eventTime, 'day'));
					const formattedTime = `${daysAgo} hari yang lalu`;

					newGroupedNotifications.lastWeek.push({
						...notification,
						icon,
						eventTime: formattedTime,
					});
				} else if (eventTime.isAfter(lastMonth)) {
					const weeksAgo = Math.floor(today.diff(eventTime, 'week'));
					const formattedTime = `${weeksAgo} minggu yang lalu`;

					newGroupedNotifications.lastMonth.push({
						...notification,
						icon,
						eventTime: formattedTime,
					});
				} else {
					newGroupedNotifications.older.push({
						...notification,
						icon,
						eventTime: eventTime.locale('id').fromNow(),
					});
				}
			});

			const updatedGroupedNotifications = isLoadMore
				? {
						...groupedNotifications,
						today: [
							...groupedNotifications.today,
							...newGroupedNotifications.today,
						],
						yesterday: [
							...groupedNotifications.yesterday,
							...newGroupedNotifications.yesterday,
						],
						lastWeek: [
							...groupedNotifications.lastWeek,
							...newGroupedNotifications.lastWeek,
						],
						lastMonth: [
							...groupedNotifications.lastMonth,
							...newGroupedNotifications.lastMonth,
						],
						older: [
							...groupedNotifications.older,
							...newGroupedNotifications.older,
						],
				  }
				: newGroupedNotifications;

			setGroupedNotifications(updatedGroupedNotifications);
			setHasNext(hasNext);
			setParams({
				...params,
				cursor: nextCursor,
			});
			setIsError(false);
		},
		onError: () => {
			setIsError(true);
		},
		onSettled: () => {
			setIsLoadMore(false);
			setIsNotificationsLoading(false);
		},
	});

	const readNotificationsMutation = useMutation(readNotifications, {
		onSuccess: () => {
			setIsUtilitiesOpen(false);
			setSelectedIds([]);
			setDeleteState(false);
			refetchNotifications({
				...params,
				limit: notifications.length,
				cursor: '',
			});
			fetchNotificationCounter();
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const removeNotifications = useMutation(deleteNotifications, {
		onSuccess: () => {
			setSelectedIds([]);
			refetchNotifications({
				...params,
				limit: notifications.length,
				cursor: '',
			});
			fetchNotificationCounter();
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: 'Data gagal dihapus.',
			});
		},
	});

	const loadMoreNotifications = () => {
		setIsLoadMore(true);
		refetchNotifications({
			...params,
			limit: 4,
		});
	};

	const onDeleteNotification = () => {
		removeNotifications.mutate(selectedIds);
	};

	const onReadNotifications = (
		_: MouseEvent<HTMLElement> | undefined = undefined,
		ids: string[] = []
	) => {
		readNotificationsMutation.mutate({
			ids,
			category: params.category,
		});
	};

	const onTogglePopover = (isOpen: boolean) => {
		setIsUtilitiesOpen(isOpen);
	};

	const onToggleNotification = (isOpen: boolean) => {
		setIsNotificationOpen(isOpen);
	};

	const onScrollNotifications = (event: UIEvent<HTMLElement>) => {
		if (!hasNext || isLoadMore) return;

		const element = event.currentTarget;

		const isScrolledToBottom =
			element.scrollTop + element.clientHeight > element.scrollHeight - 10;

		if (isScrolledToBottom) loadMoreNotifications();
	};

	const onVisibilityChange = (isVisible: boolean) => {
		if (isVisible) {
			refetchNotifications({
				...params,
				limit: notifications.length,
				cursor: '',
			});
			fetchNotificationCounter();
		}
	};

	const onUpdateDeleteState = (updatedDeleteState: boolean) => {
		setDeleteState(updatedDeleteState);
		setIsUtilitiesOpen(false);

		if (!updatedDeleteState) setSelectedIds([]);
	};

	const onSelectedId = (event: CheckboxChangeEvent, notificationId: string) => {
		const isSelected = event.target.checked;

		isSelected
			? setSelectedIds([...selectedIds, notificationId])
			: setSelectedIds(selectedIds.filter((id) => id !== notificationId));
	};

	const onSelectAllNotifications = (event: CheckboxChangeEvent) => {
		const isCheckAll = event.target.checked;

		isCheckAll
			? setSelectedIds([...new Set([...selectedIds, ...availableIds])])
			: setSelectedIds([]);
	};

	const onUpdateStatusFilter = (value: NotificationStatus) => {
		if (value === params.status) return;

		setIsNotificationsLoading(true);

		/* istanbul ignore else */
		if (notificationsRef.current) notificationsRef.current.scrollTop = 0;
		fetchNotificationCounter();
		refetchNotifications({
			...params,
			limit: 4,
			status: value,
			cursor: '',
		});
	};

	const onRedirectNotification = ({
		id,
		action,
	}: {
		id: string;
		action: NotificationAction;
	}) => {
		const selectedNotification = notifications.find(
			(notif) => notif.id === id
		) as Notification;

		if (!selectedNotification.isSeen) {
			onReadNotifications(undefined, [selectedNotification.id]);
		}

		if (action.id === NOTIFICATION_ACTION_ID.openContractDetail) {
			generateContractActivity(action.metadata.contractId);
		} else if (action.id === NOTIFICATION_ACTION_ID.openSignerList) {
			setIsNotificationOpen(false);
			toggleSharedModal({
				name: 'signerList',
				payload: {
					isOpen: true,
					data: {
						documentId: action.metadata.contractId,
					},
				},
			});
		} else if (action.id === NOTIFICATION_ACTION_ID.failedDownloadEvidence) {
			setIsNotificationOpen(false);
			toggleSharedModal({
				name: 'downloadEvidence',
				payload: {
					isOpen: true,
					data: {
						organisations,
					},
				},
			});
		} else if (
			action.id === NOTIFICATION_ACTION_ID.downloadEvidenceAttachment
		) {
			setDownloadFile({
				id: action.metadata.fileId,
				type: FILE_ACTION.download,
			});
		}
	};

	const refetchNotifications = (updatedParams: GetNotificationsParams) => {
		setParams({
			...params,
			...updatedParams,
		});

		setTimeout(() => {
			fetchNotifications();
		}, 50);
	};

	const refreshNotifications = () => {
		setIsNotificationsLoading(true);
		refetchNotifications({
			...params,
			limit: 4,
			cursor: '',
		});
	};

	const updateNotificationCategory = (category: NotificationCategory) => {
		if (category === params.category) return;

		setIsNotificationsLoading(true);

		/* istanbul ignore else */
		if (notificationsRef.current) notificationsRef.current.scrollTop = 0;
		setDeleteState(false);
		setSelectedIds([]);
		fetchNotificationCounter();
		refetchNotifications({
			...params,
			limit: 4,
			cursor: '',
			category,
			status: NOTIFICATION_STATUS.all,
		});
	};

	const counterText = counter > 99 ? '99+' : counter.toString();
	const badgeClassName = classNames(
		'flex items-center justify-center bg-red-500 rounded-full text-neutral-0 text-body-s-semibold self-start px-1 absolute left-4',
		{
			'w-4': counterText.length === 1,
		}
	);
	const filteredGroupNotifications = Object.fromEntries(
		Object.entries(groupedNotifications).filter(
			([_, value]) => value.length > 0
		)
	);
	const notifications = Object.values(groupedNotifications).reduce(
		(acc, currentValue) => acc.concat(currentValue),
		[]
	);
	const isShowCounter = !isError && counter > 0;
	const availableIds = Object.values(filteredGroupNotifications).flatMap(
		(array) => array.map((obj) => obj.id)
	);
	const isAllIdsChecked =
		selectedIds.length > 0
			? availableIds.every((value) => selectedIds.includes(value))
			: false;
	const indeterminate = selectedIds.some((value) =>
		availableIds.includes(value)
	);
	const tabContent = (
		<>
			{!isNotificationsLoading && deleteState && (
				<DeleteAction
					isDeleteDisabled={selectedIds.length === 0}
					indeterminate={!isAllIdsChecked && indeterminate}
					checked={isAllIdsChecked}
					onSelectAll={onSelectAllNotifications}
					onCancel={onUpdateDeleteState}
					onDelete={onDeleteNotification}
				/>
			)}
			{!isNotificationsLoading && !deleteState && (
				<Filter status={params.status} onUpdateStatus={onUpdateStatusFilter} />
			)}
		</>
	);

	usePageVisibility(onVisibilityChange);

	useDidMount(() => {
		setIsNotificationsLoading(true);
		refetchNotifications({
			...params,
			limit: notifications.length,
			cursor: '',
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	useEffect(() => {
		fetchNotifications();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Popover
			content={
				<Main
					isError={isError}
					isShowSkeleton={isNotificationsLoading}
					skeletonContent={<Skeleton />}
					isHeaderActionOpen={isUtilitiesOpen}
					onDeleteAction={onUpdateDeleteState}
					showDeleteAction
					notifications={Object.values(groupedNotifications).reduce(
						(acc, currentValue) => acc.concat(currentValue),
						[]
					)}
					filterContent={
						<div
							className={`flex flex-col ${
								isNotificationsLoading ? 'hidden' : ''
							}`}
						>
							<Tabs
								size="md"
								tabBarGutter={24}
								tabListClassName="h-10 px-4 mb-0"
								defaultActiveKey={NOTIFICATION_CATEGORY.general}
								onChange={(tab) => {
									updateNotificationCategory(tab as NotificationCategory);
								}}
								items={[
									{
										children: tabContent,
										key: NOTIFICATION_CATEGORY.general,
										label: 'General',
									},
									{
										children: tabContent,
										key: NOTIFICATION_CATEGORY.attachment,
										label: 'Data',
									},
								]}
							/>
						</div>
					}
					notificationContent={
						<ul
							className="max-h-[324px] overflow-y-auto"
							aria-label="Notification list"
							ref={notificationsRef}
							onScroll={onScrollNotifications}
						>
							{Object.entries(filteredGroupNotifications).map(
								([groupName, groupNotifications], index) => {
									const titleClassName = classNames('px-4 pb-2 text-disable', {
										'pt-4': index > 0,
										'pt-1': index === 0,
									});

									return (
										groupNotifications.length > 0 && (
											<div key={groupName}>
												<Paragraph
													className={titleClassName}
													weight="semibold"
													size="s"
												>
													{EVENT_TYPES[groupName]}
												</Paragraph>
												{groupNotifications.map((notification, index) => {
													const isNotificationSelected = selectedIds.includes(
														notification.id
													);

													return (
														<Fragment key={notification.id}>
															<Main.Card
																id={notification.id}
																action={notification.action}
																isSeen={notification.isSeen}
																isActive={isNotificationSelected}
																subject={notification.subject}
																messages={notification.messages}
																badge={
																	deleteState ? (
																		<div className="w-6 h-6 shrink-0 mr-3">
																			<SpCheckbox
																				checked={isNotificationSelected}
																				onChange={(event) =>
																					onSelectedId(event, notification.id)
																				}
																			/>
																		</div>
																	) : (
																		notification.icon
																	)
																}
																eventTime={notification.eventTime}
																onClick={
																	deleteState
																		? undefined
																		: onRedirectNotification
																}
															/>
															{index + 1 < notifications.length && (
																<Divider className="m-0 border-stroke-secondary" />
															)}
														</Fragment>
													);
												})}
											</div>
										)
									);
								}
							)}
							{isLoadMore && <CardSkeleton />}
						</ul>
					}
					unreadCount={counter}
					onReadNotifications={onReadNotifications}
					onToggleHeaderAction={onTogglePopover}
					refreshNotifications={refreshNotifications}
				/>
			}
			open={isNotificationOpen}
			onOpenChange={onToggleNotification}
			trigger="click"
			placement="bottomRight"
		>
			<div className="flex relative cursor-pointer">
				<BellIcon />
				{isShowCounter && <div className={badgeClassName}>{counterText}</div>}
			</div>
		</Popover>
	);
};

export default NotificationCenter;
