import { useSearchParams } from 'react-router-dom';

const useQueryUrl = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const setUrl = ({
		retain = true,
		keyPairs,
	}: {
		retain?: boolean;
		keyPairs: {
			[key: string]: string;
		};
	}) => {
		const currentPairs = {} as {
			[key: string]: string;
		};

		for (const [key, value] of searchParams.entries()) {
			currentPairs[key] = value;
		}

		let queryString = '';

		const queryPairs = retain
			? {
					...currentPairs,
					...keyPairs,
			  }
			: keyPairs;

		for (const [key, value] of Object.entries(queryPairs)) {
			if (key && value !== undefined && value !== null && value !== '') {
				queryString = queryString
					? `${queryString}&${key}=${value}`
					: `${key}=${value}`;
			}
		}

		const queryParams = new URLSearchParams(queryString);

		setSearchParams(queryParams, { replace: true });
	};

	return {
		searchParams,
		setUrl,
	};
};

export default useQueryUrl;
