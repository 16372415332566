import { Outlet } from 'react-router-dom';

const BusinessUnit = () => {
	return (
		<div className="flex flex-col py-8">
			<Outlet />
		</div>
	);
};

export default BusinessUnit;
