import axios from 'axios';
import type { AxiosRequestConfig } from 'axios';
import { ENVIRONTMENTS } from 'common/constants';
import { goToLogout } from 'common/utils/auth';
import { local } from 'common/utils/storage';

const baseURL = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
	baseURL,
});
let failedRequestsQueue = [] as AxiosRequestConfig[];
let isRefreshingToken = false;

const processFailedRequestsQueue = async () => {
	await Promise.all(
		failedRequestsQueue.map(
			/* istanbul ignore next */ (request) => axiosInstance(request)
		)
	);
	failedRequestsQueue = [];
};

axiosInstance.interceptors.request.use(
	(config) => {
		const token = local.getItem('access_token');

		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}

		// SQEPORT-1173: will use WIB only (offset 420), not multi regional
		// -new Date().getTimezoneOffset();
		config.headers['x-utc-offset'] = 420;

		if (
			process.env.REACT_APP_ENV !== ENVIRONTMENTS.production &&
			local.getItem('x-mock-expect')
		) {
			config.headers['x-mock-expect'] = local.getItem('x-mock-expect');
		}

		return config;
	},
	/* istanbul ignore next */
	(error) => {
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;
		const status = error?.response?.status;

		if (status === 401 && !originalRequest.retry) {
			if (isRefreshingToken) return failedRequestsQueue.push(originalRequest);

			originalRequest.retry = true;
			isRefreshingToken = true;

			const refreshToken = local.getItem('refresh_token');

			try {
				const response = await axiosInstance.post('/v1/oidc/refresh-token', {
					refresh_token: refreshToken,
				});

				if (response?.data && response.status) {
					const data = response?.data?.data ?? null;

					if (!data) {
						isRefreshingToken = false;
						logout();
						return;
					}

					local.setItem('access_token', data.access_token);
					local.setItem('refresh_token', data.refresh_token);

					processFailedRequestsQueue();
					isRefreshingToken = false;

					return axiosInstance(originalRequest);
				} else {
					isRefreshingToken = false;
					logout();
					return;
				}
			} catch (error) {
				isRefreshingToken = false;
				logout();
				return;
			}
		}

		return Promise.reject(error);
	}
);

const logout = async () => {
	const response = await axiosInstance.post('/v1/oidc/logout', {
		refresh_token: local.getItem('refresh_token'),
	});

	if (response?.data && response.status) {
		goToLogout();
	}
};

export default axiosInstance;
