import { Typography } from '@squantumengine/horizon';

const { Title } = Typography;

const GeneralHeader = () => {
	return (
		<div className="w-full h-[56px] px-[60px] flex items-center bg-neutral-0">
			<Title level={5}>SQE Portal</Title>
		</div>
	);
};

export default GeneralHeader;
