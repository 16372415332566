import { useState } from 'react';
import { useMutation } from 'react-query';
import { Button } from '@squantumengine/horizon';
import { Popover } from 'antd';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import type { DocumentType } from 'features/invoice-management/invoice-management.interfaces';
import { DownloadIcon } from 'common/components/icons';
import Option from 'common/components/option';
import { downloadFile } from 'common/utils/file';
import ToastMessage from 'common/utils/toast-message';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { download } from 'services/invoice';
import { FILE_TYPE } from './download-dropdown.constants';
import type { DownloadDropdownProps } from './download-dropdown.interfaces';

dayjs.extend(isSameOrBefore);

const DownloadDropdown = ({
	ids,
	date,
	selectedName,
}: DownloadDropdownProps) => {
	const [isDownloadPopoverOpen, setIsDownloadPopoverOpen] = useState(false);

	const { mutate, isLoading } = useMutation(download);

	const onSelectInvoiceType = (value: DocumentType) => {
		const yearMonth = dayjs(date).format('YYYY-MM');
		const payload = {
			data: {
				output_type: value,
				organisation_ids: ids,
				usage_period: yearMonth,
			},
		};

		const filename =
			ids.length > 1
				? `invoices-${yearMonth}.zip`
				: `${selectedName}-${yearMonth}.${value.toLowerCase()}`;
		togglePopover(false);

		ToastMessage({
			type: 'info',
			label: 'Dokumen sedang diunduh.',
		});

		mutate(payload, {
			onSuccess: (response) => {
				downloadFile(response, filename);
			},
			onError: () => {
				ToastMessage({
					type: 'error',
					label: GENERAL_ERROR_MESSAGE,
				});
			},
		});
	};

	const togglePopover = (isOpen: boolean) => {
		/* istanbul ignore next */
		if (isLoading) return;

		setIsDownloadPopoverOpen(isOpen);
	};

	const lastYearMonth = dayjs().subtract(1, 'month').format('YYYY-MM');
	const pickedYearMonth = dayjs(date).format('YYYY-MM');
	const isDownloadDisabled =
		!dayjs(pickedYearMonth).isSameOrBefore(lastYearMonth) || ids.length === 0;

	return (
		<Popover
			content={
				<ul className="w-[230px] py-1">
					{FILE_TYPE.map((option) => (
						<Option
							value={option.value}
							key={option.value}
							isActive={false}
							title={option.text}
							onClick={onSelectInvoiceType}
						></Option>
					))}
				</ul>
			}
			open={isDownloadPopoverOpen}
			onOpenChange={togglePopover}
			trigger="click"
			placement="bottomLeft"
		>
			<Button
				variant="secondary"
				disabled={isDownloadDisabled}
				loading={isLoading}
			>
				<span className="mr-2">
					<DownloadIcon />
				</span>
				Unduh Invoice
			</Button>
		</Popover>
	);
};

export default DownloadDropdown;
