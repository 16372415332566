import { useState } from 'react';

const useSort = ({ by, order }: { by: string; order: string }) => {
	const [sort, setSort] = useState({
		by,
		order,
	});

	return {
		sort,
		setSort,
	};
};

export default useSort;
