import { useState, useCallback, useEffect } from 'react';
import type { MouseEvent } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Typography, SearchBar, Icon } from '@squantumengine/horizon';
import { Checkbox, Popover } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { ColumnsType } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import classNames from 'classnames';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import _debounce from 'lodash/debounce';
import { useBusinessUnitStore } from 'features/business-unit/business-unit.store';
import DownloadDropdown from 'features/invoice-management/download-dropdown';
import { useProfileStore } from 'common/stores/profile';
import { EllipsisIcon } from 'common/components/icons';
import SpMonthPicker from 'common/components/sp-month-picker';
import SpPagination from 'common/components/sp-pagination';
import SpSelect from 'common/components/sp-select';
import SpTable from 'common/components/sp-table';
import { getValidMonthYear } from 'common/utils/date';
import ToastMessage from 'common/utils/toast-message';
import { camelize, convertToNumericOnly } from 'common/utils/transformer';
import usePagination from 'common/hooks/use-pagination';
import useSort from 'common/hooks/use-sort';
import useUpdateDefaultParams from 'common/hooks/use-update-default-params';
import type { GetOrganisationServiceApiResponse } from 'common/types/organisation';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { PAGINATION_SIZE } from 'common/constants/global';
import { getOrganisationServices } from 'services/organisation';
import style from './index.module.scss';
import {
	ACTION_MENU,
	ACTION_MENU_KEY,
	STATUS_OPTIONS,
	STATUS_OPTIONS_VALUES,
} from './organisation-service-table.constants';
import type {
	OrganisationService,
	StatusFilter,
} from './organisation-service-table.interfaces';
import ServiceTag from './service-tag';

const { Paragraph } = Typography;

const OrganisationServiceTable = () => {
	const navigate = useNavigate();
	const {
		currentPage,
		pageSize,
		totalCount,
		setTotalCount,
		setCurrentPage,
		onChangeCurrentPage,
		onChangePageSize,
	} = usePagination();

	const userId = useProfileStore((state) => state.id);
	const setIsLoadingDetails = useBusinessUnitStore(
		(action) => action.setIsLoadingDetails
	);

	const [data, setData] = useState<OrganisationService[]>([]);
	const [isHighlightedDataExist, setIsHighlightedDataExist] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isQueryEnabled, setIsQueryEnabled] = useState(false);
	const [selectedMonth, setSelectedMonth] = useState<Dayjs | null>(dayjs());
	const [selectedRows, setSelectedRows] = useState<string[]>([]);
	const [search, setSearch] = useState('');
	const [status, setStatus] = useState<StatusFilter>('');
	const [debounceSearch, setDebounceSearch] = useState('');
	const [searchParams, setSearchParams] = useSearchParams();
	const { sort, setSort } = useSort({
		by: '',
		order: '',
	});

	useQuery({
		queryKey: [
			'getOrganisationServices',
			selectedMonth,
			currentPage,
			pageSize,
			status,
			debounceSearch,
			sort,
		],
		queryFn: () =>
			getOrganisationServices(userId, {
				month: (selectedMonth as Dayjs).month() + 1,
				year: (selectedMonth as Dayjs).year(),
				organisation_id: '',
				page: currentPage,
				limit: pageSize,
				organisation_status: status,
				organisation_name: debounceSearch,
				sort_by: sort.by,
				sort_method: sort.order,
			}),
		enabled: isQueryEnabled,
		onSuccess: (response) => {
			const totalItems = response?.data?.pagination?.totalItems ?? 0;
			const organisationServicesResponse = response?.data?.items ?? [];
			const highlightOrganisationServiceResponse =
				response?.data?.highlight ?? null;
			const sanitizedResponse = camelize(
				organisationServicesResponse
			) as GetOrganisationServiceApiResponse[];
			const sanitizedHighlightResponse = camelize(
				highlightOrganisationServiceResponse
			) as GetOrganisationServiceApiResponse;
			const dataSource = highlightOrganisationServiceResponse
				? [sanitizedHighlightResponse, ...sanitizedResponse]
				: sanitizedResponse;

			const organisationServices = dataSource.map((organisation) => {
				const services = organisation?.sqeservicesInfo ?? [];
				const hasActiveService = services.some((service) => service.isActive);
				return {
					organisationId: organisation.organisationId,
					organisationName: organisation.organisationName,
					hasActiveService,
					integrationDate: organisation.integrationDate,
					isInvoiceExist: organisation.isInvoiceExist,
					isEligibleToEdit: organisation.isEligibleToEdit,
					isActive: organisation.isActive,
					key: organisation.organisationId,
					services,
				};
			});
			setData(organisationServices);
			setIsHighlightedDataExist(Boolean(highlightOrganisationServiceResponse));
			setTotalCount(totalItems);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
		onSettled: () => {
			setIsLoading(false);
		},
	});

	const handleMonthChange = (date: Dayjs | null) => {
		setIsLoading(true);
		selectedRows.length && setSelectedRows([]);
		setSelectedMonth(date);
		setCurrentPage(1);
	};

	const handleChangePageSize = (size: number) => {
		setIsLoading(true);
		onChangePageSize(size);
	};

	const handleChangeCurrentPage = (page: number) => {
		setIsLoading(true);
		onChangeCurrentPage(page);
	};

	const goToDetail = (event: MouseEvent, id: string) => {
		if (isLoading) return;

		const target = event.target as Element;
		const attribute = target?.getAttribute('alt') ?? '';

		if (attribute === 'Ellipsis icon') return;

		navigate(`/business-unit/${id}`);
	};

	const onChangeStatus = (value: StatusFilter) => {
		if (value === status) return;

		setIsLoading(true);
		selectedRows.length && setSelectedRows([]);
		setStatus(value);
		setCurrentPage(1);
	};

	const onSearch = (value: string) => {
		setIsLoading(true);
		setSearch(value);
		onDebounceInput(value);
	};

	const onMenuClick = (event: MouseEvent, url: string, key: string) => {
		event.stopPropagation();
		if (key === ACTION_MENU_KEY.PREVIEW) {
			window.open(url, '_blank');
			return;
		}
		if (key === ACTION_MENU_KEY.EDIT) setIsLoadingDetails(true);

		navigate(url);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onDebounceInput = useCallback(
		_debounce((value: string) => {
			setDebounceSearch(value);
			setCurrentPage(1);
		}, 300),
		[]
	);

	const onCheckId = (event: CheckboxChangeEvent, organisationId: string) => {
		const isSelected = event.target.checked;

		isSelected
			? setSelectedRows([...selectedRows, organisationId])
			: setSelectedRows(selectedRows.filter((id) => id !== organisationId));
	};

	const onCheckAll = (event: CheckboxChangeEvent) => {
		const isCheckAll = event.target.checked;

		isCheckAll
			? setSelectedRows([...new Set([...selectedRows, ...availableIds])])
			: setSelectedRows(
					selectedRows.filter((id) => !availableIds.includes(id))
			  );
	};

	const onTableChange = (sorter: SorterResult<OrganisationService>) => {
		setIsLoading(true);

		const by =
			sorter.order && sorter.columnKey ? (sorter.columnKey as string) : '';
		let order = '';

		if (sorter.order === 'ascend') order = 'asc';
		if (sorter.order === 'descend') order = 'desc';

		setSort({
			by,
			order,
		});
	};

	const stopCheckboxPropagation = (event: MouseEvent<HTMLInputElement>) => {
		event.stopPropagation();
	};

	const selectAllChecker = () => {
		return (
			<Checkbox
				indeterminate={!isAllIdsChecked && indeterminate}
				onChange={onCheckAll}
				checked={isAllIdsChecked}
				disabled={availableIds.length === 0}
			/>
		);
	};

	const columns: ColumnsType<OrganisationService> = [
		{
			title: selectAllChecker,
			width: 48,
			fixed: 'left',
			render: (_, { organisationId, isInvoiceExist }) => {
				if (!isInvoiceExist) return <Checkbox disabled={true} />;

				return (
					<Checkbox
						onClick={stopCheckboxPropagation}
						onChange={(event) => onCheckId(event, organisationId)}
						checked={selectedRows.includes(organisationId)}
					/>
				);
			},
		},
		{
			title: 'Business Unit',
			dataIndex: 'organisationName',
			width: 240,
			key: 'organisation_name',
			sorter: true,
			fixed: 'left',
			render: (_, { organisationName, isInvoiceExist }) => {
				return (
					<div className="flex flex-col">
						<Paragraph size="r" weight="regular">
							{organisationName}
						</Paragraph>
						{isInvoiceExist ? null : (
							<Paragraph
								className="mt-1 text-disable"
								size="s"
								weight="regular"
							>
								Invoice tidak tersedia
							</Paragraph>
						)}
					</div>
				);
			},
		},
		{
			title: 'Status',
			dataIndex: 'isActive',
			width: 128,
			fixed: 'left',
			render: (_, { isActive }) => {
				const colorTag = isActive ? 'bg-green-500' : 'bg-red-500';

				return (
					<div className="flex items-center">
						<span className={`mr-2 w-3 h-3 ${colorTag} rounded-md`} />
						{isActive ? 'Aktif' : 'Non Aktif'}
					</div>
				);
			},
		},
		{
			title: 'Tanggal integrasi',
			dataIndex: 'integrationDate',
			key: 'integration_date',
			width: 165,
			sorter: true,
			fixed: 'left',
			render: (_, { integrationDate }) => {
				return (
					<Paragraph size="r">
						{integrationDate
							? dayjs(integrationDate).format('DD MMM YYYY')
							: ''}
					</Paragraph>
				);
			},
		},
		{
			title: 'Service yang dipakai',
			dataIndex: 'sqeservicesInfo',
			fixed: 'left',
			render: (_, data) => {
				return <ServiceTag services={data.services} />;
			},
		},
		{
			title: '',
			key: 'action',
			align: 'center',
			width: 52,
			fixed: 'right',
			render: (_, data) => {
				let dropdownActions = data.hasActiveService
					? ACTION_MENU
					: ACTION_MENU.filter((action) => action.key === ACTION_MENU_KEY.EDIT);

				dropdownActions = dropdownActions.filter((action) => {
					if (!data.isInvoiceExist) {
						return action.key !== ACTION_MENU_KEY.PREVIEW;
					}

					if (!data.isEligibleToEdit) {
						return action.key === ACTION_MENU_KEY.PREVIEW;
					}

					return true;
				});

				if (!data.isEligibleToEdit && !data.isInvoiceExist) return null;

				return (
					<div className="w-full h-full flex items-center justify-center">
						<Popover
							content={
								<ul className="w-[230px] py-1">
									{dropdownActions.map((action) => {
										let url = action.href;
										url =
											action.key === ACTION_MENU_KEY.CUSTOM
												? `${action.href}/${data.organisationId}`
												: url;
										url =
											action.key === ACTION_MENU_KEY.EDIT
												? `/business-unit/${data.organisationId}/edit`
												: url;
										url =
											action.key === ACTION_MENU_KEY.PREVIEW
												? `/invoice-management/preview/${
														data.organisationId
												  }?usage_period=${selectedMonth?.format('YYYY-MM')}`
												: url;

										const actionClass = classNames(
											'py-1 px-4 text-body-r-regular',
											{
												'cursor-not-allowed text-gray-300':
													!data.isEligibleToEdit,
												'cursor-pointer pointer-events-auto hover:bg-blue-50 !text-black':
													data.isEligibleToEdit ||
													(data.isInvoiceExist &&
														action.key === ACTION_MENU_KEY.PREVIEW),
											}
										);

										return (
											<li
												onClick={(event) => {
													onMenuClick(event, url, action.key);
												}}
												key={action.key}
												className={actionClass}
											>
												{action.text}
											</li>
										);
									})}
								</ul>
							}
							trigger="click"
							placement="bottomRight"
						>
							<div className="w-6 h-6 rounded cursor-pointer hover:bg-gray-10">
								<EllipsisIcon />
							</div>
						</Popover>
					</div>
				);
			},
		},
	];

	const availableIds = data.reduce((ids, current) => {
		return current.isInvoiceExist ? [...ids, current.organisationId] : ids;
	}, [] as string[]);

	const isAllIdsChecked =
		selectedRows.length > 0
			? availableIds.every((value) => selectedRows.includes(value))
			: false;

	const indeterminate = selectedRows.some((value) =>
		availableIds.includes(value)
	);

	const selectedName =
		data.find(
			(organisation) => organisation.organisationId === selectedRows?.[0]
		)?.organisationName ?? '';

	useEffect(() => {
		const pageQuery = parseInt(
			convertToNumericOnly(searchParams.get('page') as string) || '1'
		);
		let sizeQuery = parseInt(
			convertToNumericOnly(searchParams.get('size') as string) || '10'
		);
		sizeQuery = PAGINATION_SIZE.includes(sizeQuery) ? sizeQuery : 10;

		const search = searchParams.get('search') ?? '';
		const month = searchParams.get('month') ?? '';
		const year = searchParams.get('year') ?? '';
		let status = (searchParams.get('status') as StatusFilter) ?? '';
		status = Object.values(STATUS_OPTIONS_VALUES).includes(status)
			? status
			: '';

		const validDate = getValidMonthYear(parseInt(month), parseInt(year));

		onChangeCurrentPage(parseInt(pageQuery.toString()));
		onChangePageSize(parseInt(sizeQuery.toString()));
		setSearch(search);
		setDebounceSearch(search);
		setStatus(status);
		setSelectedMonth(dayjs(`${validDate.year}-${validDate.month}-01`));
		setIsQueryEnabled(true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useUpdateDefaultParams({
		selectedMonth,
		currentPage,
		pageSize,
		search,
		status,
		setSearchParams,
	});

	return (
		<>
			<div className="flex justify-between items-center mb-6">
				<div className="flex">
					<SearchBar
						size="compact"
						className="w-[300px]"
						placeholder="Cari nama business unit"
						value={search}
						onChange={onSearch}
					/>
					<div className="ml-4">
						<SpMonthPicker
							size="lg"
							value={selectedMonth}
							onMonthChange={handleMonthChange}
						/>
					</div>
					<div className="ml-4">
						<SpSelect
							size="lg"
							placement="bottomLeft"
							contentClassName="w-[200px]"
							prefix="Status :"
							value={status}
							suffix={<Icon name="chevron-large-down" color="currentColor" />}
							options={STATUS_OPTIONS}
							onChange={onChangeStatus}
						/>
					</div>
				</div>
				<DownloadDropdown
					ids={selectedRows}
					date={selectedMonth}
					selectedName={selectedName}
				></DownloadDropdown>
			</div>
			<div className={style['org-service-table']}>
				<SpTable
					rowClassName="cursor-pointer"
					scroll={{ x: 1000 }}
					onRow={(record) => {
						return {
							onClick: (event) => goToDetail(event, record.organisationId),
						};
					}}
					className={isHighlightedDataExist ? style['highlighted'] : ''}
					dataSource={data}
					columns={columns}
					defaultTable
					pagination={false}
					isLoading={isLoading}
					bordered
					onChange={(_pagination, _filters, sorter) =>
						onTableChange(sorter as SorterResult<OrganisationService>)
					}
				/>
				{data.length && !isLoading ? (
					<SpPagination
						currentPage={currentPage}
						pageSize={pageSize}
						totalCount={totalCount}
						leftSection={
							<div className="flex gap-4 text-body-r-regular">
								<div className="flex items-center">
									<span className="flex w-5 h-5 rounded bg-green-50 border border-green-500 mr-2" />
									Harga Standar
								</div>
								<div className="flex items-center">
									<span className="flex w-5 h-5 rounded bg-yellow-50 border border-orange-500 mr-2" />
									Harga Kustom
								</div>
							</div>
						}
						onChangePageSize={handleChangePageSize}
						onChangeCurrentPage={handleChangeCurrentPage}
					></SpPagination>
				) : null}
			</div>
		</>
	);
};

export default OrganisationServiceTable;
