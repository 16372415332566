import dayjs, { locale } from 'dayjs';

locale('id');

export const getValidMonthYear = (month: number, year: number) => {
	const currentMonth = dayjs().month() + 1;
	const currentYear = dayjs().year();
	const useCurrentDate =
		((year > currentYear || year === currentYear) && month > currentMonth) ||
		isNaN(month) ||
		isNaN(year);

	return {
		month: useCurrentDate ? currentMonth : month,
		year: useCurrentDate ? currentYear : year,
	};
};
