import { useState, useEffect, useRef } from 'react';
import { Button, Typography } from '@squantumengine/horizon';
import { DatePicker, ConfigProvider } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { CalendarIcon } from 'common/components/icons';
import style from './index.module.scss';
import {
	DATE_TYPE_OPTIONS,
	LOCAL_CALENDAR,
	DATE_OPTIONS,
} from './sp-range-picker.constants';
import type {
	DateOptions,
	RangeValue,
	SpRangePickerProps,
} from './sp-range-picker.interfaces';

dayjs.extend(isSameOrAfter);

const { RangePicker } = DatePicker;
const { Paragraph } = Typography;

const SpRangePicker = ({
	dates,
	dateType = DATE_TYPE_OPTIONS.custom,
	maxRangeInDays = 0,
	showOptions = false,
	setDateType,
	setDates,
}: SpRangePickerProps) => {
	const [activeIndex, setActiveIndex] = useState<0 | 1>(0);
	const [internalDates, setinternalDates] = useState<RangeValue>([
		dayjs(dates[0]),
		dayjs(dates[1]),
	]);
	const [isOpen, setIsOpen] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);

	const disabledDate = (current: Dayjs) => {
		const validStartDate = dayjs(internalDates[0]).isValid();
		const validEndDate = dayjs(internalDates[1]).isValid();

		return (
			current > dayjs().endOf('day') ||
			(activeIndex === 0 &&
				validEndDate &&
				current >= dayjs(internalDates[1]).endOf('day')) ||
			(activeIndex === 0 &&
				validEndDate &&
				maxRangeInDays > 0 &&
				current <=
					dayjs(internalDates[1])
						.subtract(maxRangeInDays, 'day')
						.endOf('day')) ||
			(activeIndex === 1 &&
				validStartDate &&
				current <= dayjs(internalDates[0]).startOf('day')) ||
			(activeIndex === 1 &&
				validStartDate &&
				maxRangeInDays > 0 &&
				current >=
					dayjs(internalDates[0]).add(maxRangeInDays, 'day').startOf('day'))
		);
	};

	const onApplyDate = () => {
		const startDate = internalDates[0] as Dayjs;
		const endDate = internalDates[1] as Dayjs;

		setDates([startDate, endDate]);
		toggleDatePicker();
	};

	const onCalendarChange = (dates: RangeValue | null) => {
		setActiveIndex(activeIndex === 0 ? 1 : 0);
		if (dateType !== DATE_TYPE_OPTIONS.custom) {
			setDateType?.(DATE_TYPE_OPTIONS.custom);
		}
		setinternalDates(dates as RangeValue);
	};

	const onResetDate = () => {
		setActiveIndex(0);
		setinternalDates(dates);
	};

	const onSetDateType = (dateType: DateOptions) => {
		let date: RangeValue;

		if (dateType === DATE_TYPE_OPTIONS.today) {
			date = [dayjs(), dayjs()];
		} else if (dateType === DATE_TYPE_OPTIONS.yesterday) {
			date = [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')];
		} else if (dateType === DATE_TYPE_OPTIONS.lastWeek) {
			date = [dayjs().subtract(7, 'day'), dayjs()];
		} else if (dateType === DATE_TYPE_OPTIONS.thisMonth) {
			date = [dayjs().startOf('month'), dayjs()];
		} else {
			date = [null, null];
		}

		if (dateType !== DATE_TYPE_OPTIONS.custom) setActiveIndex(0);

		setDateType?.(dateType);
		setinternalDates(date);
	};

	const onOutsideClick = (event: MouseEvent) => {
		const pickerRef = document.getElementsByClassName('ant-picker-dropdown')[0];
		const rangepickerBtn = document.getElementById('rangepicker-btn');

		/* istanbul ignore next */
		if (rangepickerBtn?.contains(event.target as Node)) return;

		const isBlur =
			containerRef.current &&
			!containerRef.current.contains(event.target as Node) &&
			!pickerRef?.contains(event.target as Node);

		if (isBlur) {
			setIsOpen(false);
			onResetDate();
			setActiveIndex(0);
		}
	};

	const toggleDatePicker = () => {
		if (!isOpen) setActiveIndex(0);
		setIsOpen(!isOpen);
	};

	const isDisabledDate =
		!dayjs(internalDates[0]).isValid() || !dayjs(internalDates[1]).isValid();
	const textDate = dayjs(dates[0]).isSame(dayjs(dates[1]))
		? `${dayjs(dates[0]).format('DD MMM YYYY')}`
		: `${dayjs(dates[0]).format('DD MMM YYYY')} - ${dayjs(dates[1]).format(
				'DD MMM YYYY'
		  )}`;

	useEffect(() => {
		isOpen
			? document.addEventListener('click', onOutsideClick, true)
			: document.removeEventListener('click', onOutsideClick, true);

		return () => {
			document.removeEventListener('click', onOutsideClick, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	return (
		<div className={`relative ${style['sp-range-picker']}`} ref={containerRef}>
			<div
				className="flex border border-stroke-primary h-10 px-4 rounded-lg text-body-r-regular text-primary justify-between items-center cursor-pointer bg-neutral-0"
				onClick={toggleDatePicker}
			>
				<CalendarIcon />
				<span className="ml-2">{textDate}</span>
			</div>
			{isOpen ? (
				<div className="flex absolute z-10 rounded-lg p-4 bg-neutral-0 top-[calc(100%+12px)] shadow-base">
					{showOptions && (
						<>
							<div className="flex flex-col w-[264px]">
								<Paragraph weight="semibold" size="l">
									Tanggal diunggah
								</Paragraph>
								<ul className="mt-6 flex flex-col">
									{DATE_OPTIONS.map((option) => {
										const isActive = option.value === dateType;
										const containerClassName = classNames(
											'py-3 px-6 relative cursor-pointer rounded',
											{
												'bg-blue-50 text-blue-500': isActive,
												'text-primary hover:bg-neutral-50': !isActive,
											}
										);

										return (
											<li
												key={option.value}
												className={containerClassName}
												onClick={() => onSetDateType(option.value)}
											>
												<Paragraph
													className="text-inherit"
													size="r"
													weight="semibold"
												>
													{option.title}
												</Paragraph>
												{isActive && (
													<span className="absolute transform -translate-y-1/2 translate-x-0 flex w-1 h-5 bg-blue-500 rounded-l rounded-[24px] top-1/2 left-0" />
												)}
											</li>
										);
									})}
								</ul>
							</div>
							<div className="flex flex-col border-r border-[#e8e8e8] mx-3" />
						</>
					)}
					<div className="flex flex-col">
						<div>
							<ConfigProvider
								theme={{
									token: {
										motion: false,
									},
								}}
							>
								<RangePicker
									mode={['date', 'date']}
									open={true}
									activePickerIndex={activeIndex}
									placeholder={['Tanggal Mulai', 'Tanggal Berakhir']}
									format="DD/MM/YYYY"
									locale={LOCAL_CALENDAR}
									value={internalDates}
									onCalendarChange={onCalendarChange}
									disabledDate={disabledDate}
									getPopupContainer={(triggerNode) =>
										triggerNode.parentElement as HTMLElement
									}
									className="hidden"
									popupClassName="static top-0 left-0 p-0"
								/>
							</ConfigProvider>
						</div>
						<div className="flex justify-end mt-4">
							<span className="mr-4">
								<Button variant="text" onClick={onResetDate}>
									Reset
								</Button>
							</span>
							<Button disabled={isDisabledDate} onClick={onApplyDate}>
								Terapkan
							</Button>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default SpRangePicker;
