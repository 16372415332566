import type { CalculationType } from 'features/price/price.interfaces';

export const TYPE_OPTIONS: {
	title: string;
	value: CalculationType;
}[] = [
	{
		value: 'UNIT',
		title: 'Satuan',
	},
	{
		value: 'TIER',
		title: 'Tier',
	},
	{
		value: 'MULTIPLIER',
		title: 'Multiplier',
	},
	{
		value: 'FREE',
		title: 'Gratis',
	},
];
