import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.5312 4L14.4375 4.03125C14.1875 4.03125 14 4.21875 14 4.46875V5.5C14 5.75 14.1875 5.96875 14.4375 5.96875L16.75 5.875L16.8125 5.9375L8.09375 14.6562C8.03125 14.7188 7.96875 14.8125 7.96875 14.9062C7.96875 15.0312 8.03125 15.125 8.09375 15.1875L8.8125 15.9062C8.875 15.9688 8.96875 16.0312 9.09375 16.0312C9.1875 16.0312 9.28125 15.9688 9.34375 15.9062L18.0625 7.1875L18.125 7.25L18.0312 9.5625C18.0312 9.8125 18.25 10.0312 18.5 10.0312V10H19.5312C19.7812 10 19.9688 9.8125 19.9688 9.5625L20 4.46875C20 4.21875 19.7812 4 19.5312 4ZM17.5 13H17C16.7188 13 16.5 13.25 16.5 13.5V18.3125C16.5 18.4375 16.4062 18.5 16.3125 18.5H5.6875C5.5625 18.5 5.5 18.4375 5.5 18.3125V7.6875C5.5 7.59375 5.5625 7.5 5.6875 7.5H10.5C10.75 7.5 11 7.28125 11 7V6.5C11 6.25 10.75 6 10.5 6H5.5C4.65625 6 4 6.6875 4 7.5V18.5C4 19.3438 4.65625 20 5.5 20H16.5C17.3125 20 18 19.3438 18 18.5V13.5C18 13.25 17.75 13 17.5 13Z"
			fill="#006CEB"
		/>
	</svg>
);

const ExternalLinkIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default ExternalLinkIcon;
