import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import PriceDashboard from 'features/price/dashboard';
import type { PriceServices } from 'features/price/price.interfaces';
import { useProfileStore } from 'common/stores/profile';
import ToastMessage from 'common/utils/toast-message';
import { camelize } from 'common/utils/transformer';
import useQueryUrl from 'common/hooks/use-query-url';
import type { GetOrganisationServiceApiResponse } from 'common/types/organisation';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { getOrganisationServices } from 'services/organisation';

const CustomPrice = () => {
	const { searchParams, setUrl } = useQueryUrl();
	const userId = useProfileStore((state) => state.id);

	const [currentOrganisationName, setCurrentOrganisationName] = useState('');
	const [services, setServices] = useState<PriceServices[]>([]);

	const { organisationId } = useParams();

	const onChipClick = (serviceId: string) => {
		const currentService = services.find(
			(app) => app.sqeserviceId === serviceId
		);
		const currentServiceName = currentService?.sqeserviceName as string;

		setUrl({
			keyPairs: {
				sid: serviceId,
				sname: currentServiceName,
			},
		});
	};

	const { isFetching } = useQuery({
		queryKey: 'getCurrentOrganisationServices',
		queryFn: () =>
			getOrganisationServices(userId, {
				month: dayjs().month() + 1,
				year: dayjs().year(),
				organisation_id: organisationId as string,
				page: 1,
				limit: 1,
			}),
		onSuccess: (response) => {
			const organisationServices = response?.data?.items ?? [];
			const sanitizedResponse = camelize(
				organisationServices
			) as GetOrganisationServiceApiResponse[];
			let services = sanitizedResponse?.[0]?.sqeservicesInfo ?? [];
			services = services.filter((service) => service.isActive);
			const serviceIdQuery = searchParams.get('sid');
			const currentOrganisationName =
				sanitizedResponse?.[0]?.organisationName ?? '';

			if (services.length) {
				const service = services.find(
					(service) => service.sqeserviceId === serviceIdQuery
				);

				const currentServiceId =
					service?.sqeserviceId ?? services?.[0]?.sqeserviceId;
				const currentServiceName =
					service?.sqeserviceName ?? services?.[0]?.sqeserviceName;

				setServices(services);
				setCurrentOrganisationName(currentOrganisationName);
				setUrl({
					keyPairs: {
						sid: currentServiceId,
						sname: currentServiceName,
					},
				});
			}
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const title = currentOrganisationName
		? `Kustom harga - ${currentOrganisationName}`
		: 'Kustom harga';

	return (
		<PriceDashboard
			currentOrganisationName={currentOrganisationName}
			isServicesLoading={isFetching}
			organisationId={organisationId as string}
			serviceList={services}
			title={title}
			onChipClick={onChipClick}
		></PriceDashboard>
	);
};

export default CustomPrice;
