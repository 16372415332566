import { datadogRum } from '@datadog/browser-rum';
import { local } from 'common/utils/storage';

export const getRegistrationState = () => {
	try {
		const lastState = local.getItem('draft_organisation') ?? '';
		return lastState ? JSON.parse(lastState) : {};
	} catch (error) {
		datadogRum.addError(error);
		return {};
	}
};
export const setRegistrationState = ({
	key,
	value,
}: {
	key: string;
	value: unknown;
}) => {
	const lastState = getRegistrationState();
	const updatedState = {
		...lastState,
		[key]: value,
	};

	try {
		local.setItem('draft_organisation', JSON.stringify(updatedState));
	} catch (error) {
		datadogRum.addError(error);
	}
};
