import { Button, SearchBar } from '@squantumengine/horizon';
import { Checkbox } from 'antd';
import { ALL_DOCUMENT_STATUS } from 'features/dashboard/know-your-customer/kyc-dashboard.constants';
import type { DocumentStatus } from 'features/dashboard/know-your-customer/kyc-dashboard.interfaces';
import SortIcon from 'common/components/icons/sort';
import SpSelect from 'common/components/sp-select';
import { DOCUMENT_STATUS_OPTIONS } from './document-filter.constants';
import type { DocumentFilterProps } from './document-filter.interfaces';
import style from './index.module.scss';

const DocumentFilter = ({
	searchValue,
	status,
	onSearch,
	onChangeStatus,
	onApplyFilter,
}: DocumentFilterProps) => {
	const searchDocument = (value: string) => {
		onSearch(value);
	};

	const onSelectStatus = (value: DocumentStatus) => {
		const updatedStatus = status.includes(value)
			? status.filter((option) => option !== value)
			: [...status, value];

		onChangeStatus(updatedStatus);
	};

	const onSelectAll = () => {
		const updatedStatus =
			status.length === ALL_DOCUMENT_STATUS.length ? [] : ALL_DOCUMENT_STATUS;

		onChangeStatus(updatedStatus);
	};

	const statusFilterContent = () => (
		<ul
			className={`${style['document-filter']} rounded w-[200px] py-1`}
			data-testid="documentStatusFilter"
		>
			<li className="flex px-4 h-7 items-center">
				<Checkbox
					checked={isSelectAll}
					aria-label="Select all status"
					indeterminate={!isSelectAll && status.length > 0}
					onChange={onSelectAll}
				>
					Semua status
				</Checkbox>
			</li>
			{DOCUMENT_STATUS_OPTIONS.map((option) => (
				<li className="flex px-4 h-7 items-center" key={option.value}>
					<Checkbox
						checked={status.includes(option.value)}
						onChange={() => onSelectStatus(option.value)}
					>
						{option.title}
					</Checkbox>
				</li>
			))}
			<li className="py-3 px-4">
				<Button
					size="sm"
					full
					onClick={onApplyFilter}
					disabled={status.length === 0}
				>
					Terapkan
				</Button>
			</li>
		</ul>
	);

	const isSelectAll = status.length === ALL_DOCUMENT_STATUS.length;
	const statusValue =
		status.length === ALL_DOCUMENT_STATUS.length
			? 'Semua status'
			: `Status (${status.length})`;

	return (
		<div className="flex items-center">
			<SearchBar
				className="w-[300px] mr-4"
				placeholder="Cari ID nasabah atau nama dokumen"
				value={searchValue}
				aria-label="Search document"
				onChange={searchDocument}
				size="compact"
				debounceDuration={300}
			/>
			<SpSelect
				size="lg"
				placement="bottomLeft"
				placeholder="Status Dokumen"
				value={statusValue}
				prefix={<SortIcon />}
				content={statusFilterContent}
				popoverClassName="z-[18]"
			/>
		</div>
	);
};

export default DocumentFilter;
