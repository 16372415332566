import { Suspense, lazy, useState } from 'react';
import { Label } from '@squantumengine/horizon';
import type { ColumnsType } from 'antd/es/table';
import FieldColumn from 'features/dashboard/know-your-customer/field-column';
import { EMATERAI_STATUS_PROPS } from 'features/dashboard/know-your-customer/kyc-dashboard.constants';
import style from 'features/dashboard/know-your-customer/table/index.module.scss';
import type { EmateraiTableProps } from 'features/dashboard/know-your-customer/table/table.interfaces';
import SpPagination from 'common/components/sp-pagination';
import SpTable from 'common/components/sp-table';
import useModal from 'common/hooks/use-modal';
import type { EmateraiList } from './ematerai-table.interfaces';

const EmateraiModal = lazy(
	() => import(/* webpackChunkName: "emateraiDetailModal" */ './modal')
);

const EmateraiTable = ({
	data,
	isLoading,
	limit,
	page,
	totalCount,
	onChangePageSize,
	onChangeCurrentPage,
}: EmateraiTableProps) => {
	const { visible, closeModal, openModal } = useModal();
	const [selectedData, setSelectedData] = useState<{
		contractId: string;
		stampId: string;
	}>({
		contractId: '',
		stampId: '',
	});

	const columns: ColumnsType<EmateraiList> = [
		{
			title: 'E-materai ID',
			dataIndex: 'stampId',
			key: 'stampId',
			width: 340,
			className: 'min-w-[340px]',
			render: (_, { stampId }) => <FieldColumn title={stampId} />,
		},
		{
			title: 'Document ID',
			dataIndex: 'contractId',
			key: 'contractId',
			width: 250,
			className: 'min-w-[250px]',
			render: (_, { contractId }) => <FieldColumn title={contractId} />,
		},
		{
			title: 'Nama Dokumen',
			dataIndex: 'contractName',
			key: 'contractName',
			width: 240,
			className: 'min-w-[240px]',
			render: (_, { contractName }) => <FieldColumn title={contractName} />,
		},

		{
			title: 'Status',
			dataIndex: 'stampStatus',
			key: 'stampStatus',
			width: 240,
			className: 'min-w-[240px]',
			render: (_, { stampStatus }) => {
				const documentProps =
					EMATERAI_STATUS_PROPS[stampStatus] ?? EMATERAI_STATUS_PROPS.DEFAULT;

				return (
					<div className="flex items-center justify-between">
						<Label
							label={documentProps.label}
							type={documentProps.type}
							border
						/>
					</div>
				);
			},
		},
	];

	const showEmateraiModal = (contractId: string, stampId: string) => {
		openModal();
		setSelectedData({
			contractId,
			stampId,
		});
	};

	return (
		<>
			<SpTable
				dataSource={data}
				columns={columns}
				className={style.table}
				pagination={false}
				isLoading={isLoading}
				bordered
				defaultTable
				scroll={{
					x: 900,
				}}
				rowClassName="cursor-pointer"
				onRow={({ contractId, stampId }) => {
					return {
						onClick: () => showEmateraiModal(contractId, stampId),
					};
				}}
			/>
			{data.length && !isLoading ? (
				<div className="px-6">
					<SpPagination
						currentPage={page}
						pageSize={limit}
						totalCount={totalCount}
						onChangePageSize={onChangePageSize}
						onChangeCurrentPage={onChangeCurrentPage}
					/>
				</div>
			) : null}

			<Suspense>
				{visible && selectedData && (
					<EmateraiModal
						isVisible={visible}
						closeModal={closeModal}
						isLoading={false}
						contractId={selectedData.contractId}
						stampId={selectedData.stampId}
					/>
				)}
			</Suspense>
		</>
	);
};

export default EmateraiTable;
