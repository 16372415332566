import { Suspense, lazy } from 'react';
import { useModalContext } from 'common/context/shared-modal';

const SignersModal = lazy(
	() => import(/* webpackChunkName: "signersModal" */ './signers-modal')
);

const SignersStatusModal = lazy(
	() =>
		import(
			/* webpackChunkName: "signersStatusModal" */ './signers-status-modal'
		)
);

const DownloadModal = lazy(
	() =>
		import(/* webpackChunkName: "downloadModal" */ './download-evidences-modal')
);

const SharedModal = () => {
	const { modalState, toggleSharedModal } = useModalContext();

	const closeSignersModal = () => {
		toggleSharedModal({
			name: 'signerList',
			payload: {
				isOpen: false,
				data: {
					documentId: '',
				},
			},
		});
	};

	const closeSignerStatusModal = () => {
		toggleSharedModal({
			name: 'signerStatus',
			payload: {
				isOpen: false,
				data: {
					weblinkId: '',
					contractId: '',
					refUserId: '',
				},
			},
		});
	};

	const closeDownloadModal = () => {
		toggleSharedModal({
			name: 'downloadEvidence',
			payload: {
				isOpen: false,
				data: {
					organisations: [],
				},
			},
		});
	};

	return (
		<Suspense>
			{modalState.signerList.isOpen &&
				modalState.signerList.data.documentId && (
					<SignersModal
						closeModal={closeSignersModal}
						documentId={modalState.signerList.data.documentId}
						isVisible={modalState.signerList.isOpen}
					></SignersModal>
				)}
			{modalState.signerStatus.isOpen &&
				modalState.signerStatus.data.weblinkId && (
					<SignersStatusModal
						closeModal={closeSignerStatusModal}
						weblinkId={modalState.signerStatus.data.weblinkId}
						isVisible={modalState.signerStatus.isOpen}
						refUserId={modalState.signerStatus.data.refUserId}
					/>
				)}
			{modalState.signerStatus.isOpen &&
				modalState.signerStatus.data.contractId && (
					<SignersStatusModal
						closeModal={closeSignerStatusModal}
						contractId={modalState.signerStatus.data.contractId}
						isVisible={modalState.signerStatus.isOpen}
						refUserId={modalState.signerStatus.data.refUserId}
					/>
				)}
			{modalState.downloadEvidence.isOpen && (
				<DownloadModal
					closeModal={closeDownloadModal}
					isVisible={modalState.downloadEvidence.isOpen}
					organisations={modalState.downloadEvidence.data.organisations}
				/>
			)}
		</Suspense>
	);
};

export default SharedModal;
