import type {
	BillingStatus,
	StatusGenerator,
	StatusToProgress,
} from './billing-approval.interfaces';

export const BILLING_STATUS = {
	waitingInitialization: 'WAITING_INITIALIZATION',
	reportUploaded: 'REPORT_UPLOADED',
	reportApproved: 'REPORT_APPROVED',
	reportRejected: 'REPORT_REJECTED',
	invoiceUploaded: 'INVOICE_UPLOADED',
	paymentUploaded: 'PAYMENT_UPLOADED',
	paymentConfirmed: 'PAYMENT_CONFIRMED',
} as const;
export const BILLING_GROUP_STATUS = {
	reportReconcilitation: 'REPORT_RECONCILIATION',
	invoiceIssuance: 'INVOICE_ISSUANCE',
	payment: 'PAYMENT',
} as const;
export const BILLING_ACCESS_TYPE = {
	readOnly: 'READ_ONLY',
	signer: 'SIGNER',
} as const;
export const BILLING_ACTION_ID = {
	report: 'REPORT',
	invoice: 'INVOICE',
	payment: 'PAYMENT',
} as const;
export const BILLING_CONFIRMATION_TYPE = {
	approved: 'APPROVED',
	rejected: 'REJECTED',
} as const;
export const FILE_ACTION = {
	download: 'DOWNLOAD',
	open: 'OPEN',
} as const;
export const MANAGER_STATUS_POV: {
	[key in BillingStatus]: StatusGenerator;
} = {
	WAITING_INITIALIZATION: {
		type: 'warning',
		label: 'Unggah laporan',
	},
	REPORT_UPLOADED: {
		type: 'default',
		label: 'Menunggu persetujuan',
	},
	REPORT_APPROVED: {
		type: 'warning',
		label: 'Unggah invoice',
	},
	REPORT_REJECTED: {
		type: 'danger',
		label: 'Revisi laporan',
	},
	INVOICE_UPLOADED: {
		type: 'default',
		label: 'Menunggu pembayaran',
	},
	PAYMENT_UPLOADED: {
		type: 'warning',
		label: 'Konfirmasi pembayaran',
	},
	PAYMENT_CONFIRMED: {
		type: 'info',
		label: 'Selesai',
	},
} as const;
export const PENGELOLA_STATUS_POV: {
	[key in BillingStatus]: StatusGenerator;
} = {
	WAITING_INITIALIZATION: {
		type: 'warning',
		label: 'Unggah laporan',
	},
	REPORT_UPLOADED: {
		type: 'warning',
		label: 'Konfirmasi persetujuan',
	},
	REPORT_APPROVED: {
		type: 'default',
		label: 'Menunggu invoice',
	},
	REPORT_REJECTED: {
		type: 'danger',
		label: 'Menunggu revisi',
	},
	INVOICE_UPLOADED: {
		type: 'warning',
		label: 'Unggah pembayaran',
	},
	PAYMENT_UPLOADED: {
		type: 'default',
		label: 'Menunggu selesai',
	},
	PAYMENT_CONFIRMED: {
		type: 'info',
		label: 'Selesai',
	},
} as const;
export const MANAGER_STATUS_TO_PROGRESS: {
	[key in BillingStatus]: StatusToProgress;
} = {
	WAITING_INITIALIZATION: {
		step: 1,
		label: 'Laporan pengunaan',
	},
	REPORT_UPLOADED: {
		step: 1,
		label: 'Laporan pengunaan',
	},
	REPORT_REJECTED: {
		step: 1,
		label: 'Laporan pengunaan',
	},
	REPORT_APPROVED: {
		step: 2,
		label: 'Unggah invoice',
	},
	INVOICE_UPLOADED: {
		step: 3,
		label: 'Konfirmasi pembayaran',
	},
	PAYMENT_UPLOADED: {
		step: 3,
		label: 'Konfirmasi pembayaran',
	},
	PAYMENT_CONFIRMED: {
		step: 3,
		label: '',
	},
} as const;
export const PENGELOLA_STATUS_TO_PROGRESS: {
	[key in BillingStatus]: StatusToProgress;
} = {
	WAITING_INITIALIZATION: {
		step: 1,
		label: '',
	},
	REPORT_UPLOADED: {
		step: 1,
		label: 'Konfirmasi laporan',
	},
	REPORT_REJECTED: {
		step: 1,
		label: 'Konfirmasi laporan',
	},
	REPORT_APPROVED: {
		step: 1,
		label: 'Konfirmasi laporan',
	},
	INVOICE_UPLOADED: {
		step: 2,
		label: 'Invoice dan pembayaran',
	},
	PAYMENT_UPLOADED: {
		step: 2,
		label: 'Invoice dan pembayaran',
	},
	PAYMENT_CONFIRMED: {
		step: 2,
		label: 'Invoice dan pembayaran',
	},
} as const;
export const MAX_FILES = 10;
export const MAX_FILE_SIZE = 25 * 1024 * 1024;
export const ALLOWED_TYPES = [
	'application/pdf',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'image/jpeg',
	'image/png',
];
export const BILLING_SORT_KEYS = {
	organisationName: 'organisation_name',
};
export const MANAGER_STATUS_FOR_SKIP_REPORT: BillingStatus[] = [
	BILLING_STATUS.reportUploaded,
	BILLING_STATUS.invoiceUploaded,
	BILLING_STATUS.paymentConfirmed,
];
export const PENGELOLA_STATUS_FOR_SKIP_REPORT: BillingStatus[] = [
	BILLING_STATUS.waitingInitialization,
	BILLING_STATUS.reportRejected,
	BILLING_STATUS.reportApproved,
	BILLING_STATUS.paymentUploaded,
	BILLING_STATUS.paymentConfirmed,
];
