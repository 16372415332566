import { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Typography } from '@squantumengine/horizon';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import type { TooltipProps } from 'recharts';
import type {
	ValueType,
	NameType,
} from 'recharts/types/component/DefaultTooltipContent';
import type {
	UsageSummary,
	ChartData,
} from 'common/components/analytic/analytic.interfaces';
import Filter from 'common/components/analytic/filter';
import Summary from 'common/components/analytic/summary';
import Tooltip from 'common/components/analytic/tooltip';
import useInitiateAnalytic from 'common/components/analytic/use-initiate-analytic';
import Widget from 'common/components/analytic/widget';
import SectionHeader from 'common/components/section-header';
import { downloadFile } from 'common/utils/file';
import ToastMessage from 'common/utils/toast-message';
import {
	camelize,
	convertToThousandsSeparator,
} from 'common/utils/transformer';
import useAnalytic from 'common/hooks/use-analytic';
import { useIntersection } from 'common/hooks/use-intersection';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { downloadAnalytic, getAnalytic } from 'services/usage';
import type { GetCCAnalyticUsagesApiResponse } from './contact-center.interfaces';
import Skeleton from './skeleton';

const { Paragraph } = Typography;

const ContactCenter = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { selectedBusinessUnit, selectedBusinessUnitName, generateChartData } =
		useAnalytic();
	const { isQueryEnabled, selectedMonth, setSelectedMonth } =
		useInitiateAnalytic();

	const [analytic, setAnalytic] = useState({
		status: false,
		date: '-',
	});
	const [agentChart, setAgentChart] = useState<ChartData[]>([]);
	const [conversationChart, setConversationChart] = useState<ChartData[]>([]);
	const [total, setTotal] = useState({
		duration: 0,
		conversation: 0,
		agent: 0,
	});
	const [summary, setSummary] = useState<UsageSummary[]>([]);
	const [updatedAt, setUpdatedAt] = useState('');

	const organisationId = searchParams.get('oid') ?? '';

	const { isFetching } = useQuery({
		queryKey: ['getAnalytic', selectedMonth, organisationId],
		queryFn: () =>
			getAnalytic({
				organisation_id: organisationId,
				period: dayjs(selectedMonth).format('YYYY-MM'),
				service_tag: 'cc',
			}),
		enabled: isQueryEnabled,
		onSuccess: (response) => {
			const data = response?.data ?? null;
			const sanitizedResponse = camelize(
				data
			) as GetCCAnalyticUsagesApiResponse;

			const totalDuration =
				sanitizedResponse.service.cc.videoCall.totalDuration;
			const totalAgent = sanitizedResponse.service.cc.agent.total;
			const analyticDashboardStatus =
				sanitizedResponse.service.cc.analyticDashboard.currentStatus;

			setAnalytic({
				date: sanitizedResponse.service.cc.analyticDashboard.lastUsedAt
					? dayjs(
							sanitizedResponse.service.cc.analyticDashboard.lastUsedAt
					  ).format('DD MMMM YYYY')
					: '-',
				status: analyticDashboardStatus,
			});
			setUpdatedAt(sanitizedResponse.service.cc.lastUpdatedAt);
			setTotal({
				duration: totalDuration,
				conversation: sanitizedResponse.service.cc.videoCall.totalConversation,
				agent: totalAgent,
			});
			setSummary([
				{
					key: 1,
					serviceType: 'Video Call',
					usages: `${convertToThousandsSeparator(
						totalDuration.toString()
					)} minutes`,
					status: sanitizedResponse.service.cc.videoCall.isActive,
				},
				{
					key: 2,
					serviceType: 'Text',
					usages: `${convertToThousandsSeparator(totalAgent.toString())} agent`,
					status: sanitizedResponse.service.cc.agent.isActive,
				},
				{
					key: 3,
					serviceType: 'Analytic Dashboard',
					usages: '-',
					status: analyticDashboardStatus,
				},
			]);

			const conversationChart = generateChartData(
				sanitizedResponse.service.cc.videoCall.usagesPerWeek
			);
			const agentChart = generateChartData(
				sanitizedResponse.service.cc.agent.usagesPerWeek
			);

			setConversationChart(conversationChart);
			setAgentChart(agentChart);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const { refetch: download, isFetching: isDownloadLoading } = useQuery({
		queryKey: ['downloadAnalytic'],
		enabled: false,
		queryFn: () =>
			downloadAnalytic({
				organisation_id: organisationId,
				period: dayjs(selectedMonth).format('YYYY-MM'),
				service_tag: 'cc',
			}),
		onSuccess: (response) => {
			const filename = `SQECC-${selectedBusinessUnitName}-${dayjs(
				selectedMonth
			).format('YYYY-MM')}.xlsx`;

			downloadFile(response, filename);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const onDownload = () => {
		ToastMessage({
			type: 'info',
			label: 'Dokumen sedang diunduh.',
		});
		download();
	};

	const onMonthChange = (date: Dayjs | null) => {
		setSelectedMonth(date);
	};

	const renderConversationCustomTooltip = ({
		active,
		payload,
	}: TooltipProps<ValueType, NameType>) => {
		if (!active) return null;

		const data =
			(payload?.[0].payload as ChartData) ?? /* istanbul ignore next */ null;

		/* istanbul ignore next */
		if (!data) return null;

		const usages =
			data.additionalData?.dayUsages ?? /* istanbul ignore next */ [];

		return (
			<Tooltip
				usages={usages}
				unit="menit"
				totalText="Total durasi"
				total={convertToThousandsSeparator(data.value.toString())}
			/>
		);
	};

	const renderAgentCustomTooltip = ({
		active,
		payload,
	}: TooltipProps<ValueType, NameType>) => {
		if (!active) return null;

		const data =
			(payload?.[0].payload as ChartData) ?? /* istanbul ignore next */ null;

		/* istanbul ignore next */
		if (!data) return null;

		const usages =
			data.additionalData?.dayUsages ?? /* istanbul ignore next */ [];

		return (
			<Tooltip
				usages={usages}
				unit="agen"
				totalText="Total agen"
				total={convertToThousandsSeparator(data.value.toString())}
			/>
		);
	};

	const isDownloadDisable =
		total.agent === 0 && total.conversation === 0 && total.duration === 0;

	const filterRef = useRef<HTMLDivElement>(null);
	const isIntersecting = useIntersection(filterRef);

	return (
		<div className="py-8">
			<div ref={filterRef}>
				<SectionHeader title="Dashboard" backHandler={() => navigate(-1)} />
			</div>
			<div
				className={
					!isIntersecting
						? 'sticky top-0 bg-neutral-0 z-[19] py-4 shadow-base right-0 -mx-8 px-8'
						: 'mt-8'
				}
			>
				<Filter
					path="/contact-center"
					activeService="SQECC"
					downloadDisable={isDownloadDisable}
					downloadLoading={isDownloadLoading}
					selectedBusinessUnit={selectedBusinessUnit}
					selectedMonth={selectedMonth}
					updatedAt={updatedAt}
					onMonthChange={onMonthChange}
					onDownload={onDownload}
				/>
			</div>
			{isFetching ? (
				<Skeleton />
			) : (
				<div className="flex flex-wrap gap-6 mt-6">
					<div className="w-[calc((100%-48px)/3)]">
						<Widget
							data={conversationChart}
							header={[
								{
									title: 'Total durasi panggilan',
									subtitle: `${convertToThousandsSeparator(
										total.duration.toString()
									)} menit`,
								},
								{
									title: 'Jumlah panggilan video',
									subtitle: convertToThousandsSeparator(
										total.conversation.toString()
									),
								},
							]}
							footer="Durasi panggilan"
							renderTooltip={renderConversationCustomTooltip}
						/>
					</div>
					<div className="w-[calc((100%-48px)/3)]">
						<Widget
							data={agentChart}
							header={[
								{
									title: 'Jumlah agen yang menggunakan text',
									subtitle: convertToThousandsSeparator(total.agent.toString()),
								},
							]}
							footer="Jumlah agen yang menggunakan text"
							renderTooltip={renderAgentCustomTooltip}
						/>
					</div>
					<div className="w-[calc((100%-48px)/3)]">
						<Widget
							header={[
								{
									title: 'Analytic terakhir dipakai',
									subtitle: analytic.date,
								},
							]}
							footer={
								<div className="flex justify-end items-center mt-6">
									<Paragraph
										className="text-secondary"
										size="s"
										weight="regular"
									>
										Status saat ini
									</Paragraph>
									<div
										className={`mr-2 ml-3 w-3 h-3 rounded-[6px] ${
											analytic.status ? 'bg-green-500' : 'bg-red-500'
										}`}
									/>
									<Paragraph
										className="text-secondary"
										size="s"
										weight="regular"
									>
										{analytic.status ? 'Aktif' : 'Nonaktif'}
									</Paragraph>
								</div>
							}
						/>
					</div>
				</div>
			)}
			<div className="mt-8">
				<Summary data={summary} isLoading={isFetching} />
			</div>
		</div>
	);
};

export default ContactCenter;
