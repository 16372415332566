import type { GetActivityLogApiParams } from 'features/activity-log/activity-log.interfaces';
import axios from './axios-instance';

const baseUrl = '/v1/activity-log';

export const getActivityLog = async (params: GetActivityLogApiParams) => {
	const response = await axios.get(baseUrl, {
		params,
	});

	return response.data;
};
export const downloadActivityLog = async (params: GetActivityLogApiParams) => {
	const response = await axios.get(`${baseUrl}/download`, {
		params,
		responseType: 'blob',
	});

	return response.data;
};
