import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
	createSearchParams,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import { Button, Typography } from '@squantumengine/horizon';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import type {
	GetPriceApiResponse,
	PriceServiceText,
} from 'features/price/price.interfaces';
import ServiceChip from 'features/price/service-chip';
import ServiceChipSkeleton from 'features/price/service-chip/skeleton';
import PriceTable from 'features/price/table';
import SectionHeader from 'common/components/section-header';
import SpSkeleton from 'common/components/sp-skeleton';
import ToastMessage from 'common/utils/toast-message';
import { camelize } from 'common/utils/transformer';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { getPrices } from 'services/price';
import type { PriceDashboardProps } from './dashboard.interfaces';
import {
	generateCurrentPeriodPrices,
	generateNextPeriodPrices,
} from './dashboard.utils';

dayjs.locale('en');

const { Paragraph } = Typography;

const PriceDashboard = ({
	currentOrganisationName,
	isServicesLoading,
	organisationId,
	serviceList,
	title,
	onChipClick,
}: PriceDashboardProps) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [currentPeriodActiveDate, setCurrentPeriodActiveDate] = useState('');
	const [currentPeriodPrices, setCurrentPeriodPrices] = useState<
		PriceServiceText[]
	>([]);
	const [isPricesLoading, setIsPricesLoading] = useState(true);
	const [lastUpdatedAt, setLastUpdatedAt] = useState('');
	const [nextPeriodPrices, setNextPeriodPrices] = useState<PriceServiceText[]>(
		[]
	);
	const [nextPeriodActiveDate, setNextPeriodActiveDate] = useState('');

	const serviceId = searchParams.get('sid') ?? '';
	const serviceName = searchParams.get('sname') ?? '';

	const { refetch: getServicePrices } = useQuery({
		queryKey: 'getPrices',
		queryFn: () =>
			getPrices({
				service_id: serviceId,
				organisation_id: organisationId,
			}),
		enabled: false,
		onSuccess: (response) => {
			const data = response?.data ?? null;
			const sanitizedResponse: GetPriceApiResponse | null = data
				? camelize(data)
				: data;
			const activePrices = sanitizedResponse?.activePrices ?? [];
			const currentActivePricesPeriod =
				sanitizedResponse?.activePriceEffectiveDate ?? '';
			const currentNextPricesPeriod =
				sanitizedResponse?.nextPeriodPriceEffectiveDate ?? '';

			const nextPrices = sanitizedResponse?.nextPeriodPrices ?? [];
			const lastUpdatedAt = sanitizedResponse?.lastUpdatedAt ?? '';
			const currentPeriodPrices = generateCurrentPeriodPrices(activePrices);
			const nextPeriodPrices = generateNextPeriodPrices(
				activePrices,
				nextPrices
			);

			setCurrentPeriodPrices(currentPeriodPrices);
			setNextPeriodPrices(nextPeriodPrices);
			setCurrentPeriodActiveDate(currentActivePricesPeriod);
			setNextPeriodActiveDate(currentNextPricesPeriod);
			setLastUpdatedAt(lastUpdatedAt);
			setIsPricesLoading(false);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
			setCurrentPeriodPrices([]);
			setNextPeriodPrices([]);
			setLastUpdatedAt('');
			setIsPricesLoading(false);
		},
	});

	const goToEditPrice = () => {
		const pathname = organisationId
			? `/price/custom/${organisationId}/edit`
			: '/price/general/edit';
		const searchQuery = {
			sid: serviceId,
			sname: serviceName,
		} as {
			sid: string;
			sname: string;
			oname?: string;
		};

		if (currentOrganisationName) searchQuery.oname = currentOrganisationName;

		navigate({
			pathname,
			search: createSearchParams(searchQuery).toString(),
		});
	};

	const activePricesSubtitle = dayjs(currentPeriodActiveDate).isValid()
		? `Harga ini berlaku sampai ${dayjs(currentPeriodActiveDate)
				.endOf('month')
				.format('D MMMM YYYY')}.`
		: '';

	const lastUpdatedInfo = lastUpdatedAt
		? `Terakhir diperbarui: ${dayjs(lastUpdatedAt).format('D MMMM YYYY')}`
		: '';

	const nextPricesSubtitle = dayjs(nextPeriodActiveDate).isValid()
		? `Harga baru ini akan efektif berlaku pada ${dayjs(
				nextPeriodActiveDate
		  ).format('D MMMM YYYY')}.`
		: '';

	let serviceSection = isServicesLoading ? (
		<ServiceChipSkeleton></ServiceChipSkeleton>
	) : null;
	serviceSection = serviceList.length ? (
		<ServiceChip
			className="mt-6"
			services={serviceList}
			activeService={serviceId}
			setActiveService={onChipClick}
		/>
	) : (
		serviceSection
	);

	useEffect(() => {
		if (!serviceId || serviceList.length === 0) return;

		setIsPricesLoading(true);
		getServicePrices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serviceId, serviceList]);

	return (
		<div className="py-8">
			<SectionHeader
				backHandler={() => navigate(-1)}
				isLoading={isServicesLoading}
				title={title}
			></SectionHeader>
			{serviceSection}
			{isPricesLoading || isServicesLoading ? (
				<>
					<SpSkeleton className="w-full rounded-lg h-[400px] mt-6"></SpSkeleton>
					<SpSkeleton className="w-full rounded-lg h-[400px] mt-6"></SpSkeleton>
				</>
			) : (
				<>
					{nextPeriodPrices.length ? (
						<PriceTable
							showFlag={Boolean(organisationId)}
							title="Perubahan harga bulan depan"
							subtitle={nextPricesSubtitle}
							prices={nextPeriodPrices}
							showLegend
						/>
					) : null}
					<PriceTable
						showFlag={Boolean(organisationId)}
						title="Harga aktual bulan ini"
						subtitle={activePricesSubtitle}
						prices={currentPeriodPrices}
					/>
				</>
			)}
			<div className="flex justify-end items-center mt-6">
				{isPricesLoading || isServicesLoading ? (
					<SpSkeleton className="w-[250px] h-[32px]"></SpSkeleton>
				) : (
					<>
						{lastUpdatedInfo ? (
							<Paragraph
								className="text-secondary mr-4"
								weight="regular"
								size="r"
							>
								{lastUpdatedInfo}
							</Paragraph>
						) : null}
						<Button size="sm" onClick={goToEditPrice}>
							Edit
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

export default PriceDashboard;
