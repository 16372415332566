import { Divider } from 'antd';
import CardSkeleton from 'features/main-dashboard/product-card/skeleton';
import SpSkeleton from 'common/components/sp-skeleton';

const ManagerSkeleton = () => {
	const skeletonArray = new Array(3).fill(null).map((_, index) => index);
	const rowArray = new Array(4).fill(null).map((_, index) => index);

	return (
		<div>
			<SpSkeleton className="w-[132px] h-[32px]" />
			<div className="mt-6">
				<div className="flex">
					<div className="flex items-center w-[70px] h-10">
						<SpSkeleton className="h-5" />
					</div>
				</div>
				<Divider className="m-0 border-stroke-primary" />
				<div className="flex justify-between items-center">
					<SpSkeleton className="h-10 w-[300px] mt-6" />
					<SpSkeleton className="h-10 w-[200px] mt-6" />
				</div>
				{skeletonArray.map((parentIndex) => (
					<div key={`parent-${parentIndex}`} className=" mt-6">
						<SpSkeleton className="h-6 w-[100px]" />
						<div className="grid grid-cols-4 gap-6 mt-6">
							{rowArray.map((column) => (
								<CardSkeleton key={column} />
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default ManagerSkeleton;
