import classNames from 'classnames';
import { SIZES } from './sp-chip.constants';
import type { SpChipProps } from './sp-chip.interfaces';

const SpChip = ({
	className,
	active: isActive = false,
	disabled: isDisabled = false,
	label,
	new: isNew = false,
	size = 'md',
	onClick,
}: SpChipProps) => {
	const spChipClassNames = classNames(
		'rounded-lg border flex items-center px-3 hover:text-blue-500 hover:border-blue-500',
		{
			'text-blue-500 border-blue-500 bg-blue-50 cursor-pointer': isActive,
			'text-secondary border-stroke-primary cursor-pointer': !isActive,
			'text-disable bg-neutral-50 border-stroke-secondary cursor-not-allowed hover:text-disable hover:border-stroke-secondary':
				isDisabled,
			'h-[40px] text-body-r-semibold': size === SIZES.MD,
			'h-[24px] text-body-s-regular': size === SIZES.SM,
		}
	);

	return (
		<div className={`${spChipClassNames} ${className}`} onClick={onClick}>
			{label}
			{isNew ? (
				<div className="ml-1 bg-red-400 text-neutral-0 text-body-s-semibold h-4 flex items-center px-1 rounded">
					NEW
				</div>
			) : null}
		</div>
	);
};

export default SpChip;
