import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
	BILLING_ACCESS_TYPE,
	PENGELOLA_STATUS_FOR_SKIP_REPORT,
} from 'features/billing-approval/billing-approval.constants';
import type { ReportAccess } from 'features/billing-approval/billing-approval.interfaces';
import { useBillingStore } from 'features/billing-approval/billing-approval.store';
import ApprovalLogs from 'features/billing-approval/log';

const Log = () => {
	const { reportId } = useParams<{ reportId: string }>();
	const navigate = useNavigate();
	const reportAccess = useBillingStore(
		(state) => state.reportAccess
	) as ReportAccess;
	const isReportDetailLoading = useBillingStore(
		(state) => state.isReportDetailLoading
	);

	const isBackToBeranda =
		PENGELOLA_STATUS_FOR_SKIP_REPORT.includes(reportAccess?.currentState) ||
		reportAccess?.recipientAccessInfo?.accessType ===
			BILLING_ACCESS_TYPE.readOnly;
	const backUrl = isBackToBeranda ? '/' : `/approval/${reportId}`;

	useEffect(() => {
		if (!isReportDetailLoading && !reportAccess) {
			navigate('/');
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isReportDetailLoading, reportAccess]);

	return <ApprovalLogs backUrl={backUrl} />;
};

export default Log;
