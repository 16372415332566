import { Divider } from 'antd';
import SpSkeleton from 'common/components/sp-skeleton';

const SkeletonLoader = () => {
	const skeletonArray = (item: number) =>
		new Array(item).fill(null).map((_, index) => index);

	return (
		<div className="pb-[40px] pt-[34px]">
			<div className="w-[1080px] mx-auto">
				<div className="flex justify-end">
					<div className="flex gap-3 mb-[40px]">
						<SpSkeleton className="h-10 w-[100px]" />
						<SpSkeleton className="h-10 w-[100px]" />
					</div>
				</div>

				<div className="flex justify-between">
					<SpSkeleton className="mb-3 h-16 w-[218px]" />
					<SpSkeleton className="mb-3 h-16 w-[218px]" />
				</div>
				<Divider className="my-6 border-stroke-primary" />
				<SpSkeleton className="h-8 w-[80px]" />

				<div className="my-4">
					{skeletonArray(5).map((item) => (
						<SpSkeleton className="h-6 w-[300px] mb-2" key={item} />
					))}
				</div>

				{skeletonArray(10).map((item) => (
					<div className="flex gap-2 mb-2" key={item}>
						<SpSkeleton className="h-8 w-[300px]" />
						<SpSkeleton className="h-8 w-[300px]" />
						<SpSkeleton className="h-8 w-[300px]" />
						<SpSkeleton className="h-8 w-[300px]" />
					</div>
				))}

				<div className="my-8">
					{skeletonArray(5).map((item) => (
						<SpSkeleton className="h-4 w-[300px] mb-2" key={item} />
					))}
				</div>

				<SpSkeleton className="h-4 w-[600px]" />
				<SpSkeleton className="h-4 w-[200px] mb-24 mt-6" />
				<SpSkeleton className="h-4 w-[800px]" />
				<div className="flex justify-end">
					<SpSkeleton className="h-4 w-[200px] mb-24 mt-6" />
				</div>
			</div>
		</div>
	);
};

export default SkeletonLoader;
