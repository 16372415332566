import { Label, Typography } from '@squantumengine/horizon';
import dayjs from 'dayjs';
import { convertToNpwpFormat } from 'common/utils/transformer';
import InfoCard from './card';
import type { InfoProps } from './info.interfaces';

const { Paragraph } = Typography;

const Info = ({ organisation }: InfoProps) => {
	const npwp = convertToNpwpFormat(organisation.npwp);

	return (
		<div className="flex flex-col">
			<Paragraph weight="bold" size="l" className="mb-6">
				Informasi Business Unit
			</Paragraph>
			<InfoCard
				className="mb-2"
				title="Nama business unit"
				value={organisation.name}
			></InfoCard>
			<InfoCard
				className="mb-2"
				title="Nama perusahaan"
				value={organisation.companyName}
			></InfoCard>
			<InfoCard className="mb-2" title="NPWP" value={npwp}></InfoCard>
			<InfoCard
				className="mb-2"
				title="Alamat business unit"
				value={organisation.address}
			></InfoCard>
			<InfoCard
				className="mb-2"
				title="Negara"
				value={organisation.countryName}
			></InfoCard>
			{organisation.isActive ? (
				<InfoCard className="mb-2" title="Status">
					:<Label label="Aktif" type="success" className="ml-1" />
				</InfoCard>
			) : (
				<>
					<InfoCard className="mb-2" title="Status">
						:<Label label="Nonaktif" type="danger" className="ml-1" />
					</InfoCard>
					<InfoCard
						title="Alasan nonaktif"
						value={organisation.inactiveReason}
					></InfoCard>
				</>
			)}
			<InfoCard
				title="Tanggal integrasi"
				value={
					organisation.integrationDate
						? dayjs(organisation.integrationDate).format('D MMMM YYYY')
						: ''
				}
			></InfoCard>
		</div>
	);
};

export default Info;
