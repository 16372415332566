import { Skeleton } from '@squantumengine/horizon';

const UserDetailSkeleton = () => {
	return (
		<div className="flex items-center justify-between">
			<div className="flex items-center gap-6">
				<div className="w-[64px] h-[64px] p-1">
					<Skeleton className="rounded-full w-[56px] h-[56px]" />
				</div>
				<div className="flex flex-col">
					<Skeleton className="w-[70px] h-6" />
					<Skeleton className="w-[120px] h-5 mt-1" />
				</div>
			</div>
			<div className="flex flex-col">
				<Skeleton className="w-[120px] h-6" />
				<Skeleton className="w-[150px] h-6 mt-1" />
			</div>
			<Skeleton className="w-[124px] h-8" />
		</div>
	);
};

export default UserDetailSkeleton;
