import type { UserStatus, UserActions } from './list.interfaces';

export const USER_STATUS: {
	[key: string]: UserStatus;
} = {
	'inactive-sent': {
		label: 'Undangan Terkirim',
		color: 'yellow',
	},
	'inactive-pending': {
		label: 'Undangan Terkirim',
		color: 'yellow',
	},
	'inactive-expired': {
		label: 'Undangan Kedaluwarsa',
		color: 'red',
	},
	'active-verified': {
		label: 'Aktif',
		color: 'green',
	},
	default: {
		label: '',
		color: '',
	},
};
export const USER_ACTIONS: {
	[key: string]: UserActions[];
} = {
	'active-verified': [
		{
			text: 'Nonaktifkan akun',
			key: 'deactivate',
		},
	],
	default: [],
};
