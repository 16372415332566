import { Fragment } from 'react';
import { Divider } from 'antd';
import useBusinessUnitForm from 'features/business-unit/form/use-business-unit-form';
import Card from './card';
import { STEPPER_INFO } from './stepper.constants';

const Stepper = () => {
	const {
		contextHolder,
		formStep,
		isDataChanged,
		isUseStepperGuard,
		setIsDataChanged,
		setFormStep,
		showConfirmationModal,
	} = useBusinessUnitForm();

	const resetState = (step: number) => {
		setIsDataChanged(false);
		setFormStep(step);
	};

	const updateStep = (step: number) => {
		if (!isUseStepperGuard && isDataChanged) {
			return showConfirmationModal(() => resetState(step));
		}

		setFormStep(step);
	};

	return (
		<div className="rounded-lg border border-stroke-primary overflow-hidden">
			{STEPPER_INFO.map((stepper, index) => (
				<Fragment key={stepper.title}>
					<Card
						description={stepper.description}
						isActive={stepper.step === formStep}
						isDisabled={isUseStepperGuard ? stepper.step > formStep : false}
						isDisableClick={isUseStepperGuard}
						step={stepper.step}
						title={stepper.title}
						onClick={updateStep}
					></Card>
					{index < STEPPER_INFO.length - 1 && stepper.step !== formStep ? (
						<Divider className="m-0"></Divider>
					) : null}
				</Fragment>
			))}
			{contextHolder}
		</div>
	);
};

export default Stepper;
