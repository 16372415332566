import { Label, Typography } from '@squantumengine/horizon';
import type { ColumnsType } from 'antd/es/table';
import type { UsageSummary } from 'common/components/analytic/analytic.interfaces';
import SpTable from 'common/components/sp-table';
import style from './index.module.scss';
import type { TableProps } from './table.interfaces';

const { Paragraph } = Typography;

const Summary = ({ data, isLoading }: TableProps) => {
	const columns: ColumnsType<UsageSummary> = [
		{
			title: 'Product/service',
			dataIndex: 'serviceType',
			key: 'serviceType',
		},
		{
			title: 'Penggunaan',
			dataIndex: 'usages',
			key: 'usages',
			render: (_, { usages }) => {
				return usages;
			},
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (_, { status }) => {
				return (
					<Label
						label={status ? 'Aktif' : 'Nonaktif'}
						type={status ? 'success' : 'danger'}
						border
					/>
				);
			},
		},
	];

	return (
		<>
			<Paragraph size="l" weight="bold">
				Ringkasan
			</Paragraph>
			<SpTable
				columns={columns}
				dataSource={data}
				className={`mt-6 ${style.table}`}
				pagination={false}
				defaultTable
				bordered
				isLoading={isLoading}
			/>
		</>
	);
};

export default Summary;
