import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'antd';
import type {
	OrganisationDetailsInfo,
	OrganisationDetailsInvoiceSettings,
	OrganisationDetailsPIC,
	OrganisationDetailsService,
	OrganisationDetails,
	PostOrganisationApiPayload,
} from 'features/business-unit/business-unit.interfaces';
import { useBusinessUnitStore } from 'features/business-unit/business-unit.store';
import { local } from 'common/utils/storage';
import ToastMessage from 'common/utils/toast-message';
import { camelize, snakify } from 'common/utils/transformer';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import {
	getOrganisationDetails,
	upsertOrganisation,
} from 'services/organisation';

const useBusinessUnitForm = () => {
	const { organisationId } = useParams();
	const navigate = useNavigate();

	const formStep = useBusinessUnitStore((state) => state.formStep);
	const isUseStepperGuard = useBusinessUnitStore(
		(state) => state.isUseStepperGuard
	);
	const isDataChanged = useBusinessUnitStore((state) => state.isDataChanged);
	const organisationForm = useBusinessUnitStore(
		(state) => state.organisationForm
	);
	const setFormStep = useBusinessUnitStore((action) => action.setFormStep);
	const setIsDataChanged = useBusinessUnitStore(
		(action) => action.setIsDataChanged
	);
	const setIsLoadingDetails = useBusinessUnitStore(
		(action) => action.setIsLoadingDetails
	);
	const setOrganisationForm = useBusinessUnitStore(
		(action) => action.setOrganisationForm
	);

	const [modal, contextHolder] = Modal.useModal();

	const upsertOrganisationMutation = useMutation(upsertOrganisation);

	const backToPreviousPage = (payload?: { deleteStorage?: boolean }) => {
		setIsDataChanged(false);
		if (payload?.deleteStorage) local.removeItem('draft_organisation');
		navigate(-1);
		setIsLoadingDetails(true);
		setFormStep(1);
	};

	const backToPreviousStep = () => {
		setFormStep(formStep - 1);
	};

	const goToNextStep = () => {
		setFormStep(formStep + 1);
	};

	const getUpsertOrganisationPayload = (payload: {
		key: string;
		data: unknown;
	}) => {
		const info = organisationForm?.organisation as OrganisationDetailsInfo;
		const pics = organisationForm?.pics as OrganisationDetailsPIC[];
		const services =
			organisationForm?.organisationServices as OrganisationDetailsService[];
		const settings =
			organisationForm?.invoiceComponentSettings as OrganisationDetailsInvoiceSettings[];

		const { key, data } = payload;
		const upsertPayload = {
			organisation: {
				id: info.id,
				name: info.name,
				companyName: info.companyName,
				address: info.address,
				npwp: info.npwp,
				invoiceAddress: info.invoiceAddress,
				countryId: info.countryId,
				isActive: info.isActive,
			},
			pics: pics.map((pic) => {
				return {
					id: pic.id,
					name: pic.name,
					email: pic.email,
					role: pic.role,
					phone: pic.phone,
					isApprover: pic.isApprover,
				};
			}),
			organisation_services: services.map((service) => {
				return {
					sqeserviceId: service.sqeserviceId,
					isActive: service.isActive,
				};
			}),
			invoice_component_settings: settings.map((setting) => {
				return {
					id: setting.id,
					name: setting.name,
					format: setting.format,
					value: setting.value,
					config: setting.config,
					componentType: setting.componentType,
				};
			}),
			[key]: data,
		};

		const sanitizedUpsertPayload = snakify(
			upsertPayload
		) as PostOrganisationApiPayload;

		return sanitizedUpsertPayload;
	};

	const { refetch: getDetails } = useQuery({
		queryKey: 'getOrganisationDetails',
		queryFn: () => getOrganisationDetails(organisationId as string),
		enabled: false,
		onSuccess: (data) => {
			const details = data?.data ?? null;

			if (!details) {
				navigate('/maintenance', { replace: true });
				return;
			}

			const sanitizedResponse = camelize(details) as OrganisationDetails;
			setOrganisationForm(sanitizedResponse);
			setIsLoadingDetails(false);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});

			navigate('/maintenance', { replace: true });
		},
	});

	const showConfirmationModal = (onOk: () => void) => {
		modal.confirm({
			title:
				'Perubahan yang Anda buat tidak akan tersimpan. Yakin ingin membatalkan?',
			cancelText: 'Kembali',
			okText: 'Ya, batalkan',
			className: 'confirm-modal',
			centered: true,
			zIndex: 1041,
			onOk: onOk,
		});
	};

	const updateOrganisation = (upsertPayload: PostOrganisationApiPayload) => {
		setIsLoadingDetails(true);

		upsertOrganisationMutation.mutate(upsertPayload, {
			onSuccess: () => {
				ToastMessage({
					type: 'success',
					label: 'Pembaruan data berhasil.',
				});
				getDetails();
			},
			onError: () => {
				ToastMessage({
					type: 'error',
					label: 'Pembaruan data gagal.',
				});
				setIsLoadingDetails(false);
			},
		});
	};

	return {
		contextHolder,
		formStep,
		isUseStepperGuard,
		isDataChanged,
		upsertOrganisationMutation,
		backToPreviousPage,
		backToPreviousStep,
		goToNextStep,
		getUpsertOrganisationPayload,
		getDetails,
		setIsDataChanged,
		setIsLoadingDetails,
		setFormStep,
		showConfirmationModal,
		updateOrganisation,
	};
};

export default useBusinessUnitForm;
