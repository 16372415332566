import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Typography } from '@squantumengine/horizon';
import classNames from 'classnames';
import dayjs from 'dayjs';
import type { ServiceAvailableQuota } from 'common/components/analytic/analytic.interfaces';
import RedoIcon from 'common/components/icons/redo';
import SpSkeleton from 'common/components/sp-skeleton';
import ToastMessage from 'common/utils/toast-message';
import {
	camelize,
	convertToThousandsSeparator,
} from 'common/utils/transformer';
import { getAnalyticQuota } from 'services/usage';
import type { EmeteraiWidgetProps } from './emeterai-widget.interfaces';
import style from './index.module.scss';

const { Paragraph } = Typography;

const EmeteraiWidget = ({
	organisationId,
	showDateWithColumn,
}: EmeteraiWidgetProps) => {
	const [emeteraiQuota, setEmeteraiQuota] = useState({
		count: 0,
		lastUpdatedAt: '',
	});

	const { refetch: getQuota, isFetching: isQuotaLoading } = useQuery({
		queryKey: ['getQuota'],
		enabled: false,
		queryFn: () => getAnalyticQuota('ekyc', organisationId),
		onSuccess: (response) => {
			const data = response?.data ?? null;
			const sanitizedResponse = camelize(data) as ServiceAvailableQuota;
			const emeteraiQuota = sanitizedResponse?.details?.find(
				(quota) => quota.serviceTypeTag === 'e-meterai'
			);

			if (emeteraiQuota) {
				setEmeteraiQuota({
					count: emeteraiQuota.remainingQuota,
					lastUpdatedAt: emeteraiQuota.lastUpdatedAt,
				});
			}
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: 'Gagal mengambil quota data. Silahkan coba lagi.',
			});
		},
	});

	const getWidgetQuota = () => {
		getQuota();
	};

	const dateInfo = classNames('flex mt-1', {
		'flex-col': showDateWithColumn,
		'items-center': !showDateWithColumn,
	});
	const dateSkeleton = classNames('h-4', {
		'w-full': showDateWithColumn,
		'ml-1 w-[40px]': !showDateWithColumn,
	});
	const redoClassName = classNames(
		'w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-neutral-0 cursor-pointer',
		{
			[style['redo-loading']]: isQuotaLoading,
		}
	);
	const dateClassName = classNames(
		'text-secondary text-[10px] leading-[16px]',
		{
			'ml-1': !showDateWithColumn,
		}
	);

	useEffect(() => {
		if (!organisationId) return;

		getQuota();
	}, [organisationId, getQuota]);

	return (
		<div className="flex flex-shrink-0 rounded-lg bg-neutral-50 py-2 px-3 border border-stroke-secondary">
			<div
				className={redoClassName}
				aria-label="Redo icon"
				onClick={getWidgetQuota}
			>
				<RedoIcon />
			</div>
			<div className="flex flex-col ml-2">
				<Paragraph weight="regular" size="s" className="text-secondary">
					Sisa kuota e-materai
				</Paragraph>
				{isQuotaLoading ? (
					<SpSkeleton className="h-6 w-5" />
				) : (
					<Paragraph weight="bold" size="l">
						{convertToThousandsSeparator(emeteraiQuota.count.toString())}
					</Paragraph>
				)}
				<div className={dateInfo}>
					<Paragraph
						weight="regular"
						size="s"
						className="text-secondary text-[10px] leading-[14px]"
					>
						Terakhir diperbarui:
					</Paragraph>
					{isQuotaLoading ? (
						<SpSkeleton className={dateSkeleton} />
					) : (
						<Paragraph weight="regular" size="s" className={dateClassName}>
							{dayjs(emeteraiQuota.lastUpdatedAt).isValid()
								? dayjs(emeteraiQuota.lastUpdatedAt).format('DD/MM/YYYY HH:mm')
								: '-'}
						</Paragraph>
					)}
				</div>
			</div>
		</div>
	);
};

export default EmeteraiWidget;
