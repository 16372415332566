import { lazy, Suspense, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@squantumengine/horizon';
import {
	PENGELOLA_STATUS_FOR_SKIP_REPORT,
	BILLING_ACCESS_TYPE,
} from 'features/billing-approval//billing-approval.constants';
import Skeleton from 'features/billing-approval/detail-skeleton';
import useBillingDetailPageHelper from 'features/billing-approval/use-billing-detail-page-helper';
import SectionHeader from 'common/components/section-header';
import SpTimeline from 'common/components/sp-timeline';

const Report = lazy(
	() =>
		import(
			/* webpackChunkName: "pengelolaApprovalDetailReport" */ './detail/report'
		)
);
const Payment = lazy(
	() =>
		import(
			/* webpackChunkName: "pengelolaApprovalDetailPayment" */ './detail/payment'
		)
);

const DetailApproval = () => {
	const { reportId } = useParams<{ reportId: string }>();
	const navigate = useNavigate();
	const {
		isReportDetailLoading,
		reportAccess,
		currentState,
		step,
		title,
		setIsReportDetailLoading,
	} = useBillingDetailPageHelper();

	const goToLogs = () => {
		setIsReportDetailLoading(true);
		navigate(`/approval/log/${reportId}`);
	};

	useEffect(() => {
		if (
			!isReportDetailLoading &&
			(!reportAccess ||
				PENGELOLA_STATUS_FOR_SKIP_REPORT.includes(currentState) ||
				reportAccess?.recipientAccessInfo?.accessType ===
					BILLING_ACCESS_TYPE.readOnly)
		) {
			navigate('/');
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isReportDetailLoading, reportAccess]);

	return (
		<div className="py-8">
			<SectionHeader
				backHandler={() => navigate('/')}
				title={title}
				isLoading={isReportDetailLoading}
			>
				<Button variant="secondary" size="sm" onClick={goToLogs}>
					Lihat Log Details
				</Button>
			</SectionHeader>
			{isReportDetailLoading ? (
				<Skeleton />
			) : (
				<div className="flex mt-6">
					<div className="w-[352px] shrink-0">
						<div className="p-6 bg-neutral-0 rounded-lg shadow-base flex">
							<SpTimeline
								activeStep={step}
								timeline={[
									{
										title: 'Konfirmasi laporan',
										step: 1,
									},
									{
										title: 'Invoice dan pembayaran',
										step: 2,
									},
								]}
							/>
						</div>
					</div>
					<div className="w-[calc(100%-352px)] ml-6">
						{step === 1 && (
							<Suspense>
								<Report />
							</Suspense>
						)}
						{step === 2 && (
							<Suspense>
								<Payment />
							</Suspense>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default DetailApproval;
