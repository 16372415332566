import BaseAnalytic from 'common/components/analytic';
import Skeleton from './skeleton';

const Identity = () => {
	return (
		<BaseAnalytic
			path="/identity"
			skeleton={<Skeleton />}
			tag="id"
		></BaseAnalytic>
	);
};

export default Identity;
