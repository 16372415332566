import { useState } from 'react';
import { Typography } from '@squantumengine/horizon';
import classNames from 'classnames';
import { convertToThousandsSeparator } from 'common/utils/transformer';
import Chart from './chart';
import type { WidgetProps } from './widget.interfaces';

const { Paragraph, Title } = Typography;

const Widget = ({ title, data, isVertical, description }: WidgetProps) => {
	const [hoveredColor, setHoveredColor] = useState('');

	const totalCount = data.reduce((previous, current) => {
		return previous + current.count;
	}, 0);
	const totalText = convertToThousandsSeparator(totalCount.toString());
	const usedData = data.map((value) => {
		return {
			...value,
			percentage: (value.count * 100) / totalCount,
		};
	});
	const chartData = data.map((value) => {
		return {
			name: value.name,
			value: value.count,
			detail: value.detail,
			percentage: (value.count * 100) / totalCount,
		};
	});
	const chartColors = data.reduce((previous, current) => {
		return [...previous, current.color];
	}, [] as string[]);
	const containerClass = isVertical ? 'flex-col' : 'flex-row mt-4';
	const paragraphClass = classNames('mb-6 text-secondary', {
		'min-h-8': isVertical,
	});

	return (
		<div className="w-full p-4 flex flex-col bg-neutral-0 rounded-lg shadow-base h-full">
			<Paragraph size="l" weight="bold">
				{title}
			</Paragraph>
			<Paragraph size="s" className={paragraphClass}>
				{description}
			</Paragraph>
			<div
				className={`flex items-center ${containerClass}`}
				data-testid="containerWidgetKyc"
			>
				<div className={`relative ${isVertical ? '' : 'ml-5'}`}>
					<Chart
						width={isVertical ? 150 : 222}
						height={isVertical ? 150 : 222}
						innerRadius={isVertical ? 62 : 95}
						outerRadius={isVertical ? 72 : 108}
						data={chartData}
						colors={chartColors}
						setHoveredColor={setHoveredColor}
						hoveredColor={hoveredColor}
					/>
					<div className="flex flex-col items-center absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%]">
						<Title level={4}>{totalText}</Title>
						<Paragraph size="l" weight="regular">
							Total
						</Paragraph>
					</div>
				</div>
				<div
					className={`flex flex-col ${isVertical ? 'mt-6' : 'ml-6'}`}
					data-testid="detailWidgetKyc"
				>
					{usedData.map((value) => {
						const hoveredClassName =
							hoveredColor && value.color !== hoveredColor ? 'opacity-30' : '';
						const percentage = isNaN(value.percentage) ? 0 : value.percentage;

						return (
							<div
								className={`flex items-center first:mt-0 ${
									isVertical ? '' : 'mt-1'
								}`}
								key={value.name}
							>
								<div
									className={`flex items-center w-[110px] ${hoveredClassName}`}
								>
									<span
										className="w-[10px] h-[10px] rounded-full"
										style={{
											background: value.color,
										}}
									/>
									<Paragraph className="ml-2" size="l" weight="regular">
										{value.name}
									</Paragraph>
								</div>
								<Paragraph weight="bold" size="r" className={hoveredClassName}>
									: {value.count} ({percentage.toFixed(2)}%)
								</Paragraph>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Widget;
