import InfoIcon from 'common/components/icons/info';
import type { BannerProps } from './banner.interfaces';

const Banner = ({ className, description }: BannerProps) => {
	return (
		<div className={`p-3 bg-blue-50 flex rounded-lg ${className}`}>
			<div className="text-blue-600 leading-none">
				<InfoIcon />
			</div>
			<div className="flex flex-col ml-2">
				<p
					dangerouslySetInnerHTML={{
						__html: description,
					}}
				></p>
			</div>
		</div>
	);
};

export default Banner;
