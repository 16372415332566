import {
	COLORS,
	DEFAULT_TITLE_FONT_WEIGHT,
	DEFAULT_TITLE_MARGIN_BOTTOM,
	TITLE_H1_STYLE,
	TITLE_H2_STYLE,
	TITLE_H3_STYLE,
	TITLE_H4_STYLE,
	TITLE_H5_STYLE,
} from 'design.token';

const config = {
	token: {
		colorTextBase: COLORS.primary,
		fontFamily:
			"Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
	},
	components: {
		Typography: {
			fontWeightStrong: DEFAULT_TITLE_FONT_WEIGHT,
			fontSizeHeading1: TITLE_H1_STYLE.fontSize,
			lineHeightHeading1: TITLE_H1_STYLE.lineHeight,
			fontSizeHeading2: TITLE_H2_STYLE.fontSize,
			lineHeightHeading2: TITLE_H2_STYLE.lineHeight,
			fontSizeHeading3: TITLE_H3_STYLE.fontSize,
			lineHeightHeading3: TITLE_H3_STYLE.lineHeight,
			fontSizeHeading4: TITLE_H4_STYLE.fontSize,
			lineHeightHeading4: TITLE_H4_STYLE.lineHeight,
			fontSizeHeading5: TITLE_H5_STYLE.fontSize,
			lineHeightHeading5: TITLE_H5_STYLE.lineHeight,
			titleMarginBottom: DEFAULT_TITLE_MARGIN_BOTTOM,
		},
		Button: {
			colorPrimary: COLORS.blue[500],
			colorLink: COLORS.blue[500],
			borderRadius: 4,
			fontSize: 14,
			lineHeight: 1.44,
		},
		Divider: {
			colorSplit: COLORS.neutral[50],
		},
		Input: {
			borderRadiusLG: 12,
			colorBorder: '#949494',
			controlHeightLG: 48,
		},
		Select: {
			controlHeightLG: 48,
			borderRadiusLG: 12,
			colorBorder: COLORS.neutral[200],
			colorTextPlaceholder: COLORS.neutral[400],
		},
	},
};

export default config;
