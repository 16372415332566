import { useEffect, useState, type RefObject } from 'react';

export function useIntersection(ref: RefObject<HTMLDivElement>) {
	const [isIntersecting, setIntersecting] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) =>
			setIntersecting(entry.isIntersecting)
		);

		ref.current && observer.observe(ref.current);

		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			ref.current && observer.unobserve(ref.current);
		};
	}, [ref]);

	return isIntersecting;
}
