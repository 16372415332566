import type {
	GetUnitTypesParams,
	UpdateUnitTypeApiPayload,
} from 'features/price/edit-form/edit-form.interfaces';
import axios from './axios-instance';

const baseUrl = '/v1/unit-type';

export const getUnitTypes = async (params: GetUnitTypesParams) => {
	const response = await axios.get(baseUrl, {
		params,
	});

	return response.data;
};
export const updateUnitType = async (payload: UpdateUnitTypeApiPayload) => {
	const response = await axios.put(baseUrl, {
		data: {
			...payload,
		},
	});

	return response.data;
};
export const deleteUnitType = async (id: string) => {
	const response = await axios.delete(`${baseUrl}/${id}`);

	return response.data;
};
