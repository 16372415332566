import { useCallback } from 'react';
import { ACTIVITY_TYPE } from 'features/activity-log/activity-log.constants';
import type { ValueProps } from './value.interfaces';

const Value = ({
	level,
	title,
	activityType,
	childs,
	oldValue,
	newValue,
}: ValueProps) => {
	const generateValue = useCallback(
		(logValue: string, type: string) => {
			let value = '';
			const isValueExist = Boolean(logValue);

			if (activityType === ACTIVITY_TYPE.create && type === 'old') return '';
			if (activityType === ACTIVITY_TYPE.delete && type === 'new') return '';

			value = isValueExist && title ? `${title} :` : title;
			value = `${value} ${logValue}`;

			return value;
		},
		[title, activityType]
	);

	const oldTextValue = generateValue(oldValue, 'old');
	const newTextValue = generateValue(newValue, 'new');
	const padding = 12 + level * 8;

	return (
		<>
			<div className="flex">
				<div
					className="py-4 border-b border-stroke-secondary w-1/2 border-r "
					style={{
						paddingLeft: `${padding}px`,
					}}
				>
					{Boolean(oldTextValue) && level > 1 && <span>&#8226; </span>}
					{oldTextValue}
				</div>
				<div
					className="py-4 border-b border-stroke-secondary w-1/2"
					style={{
						paddingLeft: `${padding}px`,
					}}
				>
					{Boolean(newTextValue) && level > 1 && <span>&#8226; </span>}
					{newTextValue}
				</div>
			</div>
			{childs?.map((child) => (
				<div key={child.id} className="flex flex-col">
					<Value
						title={child.title}
						level={level + 1}
						activityType={activityType}
						oldValue={child.oldValue}
						newValue={child.newValue}
						childs={child.childs}
					/>
				</div>
			))}
		</>
	);
};

export default Value;
