import { Label, Typography } from '@squantumengine/horizon';
import type { ColumnsType } from 'antd/es/table';
import type { PriceServiceText } from 'features/price/price.interfaces';
import Banner from 'common/components/banner';
import CheckmarkIcon from 'common/components/icons/checkmark';
import SpTable from 'common/components/sp-table';
import { convertToRpPrice } from 'common/utils/transformer';
import style from './index.module.scss';
import type { TableProps } from './table.interfaces';

const { Paragraph, Title } = Typography;

const PriceTable = ({
	subtitle,
	prices,
	title,
	showFlag,
	showLegend = false,
}: TableProps) => {
	let columns: ColumnsType<PriceServiceText> = [
		{
			dataIndex: 'name',
			title: 'Produk / Service',
			key: 'name',
			width: 182,
			className: 'min-w-[182px]',
			render: (_, { textClassName, name }) => {
				const isServiceInfoChanges = Boolean(textClassName);

				return (
					<div className="flex items-center justify">
						<Paragraph
							className={textClassName}
							weight={isServiceInfoChanges ? 'semibold' : 'regular'}
							size="r"
						>
							{name}
						</Paragraph>
						{isServiceInfoChanges && (
							<span className="w-2 h-2 rounded bg-blue-500 ml-2" />
						)}
					</div>
				);
			},
		},
		{
			dataIndex: 'type',
			title: 'Tipe perhitungan',
			key: 'type',
			width: 148,
			className: 'min-w-[148px]',
		},
		{
			dataIndex: 'minQty',
			title: 'Volume',
			key: 'minQty',
			render: (_, { textClassName, qtyText }) => {
				const isServiceInfoChanges = Boolean(textClassName);

				return (
					<div className="flex items-center justify">
						<Paragraph
							className={textClassName}
							weight={isServiceInfoChanges ? 'semibold' : 'regular'}
							size="r"
						>
							{qtyText}
						</Paragraph>
						{isServiceInfoChanges && (
							<span className="w-2 h-2 rounded bg-blue-500 ml-2" />
						)}
					</div>
				);
			},
		},
		{
			dataIndex: 'price',
			title: 'Harga satuan',
			key: 'price',
			render: (_, { textClassName, price }) => {
				const isServiceInfoChanges = Boolean(textClassName);

				return (
					<div className="flex items-center justify">
						<Paragraph
							className={textClassName}
							weight={isServiceInfoChanges ? 'semibold' : 'regular'}
							size="r"
						>
							{convertToRpPrice(price)}
						</Paragraph>
						{isServiceInfoChanges && (
							<span className="w-2 h-2 rounded bg-blue-500 ml-2" />
						)}
					</div>
				);
			},
		},
		{
			dataIndex: 'unit',
			title: 'Satuan',
			key: 'unit',
		},
	];

	columns = showFlag
		? [
				...columns,
				{
					dataIndex: 'priceType',
					title: 'Tipe harga',
					key: 'priceType',
					render: (_, { isCustom }) => {
						return (
							<Label
								label={isCustom ? 'Harga Kustom' : 'Harga Standar'}
								type={isCustom ? 'warning' : 'success'}
								border
							/>
						);
					},
				},
		  ]
		: columns;

	columns = [
		...columns,
		{
			dataIndex: 'nonTaxableGoods',
			title: 'Tidak kena pajak',
			align: 'center',
			key: 'nonTaxableGoods',
			render: (_, { nonTaxableGoods }) => {
				return (
					<div className="flex items-center justify-center">
						{nonTaxableGoods ? <CheckmarkIcon /> : null}
					</div>
				);
			},
		},
	];

	return (
		<div className="mt-6 rounded-lg shadow-base p-6 bg-white">
			<Title level={5}>{title}</Title>
			{subtitle ? <Banner description={subtitle} className="mt-4" /> : null}
			<SpTable
				defaultTable={true}
				columns={columns}
				bordered
				dataSource={prices}
				pagination={false}
				className={`${style['pricing-table']} mt-4`}
			/>
			{showLegend && (
				<div className="flex items-center mt-6">
					<span className="bg-blue-500 w-3 h-3 rounded-[6px]" />
					<Paragraph className="ml-2" weight="regular" size="s">
						Data terbaru
					</Paragraph>
				</div>
			)}
		</div>
	);
};

export default PriceTable;
