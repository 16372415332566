import { create } from 'zustand';
import type {
	BillingSliceState,
	BillingSliceActions,
	ReportAccess,
} from './billing-approval.interfaces';

const initialState: BillingSliceState = {
	reportAccess: null,
	isReportDetailLoading: true,
};

export const useBillingStore = create<
	BillingSliceState & BillingSliceActions
>()((set, get) => ({
	...initialState,
	setReportAccess: (reportAccess: ReportAccess) =>
		set(() => ({
			reportAccess,
		})),
	setIsReportDetailLoading: (isReportDetailLoading: boolean) =>
		set(() => ({
			isReportDetailLoading,
		})),
}));
