import { Typography } from '@squantumengine/horizon';
import BaseAnalytic from 'common/components/analytic';
import Skeleton from './skeleton';

const { Paragraph } = Typography;

const MessagingPlatform = () => {
	return (
		<BaseAnalytic
			path="/messaging-platform"
			skeleton={<Skeleton />}
			tag="mp"
			interceptUsageInterfaceKey={4}
			interceptUsageInterface={
				<Paragraph weight="bold" size="l" className="mt-8">
					WhatsApp
				</Paragraph>
			}
		></BaseAnalytic>
	);
};

export default MessagingPlatform;
