import GeneralHeader from 'common/components/general-header';
import InfoPanel from 'common/components/info-panel';

const Maintenance = () => {
	return (
		<div className="flex flex-col bg-background h-full">
			<GeneralHeader />
			<div className="w-full items-center justify-center h-full flex">
				<InfoPanel
					description="Silahkan refresh browser Anda atau coba dalam beberapa saat lagi."
					imgAlt="Maintenance app"
					imgSource="/assets/illustrations/maintenance.svg"
					title="Sistem sedang bermasalah"
				></InfoPanel>
			</div>
		</div>
	);
};

export default Maintenance;
