import { Typography } from '@squantumengine/horizon';
import { Col, Row } from 'antd';
import EmptyData from 'common/components/empty-data';
import type { PICProps } from './pic.interfaces';

const { Paragraph } = Typography;

const PIC = ({ pics }: PICProps) => {
	return (
		<div className="mt-8">
			<Paragraph weight="bold" size="l" className="mb-6">
				Daftar PIC
			</Paragraph>
			<Row className="border-b border-stroke-secondary">
				<Col span={6} className="px-4 py-3 text-body-r-semibold">
					Nama
				</Col>
				<Col span={5} className="px-4 py-3 text-body-r-semibold">
					Email
				</Col>
				<Col span={5} className="px-4 py-3 text-body-r-semibold">
					Phone number
				</Col>
				<Col span={4} className="px-4 py-3 text-body-r-semibold">
					Jabatan PIC
				</Col>
				<Col span={4} className="px-4 py-3 text-body-r-semibold">
					Penandatangan
				</Col>
			</Row>
			{pics.length > 0 ? (
				pics.map((pic) => (
					<Row className="border-b border-stroke-secondary" key={pic.id}>
						<Col
							span={6}
							className="p-4 text-body-r-regular overflow-hidden text-ellipsis"
						>
							{pic.name}
						</Col>
						<Col
							span={5}
							className="p-4 text-body-r-regular overflow-hidden text-ellipsis"
						>
							{pic.email}
						</Col>
						<Col
							span={5}
							className="p-4 text-body-r-regular overflow-hidden text-ellipsis"
						>
							{pic.phone}
						</Col>
						<Col
							span={4}
							className="p-4 text-body-r-regular overflow-hidden text-ellipsis"
						>
							{pic.role}
						</Col>
						<Col
							span={4}
							className="p-4 text-body-r-regular overflow-hidden text-ellipsis"
						>
							{pic.isApprover ? 'Aktif' : 'Non Aktif'}
						</Col>
					</Row>
				))
			) : (
				<div className="py-8 bg-neutral-50">
					<EmptyData text="Belum ada PIC yang ditampilkan." />
				</div>
			)}
		</div>
	);
};

export default PIC;
