import { Typography } from '@squantumengine/horizon';
import { Divider } from 'antd';
import type { FieldRowsProps } from './field-rows.interfaces';

const { Paragraph } = Typography;

const FieldRows = ({
	values,
	rowClassName,
	textClassName,
	useDivider = false,
}: FieldRowsProps) => {
	return (
		<div className="flex flex-col">
			<div className="flex">
				<div className={`${rowClassName} w-[420px]`}>
					<Paragraph size="r" weight="regular" className={textClassName}>
						{values?.[0]}
					</Paragraph>
				</div>
				<div className={`${rowClassName} w-[220px]`}>
					<Paragraph size="r" weight="regular" className={textClassName}>
						{values?.[1]}
					</Paragraph>
				</div>
				<div className={`${rowClassName} w-[220px]`}>
					<Paragraph size="r" weight="regular" className={textClassName}>
						{values?.[2]}
					</Paragraph>
				</div>
				<div className={`${rowClassName} flex flex-1 justify-end`}>
					<Paragraph size="r" weight="regular" className={textClassName}>
						{values?.[3]}
					</Paragraph>
				</div>
			</div>
			{useDivider && <Divider className="m-0 border-stroke-secondary" />}
		</div>
	);
};

export default FieldRows;
