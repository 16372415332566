import type { GetUserApiParams } from 'features/admin-dashboard/admin-dashboard.interfaces';
import axios from './axios-instance';

const baseUrl = '/v1/user-organisation';

export const updateUserStatus = async ({
	userId,
	userStatus,
	archiveNote,
}: {
	userId: string;
	userStatus: 'ACTIVE' | 'INACTIVE';
	archiveNote?: string;
}) => {
	const response = await axios.put(`/v1/user/status/${userId}`, {
		user_status: userStatus,
		archive_note: archiveNote,
	});

	return response.data;
};
export const getUserOrganisation = async (params: GetUserApiParams) => {
	const response = await axios.get(baseUrl, {
		params: {
			page: params.page,
			limit: params.limit,
			user_status: params.userStatus,
			name_or_email: params.nameOrEmail,
			organisation_ids: params.organisationIds,
			order: params.order,
			ascending: params.ascending,
		},
	});

	return response.data;
};
