import { ConfigProvider, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';
import EmptyData from 'common/components/empty-data';
import SpSkeleton from 'common/components/sp-skeleton';
import type { DataKeys, SpTableProps, TitleProps } from './sp-table.interfaces';
import './index.scss';

const SpTable = <T extends object>(props: SpTableProps<T>) => {
	const emptyTableInterface = () => (
		<div className="h-[300px] flex items-center justify-center">
			<EmptyData />
		</div>
	);

	const { defaultTable = false, isLoading, columns } = props;

	const tableClass = classNames(props.className, {
		'sp-table': !defaultTable,
	});

	const dataKeys: DataKeys = {};
	const loadingColumns: ColumnsType<T> = [];

	columns?.forEach((column) => {
		let key = column?.title as string | TitleProps;
		key = typeof key === 'string' ? key : key?.props?.children;

		if (key) {
			key = key.toLowerCase().replace(/\s/g, '_');

			dataKeys[key] = '';
			loadingColumns.push({
				...column,
				dataIndex: key,
				render: () => <SpSkeleton className="h-[20px]"></SpSkeleton>,
			});
		} else {
			loadingColumns.push({
				...column,
				render: () => <SpSkeleton className="h-[20px]"></SpSkeleton>,
			});
		}
	});

	const skeletonArray = new Array(4).fill(dataKeys).map((data, index) => {
		return {
			...data,
			key: index,
		};
	});

	return (
		<ConfigProvider renderEmpty={emptyTableInterface}>
			{isLoading ? (
				<Table
					{...props}
					className={tableClass}
					columns={loadingColumns}
					dataSource={skeletonArray}
				></Table>
			) : (
				<Table<T> className={tableClass} {...props} />
			)}
		</ConfigProvider>
	);
};

export default SpTable;
