import type {
	PostResendWeblinkApiPayload,
	PostRetrySigningApiPayload,
} from 'layouts/dashboard/shared-modal/signers-modal/signers-modal.interfaces';
import type { GetSignerStatusDetailParams } from 'layouts/dashboard/shared-modal/signers-status-modal/signer-status.interfaces';
import type {
	GetNonWeblinkSignerParams,
	GetWeblinkSignerParams,
	PutUpdateSignerEkycPayload,
} from 'features/dashboard/know-your-customer/detail/signer-detail.interfaces';
import type {
	GetDocumentCountParams,
	GetDocumentParams,
	GetDownloadDocumentActivityParams,
	GetSignerListParams,
	GetStampListParams,
} from 'features/dashboard/know-your-customer/kyc-dashboard.interfaces';
import type { PutUpdateAutoReminderPayload } from 'features/dashboard/know-your-customer/send-reminder-settings/send-reminder.interfaces';
import type { GetSignerActivityLogParams } from 'features/dashboard/know-your-customer/signer-activity-log/signer-activity-log.interfaces';
import type {
	GetAnalyticParams,
	ServiceTag,
} from 'common/components/analytic/analytic.interfaces';
import axios from './axios-instance';

const baseUrl = '/v1/usage';

export const getAnalytic = async (params: GetAnalyticParams) => {
	const response = await axios.get(`${baseUrl}/analytic`, {
		params,
	});

	return response.data;
};
export const downloadAnalytic = async (params: GetAnalyticParams) => {
	const response = await axios.get(`${baseUrl}/analytic/download`, {
		params,
		responseType: 'blob',
	});

	return response.data;
};
export const getAnalyticQuota = async (
	tag: ServiceTag,
	organisationId: string
) => {
	const response = await axios.get(`${baseUrl}/${tag}/quota/${organisationId}`);

	return response.data;
};
export const getDocumentCount = async (params: GetDocumentCountParams) => {
	const response = await axios.get(`${baseUrl}/analytic/ekyc/contract/count`, {
		params,
	});

	return response.data;
};
export const getStampedDocumentCount = async (
	params: GetDocumentCountParams
) => {
	const response = await axios.get(`${baseUrl}/analytic/ekyc/stamp/count`, {
		params,
	});

	return response.data;
};

export const getSignedDocumentCount = async (
	params: GetDocumentCountParams
) => {
	const response = await axios.get(`${baseUrl}/analytic/ekyc/sign/count`, {
		params,
	});

	return response.data;
};

export const getDocuments = async (params: GetDocumentParams) => {
	const response = await axios.get(`${baseUrl}/analytic/ekyc/contract`, {
		params,
	});

	return response.data;
};
export const getDocumentSigners = async (id: string) => {
	const response = await axios.get(
		`${baseUrl}/analytic/ekyc/contract/${id}/signer`
	);

	return response.data;
};
export const getAverageContractCompletenessTime = async (
	params: GetDocumentCountParams
) => {
	const response = await axios.get(
		`${baseUrl}/analytic/ekyc/contract/completeness-avg-time`,
		{ params }
	);

	return response.data;
};
export const downloadDocumentActivity = async (
	params: GetDownloadDocumentActivityParams
) => {
	const response = await axios.get(
		`${baseUrl}/analytic/ekyc/contract/download`,
		{
			params,
			responseType: 'blob',
		}
	);

	return response.data;
};
export const generateDocumentActivity = async (id: string) => {
	const response = await axios.get(
		`${baseUrl}/analytic/ekyc/contract/${id}/download`,
		{
			responseType: 'blob',
		}
	);

	return response.data;
};
export const resendWeblink = async ({
	id,
	payload,
}: {
	id: string;
	payload: PostResendWeblinkApiPayload;
}) => {
	const response = await axios.post(
		`${baseUrl}/analytic/ekyc/contract/${id}/reminder`,
		payload
	);
	return response.data;
};
export const retryStamping = async (id: string) => {
	const response = await axios.post(
		`${baseUrl}/analytic/ekyc/stamp/${id}/retry`
	);
	return response.data;
};
export const retrySigning = async ({
	id,
	payload,
}: {
	id: string;
	payload: PostRetrySigningApiPayload;
}) => {
	const response = await axios.post(
		`${baseUrl}/analytic/ekyc/sign/${id}/retry`,
		payload
	);
	return response.data;
};
export const getSignerList = async (params: GetSignerListParams) => {
	const response = await axios.get(`${baseUrl}/analytic/ekyc/user`, {
		params,
	});
	return response.data;
};
export const getStampList = async (params: GetStampListParams) => {
	const response = await axios.get(`${baseUrl}/analytic/ekyc/stamp`, {
		params,
	});
	return response.data;
};
export const getStampDetail = async (id: string) => {
	const response = await axios.get(`${baseUrl}/analytic/ekyc/user/${id}/stamp`);
	return response.data;
};
export const getWeblinkSigner = async ({
	id,
	params,
}: {
	id?: string;
	params: GetWeblinkSignerParams;
}) => {
	const response = await axios.get(
		`${baseUrl}/analytic/ekyc/user/${id}/contract/weblink`,
		{
			params,
		}
	);
	return response.data;
};
export const getNonWeblinkSigner = async ({
	id,
	params,
}: {
	id?: string;
	params: GetNonWeblinkSignerParams;
}) => {
	const response = await axios.get(
		`${baseUrl}/analytic/ekyc/user/${id}/contract/non-weblink`,
		{
			params,
		}
	);
	return response.data;
};
export const getSignerUserDetail = async (id?: string) => {
	const response = await axios.get(`${baseUrl}/analytic/ekyc/${id}/user`);
	return response.data;
};
export const getSignerStatusDetail = async (
	params: GetSignerStatusDetailParams
) => {
	const response = await axios.get(`${baseUrl}/analytic/ekyc/user/signing`, {
		params,
	});
	return response.data;
};
export const getSignerActivityLog = async (
	params: GetSignerActivityLogParams
) => {
	const response = await axios.get(
		`${baseUrl}/analytic/ekyc/user/activity-log`,
		{
			params,
		}
	);
	return response.data;
};
export const downloadSignerActivityLog = async (
	params: GetSignerActivityLogParams
) => {
	const response = await axios.get(
		`${baseUrl}/analytic/ekyc/user/activity-log/download`,
		{
			params,
			responseType: 'blob',
		}
	);
	return response.data;
};
export const updateSignersEkyc = async ({
	id,
	payload,
}: {
	id: string;
	payload: PutUpdateSignerEkycPayload;
}) => {
	const response = await axios.put(`${baseUrl}/analytic/ekyc/user/${id}`, {
		data: payload,
	});
	return response.data;
};
export const getAutoReminder = async () => {
	const response = await axios.get(
		`${baseUrl}/analytic/ekyc/user/reminder/config`
	);
	return response.data;
};
export const updateAutoReminder = async (
	payload: PutUpdateAutoReminderPayload
) => {
	const response = await axios.put(
		`${baseUrl}/analytic/ekyc/user/reminder/config`,
		payload
	);
	return response.data;
};
