import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.8333 10.8333V15C15.8333 15.4583 15.4583 15.8333 15 15.8333H5C4.54167 15.8333 4.16667 15.4583 4.16667 15V10.8333C4.16667 10.375 3.79167 10 3.33333 10C2.875 10 2.5 10.375 2.5 10.8333V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10.8333C17.5 10.375 17.125 10 16.6667 10C16.2083 10 15.8333 10.375 15.8333 10.8333ZM10.8333 10.5583L12.4 8.99167C12.725 8.66667 13.25 8.66667 13.575 8.99167C13.9 9.31667 13.9 9.84167 13.575 10.1667L10.5833 13.1583C10.2583 13.4833 9.73333 13.4833 9.40833 13.1583L6.41667 10.1667C6.09167 9.84167 6.09167 9.31667 6.41667 8.99167C6.74167 8.66667 7.26667 8.66667 7.59167 8.99167L9.16667 10.5583V3.33333C9.16667 2.875 9.54167 2.5 10 2.5C10.4583 2.5 10.8333 2.875 10.8333 3.33333V10.5583Z"
			fill="currentColor"
		/>
	</svg>
);

const DownloadIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default DownloadIcon;
