import { lazy, Suspense, useState } from 'react';
import { Button, Popover, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { INVITATION_STATUS } from 'features/admin-dashboard/admin-dashboard.constants';
import type { User } from 'features/admin-dashboard/admin-dashboard.interfaces';
import EllipsisIcon from 'common/components/icons/ellipsis';
import SpPagination from 'common/components/sp-pagination';
import SpTable from 'common/components/sp-table';
import { capitalizeEachWord } from 'common/utils/transformer';
import useModal from 'common/hooks/use-modal';
import { ROLES, SORT } from 'common/constants/index';
import style from './index.module.scss';
import type { ListProps } from './list.interfaces';
import { getUserStatus, getUserActions } from './list.utils';

const DeactivateAccountModal = lazy(
	() =>
		import(
			/* webpackChunkName: "deactivateAccountModal" */ 'features/admin-dashboard/deactivate-account-modal'
		)
);
const ReactivateAccountModal = lazy(
	() =>
		import(
			/* webpackChunkName: "reactivateAccountModal" */ 'features/admin-dashboard/reactivate-account-modal'
		)
);

const List = ({
	data,
	isLoading,
	isGetOrganisationsLoading,
	isUserStatusActive,
	limit,
	page,
	totalCount,
	onChangePage,
	onChangeLimit,
	setSort,
}: ListProps) => {
	const deactivateAccountModal = useModal();
	const reactivateAccountModal = useModal();

	const [record, setRecord] = useState<User>();

	const handleUserAction = (key: string, record: User) => {
		if (record.userRole === ROLES.ADMIN) return;

		setRecord(record);

		switch (key) {
			case 'deactivate':
				deactivateAccountModal.openModal();
				break;
			case 'reactivate':
				reactivateAccountModal.openModal();
				break;
		}
	};

	const onTableChange = (sorter: SorterResult<User>) => {
		setSort({
			order: sorter.field as string,
			ascending: sorter.order === SORT.ASCEND,
		});
	};

	const onChangePageSize = (size: number) => {
		onChangeLimit(size);
	};

	const onChangeCurrentPage = (page: number) => {
		onChangePage(page);
	};

	const additionalColumns: ColumnsType<User> = isUserStatusActive
		? [
				{
					title: 'Status',
					dataIndex: 'user_status',
					key: 'userStatus',
					sorter: true,
					render: (_, { userStatus, invitationStatus }) => {
						const status = getUserStatus(userStatus, invitationStatus);

						if (!status.color || !status.label) return '-';

						return (
							<Tag color={status.color} bordered={false}>
								{status.label}
							</Tag>
						);
					},
				},
				{
					title: '',
					key: 'action',
					fixed: 'right',
					width: 52,
					render: (_, data) => {
						const actions = getUserActions(
							data.userStatus,
							data.invitationStatus
						);

						if (!actions.length) return '';

						const actionClass = classNames('py-1 px-4 text-body-r-regular', {
							'cursor-not-allowed text-gray-300': data.userRole === ROLES.ADMIN,
							'cursor-pointer hover:bg-blue-50': data.userRole !== ROLES.ADMIN,
						});

						return (
							<Popover
								content={
									<ul className="w-[268px] py-1">
										{actions.map((action) => (
											<li
												key={action.key}
												className={actionClass}
												onClick={() => handleUserAction(action.key, data)}
											>
												{action.text}
											</li>
										))}
									</ul>
								}
								trigger="click"
								placement="bottomRight"
							>
								<div className="w-6 h-6 rounded cursor-pointer hover:bg-gray-10">
									<EllipsisIcon />
								</div>
							</Popover>
						);
					},
				},
		  ]
		: [
				{
					title: 'Catatan',
					dataIndex: 'archiveNote',
					width: 220,
					key: 'archiveNote',
				},
				{
					title: '',
					key: 'action',
					fixed: 'right',
					width: 169,
					render: (_, data) => (
						<Button
							type="link"
							onClick={() => handleUserAction('reactivate', data)}
						>
							Reaktivasi Akun
						</Button>
					),
				},
		  ];

	const columns: ColumnsType<User> = [
		{
			title: 'Nama',
			dataIndex: 'user_name',
			key: 'userName',
			width: 220,
			sorter: true,
			render: (_, { userName }) => {
				return <p>{userName}</p>;
			},
		},
		{
			title: 'Email',
			dataIndex: 'user_email',
			key: 'userEmail',
			sorter: true,
			width: 220,
			render: (_, { userEmail, invitationStatus }) => {
				return (
					<>
						<p>{userEmail}</p>
						{invitationStatus === INVITATION_STATUS.SENT && (
							<p className="text-orange-300 text-xs">Belum terverifikasi</p>
						)}
					</>
				);
			},
		},
		{
			title: 'Tanggal diundang',
			width: 220,
			dataIndex: 'invitation_created_at',
			key: 'invitationCreatedAt',
			sorter: true,
			render: (_, { invitationCreatedAt }) => {
				return <p>{dayjs(invitationCreatedAt).format('DD MMMM YYYY')}</p>;
			},
		},
		{
			title: 'Akses',
			width: 150,
			dataIndex: 'user_type',
			key: 'userType',
			sorter: true,
			render: (_, { userRole }) => {
				return <p>{capitalizeEachWord(userRole)}</p>;
			},
		},
		{
			title: 'Business Unit',
			width: 300,
			dataIndex: 'organisations',
			key: 'organisations',
			render: (_, { organisations }) => (
				<>
					{organisations?.length > 0
						? organisations.map((item) => {
								return (
									<Tag key={item.organisationId} className="rounded-lg mb-1">
										{item.organisationName}
									</Tag>
								);
						  })
						: '-'}
				</>
			),
		},
		...additionalColumns,
	];

	return (
		<div className="mt-6">
			<SpTable
				columns={columns}
				dataSource={data}
				className={style['admin-list']}
				defaultTable
				bordered
				pagination={false}
				isLoading={isLoading}
				scroll={{ x: 1400 }}
				onChange={(_pagination, _filters, sorter) =>
					onTableChange(sorter as SorterResult<User>)
				}
				sortDirections={['ascend', 'descend', 'ascend']}
			/>
			{data.length && !isLoading ? (
				<div className="px-6">
					<SpPagination
						currentPage={page}
						pageSize={limit}
						totalCount={totalCount}
						onChangePageSize={onChangePageSize}
						onChangeCurrentPage={onChangeCurrentPage}
					></SpPagination>
				</div>
			) : null}
			{record && (
				<>
					<Suspense>
						{deactivateAccountModal.visible && (
							<DeactivateAccountModal
								isVisible={deactivateAccountModal.visible}
								closeModal={deactivateAccountModal.closeModal}
								record={record}
							></DeactivateAccountModal>
						)}
					</Suspense>
					<Suspense>
						{reactivateAccountModal.visible && (
							<ReactivateAccountModal
								isVisible={reactivateAccountModal.visible}
								closeModal={reactivateAccountModal.closeModal}
								record={record}
							></ReactivateAccountModal>
						)}
					</Suspense>
				</>
			)}
		</div>
	);
};

export default List;
