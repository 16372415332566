import SpSkeleton from 'common/components/sp-skeleton';
import type { SkeletonProps } from './skeleton.interfaces';

const Skeleton = ({ count }: SkeletonProps) => {
	const skeletonArray = new Array(count).fill(null).map((_, index) => index);

	return (
		<div className="flex flex-wrap gap-6 mt-6">
			{skeletonArray.map((index) => (
				<div className="w-[calc((100%-48px)/3)]" key={index}>
					<div className="flex flex-col bg-neutral-0 shadow-base rounded-lg p-4">
						<SpSkeleton className="h-5 w-[100px]" />
						<SpSkeleton className="h-8 mt-2 w-[200px]" />
						<SpSkeleton className="h-[196px] mt-6 w-full" />
						<div className="flex justify-end mt-6">
							<SpSkeleton className="h-4 w-[100px]" />
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default Skeleton;
