import { Typography, Icon } from '@squantumengine/horizon';
import classNames from 'classnames';
import SpCheckbox from 'common/components/sp-checkbox';
import type { DeleteActionProps } from './delete-action.interfaces';

const { Paragraph } = Typography;

const DeleteAction = ({
	isDeleteDisabled,
	indeterminate,
	checked,
	onSelectAll,
	onDelete,
	onCancel,
}: DeleteActionProps) => {
	const deleteSectionClassName = classNames('flex items-center', {
		'text-blue-500 cursor-pointer': !isDeleteDisabled,
		'text-disable cursor-not-allowed': isDeleteDisabled,
	});

	const onDeleteNotifications = () => {
		!isDeleteDisabled && onDelete();
	};

	return (
		<div className="flex justify-between items-center py-4 px-4">
			<div className="flex items-center">
				<SpCheckbox
					checked={checked}
					indeterminate={indeterminate}
					onChange={onSelectAll}
					label="Select all"
				/>
				<div className="h-[32px] border-r border-neutral-200 ml-1 mr-2"></div>
				<div
					className={deleteSectionClassName}
					data-testid="deleteNotification"
					onClick={onDeleteNotifications}
				>
					<div className="text-inherit mt-[-2px]">
						<Icon name="trash" size="lg" color="currentColor" />
					</div>
					<Paragraph className="ml-1 text-inherit" weight="semibold">
						Delete
					</Paragraph>
				</div>
			</div>
			<Paragraph
				className="text-blue-500 cursor-pointer"
				weight="semibold"
				onClick={() => onCancel(false)}
			>
				Cancel
			</Paragraph>
		</div>
	);
};

export default DeleteAction;
