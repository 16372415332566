import { GrowthBook } from '@growthbook/growthbook-react';

const growthbook = new GrowthBook({
	apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
	clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
	enableDevMode: process.env.REACT_APP_GROWTHBOOK_DEV_MODE === 'true',
	subscribeToChanges: true,
});

export { growthbook };
