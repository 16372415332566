import { useState, useMemo, useRef } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { CALCULATION_TYPES_KEY } from 'features/price/price.constants';
import Filter from 'common/components/analytic/filter';
import Summary from 'common/components/analytic/summary';
import useInitiateAnalytic from 'common/components/analytic/use-initiate-analytic';
import Widget from 'common/components/analytic/widget';
import EmeteraiWidget from 'common/components/emeterai-widget';
import SectionHeader from 'common/components/section-header';
import { downloadFile } from 'common/utils/file';
import ToastMessage from 'common/utils/toast-message';
import { camelize } from 'common/utils/transformer';
import useAnalytic from 'common/hooks/use-analytic';
import { useIntersection } from 'common/hooks/use-intersection';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { downloadAnalytic, getAnalytic } from 'services/usage';
import { SERVICE_NAME } from './analytic.constants';
import type {
	AnalyticProps,
	Usages,
	ServiceUsages,
} from './analytic.interfaces';

const BaseAnalytic = ({
	tag,
	path,
	skeleton,
	interceptUsageInterfaceKey = -1,
	interceptUsageInterface = null,
}: AnalyticProps) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const {
		selectedBusinessUnit,
		selectedBusinessUnitName,
		generateUsages,
		renderTooltip,
	} = useAnalytic();
	const { isQueryEnabled, selectedMonth, setSelectedMonth } =
		useInitiateAnalytic();

	const [usages, setUsages] = useState<Usages[]>([]);
	const [updatedAt, setUpdatedAt] = useState('');

	const organisationId = searchParams.get('oid') ?? '';

	const { isFetching } = useQuery({
		queryKey: ['getAnalytic', selectedMonth, organisationId],
		queryFn: () =>
			getAnalytic({
				organisation_id: organisationId,
				period: dayjs(selectedMonth).format('YYYY-MM'),
				service_tag: tag,
			}),
		enabled: isQueryEnabled,
		onSuccess: (response) => {
			const data = response?.data ?? null;
			const sanitizedResponse = camelize(data) as ServiceUsages;

			if (sanitizedResponse?.service) {
				const generatedUsages = generateUsages(
					sanitizedResponse.service[tag].usages
				) as Usages[];

				setUpdatedAt(sanitizedResponse.service[tag].lastUpdatedAt);
				setUsages(generatedUsages);
			}
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const { refetch: download, isFetching: isDownloadLoading } = useQuery({
		queryKey: ['downloadAnalytic'],
		enabled: false,
		queryFn: () =>
			downloadAnalytic({
				organisation_id: organisationId,
				period: dayjs(selectedMonth).format('YYYY-MM'),
				service_tag: tag,
			}),
		onSuccess: (response) => {
			const filename = `${
				SERVICE_NAME[tag]
			}-${selectedBusinessUnitName}-${dayjs(selectedMonth).format(
				'YYYY-MM'
			)}.xlsx`;

			downloadFile(response, filename);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const onMonthChange = (date: Dayjs | null) => {
		setSelectedMonth(date);
	};

	const onDownload = () => {
		ToastMessage({
			type: 'info',
			label: 'Dokumen sedang diunduh.',
		});
		download();
	};

	const totalUsages = usages.reduce(
		(prev, current) => prev + parseInt(current.totalUsage),
		0
	);

	const defaultUsageWidget = (
		<div className="flex flex-wrap gap-6 mt-6">
			{usages.map((usage) => {
				const headers = [
					{
						title: usage.name,
						subtitle: usage.totalUsage,
						additionalTotalUsage: usage.additionalTotalUsage,
					},
				];

				return (
					<div className="w-[calc((100%-48px)/3)]" key={usage.tag}>
						<Widget
							data={usage.chartData}
							header={
								usage.tag === 'e-meterai'
									? [
											...headers,
											<EmeteraiWidget
												key={`${usage.name}-${usage.tag}`}
												organisationId={organisationId}
												showDateWithColumn
											/>,
									  ]
									: headers
							}
							footer={usage.unitType}
							renderTooltip={({ active, payload }) =>
								renderTooltip({
									active,
									payload,
								})
							}
						/>
					</div>
				);
			})}
		</div>
	);

	const interceptUsageWidget = (
		<>
			<div className="flex flex-wrap gap-6 mt-6">
				{usages.slice(0, interceptUsageInterfaceKey - 1).map((usage) => (
					<div className="w-[calc((100%-48px)/3)]" key={usage.tag}>
						<Widget
							data={usage.chartData}
							header={[
								{
									title: usage.name,
									subtitle: usage.totalUsage,
									additionalTotalUsage: usage.additionalTotalUsage,
								},
							]}
							footer={usage.unitType}
							renderTooltip={({ active, payload }) =>
								renderTooltip({
									active,
									payload,
								})
							}
						/>
					</div>
				))}
			</div>
			{interceptUsageInterface}
			<div className="flex flex-wrap gap-6 mt-6">
				{usages.slice(-4).map((usage) => (
					<div className="w-[calc((100%-48px)/3)]" key={usage.tag}>
						<Widget
							data={usage.chartData}
							header={[
								{
									title: usage.name,
									subtitle: usage.totalUsage,
									additionalTotalUsage: usage.additionalTotalUsage,
								},
							]}
							footer={usage.unitType}
							renderTooltip={({ active, payload }) =>
								renderTooltip({
									active,
									payload,
								})
							}
						/>
					</div>
				))}
			</div>
		</>
	);

	const summaries = useMemo(() => {
		return usages.map((usage, index) => {
			const unitType = usage.usagesPerWeek.length
				? usage.usagesPerWeek[0]?.unitType
				: '';
			let usages = usage.totalUsage;

			if (usage.isFree) {
				usages = `${usages} ${usage.additionalTotalUsage}`;
			} else if (usage.calculationType === CALCULATION_TYPES_KEY.MULTIPLIER) {
				usages = `${usages} ${unitType} ${usage.additionalTotalUsage}`;
			} else {
				usages = `${usages} ${unitType}`;
			}

			return {
				key: index,
				serviceType: usage.name,
				status: usage.isActive,
				usages,
			};
		});
	}, [usages]);

	const filterRef = useRef<HTMLDivElement>(null);
	const isIntersecting = useIntersection(filterRef);

	return (
		<div className="py-8">
			<div ref={filterRef}>
				<SectionHeader title="Dashboard" backHandler={() => navigate(-1)} />
			</div>
			<div
				className={
					!isIntersecting
						? 'sticky top-0 bg-neutral-0 z-[19] py-4 shadow-base right-0 -mx-8 px-8'
						: 'mt-8'
				}
			>
				<Filter
					path={path}
					activeService={SERVICE_NAME[tag]}
					downloadDisable={totalUsages === 0}
					downloadLoading={isDownloadLoading}
					selectedBusinessUnit={selectedBusinessUnit}
					selectedMonth={selectedMonth}
					updatedAt={updatedAt}
					onMonthChange={onMonthChange}
					onDownload={onDownload}
				/>
			</div>
			{isFetching && skeleton}
			{!isFetching && interceptUsageInterfaceKey > -1
				? interceptUsageWidget
				: defaultUsageWidget}
			<div className="mt-8">
				<Summary data={summaries} isLoading={isFetching} />
			</div>
		</div>
	);
};

export default BaseAnalytic;
