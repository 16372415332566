import { useState } from 'react';
import { useQuery } from 'react-query';
import { downloadFile } from 'common/utils/file';
import ToastMessage from 'common/utils/toast-message';
import type { FileDownload } from 'common/types/global';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { FILE_ACTION } from 'common/constants/global';
import { fileDownload } from 'services/file';

const useFileDownload = () => {
	const [fileMeta, setFileMeta] = useState<FileDownload>({
		id: '',
		name: '',
		type: FILE_ACTION.download,
	});

	const setDownloadFile = (fileDownload: FileDownload) => {
		setFileMeta(fileDownload);
		setTimeout(() => download());
	};

	const { refetch: download } = useQuery({
		queryKey: ['fileDownload', fileMeta.id],
		queryFn: () => fileDownload(fileMeta.id),
		enabled: Boolean(fileMeta.id),
		onSuccess: (response) => {
			const { blob, disposition } = response;
			const serverFilename = disposition.split('filename=')[1];
			const filename =
				fileMeta?.name ?? serverFilename.replace(/"/g, '').replace(/ /g, '_');

			if (fileMeta.type === FILE_ACTION.download) {
				downloadFile(blob, filename);
			} else {
				const pdfUrl = URL.createObjectURL(blob);
				window.open(pdfUrl, '_blank');
			}
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	return {
		setDownloadFile,
	};
};

export default useFileDownload;
