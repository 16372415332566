import InfoPanel from 'common/components/info-panel';

const Unauthorized = () => {
	return (
		<div className="h-full flex items-center w-full justify-center">
			<InfoPanel
				description="Silakan kontak admin agar Anda dapat mengakses halaman ini."
				imgAlt="Unauthorize access"
				imgSource="/assets/illustrations/unauthorized-access.svg"
				title="Anda butuh akses ke halaman ini"
			></InfoPanel>
		</div>
	);
};

export default Unauthorized;
