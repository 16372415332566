import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.9998 10.8334H10.8332V15C10.8332 15.4584 10.4582 15.8334 9.99984 15.8334C9.5415 15.8334 9.1665 15.4584 9.1665 15V10.8334H4.99984C4.5415 10.8334 4.1665 10.4584 4.1665 10C4.1665 9.54169 4.5415 9.16669 4.99984 9.16669H9.1665V5.00002C9.1665 4.54169 9.5415 4.16669 9.99984 4.16669C10.4582 4.16669 10.8332 4.54169 10.8332 5.00002V9.16669H14.9998C15.4582 9.16669 15.8332 9.54169 15.8332 10C15.8332 10.4584 15.4582 10.8334 14.9998 10.8334Z"
			fill="currentColor"
		/>
	</svg>
);

const AddIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default AddIcon;
