import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SERVICES } from 'common/constants/global';
import EditPriceForm from './edit-form';

const EditGeneralPrice = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const currentServiceName = searchParams.get('sname') ?? '';
	const breadcrumbTitle = `Edit harga standar ${currentServiceName}`;

	useEffect(() => {
		if (!SERVICES.includes(currentServiceName))
			return navigate('/price/general');

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="py-8">
			<EditPriceForm breadcrumbTitle={breadcrumbTitle} />
		</div>
	);
};

export default EditGeneralPrice;
