import { lazy, Suspense, useEffect, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import type { User } from 'features/admin-dashboard/admin-dashboard.interfaces';
import { useMasterStore } from 'common/stores/master';
import { useProfileStore } from 'common/stores/profile';
import SectionHeader from 'common/components/section-header';
import ToastMessage from 'common/utils/toast-message';
import { camelize, capitalizeEachWord } from 'common/utils/transformer';
import useModal from 'common/hooks/use-modal';
import usePagination from 'common/hooks/use-pagination';
import type { Organisation } from 'common/types/organisation';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { getOrganisation } from 'services/organisation';
import { getUserOrganisation } from 'services/user-organisation';
import AccountCategorySwitcher from './account-category-switcher';
import type { UserFilter } from './admin-dashboard.interfaces';
import Filter from './filter';
import List from './list';

const InvitationModal = lazy(
	() => import(/* webpackChunkName: "invitationModal" */ './invitation-modal')
);

const AdminDashboard = () => {
	const invitationModal = useModal();
	const navigate = useNavigate();

	const setOrganisations = useMasterStore((state) => state.setOrganisations);
	const checkPermission = useProfileStore((state) => state.checkPermission);

	const {
		currentPage,
		pageSize,
		totalCount,
		setTotalCount,
		setCurrentPage,
		onChangeCurrentPage,
		onChangePageSize,
	} = usePagination();
	const [filter, setFilter] = useState<UserFilter>({
		ascending: false,
		userStatus: true,
		organisationIds: [],
		order: '',
	});
	const [nameOrEmail, setNameOrEmail] = useState('');
	const [isUserTyping, setIsUserTyping] = useState(false);
	const [search, setSearch] = useState('');
	const [users, setUsers] = useState<User[]>([]);

	const { isLoading, isFetching } = useQuery({
		queryKey: ['getUsers', filter, nameOrEmail, pageSize, currentPage],
		queryFn: () =>
			getUserOrganisation({
				...filter,
				organisationIds: filter.organisationIds.join(),
				nameOrEmail,
				limit: pageSize,
				page: currentPage,
			}),
		onSuccess: (data) => {
			const totalItems = data?.data?.pagination?.totalItems ?? 0;
			const users = data?.data?.items ?? [];
			let sanitizedResponse = camelize(users) as User[];
			sanitizedResponse = sanitizedResponse.map((user) => {
				return {
					...user,
					key: user.userId,
				};
			});

			setTotalCount(totalItems);
			setUsers(sanitizedResponse);
			setIsUserTyping(false);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const { isLoading: isGetOrganisationsLoading } = useQuery({
		queryKey: ['getOrganisations'],
		queryFn: () =>
			getOrganisation({
				limit: 100,
				page: 1,
			}),
		onSuccess: (response) => {
			const organisations = response?.data?.items ?? [];
			const sanitizedResponse = camelize(organisations) as Organisation[];

			setOrganisations(
				sanitizedResponse.map((organisation) => {
					return {
						...organisation,
						organisationName: capitalizeEachWord(organisation.organisationName),
					};
				})
			);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onDebounceSearch = useCallback(
		_debounce((value: string) => {
			setNameOrEmail(value);
			setCurrentPage(1);
		}, 300),
		[]
	);

	const searchUsers = (value: string) => {
		setSearch(value);
		setIsUserTyping(true);
		onDebounceSearch(value);
	};

	const setUserFilter = ({
		key,
		value,
	}: {
		key: string;
		value: string | boolean | string[] | number;
	}) => {
		setFilter({
			...filter,
			organisationIds:
				key === 'userStatus' && !value ? [] : filter.organisationIds,
			[key]: value,
		});
		onChangeCurrentPage(1);
	};

	const setSort = ({
		order,
		ascending,
	}: {
		order: string;
		ascending: boolean;
	}) => {
		setFilter({
			...filter,
			order,
			ascending,
		});
	};

	const buttonText = checkPermission('user', 'create') ? 'Undang' : '';
	const isGetUsersLoading = isLoading || isFetching || isUserTyping;

	useEffect(() => {
		if (!checkPermission('admin', 'view')) {
			return navigate('/unauthorized');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="py-8">
			<SectionHeader
				buttonText={buttonText}
				title="Pengaturan"
				onActionClick={invitationModal.openModal}
			></SectionHeader>
			<div className="bg-white rounded-lg overflow-hidden mt-7 py-6 px-4">
				<Filter
					isUserStatusActive={filter.userStatus}
					organisationIds={filter.organisationIds}
					search={search}
					setUserFilter={setUserFilter}
					searchUsers={searchUsers}
				></Filter>
				<AccountCategorySwitcher
					isUserStatusActive={filter.userStatus}
					setUserFilter={setUserFilter}
				></AccountCategorySwitcher>
				<List
					data={users}
					isLoading={isGetUsersLoading}
					isGetOrganisationsLoading={isGetOrganisationsLoading}
					isUserStatusActive={filter.userStatus}
					limit={pageSize}
					page={currentPage}
					totalCount={totalCount}
					onChangePage={onChangeCurrentPage}
					onChangeLimit={onChangePageSize}
					setSort={setSort}
				></List>
			</div>
			<Suspense>
				{invitationModal.visible && (
					<InvitationModal
						isLoading={isGetOrganisationsLoading}
						closeModal={invitationModal.closeModal}
						isVisible={invitationModal.visible}
					></InvitationModal>
				)}
			</Suspense>
		</div>
	);
};

export default AdminDashboard;
