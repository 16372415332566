import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M23.8333 7.3335H24C24.25 7.3335 24.5 7.12516 24.5 6.8335V5.8335C24.5 5.5835 24.25 5.3335 24 5.3335H9C8.70833 5.3335 8.5 5.5835 8.5 5.8335V6.8335C8.5 7.12516 8.70833 7.3335 9 7.3335H9.16667C9.16667 10.7085 10.5 14.2502 13.2083 16.0002C10.4583 17.7918 9.16667 21.3335 9.16667 24.6668H9C8.70833 24.6668 8.5 24.9168 8.5 25.1668V26.1668C8.5 26.4585 8.70833 26.6668 9 26.6668H24C24.25 26.6668 24.5 26.4585 24.5 26.1668V25.1668C24.5 24.9168 24.25 24.6668 24 24.6668H23.8333C23.8333 21.3335 22.4583 17.7918 19.75 16.0002C22.5 14.2502 23.8333 10.7085 23.8333 7.3335ZM11.1667 7.3335H21.8333C21.8333 11.5835 19.4167 15.0002 16.5 15.0002C13.5417 15.0002 11.1667 11.5835 11.1667 7.3335ZM21.8333 24.6668H11.1667C11.1667 20.4585 13.5417 17.0002 16.5 17.0002C19.4167 17.0002 21.8333 20.4585 21.8333 24.6668Z"
			fill="currentColor"
		/>
	</svg>
);

const HourglassIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default HourglassIcon;
