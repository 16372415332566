import type { PickerLocale } from 'antd/es/date-picker/generatePicker';
import local from 'antd/es/date-picker/locale/en_US';
import type { DateSelect } from './sp-range-picker.interfaces';

export const DATE_TYPE_OPTIONS = {
	today: 'today',
	yesterday: 'yesterday',
	lastWeek: 'lastWeek',
	thisMonth: 'thisMonth',
	custom: 'custom',
} as const;
export const DATE_OPTIONS: DateSelect[] = [
	{
		title: 'Hari ini',
		value: DATE_TYPE_OPTIONS.today,
	},
	{
		title: 'Kemarin',
		value: DATE_TYPE_OPTIONS.yesterday,
	},
	{
		title: '7 hari terakhir',
		value: DATE_TYPE_OPTIONS.lastWeek,
	},
	{
		title: 'Bulan ini',
		value: DATE_TYPE_OPTIONS.thisMonth,
	},
	{
		title: 'Pilih rentang waktu',
		value: DATE_TYPE_OPTIONS.custom,
	},
];
export const LOCAL_CALENDAR: PickerLocale = {
	...local,
	lang: {
		...local.lang,
		shortWeekDays: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
		shortMonths: [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'Mei',
			'Jun',
			'Jul',
			'Agu',
			'Sep',
			'Okt',
			'Nov',
			'Des',
		],
	},
};
