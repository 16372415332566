import {
	REQUIRED_FIELD_MESSAGE,
	INVALID_EMAIL_MESSAGE,
	INVALID_MINIMUM_PHONE_NUMBER,
	INVALID_FIRST_PHONE_NUMBER,
	EKYC_INVALID_FIRST_PHONE_NUMBER,
	EKYC_INVALID_MINIMUM_PHONE_NUMBER,
} from './errors';
import {
	VALID_EMAIL,
	VALID_FIRST_PHONE_NUMBER,
	VALID_EKYC_FIRST_PHONE_NUMBER,
} from './regex';

export const REQUIRED_RULES = [
	{ required: true, message: REQUIRED_FIELD_MESSAGE },
];
export const EMAIL_VALIDATION_RULES = [
	{
		validator: (_: unknown, value: string) => {
			if (!value) return Promise.reject(REQUIRED_FIELD_MESSAGE);

			if (!VALID_EMAIL.test(value)) {
				return Promise.reject(INVALID_EMAIL_MESSAGE);
			}

			return Promise.resolve();
		},
	},
];
export const PHONE_RULES = [
	{
		validator: (_: unknown, value: string) => {
			if (value && !VALID_FIRST_PHONE_NUMBER.test(value)) {
				return Promise.reject(INVALID_FIRST_PHONE_NUMBER);
			}

			if (value && value.length < 9) {
				return Promise.reject(INVALID_MINIMUM_PHONE_NUMBER);
			}

			return Promise.resolve();
		},
	},
];
export const EKYC_PHONE_RULES = [
	{
		validator: (_: unknown, value: string) => {
			if (!value) return Promise.reject(REQUIRED_FIELD_MESSAGE);

			if (value && !VALID_EKYC_FIRST_PHONE_NUMBER.test(value)) {
				return Promise.reject(EKYC_INVALID_FIRST_PHONE_NUMBER);
			}

			if (value && value.length < 6) {
				return Promise.reject(EKYC_INVALID_MINIMUM_PHONE_NUMBER);
			}

			return Promise.resolve();
		},
	},
];
