import { useState, useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { GENERAL_ERROR_MESSAGE } from 'common/constants';
import dayjs from 'dayjs';
import _debounce from 'lodash/debounce';
import SectionHeader from 'common/components/section-header';
import { downloadFile } from 'common/utils/file';
import ToastMessage from 'common/utils/toast-message';
import { camelize, convertToNumericOnly } from 'common/utils/transformer';
import { generateQueryString } from 'common/utils/url';
import useDidMount from 'common/hooks/use-did-mount';
import usePagination from 'common/hooks/use-pagination';
import useSort from 'common/hooks/use-sort';
import { PAGINATION_SIZE } from 'common/constants/global';
import { downloadActivityLog, getActivityLog } from 'services/activity-log';
import {
	ACTIVITY_OPTIONS_VALUES,
	SUBJECT_LIST_VALUES,
} from './activity-log.constants';
import type {
	ActivityLogSort,
	ActivityFilter,
	SubjectFilter,
	Log,
	Filter,
	GetActivityLogApiResponse,
} from './activity-log.interfaces';
import ActivityLogFilter from './filter';
import ActivityLogTable from './table';

const ActivityLog = () => {
	const {
		currentPage,
		pageSize,
		totalCount,
		setTotalCount,
		setCurrentPage,
		onChangeCurrentPage,
		onChangePageSize,
	} = usePagination();
	const { sort, setSort } = useSort({
		by: 'created_at',
		order: 'desc',
	});

	const [filter, setFilter] = useState<Filter>({
		activity: '',
		dates: [dayjs(), dayjs()],
		search: '',
		subject: '',
		subjectType: '',
		businessUnit: '',
	});
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchValue, setSearchValue] = useState('');
	const [logs, setLogs] = useState<Log[]>([]);
	const [isTyping, setIsTyping] = useState(false);

	const { isFetching } = useQuery({
		queryKey: [
			'getActivityLog',
			filter.search,
			filter.dates,
			filter.activity,
			filter.businessUnit,
			filter.subject,
			currentPage,
			pageSize,
			sort,
		],
		queryFn: () =>
			getActivityLog({
				email: filter.search,
				start_date: dayjs(filter.dates[0]).format('YYYY-MM-DD'),
				end_date: dayjs(filter.dates[1]).format('YYYY-MM-DD'),
				activity_type: filter.activity,
				activity_subject: filter.businessUnit
					? filter.businessUnit
					: filter.subject,
				page: currentPage,
				limit: pageSize,
				sort_by: sort.by,
				sort_method: sort.order,
			}),
		onSuccess: (response) => {
			const data = response?.data ?? null;
			const sanitizedResponse = camelize(data) as GetActivityLogApiResponse;

			if (sanitizedResponse?.items) {
				setLogs(
					sanitizedResponse.items.map((item) => {
						return {
							...item,
							key: item.id,
						};
					})
				);
				setTotalCount(sanitizedResponse.pagination.totalItems);
			}
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
		onSettled: () => {
			setIsTyping(false);
		},
	});

	const { refetch: download, isFetching: isDownloadLoading } = useQuery({
		queryKey: ['downloadActivityLog'],
		enabled: false,
		queryFn: () =>
			downloadActivityLog({
				email: filter.search,
				start_date: dayjs(filter.dates[0]).format('YYYY-MM-DD'),
				end_date: dayjs(filter.dates[1]).format('YYYY-MM-DD'),
				activity_type: filter.activity,
				activity_subject: filter.businessUnit
					? filter.businessUnit
					: filter.subject,
				page: currentPage,
				limit: pageSize,
				sort_by: sort.by,
				sort_method: sort.order,
			}),
		onSuccess: (response) => {
			const filename = `activity-log-${dayjs(filter.dates[0]).format(
				'DD/MM/YYYY'
			)}-${dayjs(filter.dates[1]).format('DD/MM/YYYY')}.xlsx`;

			downloadFile(response, filename);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const onDownload = () => {
		ToastMessage({
			type: 'info',
			label: 'Dokumen sedang diunduh.',
		});
		download();
	};

	const onSearch = (payload: string) => {
		setIsTyping(true);
		setSearchValue(payload);
		onDebounceSearch(payload);
	};

	const onSetFilter = (filter: Filter) => {
		setFilter(filter);
		onChangeCurrentPage(1);
	};

	const onSort = (sort: ActivityLogSort) => {
		setSort(sort);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onDebounceSearch = useCallback(
		_debounce((value: string) => {
			setFilter({
				...filter,
				search: value,
			});
			setCurrentPage(1);
		}, 300),
		[filter]
	);

	const isLoading = isFetching || isTyping;

	useEffect(() => {
		const pageQuery = parseInt(
			convertToNumericOnly(searchParams.get('page') as string) || '1'
		);
		let sizeQuery = parseInt(
			convertToNumericOnly(searchParams.get('size') as string) || '10'
		);
		sizeQuery = PAGINATION_SIZE.includes(sizeQuery) ? sizeQuery : 10;

		const searchQuery = searchParams.get('search') ?? '';
		const buQuery = searchParams.get('bu') ?? '';
		const startDate = searchParams.get('start');
		const endDate = searchParams.get('end');
		const isStartDateValid = dayjs(startDate).isValid();
		const isEndDateValid = dayjs(endDate).isValid();
		const start = isStartDateValid ? startDate : dayjs().format('YYYY-MM-DD');
		const end = isEndDateValid ? endDate : dayjs().format('YYYY-MM-DD');
		const activityQuery = searchParams.get('activity');
		const subjectQuery = searchParams.get('subject');
		const isActivityValid =
			activityQuery !== null &&
			Object.values(ACTIVITY_OPTIONS_VALUES).includes(
				activityQuery as (typeof ACTIVITY_OPTIONS_VALUES)[keyof typeof ACTIVITY_OPTIONS_VALUES]
			);
		const activity = isActivityValid ? (activityQuery as ActivityFilter) : '';
		const isSubjectValid =
			subjectQuery !== null &&
			Object.values(SUBJECT_LIST_VALUES).includes(
				subjectQuery as (typeof SUBJECT_LIST_VALUES)[keyof typeof SUBJECT_LIST_VALUES]
			);
		const subject = isSubjectValid ? (subjectQuery as SubjectFilter) : '';

		onChangeCurrentPage(parseInt(pageQuery.toString()));
		onChangePageSize(parseInt(sizeQuery.toString()));
		setSearchValue(searchQuery);
		setFilter({
			...filter,
			dates: [dayjs(start), dayjs(end)],
			activity,
			subject,
			businessUnit: buQuery,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useDidMount(() => {
		const params = {
			page: currentPage,
			size: pageSize,
			search: searchValue,
			activity: filter.activity,
			bu: filter.businessUnit,
			subject: filter.subject,
			start: dayjs(filter.dates[0]).format('YYYY-MM-DD'),
			end: dayjs(filter.dates[1]).format('YYYY-MM-DD'),
		};

		const sanitizedParams = generateQueryString(params);
		setSearchParams(sanitizedParams, { replace: true });
	}, [
		searchValue,
		currentPage,
		pageSize,
		filter.activity,
		filter.businessUnit,
		filter.subject,
		filter.dates,
	]);

	return (
		<div className="py-8">
			<SectionHeader title="Riwayat dan aktivitas" />
			<div className="mt-6 bg-neutral-0 rounded-lg shadow-base p-6">
				<ActivityLogFilter
					isDownloadLoading={isDownloadLoading}
					hasData={Boolean(logs.length)}
					filter={filter}
					searchValue={searchValue}
					onSetFilter={onSetFilter}
					onSearch={onSearch}
					onDownload={onDownload}
				/>
				<div className="mt-6">
					<ActivityLogTable
						data={logs}
						isLoading={isLoading}
						limit={pageSize}
						page={currentPage}
						totalCount={totalCount}
						onChangePageSize={onChangePageSize}
						onChangeCurrentPage={onChangeCurrentPage}
						onSort={onSort}
					/>
				</div>
			</div>
		</div>
	);
};

export default ActivityLog;
