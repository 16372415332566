import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.7157 7.51667L12.4824 8.28333L4.93236 15.8333H4.16569V15.0667L11.7157 7.51667ZM14.7157 2.5C14.5074 2.5 14.2907 2.58333 14.1324 2.74167L12.6074 4.26667L15.7324 7.39167L17.2574 5.86667C17.5824 5.54167 17.5824 5.01667 17.2574 4.69167L15.3074 2.74167C15.1407 2.575 14.9324 2.5 14.7157 2.5ZM11.7157 5.15833L2.64547 14.2286C2.5517 14.3223 2.49902 14.4495 2.49902 14.5821V17C2.49902 17.2761 2.72288 17.5 2.99902 17.5H5.41692C5.54953 17.5 5.6767 17.4473 5.77047 17.3536L14.8407 8.28333L11.7157 5.15833Z"
			fill="currentColor"
		/>
	</svg>
);

const EditNewIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default EditNewIcon;
