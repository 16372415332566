import { useEffect } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { SERVICES } from 'common/constants/global';
import EditPriceForm from './edit-form';

const EditCustomPrice = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { organisationId } = useParams();

	const currentServiceName = searchParams.get('sname') ?? '';
	const currentOrganisationName = searchParams.get('oname') ?? '';

	const breadcrumbTitle = `Edit kustom harga ${currentServiceName} - ${currentOrganisationName}`;

	useEffect(() => {
		if (!SERVICES.includes(currentServiceName)) {
			return navigate(`/price/custom/${organisationId}`);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="py-8">
			<EditPriceForm breadcrumbTitle={breadcrumbTitle} />
		</div>
	);
};

export default EditCustomPrice;
