/* eslint-disable */
import { Fragment } from 'react';
import { Divider } from 'antd';
import SpSkeleton from 'common/components/sp-skeleton';

const Skeleton = () => {
	const skeletonArray = new Array(3).fill(null).map((_, index) => index);

	return (
		<div className="w-full bg-neutral-0 shadow-base rounded-lg p-6 flex flex-col">
			<div className="flex justify-between items-center mb-8">
				<SpSkeleton className="w-[80px] h-8" />
				<SpSkeleton className="w-[80px] h-8" />
			</div>
			{skeletonArray.map((index) => (
				<Fragment key={index}>
					<div className="flex gap-2 justify-evenly">
						{skeletonArray.map((index) => (
							<SpSkeleton key={index} className="w-full h-8 mb-2" />
						))}
					</div>
					<Divider className="my-0" />
				</Fragment>
			))}
		</div>
	);
};

export default Skeleton;
