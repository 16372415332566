import { Typography, Label, Icon } from '@squantumengine/horizon';
import type { ColumnsType } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import {
	BILLING_STATUS,
	BILLING_SORT_KEYS,
} from 'features/billing-approval/billing-approval.constants';
import type {
	ApprovalList,
	BillingSortKeys,
} from 'features/billing-approval/billing-approval.interfaces';
import {
	generateStatus,
	generateProgress,
} from 'features/billing-approval/billing-approval.utils';
import { useProfileStore } from 'common/stores/profile';
import SpPagination from 'common/components/sp-pagination';
import SpProgress from 'common/components/sp-progress';
import SpTable from 'common/components/sp-table';
import type { SortBy } from 'common/types/global';
import style from './index.module.scss';
import type { TableProps } from './table.interfaces';

const { Paragraph } = Typography;

const Table = ({
	data,
	isLoading,
	currentPage,
	pageSize,
	totalCount,
	onChangeCurrentPage,
	onChangePageSize,
	onGoToDetail,
	onSort,
}: TableProps) => {
	const role = useProfileStore((state) => state.role);

	const onTableChange = (sorter: SorterResult<ApprovalList>) => {
		const by =
			sorter.order && sorter.columnKey
				? (sorter.columnKey as BillingSortKeys)
				: (BILLING_SORT_KEYS.organisationName as BillingSortKeys);
		let order = 'desc' as SortBy;

		if (sorter.order) order = sorter.order === 'ascend' ? 'asc' : order;

		onSort?.({
			by,
			order,
		});
	};

	let columns: ColumnsType<ApprovalList> = [
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: 200,
			className: 'min-w-[200px]',
			render: (_, { state }) => {
				const { type, label } = generateStatus(state, role);

				return type ? <Label label={label} border type={type} /> : label;
			},
		},
		{
			title: 'Progres',
			dataIndex: 'progres',
			key: 'progress',
			width: 296,
			className: 'min-w-[296px]',
			render: (_, { state, paymentConfirmedBy, progressCount }) => {
				if (state === BILLING_STATUS.paymentConfirmed) {
					return (
						<div className="flex items-center">
							<span className="text-blue-500 mr-1">
								<Icon name="check-circle" color="currentColor" />
							</span>
							<Paragraph
								size="s"
								className="text-primary mr-1 whitespace-nowrap"
							>
								Pembayaran telah dikonfirmasi
							</Paragraph>
							<Paragraph size="s" weight="bold" className="whitespace-nowrap">
								User {paymentConfirmedBy}
							</Paragraph>
						</div>
					);
				}

				const { step, label } = generateProgress(state, role);
				const totalStep = role === 'pengelola' ? 2 : 3;

				return (
					<div className="flex flex-col">
						<SpProgress
							full
							progress={progressCount}
							progressColor="#006ceb"
							trailColor="#edf0f5"
						/>
						<div className="flex mt-2 items-center">
							<Paragraph size="s" className="text-secondary mr-1">
								Step {step} dari {totalStep}:
							</Paragraph>
							<Paragraph size="s" weight="bold">
								{label}
							</Paragraph>
						</div>
					</div>
				);
			},
		},
		{
			title: 'Catatan',
			dataIndex: 'note',
			key: 'note',
			render: (_, { latestStateNotes }) => {
				return (
					<Paragraph className="text-primary">
						{latestStateNotes || '-'}
					</Paragraph>
				);
			},
		},
	];

	columns =
		role === 'pengelola'
			? [
					{
						title: 'Bulan',
						dataIndex: 'period',
						key: 'period',
						width: 240,
						className: 'min-w-[240px]',
						render: (_, { period }) => {
							return (
								<Paragraph className="text-primary">
									{dayjs(period).format('MMMM YYYY')}
								</Paragraph>
							);
						},
					},
					...columns,
			  ]
			: [
					{
						title: 'Business unit',
						dataIndex: 'name',
						key: 'name',
						sorter: true,
						width: 240,
						className: 'min-w-[240px]',
						render: (_, { organisationName }) => {
							return (
								<Paragraph className="text-primary">
									{organisationName}
								</Paragraph>
							);
						},
					},
					...columns,
			  ];

	return (
		<>
			<SpTable
				columns={columns}
				dataSource={data}
				className={style.table}
				rowClassName="cursor-pointer"
				pagination={false}
				defaultTable
				bordered
				scroll={{ x: 1000 }}
				isLoading={isLoading}
				onRow={(record) => {
					return {
						onClick: () => onGoToDetail(record.reportId, record.state),
					};
				}}
				onChange={
					/* istanbul ignore next */
					(_pagination, _filters, sorter) =>
						onTableChange(sorter as SorterResult<ApprovalList>)
				}
			/>
			{data.length && !isLoading ? (
				<SpPagination
					currentPage={currentPage}
					pageSize={pageSize}
					totalCount={totalCount}
					onChangePageSize={onChangePageSize}
					onChangeCurrentPage={onChangeCurrentPage}
				/>
			) : null}
		</>
	);
};

export default Table;
