import { useRef } from 'react';
import { Button, Typography, SearchBar, Icon } from '@squantumengine/horizon';
import type { Dayjs } from 'dayjs';
import { SUBJECT_LIST_VALUES } from 'features/activity-log/activity-log.constants';
import type {
	ActivityFilter,
	SubjectFilter,
} from 'features/activity-log/activity-log.interfaces';
import { useProfileStore } from 'common/stores/profile';
import { DownloadIcon } from 'common/components/icons';
import Option from 'common/components/option';
import SpRangePicker from 'common/components/sp-range-picker';
import SpSelect from 'common/components/sp-select';
import { capitalizeEachWord } from 'common/utils/transformer';
import {
	ACTIVITY_OPTIONS,
	SUBJECT_LIST,
	SUBJECT_LIST_VALUE_MAP,
} from './filter.constants';
import type { FilterProps, SubjectRef } from './filter.interfaces';

const { Paragraph } = Typography;

const ActivityLogFilter = ({
	isDownloadLoading,
	filter,
	hasData,
	searchValue,
	onSetFilter,
	onSearch,
	onDownload,
}: FilterProps) => {
	const subjectFilterRef = useRef<SubjectRef>(null);

	const organisations = useProfileStore((state) => state.organisations);

	const businessUnitList = organisations.map((organisations) => {
		return {
			value: organisations.organisationId,
			title: organisations.organisationName,
		};
	});

	const onSearchLog = (value: string) => {
		onSearch(value);
	};

	const onChangeActivityFilter = (activity: ActivityFilter) => {
		onSetFilter({
			...filter,
			activity,
		});
	};

	const onChangeSubject = (subject: SubjectFilter) => {
		if (subject !== SUBJECT_LIST_VALUES.businessUnit) {
			subjectFilterRef.current?.closePopover();
		}

		onSetFilter({
			...filter,
			subject:
				subject === SUBJECT_LIST_VALUES.businessUnit ? filter.subject : subject,
			subjectType: subject,
			businessUnit:
				subject === SUBJECT_LIST_VALUES.businessUnit ? filter.businessUnit : '',
		});
	};

	const onChangeBusinessUnit = (businessUnit: string) => {
		subjectFilterRef.current?.closePopover();

		onSetFilter({
			...filter,
			subject: SUBJECT_LIST_VALUES.businessUnit,
			businessUnit,
		});
	};

	const onSetDates = (dateRange: [Dayjs, Dayjs]) => {
		onSetFilter({
			...filter,
			dates: dateRange,
		});
	};

	const subjectFilterContent = () => (
		<div className="rounded">
			<div className="h-[42px] flex items-center px-4 border-b border-neutral-50">
				<Paragraph weight="semibold" size="r">
					Subyek
				</Paragraph>
			</div>
			<div className="pb-2 flex">
				<ul className="w-[240px]">
					{SUBJECT_LIST.map((option) => {
						return (
							<Option
								key={option.value}
								isActive={option.value === filter.subjectType}
								value={option.value}
								title={option.title}
								suffix={option.suffix ?? null}
								onClick={onChangeSubject}
							/>
						);
					})}
				</ul>
				{filter.subjectType === SUBJECT_LIST_VALUES.businessUnit ? (
					<ul className="w-[280px] max-h-[240px] overflow-auto border-l border-neutral-50">
						{businessUnitList.map((option) => {
							return (
								<Option
									key={option.value}
									isActive={option.value === filter.businessUnit}
									value={option.value}
									title={option.title}
									onClick={onChangeBusinessUnit}
								/>
							);
						})}
					</ul>
				) : null}
			</div>
		</div>
	);

	const value =
		filter.subject === SUBJECT_LIST_VALUES.businessUnit
			? capitalizeEachWord(
					organisations.find(
						(organisation) =>
							organisation.organisationId === filter.businessUnit
					)?.organisationName ?? ''
			  )
			: SUBJECT_LIST_VALUE_MAP[filter.subject];

	return (
		<div className="flex justify-between">
			<div className="flex">
				<SearchBar
					className="w-[300px] mr-4"
					size="compact"
					aria-label="Search email"
					placeholder="Cari nama pengguna"
					value={searchValue}
					onChange={onSearchLog}
				/>
				<SpRangePicker dates={filter.dates} setDates={onSetDates} />
				<div className="ml-4">
					<SpSelect
						size="lg"
						placement="bottomRight"
						contentClassName="w-[200px] max-h-none"
						value={filter.activity}
						options={ACTIVITY_OPTIONS}
						suffix={<Icon name="chevron-large-down" color="currentColor" />}
						onChange={onChangeActivityFilter}
					/>
				</div>
				<div className="ml-4">
					<SpSelect
						size="lg"
						ref={subjectFilterRef}
						value={value}
						className="max-w-[200px]"
						suffix={<Icon name="chevron-large-down" color="currentColor" />}
						content={subjectFilterContent}
					/>
				</div>
			</div>
			<Button
				variant="secondary"
				disabled={!hasData}
				loading={isDownloadLoading}
				onClick={onDownload}
			>
				<span className="mr-2">
					<DownloadIcon />
				</span>
				Unduh data
			</Button>
		</div>
	);
};

export default ActivityLogFilter;
