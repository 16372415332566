import type { PostInviteUserApiPayload } from 'features/admin-dashboard/admin-dashboard.interfaces';
import axios from './axios-instance';

const baseUrl = '/v2/user';

export const invite = async (payload: PostInviteUserApiPayload) => {
	const response = await axios.post(`${baseUrl}/invite`, payload);

	return response.data;
};
export const edit = async () => {
	const response = await axios.get('/v1/user/generate-edit-token');

	return response.data;
};
export const updatePassword = async () => {
	const response = await axios.get('/v1/user/change-password-token');

	return response.data;
};
