import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M17.8332 11.0002L17.8332 21.0002C17.8332 21.2085 17.9999 21.3335 18.1666 21.3335L19.4999 21.3335C19.7082 21.3335 19.8332 21.2085 19.8332 21.0002L19.8332 11.0002C19.8332 10.8335 19.7082 10.6668 19.4999 10.6668L18.1666 10.6668C17.9999 10.6668 17.8332 10.8335 17.8332 11.0002ZM6.08324 8.66683L7.3749 10.4585L6.2499 12.0835C5.7499 12.7918 5.7499 13.7085 6.2499 14.4168L7.3749 16.0002L6.2499 17.6252C5.9999 17.9585 5.8749 18.3752 5.8749 18.7918C5.8749 19.2502 5.9999 19.6252 6.2499 19.9585L7.3749 21.5835L6.08324 23.3752C5.4999 24.2085 6.08324 25.3335 7.08324 25.3335L25.9582 25.3335C26.9166 25.3335 27.5416 24.2085 26.9582 23.3752L25.6249 21.5835L26.7916 19.9585C27.2916 19.2502 27.2916 18.3335 26.7916 17.6252L25.6249 16.0002L26.7916 14.4168C27.2916 13.7085 27.2916 12.7918 26.7916 12.0835L25.6249 10.4585L26.9582 8.66683C27.5416 7.8335 26.9582 6.66683 25.9582 6.66683L7.08324 6.66683C6.08324 6.66683 5.4999 7.8335 6.08324 8.66683ZM24.4582 8.66683L23.1666 10.4585L25.1666 13.2502L23.1666 16.0418L25.1666 18.7918L23.1666 21.5835L24.4582 23.3335L8.58324 23.3335L9.8749 21.5835L7.8749 18.7918L9.8749 16.0002L7.8749 13.2502L9.8749 10.4585L8.58324 8.66683L24.4582 8.66683ZM13.1666 11.0002L13.1666 21.0002C13.1666 21.2085 13.3332 21.3335 13.4999 21.3335L14.8332 21.3335C15.0416 21.3335 15.1666 21.2085 15.1666 21.0002L15.1666 11.0002C15.1666 10.8335 15.0416 10.6668 14.8332 10.6668L13.4999 10.6668C13.3332 10.6668 13.1666 10.8335 13.1666 11.0002Z"
			fill="currentColor"
		/>
		<path
			d="M21.5001 17.3332H11.5001C11.2917 17.3332 11.1667 17.4999 11.1667 17.6666V18.9999C11.1667 19.2082 11.2917 19.3332 11.5001 19.3332H21.5001C21.6667 19.3332 21.8334 19.2082 21.8334 18.9999V17.6666C21.8334 17.4999 21.6667 17.3332 21.5001 17.3332ZM23.8334 5.58324L22.0417 6.8749L20.4167 5.7499C19.7084 5.2499 18.7917 5.2499 18.0834 5.7499L16.5001 6.8749L14.8751 5.7499C14.5417 5.4999 14.1251 5.3749 13.7084 5.3749C13.2501 5.3749 12.8751 5.4999 12.5417 5.7499L10.9167 6.8749L9.12508 5.58324C8.29175 4.9999 7.16675 5.58324 7.16675 6.58324L7.16675 25.4582C7.16675 26.4166 8.29175 27.0416 9.12508 26.4582L10.9167 25.1249L12.5417 26.2916C13.2501 26.7916 14.1667 26.7916 14.8751 26.2916L16.5001 25.1249L18.0834 26.2916C18.7917 26.7916 19.7084 26.7916 20.4167 26.2916L22.0417 25.1249L23.8334 26.4582C24.6667 27.0416 25.8334 26.4582 25.8334 25.4582L25.8334 6.58324C25.8334 5.58324 24.6667 4.9999 23.8334 5.58324ZM23.8334 23.9582L22.0417 22.6666L19.2501 24.6666L16.4584 22.6666L13.7084 24.6666L10.9167 22.6666L9.16675 23.9582L9.16675 8.08324L10.9167 9.3749L13.7084 7.3749L16.5001 9.3749L19.2501 7.3749L22.0417 9.3749L23.8334 8.08324L23.8334 23.9582ZM21.5001 12.6666L11.5001 12.6666C11.2917 12.6666 11.1667 12.8332 11.1667 12.9999V14.3332C11.1667 14.5416 11.2917 14.6666 11.5001 14.6666L21.5001 14.6666C21.6667 14.6666 21.8334 14.5416 21.8334 14.3332V12.9999C21.8334 12.8332 21.6667 12.6666 21.5001 12.6666Z"
			fill="currentColor"
		/>
	</svg>
);

const StampIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default StampIcon;
