import type { LabelType } from '@squantumengine/horizon';
import type {
	DocumentStatus,
	EmateraiStatus,
} from './kyc-dashboard.interfaces';

export const DOCUMENT_STATUS = {
	all: '',
	success: 'SUCCESS',
	pending: 'PENDING',
	failed: 'FAILED',
	canceled: 'CANCELED',
} as const;
export const ALL_DOCUMENT_STATUS = [
	DOCUMENT_STATUS.success,
	DOCUMENT_STATUS.pending,
	DOCUMENT_STATUS.failed,
	DOCUMENT_STATUS.canceled,
];
export const SIGNER_STATUS = {
	success: 'SUCCESS',
	pending: 'PENDING',
	failed: 'FAILED',
} as const;
export const EMATERAI_STATUS = {
	success: 'SUCCESS',
	pending: 'PENDING',
	failed: 'FAILED',
} as const;
export const ALL_EMATERAI_STATUS = [
	EMATERAI_STATUS.success,
	EMATERAI_STATUS.pending,
	EMATERAI_STATUS.failed,
];
export const DOCUMENT_SORT_KEYS = {
	createdAt: 'created_at',
	updatedAt: 'updated_at',
} as const;
export const DOCUMENT_STATUS_PROPS: {
	[key in DocumentStatus]: {
		label: string;
		type: 'success' | 'info' | 'danger' | 'warning' | 'default';
	};
} & {
	DEFAULT: {
		label: string;
		type: 'success' | 'info' | 'danger' | 'warning' | 'default';
	};
} = {
	SUCCESS: { label: 'Berhasil', type: 'info' },
	PENDING: { label: 'Pending', type: 'default' },
	FAILED: { label: 'Gagal', type: 'warning' },
	CANCELED: { label: 'Dibatalkan', type: 'danger' },
	DEFAULT: { label: '-', type: 'default' },
} as const;

export const EMATERAI_STATUS_PROPS: {
	[key in EmateraiStatus]: {
		label: string;
		type: LabelType;
	};
} & {
	DEFAULT: {
		label: string;
		type: LabelType;
	};
} = {
	SUCCESS: { label: 'Berhasil', type: 'info' },
	PENDING: { label: 'Pending', type: 'default' },
	FAILED: { label: 'Gagal', type: 'warning' },
	ON_GOING: { label: 'Pending', type: 'default' },
	DEFAULT: { label: '-', type: 'default' },
} as const;
