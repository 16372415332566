import { Label } from '@squantumengine/horizon';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import type { GetOrganisationServiceApiServicesResponse } from 'common/types/organisation';

const ServiceTag = ({
	services,
}: {
	services: GetOrganisationServiceApiServicesResponse[];
}) => {
	return (
		<div className="flex">
			{services
				.filter((service) => service.isActive)
				.map((service) => {
					if (service.useCustomPrice) {
						return (
							<Tooltip
								placement="topLeft"
								title={`Update terakhir : ${dayjs(
									service.servicePriceUpdatedAt
								).format('DD/MM/YY')}`}
								key={service.sqeserviceName}
							>
								<div className="mr-2">
									<Label label={service.sqeserviceName} type="warning" border />
								</div>
							</Tooltip>
						);
					}

					return (
						<div key={service.sqeserviceName} className="mr-2">
							<Label label={service.sqeserviceName} type="success" border />
						</div>
					);
				})}
		</div>
	);
};

export default ServiceTag;
