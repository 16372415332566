import BaseAnalytic from 'common/components/analytic';
import Skeleton from './skeleton';

const KnowYourCustomer = () => {
	return (
		<BaseAnalytic
			path="/know-your-customer"
			skeleton={<Skeleton />}
			tag="ekyc"
		></BaseAnalytic>
	);
};

export default KnowYourCustomer;
