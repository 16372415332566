import { useEffect, useRef } from 'react';
import type { SpProgressProps } from './sp-progress.interfaces';

const SpProgress = ({
	width,
	full,
	progress,
	progressColor = '#E5AC00',
	trailColor = '#D5DBE5',
}: SpProgressProps) => {
	const containerRef = useRef<null | HTMLDivElement>(null);
	const progressRef = useRef<null | HTMLDivElement>(null);

	const containerStyle = {
		width: full ? '100%' : `${width}px`,
	};
	const trailStyle = {
		background: trailColor,
	};
	const progressStyle = {
		background: progressColor,
	};

	useEffect(() => {
		const containerWidth = containerRef.current?.clientWidth ?? 0;
		const progressWidth = containerWidth
			? `${(progress / 100) * containerWidth}px`
			: '0px';

		// SQEPORT-1246: commented first until the current can be mocked
		/* istanbul ignore else */
		if (progressRef.current) {
			progressRef.current.style.width = progressWidth;
		}
	}, [width, full, progress]);

	return (
		<div
			className="h-2 relative overflow-hidden"
			data-testid="progressContainer"
			ref={containerRef}
			style={containerStyle}
		>
			<div className="w-full h-full rounded-[20px]" style={trailStyle} />
			<div
				className="rounded-[20px] absolute absolute top-0 left-0 z-[1] h-full"
				ref={progressRef}
				data-testid="progressBar"
				style={progressStyle}
			/>
		</div>
	);
};

export default SpProgress;
