export const SORT = {
	ASCEND: 'ascend',
} as const;
export const API_STATUS_RESPONSE = {
	SUCCESS: 'SUCCESS',
	FAILED: 'FAILED',
} as const;
export const PAGINATION_SIZE = [10, 20, 30];
export const SERVICES = ['SQECC', 'SQEKYC', 'SQEID', 'SQEMP', 'SQEPORTAL'];
export const ROLES = {
	ADMIN: 'ADMIN',
	MANAGER: 'MANAGER',
	PENGELOLA: 'PENGELOLA',
} as const;
export const ENVIRONTMENTS = {
	production: 'production',
	staging: 'staging',
	development: 'development',
} as const;
export const FILE_ACTION = {
	download: 'download',
	open: 'open',
} as const;
