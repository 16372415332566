import { Typography } from '@squantumengine/horizon';

const { Paragraph } = Typography;

const Empty = () => {
	return (
		<div className="flex flex-col items-center justify-center h-[394px] bg-[#fafafb] px-8">
			<div className="w-[100px] h-[100px]">
				<img
					src="/assets/illustrations/empty-notification.svg"
					alt="Empty notification"
					className="h-full w-auto"
				/>
			</div>
			<div className="flex flex-col items-center mt-4">
				<Paragraph weight="semibold" size="r" className="text-primary">
					Semua informasi baru yang muncul
				</Paragraph>
				<Paragraph
					weight="regular"
					size="r"
					className="mt-2 text-secondary text-center"
				>
					Kami akan memberitahukanmu jika terdapat informasi baru yang masuk
				</Paragraph>
			</div>
		</div>
	);
};

export default Empty;
