import SpSkeleton from 'common/components/sp-skeleton';

const Skeleton = () => {
	return (
		<div className="flex flex-col justify-between shadow-light-lg border-none rounded-lg bg-neutral-0 p-4 h-[224px]">
			<div className="flex flex-col">
				<SpSkeleton className="h-12 w-12" />
				<SpSkeleton className="w-[20%] h-6 mt-2" />
				<SpSkeleton className="w-[70%] h-5 mt-1" />
			</div>
			<SpSkeleton className="w-full h-8" />
		</div>
	);
};

export default Skeleton;
