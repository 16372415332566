import {
	ACTIVITY_OPTIONS_VALUES,
	SUBJECT_LIST_VALUES,
} from 'features/activity-log/activity-log.constants';
import { ArrowRightIcon } from 'common/components/icons';
import type { ActivitySelect, SubjectSelect } from './filter.interfaces';

export const ACTIVITY_OPTIONS: ActivitySelect[] = [
	{
		title: 'Semua aktivitas',
		value: ACTIVITY_OPTIONS_VALUES.all,
	},
	{
		title: 'Create new',
		value: ACTIVITY_OPTIONS_VALUES.create,
	},
	{
		title: 'Modified',
		value: ACTIVITY_OPTIONS_VALUES.modified,
	},
	{
		title: 'Enable',
		value: ACTIVITY_OPTIONS_VALUES.enable,
	},
	{
		title: 'Disable',
		value: ACTIVITY_OPTIONS_VALUES.disable,
	},
	{
		title: 'Delete',
		value: ACTIVITY_OPTIONS_VALUES.delete,
	},
];
export const SUBJECT_LIST: SubjectSelect[] = [
	{
		title: 'Semua subyek',
		value: SUBJECT_LIST_VALUES.all,
	},
	{
		title: 'General',
		value: SUBJECT_LIST_VALUES.general,
	},
	{
		title: 'Business unit',
		value: SUBJECT_LIST_VALUES.businessUnit,
		suffix: <ArrowRightIcon />,
	},
];
export const SUBJECT_LIST_VALUE_MAP: { [key: string]: string } = {
	'': 'Semua subyek',
	GENERAL: 'General',
};
