import { Fragment } from 'react';
import { Divider } from 'antd';
import { SETTINGS_FORMAT } from 'features/business-unit/form/invoice-setting/invoice-setting.constants';
import RowTitle from 'features/invoice-preview/row-title';
import { convertToThousandsSeparator } from 'common/utils/transformer';
import type { AdditionalCostProps } from './additional-cost.interfaces';

const AdditionalCost = ({
	title,
	titleValue,
	additionalCosts,
	summaryTitle,
	summaryValue,
	children,
}: AdditionalCostProps) => {
	return (
		<div className="rounded-lg border border-stroke-secondary flex flex-col bg-neutral-0 overflow-hidden">
			{additionalCosts.length ? (
				<Fragment>
					<RowTitle title={title} value={titleValue} rowClassName="py-3 px-4" />
					<Divider className="m-0 border-stroke-primary" />
					{additionalCosts.map((additional) => {
						const value =
							additional.format === SETTINGS_FORMAT.PERCENT
								? convertToThousandsSeparator(
										additional.additionalValue.toString()
								  )
								: convertToThousandsSeparator(additional.value.toString());

						return (
							<RowTitle
								key={additional.name}
								title={additional.name}
								value={value}
								useDivider
								rowClassName="p-4"
								bold={false}
							/>
						);
					})}
					<RowTitle
						title={summaryTitle}
						rowClassName="p-4 bg-neutral-50"
						useDivider
						value={summaryValue}
					/>
				</Fragment>
			) : null}
			{children}
		</div>
	);
};

export default AdditionalCost;
