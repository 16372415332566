import { useEffect } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import DashboardLayout from 'layouts/dashboard';
import ActivityLog from 'features/activity-log';
import AdminDashboard from 'features/admin-dashboard';
import BillingApprovalMiddleware from 'features/billing-approval/billing-approval.middleware';
import ManagerApproval from 'features/billing-approval/manager';
import DetailManagerApproval from 'features/billing-approval/manager/detail.page';
import ManagerApprovalLogs from 'features/billing-approval/manager/log.page';
import DetailPengelolaApproval from 'features/billing-approval/pengelola/detail.page';
import PengelolaApprovalLogs from 'features/billing-approval/pengelola/log.page';
import BusinessUnit from 'features/business-unit';
import AddBusinessUnit from 'features/business-unit/add-business-unit.page';
import DetailBusinessUnit from 'features/business-unit/detail-business-unit.page';
import EditBusinessUnit from 'features/business-unit/edit-business-unit.page';
import CcAnalyticDashboard from 'features/contact-center';
import KycDashboard from 'features/dashboard/know-your-customer';
import DetailSigner from 'features/dashboard/know-your-customer/detail';
import SendReminderSettings from 'features/dashboard/know-your-customer/send-reminder-settings';
import SignerActivityLog from 'features/dashboard/know-your-customer/signer-activity-log';
import IdAnalyticDashboard from 'features/identity';
import Inactive from 'features/inactive.page';
import InvoiceManagement from 'features/invoice-management';
import InvoicePreview from 'features/invoice-preview';
import KycAnalyticDashboard from 'features/know-your-customer';
import LoginCallback from 'features/login-callback.page';
import MainDashboard from 'features/main-dashboard';
import Maintenance from 'features/maintenance.page';
import MpAnalyticDashboard from 'features/messaging-platform';
import PageNotFound from 'features/page-not-found.page';
import CustomPrice from 'features/price/custom-price.page';
import EditCustomPrice from 'features/price/edit-custom-price.page';
import EditGeneralPrice from 'features/price/edit-general-price.page';
import GeneralPrice from 'features/price/general-price.page';
import Redirect from 'features/redirect.page';
import Unauthorized from 'features/unauthorized.page';
import UnregisteredAccount from 'features/unregistered-account.page';
import Version from 'features/version.page';
import PrivateRoute from 'common/components/private-route';
import { growthbook } from 'common/utils/growthbook';

function App() {
	useEffect(() => {
		growthbook.init({ streaming: true });
	}, []);

	return (
		<GrowthBookProvider growthbook={growthbook}>
			<Routes>
				<Route path="/" element={<DashboardLayout />}>
					<Route
						index
						element={
							<PrivateRoute
								component={<MainDashboard />}
								allowedRoles={['manager', 'pengelola']}
							/>
						}
					/>
					<Route
						path="contact-center"
						element={
							<PrivateRoute
								component={<CcAnalyticDashboard />}
								allowedRoles={['manager', 'pengelola']}
							/>
						}
					/>
					<Route
						path="identity"
						element={
							<PrivateRoute
								component={<IdAnalyticDashboard />}
								allowedRoles={['manager', 'pengelola']}
							/>
						}
					/>
					<Route
						path="messaging-platform"
						element={
							<PrivateRoute
								component={<MpAnalyticDashboard />}
								allowedRoles={['manager', 'pengelola']}
							/>
						}
					/>
					<Route
						path="know-your-customer"
						element={
							<PrivateRoute
								component={<KycAnalyticDashboard />}
								allowedRoles={['manager', 'pengelola']}
							/>
						}
					/>
					<Route path="dashboard" element={<Redirect />}>
						<Route
							path="know-your-customer"
							element={
								<PrivateRoute
									component={<KycDashboard />}
									allowedRoles={['pengelola']}
								/>
							}
						/>
						<Route
							path="know-your-customer/signer/:signerId"
							element={
								<PrivateRoute
									component={<DetailSigner />}
									allowedRoles={['pengelola']}
								/>
							}
						/>
						<Route
							path="know-your-customer/signer/activity-log"
							element={
								<PrivateRoute
									component={<SignerActivityLog />}
									allowedRoles={['pengelola']}
								/>
							}
						/>
						<Route
							path="know-your-customer/send-reminder"
							element={
								<PrivateRoute
									component={<SendReminderSettings />}
									allowedRoles={['pengelola']}
								/>
							}
						/>
					</Route>
					<Route
						path="admin"
						element={
							<PrivateRoute
								component={<AdminDashboard />}
								allowedRoles={['admin']}
							/>
						}
					/>
					<Route
						path="activity-log"
						element={
							<PrivateRoute
								component={<ActivityLog />}
								allowedRoles={['manager']}
							/>
						}
					/>
					<Route path="price" element={<Redirect />}>
						<Route
							path="general"
							element={
								<PrivateRoute
									component={<GeneralPrice />}
									allowedRoles={['manager']}
								/>
							}
						/>
						<Route
							path="general/edit"
							element={
								<PrivateRoute
									component={<EditGeneralPrice />}
									allowedRoles={['manager']}
								/>
							}
						/>
						<Route
							path="custom/:organisationId"
							element={
								<PrivateRoute
									component={<CustomPrice />}
									allowedRoles={['manager']}
								/>
							}
						/>
						<Route
							path="custom/:organisationId/edit"
							element={
								<PrivateRoute
									component={<EditCustomPrice />}
									allowedRoles={['manager']}
								/>
							}
						/>
					</Route>
					<Route path="invoice-management">
						<Route
							index
							element={
								<PrivateRoute
									component={<InvoiceManagement />}
									allowedRoles={['manager']}
								/>
							}
						/>
						<Route
							path="preview/:organisationId"
							element={
								<PrivateRoute
									component={<InvoicePreview />}
									allowedRoles={['manager']}
								/>
							}
						/>
						<Route path="approval">
							<Route
								index
								element={
									<PrivateRoute
										component={<ManagerApproval />}
										allowedRoles={['manager']}
									/>
								}
							/>
							<Route
								path=":reportId"
								element={
									<PrivateRoute
										component={
											<BillingApprovalMiddleware
												component={<DetailManagerApproval />}
											/>
										}
										allowedRoles={['manager']}
									/>
								}
							/>
							<Route
								path="log/:reportId"
								element={
									<PrivateRoute
										component={
											<BillingApprovalMiddleware
												component={<ManagerApprovalLogs />}
											/>
										}
										allowedRoles={['manager']}
									/>
								}
							/>
						</Route>
					</Route>
					<Route path="approval" element={<Redirect />}>
						<Route
							path="log/:reportId"
							element={
								<PrivateRoute
									component={
										<BillingApprovalMiddleware
											component={<PengelolaApprovalLogs />}
										/>
									}
									allowedRoles={['pengelola']}
								/>
							}
						/>
						<Route
							path=":reportId"
							element={
								<PrivateRoute
									component={
										<BillingApprovalMiddleware
											component={<DetailPengelolaApproval />}
										/>
									}
									allowedRoles={['pengelola']}
								/>
							}
						/>
					</Route>
					<Route
						path="business-unit"
						element={
							<PrivateRoute
								component={<BusinessUnit />}
								allowedRoles={['manager']}
							/>
						}
					>
						<Route
							path="add"
							element={
								<PrivateRoute
									component={<AddBusinessUnit />}
									allowedRoles={['manager']}
								/>
							}
						/>
						<Route
							path=":organisationId/edit"
							element={
								<PrivateRoute
									component={<EditBusinessUnit />}
									allowedRoles={['manager']}
								/>
							}
						/>
						<Route
							path=":organisationId"
							element={
								<PrivateRoute
									component={<DetailBusinessUnit />}
									allowedRoles={['manager']}
								/>
							}
						/>
					</Route>
					<Route path="unauthorized" element={<Unauthorized />} />
					<Route path="404" element={<PageNotFound />} />
					<Route
						path="unregistered-account"
						element={<UnregisteredAccount />}
					/>
					<Route path="inactive" element={<Inactive />} />
					<Route path="*" element={<Navigate to="/404" replace />} />
				</Route>
				<Route path="sso-callback" element={<LoginCallback />} />
				<Route path="version" element={<Version />} />
				<Route path="maintenance" element={<Maintenance />} />
			</Routes>
		</GrowthBookProvider>
	);
}

export default App;
