import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_12538_14529)">
			<path
				d="M15.4951 5.52051L8.64616 12.3695L6.14616 9.84342C6.01595 9.73926 5.80762 9.73926 5.70345 9.84342L4.94824 10.5986C4.84408 10.7028 4.84408 10.9111 4.94824 11.0413L8.43783 14.5049C8.56803 14.6351 8.75033 14.6351 8.88053 14.5049L16.693 6.69238C16.7972 6.58822 16.7972 6.37988 16.693 6.24967L15.9378 5.52051C15.8337 5.3903 15.6253 5.3903 15.4951 5.52051Z"
				fill="#1E252E"
			/>
		</g>
		<defs>
			<clipPath id="clip0_12538_14529">
				<path
					d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2Z"
					fill="white"
				/>
			</clipPath>
		</defs>
	</svg>
);

const CheckmarkIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} aria-label="Checkmark icon" />
);

export default CheckmarkIcon;
