import { Typography } from '@squantumengine/horizon';
import { Divider } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import type { SortBy } from 'common/types';
import dayjs from 'dayjs';
import type { Log } from 'features/dashboard/know-your-customer/signer-activity-log/signer-activity-log.interfaces';
import SpPagination from 'common/components/sp-pagination';
import SpTable from 'common/components/sp-table';
import style from './index.module.scss';
import type { SigerLogSortKey, TableProps } from './table.interfaces';
import Value from './value';

const { Paragraph } = Typography;

const SignerActivityLogTable = ({
	data: signerLogs,
	isLoading,
	limit,
	page,
	totalCount,
	onChangeCurrentPage,
	onChangePageSize,
	onSort,
}: TableProps) => {
	const onTableChange = (sorter: SorterResult<Log>) => {
		const by =
			sorter.order && sorter.columnKey
				? (sorter.columnKey as SigerLogSortKey)
				: ('created_at' as SigerLogSortKey);
		let order = 'desc' as SortBy;

		if (sorter.order) order = sorter.order === 'ascend' ? 'asc' : order;

		onSort({
			by,
			order,
		});
	};

	const valueTitle = () => {
		return (
			<div className="m-[-16px] flex flex-col h-full">
				<div className="flex items-center justify-center h-11">
					<Paragraph size="r" weight="semibold">
						Data yang diedit
					</Paragraph>
				</div>
				<Divider className="m-0" />
				<div className="flex h-11">
					<div className="flex w-[300px] items-center justify-center">
						<Paragraph size="r" weight="semibold">
							Data lama
						</Paragraph>
					</div>
					<Divider className="m-0 h-full" type="vertical" />
					<div className="flex w-[300px] items-center justify-center">
						<Paragraph size="r" weight="semibold">
							Data baru
						</Paragraph>
					</div>
				</div>
			</div>
		);
	};

	const signerLogsColumns: ColumnsType<Log> = [
		{
			title: 'Update terakhir',
			dataIndex: 'createdAt',
			fixed: 'left',
			key: 'created_at',
			sorter: true,
			render: (_, { createdAt }) => (
				<>{dayjs(createdAt).format('DD MMM YYYY - HH:mm:ss')}</>
			),
		},
		{
			title: 'Diedit oleh',
			dataIndex: 'actorEmail',
		},
		{
			title: 'User ID',
			dataIndex: 'updatedRefUserId',
		},
		{
			colSpan: 2,
			className: 'w-[600px] min-w-[600px]',
			title: valueTitle,
			render: (_, { changeLogs }) => (
				<div className="flex flex-col m-[-16px]">
					{changeLogs?.map((log) => (
						<Value
							key={log.id}
							title={log.title}
							oldValue={log.oldValue}
							newValue={log.newValue}
						/>
					))}
				</div>
			),
			onCell: () => ({
				colSpan: 2,
			}),
		},
	];

	return (
		<>
			<SpTable
				columns={signerLogsColumns}
				dataSource={signerLogs}
				bordered
				defaultTable
				scroll={{
					x: 1600,
				}}
				className={style.table}
				isLoading={isLoading}
				pagination={false}
				onChange={(_pagination, _filters, sorter) =>
					onTableChange(sorter as SorterResult<Log>)
				}
			/>
			{signerLogs.length && !isLoading ? (
				<div className="px-6">
					<SpPagination
						currentPage={page}
						pageSize={limit}
						totalCount={totalCount}
						onChangePageSize={onChangePageSize}
						onChangeCurrentPage={onChangeCurrentPage}
					/>
				</div>
			) : null}
		</>
	);
};

export default SignerActivityLogTable;
