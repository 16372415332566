import { useEffect, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IfFeatureEnabled } from '@growthbook/growthbook-react';
import { Typography, Tabs, Button } from '@squantumengine/horizon';
import { useModalContext } from 'common/context/shared-modal';
import dayjs from 'dayjs';
import RecapWidget from 'features/dashboard/know-your-customer/widget/recap';
import { useProfileStore } from 'common/stores/profile';
import type { ServiceAvailableQuota } from 'common/components/analytic/analytic.interfaces';
import {
	DocumentIcon,
	HourglassIcon,
	SignIcon,
	StampIcon,
} from 'common/components/icons';
import SectionHeader from 'common/components/section-header';
import { DATE_TYPE_OPTIONS } from 'common/components/sp-range-picker/sp-range-picker.constants';
import type { DateOptions } from 'common/components/sp-range-picker/sp-range-picker.interfaces';
import { downloadFile } from 'common/utils/file';
import ToastMessage from 'common/utils/toast-message';
import {
	camelize,
	convertToNumericOnly,
	convertToThousandsSeparator,
} from 'common/utils/transformer';
import { generateQueryString } from 'common/utils/url';
import useDidMount from 'common/hooks/use-did-mount';
import { useIntersection } from 'common/hooks/use-intersection';
import usePagination from 'common/hooks/use-pagination';
import useSort from 'common/hooks/use-sort';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { PAGINATION_SIZE } from 'common/constants/global';
import {
	getDocumentCount,
	getStampedDocumentCount,
	getDocuments,
	downloadDocumentActivity,
	getSignedDocumentCount,
	getAverageContractCompletenessTime,
	getAnalyticQuota,
	getSignerList,
	getStampList,
} from 'services/usage';
import DocumentFilter from './document-filter';
import EmateraiFilter from './ematerai-filter';
import EmptyDocument from './empty-document';
import DashboardFilter from './filter';
import {
	ALL_DOCUMENT_STATUS,
	ALL_EMATERAI_STATUS,
} from './kyc-dashboard.constants';
import type {
	Filter,
	DocumentHistory,
	DocumentStatus,
	OverviewWidget,
	DocumentSort,
	EmateraiStatus,
} from './kyc-dashboard.interfaces';
import SignerFilter from './signer-filter';
import DashboardTable from './table';
import EmateraiTable from './table/ematerai';
import type { EmateraiList } from './table/ematerai/ematerai-table.interfaces';
import SignerTable from './table/signer';
import type {
	SignerList,
	SignerSort,
} from './table/signer/signer-table.interfaces';
import Widget from './widget';
import EmeteraiWidgetRecap from './widget/emeterai';
import RecapSkeleton from './widget/recap/skeleton';
import WidgetSkeleton from './widget/skeleton';

const { Paragraph } = Typography;

const KycDashboard = () => {
	const navigate = useNavigate();
	const { toggleSharedModal } = useModalContext();
	const {
		currentPage,
		pageSize,
		totalCount,
		setTotalCount,
		setCurrentPage,
		onChangeCurrentPage,
		onChangePageSize,
	} = usePagination();
	const signerPagination = usePagination();
	const stampPagination = usePagination();

	const { sort, setSort } = useSort({
		by: 'created_at',
		order: 'desc',
	});

	const sortSignerList = useSort({
		by: 'last_activity',
		order: 'desc',
	});

	const [filter, setFilter] = useState<Filter>({
		dates: [dayjs().startOf('month'), dayjs()],
		status: ALL_DOCUMENT_STATUS,
		stampStatus: ALL_EMATERAI_STATUS,
	});

	const [search, setSearch] = useState('');
	const [searchSigner, setSearchSigner] = useState('');
	const [searchEmaterai, setSearchEmaterai] = useState('');
	const [currentTab, setCurrentTab] = useState('document');
	const [dateType, setDateType] = useState<DateOptions>(
		DATE_TYPE_OPTIONS.thisMonth
	);
	const [documents, setDocuments] = useState<DocumentHistory[]>([]);
	const [documentCount, setDocumentCount] = useState<OverviewWidget>({
		data: [],
		title: '',
		tag: '',
		completenessRate: 0,
	});
	const [signerList, setSignerList] = useState<SignerList[]>([]);
	const [stampList, setStampList] = useState<EmateraiList[]>([]);
	const [isQueryEnabled, setIsQueryEnabled] = useState(false);
	const [stampedDocumentCount, setStampedDocumentCount] =
		useState<OverviewWidget>({
			data: [],
			title: '',
		});
	const [signedDocumentCount, setSignedDocumentCount] =
		useState<OverviewWidget>({
			data: [],
			title: '',
			tag: '',
			completenessRate: 0,
		});
	const [searchParams, setSearchParams] = useSearchParams();
	const [averageCompletenessTime, setAverageCompletenessTime] = useState();
	const [emeteraiQuota, setEmeteraiQuota] = useState({
		count: 0,
		lastUpdatedAt: '',
	});
	const organisationId = useProfileStore(
		(state) => state.organisations?.[0]?.organisationId
	);

	const { isFetching: isAverageCompletenessTimeLoading } = useQuery({
		queryKey: ['averageCompletenessTime', filter.dates],
		queryFn: () =>
			getAverageContractCompletenessTime({
				start_at: dayjs(filter.dates[0]).format('YYYY-MM-DD'),
				end_at: dayjs(filter.dates[1]).format('YYYY-MM-DD'),
			}),
		refetchOnWindowFocus: true,
		enabled: isQueryEnabled,
		onSuccess: (response) => {
			const data = response?.data ?? null;

			setAverageCompletenessTime(data?.day);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const { isFetching: isDocumentCountLoading } = useQuery({
		queryKey: ['getDocumentCount', filter.dates],
		queryFn: () =>
			getDocumentCount({
				start_at: dayjs(filter.dates[0]).format('YYYY-MM-DD'),
				end_at: dayjs(filter.dates[1]).format('YYYY-MM-DD'),
			}),
		refetchOnWindowFocus: true,
		enabled: isQueryEnabled,
		onSuccess: (response) => {
			const data = response?.data ?? null;

			setDocumentCount({
				title: 'Status dokumen',
				data: [
					{
						name: 'Berhasil',
						count: data?.success ?? 0,
						color: '#2E86EF',
					},
					{
						name: 'Pending',
						count: data?.pending ?? 0,
						detail: {
							draft: data?.pending_detail?.draft ?? 0,
							signing: data?.pending_detail?.signing ?? 0,
							stamp: data?.pending_detail?.stamp ?? 0,
						},
						color: '#EDF0F5',
					},
					{
						name: 'Gagal',
						count: data?.failed ?? 0,
						detail: {
							signing: data?.failed_detail?.signing ?? 0,
							stamp: data?.failed_detail?.stamp ?? 0,
						},
						color: '#FAAD14',
					},
					{
						name: 'Dibatalkan',
						count: data?.canceled ?? 0,
						color: '#EC1F27',
					},
				],
				tag: 'digital-signing',
				completenessRate: data?.completeness_rate ?? 0,
			});
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const { isFetching: isStampedDocumentCountLoading } = useQuery({
		queryKey: ['getStampedDocumentCount', filter.dates],
		queryFn: () =>
			getStampedDocumentCount({
				start_at: dayjs(filter.dates[0]).format('YYYY-MM-DD'),
				end_at: dayjs(filter.dates[1]).format('YYYY-MM-DD'),
			}),
		refetchOnWindowFocus: true,
		enabled: isQueryEnabled,
		onSuccess: (response) => {
			const data = response?.data ?? null;

			setStampedDocumentCount({
				title: 'E-materai',
				data: [
					{
						name: 'Berhasil',
						count: data?.success ?? 0,
						color: '#2E86EF',
					},
					{
						name: 'Pending',
						count: data?.pending ?? 0,
						color: '#EDF0F5',
					},
					{
						name: 'Gagal',
						count: data?.failed ?? 0,
						color: '#FAAD14',
					},
				],
			});
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const { isFetching: isSignedDocumentCountLoading } = useQuery({
		queryKey: ['getSignedDocumentCount', filter.dates],
		queryFn: () =>
			getSignedDocumentCount({
				start_at: dayjs(filter.dates[0]).format('YYYY-MM-DD'),
				end_at: dayjs(filter.dates[1]).format('YYYY-MM-DD'),
			}),
		refetchOnWindowFocus: true,
		enabled: isQueryEnabled,
		onSuccess: (response) => {
			const data = response?.data ?? null;

			setSignedDocumentCount({
				title: 'Tanda tangan',
				data: [
					{
						name: 'Berhasil',
						count: data?.success ?? 0,
						color: '#2E86EF',
					},
					{
						name: 'Pending',
						count: data?.pending ?? 0,
						color: '#EDF0F5',
					},
					{
						name: 'Gagal',
						count: data?.failed ?? 0,
						color: '#FAAD14',
					},
				],
				tag: 'digital-signing',
				completenessRate: data?.completeness_rate ?? 0,
			});
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const { refetch: getQuota, isFetching: isQuotaLoading } = useQuery({
		queryKey: ['getQuota'],
		queryFn: () => getAnalyticQuota('ekyc', organisationId),
		refetchOnWindowFocus: true,
		enabled: isQueryEnabled,
		onSuccess: (response) => {
			const data = response?.data ?? null;
			const sanitizedResponse = camelize(data) as ServiceAvailableQuota;
			const emeteraiQuota = sanitizedResponse?.details?.find(
				(quota) => quota.serviceTypeTag === 'e-meterai'
			);

			if (emeteraiQuota) {
				setEmeteraiQuota({
					count: emeteraiQuota.remainingQuota,
					lastUpdatedAt: emeteraiQuota.lastUpdatedAt,
				});
			}
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: 'Gagal mengambil quota data. Silahkan coba lagi.',
			});
		},
	});

	const { isFetching: isDocumentLoading, refetch: fetchDocuments } = useQuery({
		queryKey: [
			'getDocuments',
			pageSize,
			currentPage,
			search,
			sort,
			filter.dates,
		],
		queryFn: () =>
			getDocuments({
				start_at: dayjs(filter.dates[0]).format('YYYY-MM-DD'),
				end_at: dayjs(filter.dates[1]).format('YYYY-MM-DD'),
				contract_name_or_customer_id: search,
				status: filter.status.join(),
				limit: pageSize,
				page: currentPage,
				sort_by: `${sort.by}:${sort.order}`,
			}),
		refetchOnWindowFocus: filter.status.length > 0,
		enabled: isQueryEnabled,
		onSuccess: (response) => {
			const data = response?.data?.items ?? [];
			const total = response?.data?.pagination?.totalItems ?? 0;
			const sanitizedResponse = camelize(data) as DocumentHistory[];

			setDocuments(
				sanitizedResponse.map((item) => {
					return {
						...item,
						key: item.refContractId,
					};
				})
			);
			setTotalCount(total);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const { refetch: download, isFetching: isDownloadLoading } = useQuery({
		queryKey: ['downloadDocumentActivity'],
		enabled: false,
		queryFn: () =>
			downloadDocumentActivity({
				start_at: dayjs(filter.dates[0]).format('YYYY-MM-DD'),
				end_at: dayjs(filter.dates[1]).format('YYYY-MM-DD'),
			}),
		onSuccess: (response) => {
			const filename = `activity-report_${dayjs(filter.dates[0]).format(
				'DD-MM-YYYY'
			)}_${dayjs(filter.dates[1]).format('DD-MM-YYYY')}.xlsx`;

			downloadFile(response, filename);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const { isLoading: isSignerListLoading } = useQuery({
		queryKey: [
			'getSignerList',
			searchSigner,
			filter.dates,
			signerPagination.pageSize,
			signerPagination.currentPage,
			sortSignerList.sort,
		],
		queryFn: () =>
			getSignerList({
				contract_created_at_gte: dayjs(filter.dates[0]).format('YYYY-MM-DD'),
				contract_created_at_lte: dayjs(filter.dates[1]).format('YYYY-MM-DD'),
				limit: signerPagination.pageSize,
				page: signerPagination.currentPage,
				sort_by: `${sortSignerList.sort.by}:${sortSignerList.sort.order}`,
				user_id: searchSigner,
			}),
		refetchOnWindowFocus: true,
		enabled: isQueryEnabled,
		onSuccess: (response) => {
			const data = response?.data?.items ?? [];
			const total = response?.data?.pagination?.totalItems ?? 0;
			const sanitizedResponse = camelize(data) as SignerList[];

			setSignerList(
				sanitizedResponse.map((item) => {
					return {
						...item,
						key: item.userId,
					};
				})
			);
			signerPagination.setTotalCount(total);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const { isLoading: isStampListLoading, refetch: refetchStampList } = useQuery(
		{
			queryKey: [
				'getStampList',
				filter.dates,
				stampPagination.pageSize,
				stampPagination.currentPage,
				searchEmaterai,
			],
			queryFn: () =>
				getStampList({
					stamp_status: filter.stampStatus.join(),
					contract_created_at_gte: dayjs(filter.dates[0]).format('YYYY-MM-DD'),
					contract_created_at_lte: dayjs(filter.dates[1]).format('YYYY-MM-DD'),
					limit: stampPagination.pageSize,
					page: stampPagination.currentPage,
					stamp_id_or_contract_name: searchEmaterai,
				}),
			refetchOnWindowFocus: filter.stampStatus.length > 0,
			enabled: isQueryEnabled,
			onSuccess: (response) => {
				const data = response?.data?.items ?? [];
				const total = response?.data?.pagination?.totalItems ?? 0;
				const sanitizedResponse = camelize(data) as EmateraiList[];

				setStampList(
					sanitizedResponse.map((item) => {
						return {
							...item,
							key: item.stampId,
						};
					})
				);
				stampPagination.setTotalCount(total);
			},
			onError: () => {
				ToastMessage({
					type: 'error',
					label: GENERAL_ERROR_MESSAGE,
				});
			},
		}
	);

	const onDownload = () => {
		ToastMessage({
			type: 'info',
			label: 'Dokumen sedang diunduh.',
		});
		download();
	};

	const onSort = (sort: DocumentSort) => {
		setSort(sort);
	};

	const onSortSigner = (sort: SignerSort) => {
		sortSignerList.setSort(sort);
	};

	const onSetFilter = (filter: Filter) => {
		setFilter(filter);
		onChangeCurrentPage(1);
	};

	const onApplyFilter = () => {
		fetchDocuments();
	};

	const onSetDateType = (dateType: DateOptions) => {
		setDateType(dateType);
	};

	const onSearch = (value: string) => {
		setSearch(value);
		setCurrentPage(1);
	};

	const onSearchSigner = (value: string) => {
		setSearchSigner(value);
		signerPagination.setCurrentPage(1);
	};

	const onSearchEmaterai = (value: string) => {
		setSearchEmaterai(value);
		stampPagination.setCurrentPage(1);
	};

	const onChangeStatus = (status: DocumentStatus[]) => {
		onSetFilter({
			...filter,
			status,
		});
	};

	const onChangeStampStatus = (status: EmateraiStatus[]) => {
		onSetFilter({
			...filter,
			stampStatus: status,
		});
	};

	const onApplyFilterStamp = () => {
		refetchStampList();
	};

	const onChangeTab = (key: string) => setCurrentTab(key);

	const openSignersModal = (id: string) => {
		const document = documents.find(
			(document) => document.contractId === id
		) as DocumentHistory;

		toggleSharedModal({
			name: 'signerList',
			payload: {
				isOpen: true,
				data: {
					documentId: document.contractId,
				},
			},
		});
	};

	const isOverviewWidgetsLoading =
		isDocumentCountLoading ||
		isStampedDocumentCountLoading ||
		isSignedDocumentCountLoading ||
		isAverageCompletenessTimeLoading;
	const isDocumentListLoading = isOverviewWidgetsLoading || isDocumentLoading;

	const totalDocument = documentCount?.data.reduce((previous, current) => {
		return previous + current.count;
	}, 0);

	const isShowDocumentList =
		isDocumentListLoading || (!isDocumentListLoading && documents.length > 0);

	const isShowSignerList =
		isSignerListLoading || (!isSignerListLoading && signerList.length > 0);

	const isShowStampList =
		isStampListLoading || (!isStampListLoading && stampList.length > 0);

	const filterRef = useRef<HTMLDivElement>(null);
	const isIntersecting = useIntersection(filterRef);

	useEffect(() => {
		const pageQuery = parseInt(
			convertToNumericOnly(searchParams.get('page') as string) || '1'
		);
		let sizeQuery = parseInt(
			convertToNumericOnly(searchParams.get('size') as string) || '10'
		);
		sizeQuery = PAGINATION_SIZE.includes(sizeQuery) ? sizeQuery : 10;

		const searchQuery = searchParams.get('search') ?? '';
		const searchEmateraiQuery = searchParams.get('ematerai') ?? '';
		const searchSignerQuery = searchParams.get('signer') ?? '';
		const statusQuery = searchParams.get('status') ?? '';
		const stampStatusQuery = searchParams.get('stamp') ?? '';
		const startDate = searchParams.get('start');
		const endDate = searchParams.get('end');
		const currentTabQuery = searchParams.get('tab') ?? '';
		const isStartDateValid = dayjs(startDate).isValid();
		const isEndDateValid = dayjs(endDate).isValid();
		const start = isStartDateValid ? startDate : dayjs().startOf('month');
		const end = isEndDateValid ? endDate : dayjs().format('YYYY-MM-DD');
		const statusQueryArr = statusQuery.split(',') as typeof ALL_DOCUMENT_STATUS;
		const stampStatusQueryArr = stampStatusQuery.split(
			','
		) as typeof ALL_EMATERAI_STATUS;
		const status = statusQuery
			? statusQueryArr.filter((status) => ALL_DOCUMENT_STATUS.includes(status))
			: ALL_DOCUMENT_STATUS;
		const stampStatus = stampStatusQuery
			? stampStatusQueryArr.filter((status) =>
					ALL_EMATERAI_STATUS.includes(status)
			  )
			: ALL_EMATERAI_STATUS;

		onChangeCurrentPage(parseInt(pageQuery.toString()));
		onChangePageSize(parseInt(sizeQuery.toString()));
		setSearch(searchQuery);
		setSearchEmaterai(searchEmateraiQuery);
		setSearchSigner(searchSignerQuery);
		setFilter({
			...filter,
			dates: [dayjs(start), dayjs(end)],
			status: status.length ? status : ALL_DOCUMENT_STATUS,
			stampStatus: stampStatus.length ? stampStatus : ALL_EMATERAI_STATUS,
		});
		setCurrentTab(currentTabQuery);
		setIsQueryEnabled(true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useDidMount(() => {
		const params = {
			page: currentPage,
			size: pageSize,
			search,
			signer: searchSigner,
			ematerai: searchEmaterai,
			start: dayjs(filter.dates[0]).format('YYYY-MM-DD'),
			end: dayjs(filter.dates[1]).format('YYYY-MM-DD'),
			status: filter.status.join() || ALL_DOCUMENT_STATUS,
			stamp: filter.stampStatus.join() || ALL_EMATERAI_STATUS,
			tab: currentTab,
		};

		const sanitizedParams = generateQueryString(params);
		setSearchParams(sanitizedParams, { replace: true });
	}, [
		search,
		currentPage,
		pageSize,
		filter,
		searchSigner,
		currentTab,
		searchEmaterai,
	]);

	return (
		<div className="py-8">
			<div ref={filterRef}>
				<SectionHeader containerClass="mb-6" title="Dashboard KYC" />
			</div>
			<div
				className={
					!isIntersecting
						? 'sticky top-0 bg-neutral-0 z-[19] py-4 shadow-base right-0 -mx-8 px-8'
						: ''
				}
			>
				<DashboardFilter
					isDownloadDisabled={totalDocument === 0}
					isDownloadLoading={isDownloadLoading}
					filter={filter}
					dateType={dateType}
					onSetFilter={onSetFilter}
					onSetDateType={onSetDateType}
					onDownload={onDownload}
				/>
			</div>
			<div className="flex mt-6">
				<div className="w-full">
					{isDocumentCountLoading && <WidgetSkeleton />}
					{!isDocumentCountLoading && (
						<Widget
							title={documentCount?.title}
							data={documentCount?.data}
							description="Akumulasi total dari semua dokumen yang ditandatangan"
						/>
					)}
				</div>
				<div className="w-full ml-2 flex">
					<div className="w-full">
						{isSignedDocumentCountLoading && <WidgetSkeleton isVertical />}
						{!isSignedDocumentCountLoading && (
							<Widget
								title={signedDocumentCount?.title}
								data={signedDocumentCount?.data}
								description="Akumulasi total tanda tangan di semua dokumen"
								isVertical
							/>
						)}
					</div>
					<div className="w-full ml-2">
						{isStampedDocumentCountLoading && <WidgetSkeleton isVertical />}
						{!isStampedDocumentCountLoading && (
							<Widget
								title={stampedDocumentCount?.title}
								data={stampedDocumentCount?.data}
								description="Akumulasi total dari semua dokumen yang ditandatangan"
								isVertical
							/>
						)}
					</div>
				</div>
			</div>
			<div className="flex justify-between mt-2">
				<div className="w-[calc((100%-24px)/4)]">
					{isDocumentCountLoading && <RecapSkeleton />}
					{!isDocumentCountLoading && (
						<RecapWidget
							iconClassColor="text-green-400"
							content={
								<Paragraph weight="bold" size="r">
									Tingkat ketuntasan dokumen
								</Paragraph>
							}
							icon={<DocumentIcon />}
							value={`${String(documentCount?.completenessRate)}%`}
						/>
					)}
				</div>
				<div className="w-[calc((100%-24px)/4)]">
					{isAverageCompletenessTimeLoading && <RecapSkeleton />}
					{!isAverageCompletenessTimeLoading && (
						<RecapWidget
							iconClassColor="text-yellow-600"
							content={
								<Paragraph weight="bold" size="r">
									Rata-rata waktu tuntas
								</Paragraph>
							}
							icon={<HourglassIcon />}
							value={`${averageCompletenessTime} Hari`}
						/>
					)}
				</div>
				<div className="w-[calc((100%-24px)/4)]">
					{isSignedDocumentCountLoading && <RecapSkeleton />}
					{!isSignedDocumentCountLoading && (
						<RecapWidget
							iconClassColor="text-purple-300"
							content={
								<Paragraph weight="bold" size="r">
									Tingkat ketuntasan tanda tangan
								</Paragraph>
							}
							icon={<SignIcon />}
							value={`${String(signedDocumentCount?.completenessRate)}%`}
						/>
					)}
				</div>
				<div className="w-[calc((100%-24px)/4)]">
					{isQuotaLoading && <RecapSkeleton />}
					{!isQuotaLoading && (
						<RecapWidget
							iconClassColor="text-green-400"
							content={
								<EmeteraiWidgetRecap
									getQuota={getQuota}
									dateTime={emeteraiQuota.lastUpdatedAt}
								/>
							}
							icon={<StampIcon />}
							value={convertToThousandsSeparator(
								emeteraiQuota.count.toString()
							)}
						/>
					)}
				</div>
			</div>
			<div className="mt-8">
				<div className="w-full p-6 flex flex-col bg-neutral-0 rounded-lg shadow-base">
					<Paragraph weight="bold" size="l" className="mb-0.5">
						Aktivitas
					</Paragraph>
					<Paragraph size="r">
						Data yang ditampilkan sesuai dengan filter tanggal dokumen yang
						diunggah:
						<span className="font-bold ml-1">
							{`${dayjs(filter.dates[0]).format('DD MMMM YYYY')} -
								${dayjs(filter.dates[1]).format('DD MMMM YYYY')}`}
						</span>
					</Paragraph>
					<Tabs
						className="mt-2"
						size="md"
						tabBarGutter={24}
						defaultActiveKey={currentTab}
						onChange={onChangeTab}
						items={[
							{
								children: (
									<div className="mt-2">
										<DocumentFilter
											searchValue={search}
											onSearch={onSearch}
											status={filter.status}
											onChangeStatus={onChangeStatus}
											onApplyFilter={onApplyFilter}
										/>
										{isShowDocumentList ? (
											<DashboardTable
												data={documents}
												isLoading={isDocumentListLoading}
												limit={pageSize}
												page={currentPage}
												totalCount={totalCount}
												onChangeCurrentPage={onChangeCurrentPage}
												onChangePageSize={onChangePageSize}
												onSort={onSort}
												onOpenSignersModal={openSignersModal}
											/>
										) : (
											<div className="mt-4">
												<EmptyDocument
													title="Belum ada data yang dapat ditampilkan"
													description="Data Anda akan muncul di sini"
												/>
											</div>
										)}
									</div>
								),
								key: 'document',
								label: 'Dokumen',
							},
							{
								children: (
									<div className="mt-2">
										<div className="flex justify-between">
											<SignerFilter
												onSearch={onSearchSigner}
												value={searchSigner}
											/>
											<IfFeatureEnabled feature="gmra-signer-activity-log">
												<Button
													variant="secondary"
													onClick={() =>
														navigate(
															'/dashboard/know-your-customer/signer/activity-log'
														)
													}
												>
													Detail Aktivitas
												</Button>
											</IfFeatureEnabled>
										</div>
										{isShowSignerList ? (
											<div className="mt-4">
												<SignerTable
													filterDates={filter.dates}
													data={signerList}
													isLoading={isSignerListLoading}
													limit={signerPagination.pageSize}
													page={signerPagination.currentPage}
													totalCount={signerPagination.totalCount}
													onChangeCurrentPage={
														signerPagination.onChangeCurrentPage
													}
													onChangePageSize={signerPagination.onChangePageSize}
													onSort={onSortSigner}
												/>
											</div>
										) : (
											<div className="mt-4">
												<EmptyDocument
													title="Belum ada data yang dapat ditampilkan"
													description="Data Anda akan muncul di sini"
												/>
											</div>
										)}
									</div>
								),
								key: 'signer',
								label: 'Penandatangan',
							},
							{
								children: (
									<div className="mt-2">
										<EmateraiFilter
											onSearch={onSearchEmaterai}
											status={filter.stampStatus}
											onChangeStatus={onChangeStampStatus}
											value={searchEmaterai}
											onApplyFilter={onApplyFilterStamp}
										/>
										{isShowStampList ? (
											<div className="mt-4">
												<EmateraiTable
													data={stampList}
													isLoading={isStampListLoading}
													limit={stampPagination.pageSize}
													page={stampPagination.currentPage}
													totalCount={stampPagination.totalCount}
													onChangeCurrentPage={
														stampPagination.onChangeCurrentPage
													}
													onChangePageSize={stampPagination.onChangePageSize}
												/>
											</div>
										) : (
											<div className="mt-4">
												<EmptyDocument
													title="Belum ada data yang dapat ditampilkan"
													description="Data Anda akan muncul di sini"
												/>
											</div>
										)}
									</div>
								),
								key: 'ematerai',
								label: 'E-materai',
							},
						]}
					/>
				</div>
			</div>
		</div>
	);
};

export default KycDashboard;
