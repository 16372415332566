import { Typography } from '@squantumengine/horizon';
import { Col, Row } from 'antd';
import EmptyData from 'common/components/empty-data';
import type { InvoiceSettingProps } from './invoice-setting.interfaces';

const { Paragraph } = Typography;

const InvoiceSetting = ({ settings }: InvoiceSettingProps) => {
	return (
		<div className="mt-8">
			<Paragraph weight="bold" size="l" className="mb-6">
				Pengaturan Komponen Tagihan
			</Paragraph>
			<Row className="border-b border-stroke-secondary">
				<Col span={5} className="px-4 py-3 text-body-r-semibold">
					Nama
				</Col>
				<Col span={5} className="px-4 py-3 text-body-r-semibold">
					Tipe
				</Col>
				<Col span={5} className="px-4 py-3 text-body-r-semibold">
					Jumlah
				</Col>
				<Col span={5} className="px-4 py-3 text-body-r-semibold">
					Perhitungan
				</Col>
				<Col span={4} className="px-4 py-3 text-body-r-semibold">
					Waktu berlaku
				</Col>
			</Row>
			{settings.length ? (
				settings.map((setting) => (
					<Row className="border-b border-stroke-secondary" key={setting.id}>
						<Col
							span={5}
							className="p-4 text-body-r-regular overflow-hidden text-ellipsis"
							title={setting.name}
						>
							{setting.name}
						</Col>
						<Col
							span={5}
							className="p-4 text-body-r-regular overflow-hidden text-ellipsis"
						>
							{setting.method}
						</Col>
						<Col
							span={5}
							className="p-4 text-body-r-regular overflow-hidden text-ellipsis"
							title={setting.realValue}
						>
							{setting.realValue}
						</Col>
						<Col
							span={5}
							className="p-4 text-body-r-regular overflow-hidden text-ellipsis"
						>
							{setting.type}
						</Col>
						<Col
							span={4}
							className="p-4 text-body-r-regular overflow-hidden text-ellipsis"
							title={setting.period}
						>
							{setting.period}
						</Col>
					</Row>
				))
			) : (
				<div className="py-8 bg-neutral-50">
					<EmptyData text="Belum ada Komponen Tagihan yang ditampilkan." />
				</div>
			)}
		</div>
	);
};

export default InvoiceSetting;
