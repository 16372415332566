import { Divider } from 'antd';
import SpSkeleton from 'common/components/sp-skeleton';

const Skeleton = () => {
	return (
		<div className="flex flex-col">
			<SpSkeleton className="h-6 w-[200px]"></SpSkeleton>
			<Divider className="my-3"></Divider>
			<div className="flex mb-2">
				<div className="w-[200px]">
					<SpSkeleton className="h-5 w-[160px]"></SpSkeleton>
				</div>
				<SpSkeleton className="h-5 w-[200px]"></SpSkeleton>
			</div>
			<div className="flex mb-2">
				<div className="w-[200px]">
					<SpSkeleton className="h-5 w-[120px]"></SpSkeleton>
				</div>
				<SpSkeleton className="h-5 w-[120px]"></SpSkeleton>
			</div>
			<div className="flex mb-2">
				<div className="w-[200px]">
					<SpSkeleton className="h-5 w-[140px]"></SpSkeleton>
				</div>
				<SpSkeleton className="h-5 w-[80px]"></SpSkeleton>
			</div>
			<div className="flex mb-2">
				<div className="w-[200px]">
					<SpSkeleton className="h-5 w-[100px]"></SpSkeleton>
				</div>
				<SpSkeleton className="h-5 w-[140px]"></SpSkeleton>
			</div>
			<SpSkeleton className="h-6 w-[200px] mt-8"></SpSkeleton>
			<Divider className="my-3"></Divider>
			<SpSkeleton className="h-[160px] w-full"></SpSkeleton>
			<SpSkeleton className="h-6 w-[200px] mt-8"></SpSkeleton>
			<Divider className="my-3"></Divider>
			<SpSkeleton className="h-[120px] w-full"></SpSkeleton>
			<SpSkeleton className="h-6 w-[200px] mt-8"></SpSkeleton>
			<Divider className="my-3"></Divider>
			<SpSkeleton className="h-[140px] w-full"></SpSkeleton>
		</div>
	);
};

export default Skeleton;
