import InfoPanel from 'common/components/info-panel';

const PageNotFound = () => {
	return (
		<div className="flex flex-col bg-background h-full">
			<div className="w-full items-center justify-center h-full flex">
				<InfoPanel
					description="Kami tidak menemukan halaman yang Anda cari. Silakan kembali ke beranda atau hubungi admin."
					imgAlt="Page not found"
					imgSource="/assets/illustrations/404.svg"
					title="Halaman tidak ditemukan"
				></InfoPanel>
			</div>
		</div>
	);
};

export default PageNotFound;
