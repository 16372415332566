import { useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import type { SortBy } from 'common/types';
import dayjs from 'dayjs';
import FieldColumn from 'features/dashboard/know-your-customer/field-column';
import style from 'features/dashboard/know-your-customer/table/index.module.scss';
import SpPagination from 'common/components/sp-pagination';
import SpTable from 'common/components/sp-table';
import type { SignerList, SignerSortKeys } from './signer-table.interfaces';
import type { SignerTableProps } from '../table.interfaces';

const columns: ColumnsType<SignerList> = [
	{
		title: 'Nama',
		dataIndex: 'userName',
		key: 'userName',
		width: 200,
		className: 'min-w-[200px]',
		render: (_, { userName }) => (
			<div className="text-blue-500">
				<FieldColumn title={userName} />
			</div>
		),
	},
	{
		title: 'User ID',
		dataIndex: 'refUserId',
		key: 'refUserId',
		width: 200,
		className: 'min-w-[200px]',
		render: (_, { refUserId }) => <FieldColumn title={refUserId} />,
	},
	{
		title: 'Email',
		dataIndex: 'userEmail',
		key: 'userEmail',
		width: 200,
		className: 'min-w-[200px]',
		render: (_, { userEmail }) => <FieldColumn title={userEmail} />,
	},
	{
		title: 'Aktivitas Terakhir',
		dataIndex: 'lastActivity',
		key: 'last_activity',
		width: 200,
		className: 'min-w-[200px]',
		sorter: true,
		render: (_, { lastActivity }) => (
			<FieldColumn
				title={dayjs(lastActivity).format('DD MMMM YYYY')}
				description={dayjs(lastActivity).format('HH:mm:ss')}
			/>
		),
	},
	{
		title: 'Daftar Dokumen',
		dataIndex: 'contractCount',
		key: 'contract_count',
		width: 200,
		sorter: true,
		className: 'min-w-[200px]',
		render: (_, { contractCount }) => (
			<FieldColumn title={`${contractCount} dokumen`} />
		),
	},
];

const SignerTable = ({
	data,
	isLoading,
	limit,
	page,
	totalCount,
	filterDates,
	onChangePageSize,
	onChangeCurrentPage,
	onSort,
}: SignerTableProps) => {
	const navigate = useNavigate();
	const onTableChange = (sorter: SorterResult<SignerList>) => {
		const by =
			sorter.order && sorter.columnKey
				? (sorter.columnKey as SignerSortKeys)
				: ('last_activity' as SignerSortKeys);
		let order = 'desc' as SortBy;

		if (sorter.order) order = sorter.order === 'ascend' ? 'asc' : order;

		onSort({
			by,
			order,
		});
	};

	const startDate = filterDates[0].format('YYYY-MM-DD');
	const endDate = filterDates[1].format('YYYY-MM-DD');
	return (
		<>
			<SpTable
				dataSource={data}
				columns={columns}
				className={style.table}
				pagination={false}
				isLoading={isLoading}
				bordered
				defaultTable
				scroll={{
					x: 900,
				}}
				rowClassName="cursor-pointer"
				onChange={
					/* istanbul ignore next */
					(_pagination, _filters, sorter) =>
						onTableChange(sorter as SorterResult<SignerList>)
				}
				onRow={({ userId, refUserId }) => {
					return {
						onClick: () =>
							navigate(
								`/dashboard/know-your-customer/signer/${userId}?start=${startDate}&end=${endDate}&refUserId=${refUserId}`
							),
					};
				}}
			/>
			{data.length && !isLoading ? (
				<div className="px-6">
					<SpPagination
						currentPage={page}
						pageSize={limit}
						totalCount={totalCount}
						onChangePageSize={onChangePageSize}
						onChangeCurrentPage={onChangeCurrentPage}
					/>
				</div>
			) : null}
		</>
	);
};

export default SignerTable;
