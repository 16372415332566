import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16.375 15H17.0938C17.2812 15 17.5 14.8125 17.5 14.625V7.40625C17.5 7.21875 17.2812 7 17.0938 7H16.375C16.1875 7 16 7.21875 16 7.40625V14.625C16 14.8125 16.1875 15 16.375 15ZM10.375 15H11.0938C11.2812 15 11.5 14.8125 11.5 14.625V8.40625C11.5 8.21875 11.2812 8 11.0938 8H10.375C10.1875 8 10 8.21875 10 8.40625V14.625C10 14.8125 10.1875 15 10.375 15ZM13.375 15H14.0938C14.2812 15 14.5 14.8125 14.5 14.625V10.4062C14.5 10.2188 14.2812 10 14.0938 10H13.375C13.1875 10 13 10.2188 13 10.4062V14.625C13 14.8125 13.1875 15 13.375 15ZM19.5 16.5H5.5V6.5C5.5 6.25 5.25 6 5 6H4.5C4.21875 6 4 6.25 4 6.5V17C4 17.5625 4.4375 18 5 18H19.5C19.75 18 20 17.7812 20 17.5V17C20 16.75 19.75 16.5 19.5 16.5ZM7.375 15H8.09375C8.28125 15 8.5 14.8125 8.5 14.625V12.4062C8.5 12.2188 8.28125 12 8.09375 12H7.375C7.1875 12 7 12.2188 7 12.4062V14.625C7 14.8125 7.1875 15 7.375 15Z"
			fill="currentColor"
		/>
	</svg>
);

const ChartIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default ChartIcon;
