import { Button, SearchBar } from '@squantumengine/horizon';
import { Checkbox } from 'antd';
import { ALL_EMATERAI_STATUS } from 'features/dashboard/know-your-customer/kyc-dashboard.constants';
import type { EmateraiStatus } from 'features/dashboard/know-your-customer/kyc-dashboard.interfaces';
import { SortIcon } from 'common/components/icons';
import SpSelect from 'common/components/sp-select';
import { STAMP_STATUS_OPTIONS } from './ematerai-filter.constants';
import type { EmateraiFilterProps } from './ematerai-filter.interfaces';
import style from './index.module.scss';

const EmateraiFilter = ({
	status,
	onChangeStatus,
	onSearch,
	onApplyFilter,
	value,
}: EmateraiFilterProps) => {
	const isSelectAll = status.length === ALL_EMATERAI_STATUS.length;
	const statusValue =
		status.length === ALL_EMATERAI_STATUS.length || status.length === 0
			? 'Semua status'
			: `Status (${status.length})`;

	const onSelectStatus = (value: EmateraiStatus) => {
		const updatedStatus = status.includes(value)
			? status.filter((option) => option !== value)
			: [...status, value];

		onChangeStatus(updatedStatus);
	};

	const onSelectAll = () => {
		const updatedStatus =
			status.length === ALL_EMATERAI_STATUS.length ? [] : ALL_EMATERAI_STATUS;

		onChangeStatus(updatedStatus);
	};

	const statusFilterContent = () => (
		<ul
			className={`${style['ematerai-filter']} rounded w-[200px] py-1`}
			data-testid="emateraiStatusFilter"
		>
			<li className="flex px-4 h-7 items-center">
				<Checkbox
					checked={isSelectAll}
					aria-label="Select all status"
					indeterminate={!isSelectAll && status.length > 0}
					onChange={onSelectAll}
				>
					Semua status
				</Checkbox>
			</li>
			{STAMP_STATUS_OPTIONS.map((option) => (
				<li className="flex px-4 h-7 items-center" key={option.value}>
					<Checkbox
						checked={status.includes(option.value)}
						onChange={() => onSelectStatus(option.value)}
					>
						{option.title}
					</Checkbox>
				</li>
			))}
			<li className="py-3 px-4">
				<Button
					size="sm"
					full
					onClick={onApplyFilter}
					disabled={status.length === 0}
				>
					Terapkan
				</Button>
			</li>
		</ul>
	);

	return (
		<div className="flex items-center">
			<SearchBar
				className="w-[300px] mr-4"
				size="compact"
				placeholder="Cari ematerai id atau nama dokumen"
				aria-label="Search ematerai"
				debounceDuration={300}
				onChange={onSearch}
				value={value}
			/>
			<SpSelect
				value={statusValue}
				size="lg"
				placement="bottomLeft"
				prefix={<SortIcon />}
				placeholder="Status ematerai"
				content={statusFilterContent}
				popoverClassName="z-[18]"
			/>
		</div>
	);
};

export default EmateraiFilter;
