import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.56032 11.4688L10.5028 9.52626C10.7953 9.23376 10.7953 8.76126 10.5028 8.46876L8.56032 6.52626C8.08782 6.06126 7.27782 6.39126 7.27782 7.05876V10.9363C7.27782 11.6113 8.08782 11.9413 8.56032 11.4688Z"
			fill="#2E3742"
		/>
	</svg>
);

const ArrowRightIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default ArrowRightIcon;
