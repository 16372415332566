import _camelCase from 'lodash/camelCase';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import _snakeCase from 'lodash/snakeCase';
import _transform from 'lodash/transform';

export const camelize = (obj: Record<string, unknown>): unknown => {
	return _transform(
		obj,
		(acc: Record<string, unknown>, value, key: string, target) => {
			const camelKey = _isArray(target) ? key : _camelCase(key);

			acc[camelKey] = _isObject(value)
				? camelize(value as Record<string, unknown>)
				: value;
		}
	);
};
export const snakify = (obj: Record<string, unknown>): unknown => {
	return _transform(
		obj,
		(acc: Record<string, unknown>, value, key: string, target) => {
			const snakeKey = _isArray(target) ? key : _snakeCase(key);

			acc[snakeKey] = _isObject(value)
				? snakify(value as Record<string, unknown>)
				: value;
		}
	);
};
export const capitalizeEachWord = (str: string) => {
	return str
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ');
};
export const convertToNpwpFormat = (value: string) => {
	if (!value) {
		return value;
	}
	const onlyNums = value.replace(/[^\d]/g, '');
	if (onlyNums.length <= 2) {
		return onlyNums;
	}
	if (onlyNums.length <= 5) {
		return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2)}`;
	}
	if (onlyNums.length <= 8) {
		return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
			5
		)}`;
	}
	if (onlyNums.length <= 10) {
		return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
			5,
			8
		)}-${onlyNums.slice(8)}`;
	}
	if (onlyNums.length <= 12) {
		return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
			5,
			8
		)}-${onlyNums.slice(8, 10)}.${onlyNums.slice(10)}`;
	}
	return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
		5,
		8
	)}.${onlyNums.slice(8, 9)}-${onlyNums.slice(9, 12)}.${onlyNums.slice(
		12,
		16
	)}`;
};
export const convertToNumericOnly = (value: string) => {
	return value?.replace(/\D/g, '');
};
export const convertToDecimalPrice = (value: string) => {
	if (!value) return value;
	if (value && value === '.') return '';

	const numberOfPeriods = (value.match(/\./g) ?? []).length;

	if (numberOfPeriods === 2) return value.slice(0, -1);

	const numericValue = value
		.replace(/[^\d.]/g, '')
		.replace(/(\.\d{2})\d*/g, '$1');

	const numericParts = numericValue.split('.');
	const intValue = parseInt(numericParts[0]);

	if (isNaN(intValue)) return '';

	const integerPart = intValue.toLocaleString('en-US');

	if (numericParts.length === 2) {
		return `${integerPart}.${numericParts[1]}`;
	}

	return integerPart;
};
export const convertToDecimalPayload = (value: string) => {
	if (!value) return value;

	const numericParts = value.split('.');

	if (numericParts.length === 2) {
		return parseFloat(
			`${convertToNumericOnly(numericParts[0])}.${numericParts[1]}`
		);
	}

	return parseFloat(convertToNumericOnly(value));
};
export const convertToThousandsSeparator = (value: string) => {
	if (!value) return value;

	let stringValue = value;
	let decimal = '';

	const lastDotIndex = stringValue.lastIndexOf('.');

	if (lastDotIndex !== -1) {
		stringValue = value.substring(0, lastDotIndex);
		decimal = value.substring(lastDotIndex + 1);
	}

	const numericValue = stringValue.replace(/[^\d]/g, '');
	const intValue = parseInt(numericValue);

	if (isNaN(intValue)) return '';

	const thousandValue = intValue.toLocaleString('en-US');

	return decimal ? `${thousandValue}.${decimal}` : thousandValue;
};
export const convertToRpPrice = (price: number) => {
	if (!price) return 'Rp0';

	return `Rp${convertToDecimalPrice(price.toString())}`;
};
