import { Fragment, Suspense, lazy, useMemo, useState } from 'react';
import type { UIEvent, MouseEvent } from 'react';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button as HzButton, Icon, Typography } from '@squantumengine/horizon';
import {
	Col,
	Button,
	Input,
	Form,
	Divider,
	Row,
	Modal,
	Switch,
	Tooltip,
} from 'antd';
import classNames from 'classnames';
import type { ServicePricesList } from 'features/price/edit-form/edit-form.interfaces';
import {
	CALCULATION_TYPES,
	CALCULATION_TYPES_KEY,
} from 'features/price/price.constants';
import type {
	CalculationType,
	PatchUpsertPriceApiPricesPayload,
	PatchUpsertPriceApiPayload,
} from 'features/price/price.interfaces';
import Banner from 'common/components/banner';
import EmptyData from 'common/components/empty-data';
import {
	AddIcon,
	EditNewIcon,
	CloseNewIcon,
	TrashIcon,
} from 'common/components/icons';
import SpSelect from 'common/components/sp-select';
import {
	convertToNumericOnly,
	convertToDecimalPrice,
	convertToThousandsSeparator,
	convertToDecimalPayload,
} from 'common/utils/transformer';
import useModal from 'common/hooks/use-modal';
import { REQUIRED_FIELD_MESSAGE } from 'common/constants/errors';
import { REQUIRED_RULES } from 'common/constants/rules';
import style from './index.module.scss';
import { TYPE_OPTIONS } from './list.constants';
import type { ListProps } from './list.interfaces';
import PrefixCurrency from './prefix-currency';
import UnitTypeSkeleton from './unit-type-skeleton';

const ValidityPeriodModal = lazy(
	() =>
		import(
			/* webpackChunkName: "validityPeriodModal" */ 'features/price/edit-form/validity-period-modal'
		)
);

const { Paragraph } = Typography;

const List = ({
	isUpdatingNextPeriod,
	isUnitTypeLoading,
	isUnitTypeLoadMore,
	isUpdatePriceLoading,
	organisationId,
	priceList,
	unitTypes,
	unitTypeHasNext,
	goToPreviousPage,
	loadMoreUnitTypes,
	onUpdatePrice,
	onOpenUpsertUnitTypeModal,
	onOpenDeleteUnitTypeModal,
	onOpenEditUnitTypeModal,
}: ListProps) => {
	const [modal, contextHolder] = Modal.useModal();
	const validityPeriodModal = useModal();
	const [form] = Form.useForm();
	const formPriceList = Form.useWatch('priceList', form);

	const [isDisabled, setIsDisabled] = useState(false);
	const [hoverIndex, setHoverIndex] = useState(-1);

	const usedUnitTypeDictionary = useMemo(() => {
		if (!formPriceList) return {};

		const dict = {} as { [key: string]: string };

		const currentPriceList = formPriceList as ServicePricesList[];

		currentPriceList.forEach((list) =>
			list.prices.forEach((prices) => {
				if (!dict[prices.unitTypeId]) {
					dict[prices.unitTypeId] = prices.unitType;
				}
			})
		);

		return dict;
	}, [formPriceList]);

	const addSubVolume = (fieldKey: number) => {
		const currentPriceList = form.getFieldValue(
			'priceList'
		) as ServicePricesList[];

		const currentServicePrices = currentPriceList?.[fieldKey]?.prices;

		const lastPriceSet =
			currentServicePrices?.[currentServicePrices.length - 1];
		const newQty = parseInt(convertToNumericOnly(lastPriceSet.minQty)) + 1;
		const newPrice = {
			minQty: convertToThousandsSeparator(newQty.toString()),
			unitPrice: '0',
			unitType: lastPriceSet.unitType,
			unitTypeId: lastPriceSet.unitTypeId,
			isFree: false,
		};

		currentPriceList[fieldKey].prices.push(newPrice);

		form.setFieldsValue({ priceList: currentPriceList });
		setTimeout(() => {
			form.validateFields();
		});
	};

	const customUnitDropdown = (
		closePopover: () => void,
		serviceKey: number,
		priceKey: number
	) => {
		const currentUnitTypeId = form.getFieldValue([
			'priceList',
			serviceKey,
			'prices',
			priceKey,
			'unitTypeId',
		]);

		return (
			<div className="flex py-1 flex-col w-[300px]">
				<div className="px-4 py-2">
					<Banner description="Anda hanya dapat mengedit / menghapus unit yang sudah tidak aktif digunakan." />
				</div>
				{isUnitTypeLoading && !isUnitTypeLoadMore ? (
					<UnitTypeSkeleton count={4} />
				) : (
					<ul
						className="max-h-[136px] overflow-y-scroll"
						onScroll={onScrollUnitType}
						aria-label="Unit type list"
					>
						{unitTypes.length ? (
							<>
								{unitTypes.map((option, index) => {
									const liClassName = classNames(
										'flex items-center px-4 cursor-pointer text-body-r-regular justify-between min-h-[36px]',
										{
											'bg-blue-50':
												index === hoverIndex || option.id === currentUnitTypeId,
										}
									);

									return (
										<li
											key={option.id}
											className={liClassName}
											onMouseEnter={() => setHoverIndex(index)}
											onMouseLeave={() => setHoverIndex(-1)}
											onClick={() =>
												onUnitTypeChange({
													closePopover,
													serviceKey,
													unitTypeId: option.id,
													unitType: option.description,
												})
											}
											aria-label={option.description}
										>
											<Paragraph
												title={option.description}
												className="overflow-hidden text-ellipsis whitespace-nowrap text-primary"
												size="r"
												weight="regular"
											>
												{option.description}
											</Paragraph>
											{index === hoverIndex &&
											!option.isUsed &&
											!usedUnitTypeDictionary[option.id] ? (
												<div className="ml-4 flex items-center text-neutral-400">
													<div
														onClick={(event) =>
															onOpenEditUnitType(event, closePopover, {
																name: option.description,
																id: option.id,
															})
														}
														aria-label="Edit unit type"
														className="text-[20px] h-5 flex items-center justify-center"
													>
														<EditNewIcon />
													</div>
													<div className="ml-4">
														<div
															onClick={(event) =>
																onOpenDeleteUnitType(
																	event,
																	closePopover,
																	option.id
																)
															}
															aria-label="Delete unit type"
															className="h-5"
														>
															<CloseNewIcon />
														</div>
													</div>
												</div>
											) : null}
										</li>
									);
								})}
								{isUnitTypeLoadMore ? <UnitTypeSkeleton count={1} /> : null}
							</>
						) : (
							<EmptyData />
						)}
					</ul>
				)}
				<Divider className="mt-2 mb-0" />
				<div className="flex items-center justify-center h-10">
					<Button
						type="link"
						className="flex"
						icon={<AddIcon />}
						onClick={() => onAddUnitType(closePopover)}
					>
						Tambah satuan
					</Button>
				</div>
			</div>
		);
	};

	const deleteSubVolume = (fieldKey: number, index: number) => {
		const formValue = form.getFieldsValue();
		const currentPriceList = formValue.priceList;

		currentPriceList[fieldKey].prices.splice(index, 1);

		form.setFieldsValue({ priceList: currentPriceList });
	};

	const onDeleteSubVolume = (fieldKey: number, index: number) => {
		modal.confirm({
			title: 'Hapus data ini?',
			cancelText: 'Kembali',
			okText: 'Ya, hapus',
			className: 'confirm-modal',
			centered: true,
			zIndex: 1041,
			onOk: () => {
				deleteSubVolume(fieldKey, index);
			},
		});
	};

	const onScrollUnitType = (event: UIEvent<HTMLElement>) => {
		if (!unitTypeHasNext || isUnitTypeLoadMore) return;

		const element = event.currentTarget;

		const isScrolledToBottom =
			element.scrollTop + element.clientHeight > element.scrollHeight - 10;

		if (isScrolledToBottom) loadMoreUnitTypes();
	};

	const onAddUnitType = (closePopover: () => void) => {
		onOpenUpsertUnitTypeModal();
		closePopover();
	};

	const onOpenEditUnitType = (
		event: MouseEvent<HTMLSpanElement>,
		closePopover: () => void,
		payload: { name: string; id: string }
	) => {
		event.stopPropagation();
		closePopover();
		onOpenEditUnitTypeModal(payload);
	};

	const onOpenDeleteUnitType = (
		event: MouseEvent<HTMLSpanElement>,
		closePopover: () => void,
		id: string
	) => {
		event.stopPropagation();
		closePopover();
		onOpenDeleteUnitTypeModal(id);
	};

	const onFormChange = () => {
		const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);

		setIsDisabled(hasErrors);
	};

	const onCancel = () => {
		modal.confirm({
			title:
				'Perubahan yang Anda buat tidak akan tersimpan. Yakin ingin batalkan?',
			cancelText: 'Kembali',
			okText: 'Ya, batalkan',
			className: 'confirm-modal',
			centered: true,
			zIndex: 1041,
			onOk: goToPreviousPage,
		});
	};

	const onUnitTypeChange = ({
		closePopover,
		serviceKey,
		unitType,
		unitTypeId,
	}: {
		closePopover: () => void;
		serviceKey: number;
		unitTypeId: string;
		unitType: string;
	}) => {
		const currentPriceList = form.getFieldValue(
			'priceList'
		) as ServicePricesList[];
		const updatedPriceList = currentPriceList.map((service, index) => {
			if (index !== serviceKey) return service;

			return {
				...service,
				unitTypeId,
				prices: service.prices.map((prices) => {
					return {
						...prices,
						unitType,
						unitTypeId,
					};
				}),
			};
		});

		form.setFieldsValue({ priceList: updatedPriceList });
		onFormChange();
		closePopover();
	};

	const onUpdateNonTaxableGoods = (checked: boolean, serviceKey: number) => {
		const currentPriceList = form.getFieldValue(
			'priceList'
		) as ServicePricesList[];
		const updatedPriceList = currentPriceList.map((service, index) => {
			if (index !== serviceKey) return service;

			return {
				...service,
				nonTaxableGoods: checked,
			};
		});

		form.setFieldsValue({ priceList: updatedPriceList });
		onFormChange();
	};

	const onValidityPeriodChange = (value: string) => {
		form.setFieldsValue({ validityPeriod: value });
	};

	const onTypeChange = (type: CalculationType, serviceKey: number) => {
		const currentPriceList = form.getFieldValue(
			'priceList'
		) as ServicePricesList[];
		const previousCalculationType = formPriceList?.[serviceKey].calculationType;
		let unitType = currentPriceList?.[serviceKey]?.prices?.[0]?.unitType ?? '';
		let unitTypeId = currentPriceList?.[serviceKey]?.unitTypeId ?? '';
		const priceId = currentPriceList?.[serviceKey]?.prices?.[0]?.id ?? '';
		const isFree = type === CALCULATION_TYPES_KEY.FREE;
		const isTypeChangesFromFree =
			previousCalculationType !== type &&
			previousCalculationType === CALCULATION_TYPES_KEY.FREE;

		if (isTypeChangesFromFree) {
			unitType = '';
			unitTypeId = '';
		}

		if (isFree) {
			unitType = CALCULATION_TYPES.FREE;
			unitTypeId = '';
		}

		const updatedPriceList = currentPriceList.map((service, index) => {
			if (index !== serviceKey) return service;

			return {
				...service,
				calculationType: type,
				unitTypeId,
				prices: [
					{
						freeText: CALCULATION_TYPES.FREE,
						isFree,
						minQty: '1',
						unitPrice: '0',
						unitType,
						unitTypeId,
						id: priceId,
					},
				],
			};
		});

		form.setFieldsValue({ priceList: updatedPriceList });
		form.validateFields();
	};

	const onOpenValidityPeriodModal = () => {
		validityPeriodModal.openModal();
	};

	const onSubmit = () => {
		form
			.validateFields()
			.then(
				(values: { priceList: ServicePricesList[]; periodConfig: string }) => {
					const newPrices = values.priceList.map((value) => {
						return {
							service_type_id: value.serviceTypeId,
							service_info: value.serviceTypeInfo,
							calculation_type:
								value.calculationType === CALCULATION_TYPES_KEY.FREE
									? CALCULATION_TYPES_KEY.UNIT
									: value.calculationType,
							unit_type_id: value.unitTypeId,
							non_taxable_goods: value.nonTaxableGoods,
							prices: value.prices.map((price) => {
								const unitPrice = convertToDecimalPayload(price.unitPrice);

								const pricePayload = {
									min_qty: parseInt(convertToNumericOnly(price.minQty)),
									unit_price: unitPrice,
									is_free: price.isFree,
								} as PatchUpsertPriceApiPricesPayload;

								if (isUpdatingNextPeriod && price.id) {
									pricePayload.id = price.id;
								}

								return pricePayload;
							}),
						};
					});

					const payload = {
						new_prices: newPrices,
						period_config: values.periodConfig,
					} as PatchUpsertPriceApiPayload;

					if (organisationId) payload.organisation_id = organisationId;

					onUpdatePrice(payload);
				}
			)
			.catch(() => {
				return;
			});
	};

	const onChangeVolume = () => {
		const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);

		hasErrors && form.validateFields();
	};

	const validateVolume = (
		serviceKey: number,
		priceKey: number,
		value: string
	) => {
		if (!value) return Promise.reject(new Error(REQUIRED_FIELD_MESSAGE));

		const currentServicePrices = form.getFieldValue([
			'priceList',
			serviceKey,
			'prices',
		]);

		let isSameVolume = false;

		for (const [index, price] of currentServicePrices.entries()) {
			isSameVolume = price.minQty === value && index !== priceKey;

			if (isSameVolume) break;
		}

		if (isSameVolume) return Promise.reject(new Error('Volume harus unik'));

		return Promise.resolve();
	};

	const validatePrice = (serviceKey: number, value: string) => {
		if (!value) return Promise.reject(new Error(REQUIRED_FIELD_MESSAGE));

		const currentServiceType = form.getFieldValue([
			'priceList',
			serviceKey,
			'calculationType',
		]);

		if (currentServiceType !== CALCULATION_TYPES_KEY.FREE && value === '0') {
			return Promise.reject(new Error(REQUIRED_FIELD_MESSAGE));
		}

		return Promise.resolve();
	};

	return (
		<>
			<div className="p-4 bg-neutral-0 rounded-lg">
				<Row gutter={16} className="mb-4">
					<Col span={5} className="h-[44px] flex items-center">
						<Paragraph size="r" weight="semibold">
							Produk / Service
						</Paragraph>
						<Tooltip
							className="ml-2"
							title="Judul produk/service yang Anda ubah di halaman ini akan terlihat di invoice."
						>
							<InfoCircleOutlined />
						</Tooltip>
					</Col>
					<Col span={3} className="h-[44px] flex items-center">
						<Paragraph size="r" weight="semibold">
							Tipe perhitungan
						</Paragraph>
					</Col>
					<Col span={16}>
						<Row gutter={16}>
							<Col span={4} className="h-[44px] flex items-center">
								<Paragraph size="r" weight="semibold">
									Volume
								</Paragraph>
							</Col>
							<Col span={6} className="h-[44px] flex items-center">
								<Paragraph size="r" weight="semibold">
									Harga satuan
								</Paragraph>
							</Col>
							<Col span={8} className="h-[44px] flex items-center">
								<Paragraph size="r" weight="semibold">
									Satuan
								</Paragraph>
							</Col>
							<Col span={6} className="h-[44px] flex items-center">
								<Paragraph size="r" weight="semibold">
									Tidak kena pajak
								</Paragraph>
							</Col>
						</Row>
					</Col>
				</Row>
				<Form
					form={form}
					initialValues={{ priceList }}
					autoComplete="off"
					onFieldsChange={onFormChange}
				>
					<Form.List name="priceList">
						{(services) =>
							services.map((service, serviceIndex) => {
								const isTierType =
									form.getFieldValue([
										'priceList',
										service.key,
										'calculationType',
									]) === CALCULATION_TYPES_KEY.TIER;

								return (
									<Fragment key={`service-${service.key}`}>
										<Row gutter={16} className={style.list}>
											<Col span={5}>
												<Form.Item
													name={[service.key, 'serviceTypeInfo']}
													className="mb-0"
													rules={REQUIRED_RULES}
												>
													<Input
														maxLength={100}
														className="text-primary text-body-r-regular h-[48px] rounded-lg"
													/>
												</Form.Item>
											</Col>
											<Col span={3}>
												<Form.Item
													name={[service.key, 'calculationType']}
													className="mb-0"
												>
													<SpSelect
														options={TYPE_OPTIONS}
														className="w-full h-[48px] text-body-r-regular"
														contentClassName="w-[160px]"
														placement="bottomRight"
														suffix={
															<Icon
																name="chevron-large-down"
																color="currentColor"
															/>
														}
														onChange={(value: CalculationType) =>
															onTypeChange(value, service.key)
														}
														value={form.getFieldValue([
															'priceList',
															service.key,
															'calculationType',
														])}
													/>
												</Form.Item>
											</Col>
											<Col span={16}>
												<Form.List name={[service.key, 'prices']}>
													{(prices) =>
														prices.map((price, priceIndex) => {
															const rowClass = classNames({
																'mb-2':
																	form.getFieldValue([
																		'priceList',
																		service.key,
																		'calculationType',
																	]) === CALCULATION_TYPES_KEY.TIER,
															});
															const isVolumeDisabled =
																priceIndex === 0 &&
																form.getFieldValue([
																	'priceList',
																	service.key,
																	'calculationType',
																]) !== CALCULATION_TYPES_KEY.MULTIPLIER;
															const isFree = form.getFieldValue([
																'priceList',
																service.key,
																'prices',
																price.key,
																'isFree',
															]);
															const unitTypeErrorMessages = form.getFieldError([
																'priceList',
																service.key,
																'prices',
																price.key,
																'unitType',
															]);

															return (
																<Row
																	key={`price-${service.key}-${price.key}`}
																	justify="start"
																	className={rowClass}
																	gutter={16}
																	align="top"
																>
																	<Col span={4}>
																		<Form.Item
																			rules={[
																				{
																					validator: (_, value) =>
																						validateVolume(
																							service.key,
																							price.key,
																							value
																						),
																				},
																			]}
																			className="mb-0"
																			name={[price.key, 'minQty']}
																			normalize={convertToThousandsSeparator}
																		>
																			<Input
																				data-testid="volumeInput"
																				className="text-primary text-body-r-regular h-[48px] rounded-lg"
																				maxLength={11}
																				disabled={isVolumeDisabled}
																				onChange={onChangeVolume}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={6}>
																		<Form.Item
																			rules={[
																				{
																					validator: (_, value) =>
																						validatePrice(service.key, value),
																				},
																			]}
																			name={[price.key, 'unitPrice']}
																			normalize={convertToDecimalPrice}
																			className="mb-0"
																		>
																			<Input
																				placeholder="Harga satuan"
																				data-testid="priceInput"
																				className="text-primary text-body-r-regular h-[48px] rounded-lg"
																				maxLength={14}
																				disabled={isFree}
																				prefix={<PrefixCurrency />}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={8}>
																		<Form.Item
																			name={[price.key, 'unitType']}
																			className="mb-0"
																			rules={REQUIRED_RULES}
																		>
																			<SpSelect
																				className="w-full h-[48px]"
																				contentClassName="w-[200px]"
																				placement="bottomRight"
																				suffix={
																					<Icon
																						name="chevron-large-down"
																						color="currentColor"
																					/>
																				}
																				content={(closePopover) =>
																					customUnitDropdown(
																						closePopover,
																						service.key,
																						price.key
																					)
																				}
																				disabled={isFree}
																				error={Boolean(
																					unitTypeErrorMessages.length
																				)}
																				value={form.getFieldValue([
																					'priceList',
																					service.key,
																					'prices',
																					price.key,
																					'unitType',
																				])}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={5} className="mt-[12px]">
																		{priceIndex === 0 ? (
																			<Form.Item className="mb-0">
																				<Switch
																					className="bg-neutral-400"
																					checked={form.getFieldValue([
																						'priceList',
																						service.key,
																						'nonTaxableGoods',
																					])}
																					onChange={(checked) =>
																						onUpdateNonTaxableGoods(
																							checked,
																							service.key
																						)
																					}
																					aria-label="Non taxable goods"
																				/>
																			</Form.Item>
																		) : (
																			<span
																				className="cursor-pointer text-neutral-600 flex justify-end"
																				onClick={() =>
																					onDeleteSubVolume(
																						service.key,
																						priceIndex
																					)
																				}
																			>
																				<TrashIcon />
																			</span>
																		)}
																	</Col>
																</Row>
															);
														})
													}
												</Form.List>
											</Col>
											<Col offset={9}>
												{isTierType ? (
													<Paragraph
														className="text-blue-500 flex items-center cursor-pointer hover:text-blue-300"
														size="l"
														weight="regular"
														onClick={() => addSubVolume(service.key)}
													>
														<PlusOutlined className="text-body-s-semibold mr-1" />
														Tambah sub volume
													</Paragraph>
												) : null}
											</Col>
										</Row>
										{serviceIndex < services.length - 1 ? (
											<Divider className="my-4"></Divider>
										) : null}
									</Fragment>
								);
							})
						}
					</Form.List>
				</Form>
			</div>
			<div className="flex w-full justify-end mt-6">
				<span className="mr-4">
					<HzButton variant="secondary" size="sm" onClick={onCancel}>
						Batal
					</HzButton>
				</span>
				<HzButton
					disabled={isDisabled}
					loading={isUpdatePriceLoading}
					size="sm"
					onClick={onOpenValidityPeriodModal}
				>
					Selanjutnya
				</HzButton>
			</div>
			<Suspense>
				{validityPeriodModal.visible && (
					<ValidityPeriodModal
						closeModal={validityPeriodModal.closeModal}
						isVisible={validityPeriodModal.visible}
						onSubmit={onSubmit}
						onChange={onValidityPeriodChange}
						form={form}
					/>
				)}
			</Suspense>
			{contextHolder}
		</>
	);
};

export default List;
