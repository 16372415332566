import InfoPanel from 'common/components/info-panel';

const UnregisteredAccount = () => {
	return (
		<div className="flex flex-col bg-background h-full">
			<div className="w-full items-center justify-center h-full flex">
				<InfoPanel
					description="Silakan kontak admin agar Anda dapat mengakses SQE Portal."
					imgAlt="Unregistered account"
					imgSource="/assets/illustrations/unregistered-account.svg"
					title="Akun Anda belum terdaftar"
				></InfoPanel>
			</div>
		</div>
	);
};

export default UnregisteredAccount;
