import SpSkeleton from 'common/components/sp-skeleton';

const ServiceChipSkeleton = () => {
	const skeletonArray = new Array(4).fill(null).map((_, index) => index);

	return (
		<div className="flex gap-3 mt-6">
			{skeletonArray.map((index) => (
				<SpSkeleton className="w-[80px] h-[40px] rounded-lg" key={index} />
			))}
		</div>
	);
};

export default ServiceChipSkeleton;
