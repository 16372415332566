import { NOTIFICATION_STATUS } from 'layouts/dashboard/notification-center/notification-center.constants';
import SpChip from 'common/components/sp-chip';
import type { FilterProps } from './filter.interfaces';

const Filter = ({ status, onUpdateStatus }: FilterProps) => {
	return (
		<div className="flex py-3 px-4">
			<SpChip
				label="Semua"
				active={status === NOTIFICATION_STATUS.all}
				onClick={() => onUpdateStatus(NOTIFICATION_STATUS.all)}
			/>
			<SpChip
				active={status === NOTIFICATION_STATUS.unread}
				className="ml-2"
				label="Belum dibaca"
				onClick={() => onUpdateStatus(NOTIFICATION_STATUS.unread)}
			/>
		</div>
	);
};

export default Filter;
