import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16.6667 2.50004H15.8334V1.66671C15.8334 1.20647 15.4603 0.833374 15 0.833374C14.5398 0.833374 14.1667 1.20647 14.1667 1.66671V2.50004H5.83335V1.66671C5.83335 1.20647 5.46026 0.833374 5.00002 0.833374C4.53978 0.833374 4.16669 1.20647 4.16669 1.66671V2.50004H3.33335C2.41669 2.50004 1.66669 3.25004 1.66669 4.16671V17.5C1.66669 18.4167 2.41669 19.1667 3.33335 19.1667H16.6667C17.5834 19.1667 18.3334 18.4167 18.3334 17.5V4.16671C18.3334 3.25004 17.5834 2.50004 16.6667 2.50004ZM16.6667 17.5H3.33335V8.33337H16.6667V17.5ZM16.6667 6.66671H3.33335V4.16671H16.6667V6.66671Z"
			fill="currentColor"
		/>
	</svg>
);

const CalendarIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default CalendarIcon;
