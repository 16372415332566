import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16 8C16 10.21 14.21 12 12 12C9.79 12 8 10.21 8 8C8 5.79 9.79 4 12 4C14.21 4 16 5.79 16 8Z"
			fill="currentColor"
		/>
		<path
			d="M4 17C4 14.34 9.33 13 12 13C14.67 13 20 14.34 20 17V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V17Z"
			fill="currentColor"
		/>
	</svg>
);

const ProfileIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} aria-label="Profile icon" />
);

export default ProfileIcon;
