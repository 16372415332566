import { Typography } from '@squantumengine/horizon';
import type { CardProps } from './card.interfaces';

const { Paragraph } = Typography;

const Card = ({ children, className, title, value }: CardProps) => {
	return (
		<div className={`flex ${className}`}>
			<Paragraph
				className="w-[200px] text-secondary shrink-0"
				weight="regular"
				size="r"
			>
				{title}
			</Paragraph>
			{children ?? (
				<Paragraph
					className="text-ellipsis overflow-hidden whitespace-nowrap"
					weight="regular"
					size="r"
				>
					: {value}
				</Paragraph>
			)}
		</div>
	);
};

export default Card;
