import { Skeleton } from 'antd';
import type { SpSkeletonProps } from './sp-skeleton.interfaces';

const SpSkeleton = ({ className }: SpSkeletonProps) => {
	const skeletonClass = `${className} overflow-hidden`;

	return (
		<div className={skeletonClass}>
			<Skeleton.Button active shape="square" className="!h-full !w-full" />
		</div>
	);
};

export default SpSkeleton;
