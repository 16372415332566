import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13.1273 11.9993L19.7635 5.3706C19.9141 5.22003 19.9986 5.01581 19.9986 4.80288C19.9986 4.58994 19.9141 4.38573 19.7635 4.23516C19.6129 4.08459 19.4087 4 19.1958 4C18.9829 4 18.7787 4.08459 18.6282 4.23516L12 10.8719L5.37184 4.23516C5.2973 4.1606 5.20879 4.10146 5.11139 4.06112C5.01399 4.02077 4.9096 4 4.80417 4C4.69875 4 4.59435 4.02077 4.49695 4.06112C4.39955 4.10146 4.31105 4.1606 4.2365 4.23516C4.08595 4.38573 4.00136 4.58994 4.00136 4.80288C4.00136 5.01581 4.08595 5.22003 4.2365 5.3706L10.8727 11.9993L4.2365 18.628C4.16156 18.7024 4.10208 18.7908 4.06149 18.8882C4.0209 18.9857 4 19.0902 4 19.1958C4 19.3013 4.0209 19.4058 4.06149 19.5033C4.10208 19.6007 4.16156 19.6891 4.2365 19.7635C4.31083 19.8384 4.39926 19.8979 4.49669 19.9385C4.59412 19.9791 4.69862 20 4.80417 20C4.90972 20 5.01422 19.9791 5.11166 19.9385C5.20909 19.8979 5.29752 19.8384 5.37184 19.7635L12 13.1348L18.6282 19.7635C18.7025 19.8384 18.7909 19.8979 18.8883 19.9385C18.9858 19.9791 19.0903 20 19.1958 20C19.3014 20 19.4059 19.9791 19.5033 19.9385C19.6007 19.8979 19.6892 19.8384 19.7635 19.7635C19.8384 19.6891 19.8979 19.6007 19.9385 19.5033C19.9791 19.4058 20 19.3013 20 19.1958C20 19.0902 19.9791 18.9857 19.9385 18.8882C19.8979 18.7908 19.8384 18.7024 19.7635 18.628L13.1273 11.9993Z"
			fill="currentColor"
		/>
	</svg>
);

const CloseIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} aria-label="Close icon" />
);

export default CloseIcon;
