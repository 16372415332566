import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_13623_14332)">
			<path
				d="M13.3327 7.5V15.8333H6.66602V7.5H13.3327ZM12.3756 2.79289C12.188 2.60536 11.9337 2.5 11.6685 2.5H8.33023C8.06501 2.5 7.81066 2.60536 7.62312 2.79289L7.08268 3.33333H4.99935C4.53911 3.33333 4.16602 3.70643 4.16602 4.16667C4.16602 4.6269 4.53911 5 4.99935 5H14.9993C15.4596 5 15.8327 4.6269 15.8327 4.16667C15.8327 3.70643 15.4596 3.33333 14.9993 3.33333H12.916L12.3756 2.79289ZM14.9993 7.83333C14.9993 6.72876 14.1039 5.83333 12.9993 5.83333H6.99935C5.89478 5.83333 4.99935 6.72876 4.99935 7.83333V15.8333C4.99935 16.75 5.74935 17.5 6.66602 17.5H13.3327C14.2493 17.5 14.9993 16.75 14.9993 15.8333V7.83333Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_13623_14332">
				<path d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2Z" />
			</clipPath>
		</defs>
	</svg>
);

const TrashIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} aria-label="Trash icon" />
);

export default TrashIcon;
