import type { RandomStringGenerator } from 'common/types';
import { SHA256, enc } from 'crypto-js';
import secureRandom from 'secure-random';
import { local } from 'common/utils/storage';

export const generateRandomSecret = (
	params: RandomStringGenerator = {
		length: 64,
		useSpecialChar: true,
	}
) => {
	let charset =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	charset = params.useSpecialChar ? `${charset}-._~` : charset;
	let codeVerifier = '';

	const randomBytes = secureRandom(params.length, { type: 'Array' });

	for (let i = 0; i < params.length; i++) {
		const randomIndex = randomBytes[i] % charset.length;
		codeVerifier += charset.charAt(randomIndex);
	}

	return codeVerifier;
};
export const generateCodeChallenge = (codeVerifier: string) => {
	const hashedCodeVerifier = SHA256(codeVerifier);
	const codeChallenge = hashedCodeVerifier
		.toString(enc.Base64)
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.replace(/={1,5}$/, '');

	return codeChallenge;
};
export const goToLogin = () => {
	const codeVerifier = generateRandomSecret();
	const codeChallenge = encodeURIComponent(generateCodeChallenge(codeVerifier));
	const state = encodeURIComponent(
		generateRandomSecret({
			length: 43,
			useSpecialChar: false,
		})
	);
	const clientId = encodeURIComponent(
		/* istanbul ignore next */
		process.env.REACT_APP_SQE_ID_CLIENT_ID ?? ''
	);
	const redirectUri = encodeURIComponent(
		/* istanbul ignore next */
		process.env.REACT_APP_SQE_ID_REDIRECT_URI ?? ''
	);
	const sqeIdUrl = process.env.REACT_APP_SQE_ID_URL;

	local.setItem('oa_code_verifier', codeVerifier);
	local.setItem('oa_state', state);

	window.location.href = `${sqeIdUrl}?state=${state}&scope=openid+profile+email&response_type=code&code_challenge=${codeChallenge}&code_challenge_method=SHA256&client_id=${clientId}&redirect_uri=${redirectUri}&flow=login&type=1`;
};
export const goToLogout = () => {
	local.removeItem('access_token');
	local.removeItem('refresh_token');
	local.removeItem('oa_code_verifier');
	local.removeItem('oa_state');
	local.removeItem('draft_organisation');

	goToLogin();
};
