import { Typography } from '@squantumengine/horizon';
import classNames from 'classnames';
import type { SpTimelineProps } from './sp-timeline.interfaces';

const { Paragraph } = Typography;

const SpTimeline = ({ activeStep, timeline }: SpTimelineProps) => {
	return (
		<div className="flex flex-col">
			{timeline.map((item, index) => {
				const isCurrentStepActive = item.step === activeStep;
				const isAfterStep = item.step > activeStep;
				const isBeforeStep = item.step < activeStep;

				const firstCircleBorderClassName =
					"w-6 h-6 border-[4px] rounded-full relative border-transparent before:border-[4px] before:w-6 before:h-6 before:rounded-full before:absolute before:content-[''] before:top-1/2 before:left-1/2 before:translate-x-[-50%] before:translate-y-[-50%]";
				const generatedFirstCircleBorderClassName = classNames('', {
					'before:border-neutral-300': isAfterStep,
					'before:border-blue-500': isCurrentStepActive,
				});
				const generatedFirstCircleFillClassName = classNames(
					'flex items-center justify-center h-5 w-5 rounded-full',
					{
						'bg-neutral-300': isBeforeStep,
					}
				);
				const titleClassName = classNames('', {
					'text-primary': isCurrentStepActive,
					'text-disable': isAfterStep || isBeforeStep,
				});
				const stepClassName = classNames('mt-1', {
					'text-secondary': isCurrentStepActive,
					'text-disable': isAfterStep || isBeforeStep,
				});
				const lineClassName = classNames(
					'absolute h-8 w-[1px] border-l top-[calc(100%+4px)] border-neutral-300',
					{
						'border-dashed': isCurrentStepActive || isAfterStep,
						'border-solid': isBeforeStep,
					}
				);

				const firstCircleClassName =
					isCurrentStepActive || isAfterStep
						? `${generatedFirstCircleBorderClassName} ${firstCircleBorderClassName}`
						: generatedFirstCircleFillClassName;

				return (
					<div
						key={item.step}
						className="h-[60px] flex items-center mb-4 last:mb-0"
					>
						<div className="flex items-center justify-center w-9 h-9 rounded-full relative">
							<div className={firstCircleClassName}>
								{isBeforeStep ? (
									<div className="w-[10px] h-[10px] relative after:content-[''] after:absolute after:top-[40%] after:left-[50%] after:w-[5px] after:h-[10px] after:border after:border-neutral-0 after:border-t-0 after:border-r-2 after:border-b-2 after:border-l-0 after:rotate-45 after:translate-y-[-50%] after:translate-x-[-50%]"></div>
								) : null}
							</div>
							{index < timeline.length - 1 ? (
								<div className={lineClassName}></div>
							) : null}
						</div>
						<div className="flex flex-col justify-center ml-3">
							<Paragraph size="r" weight="semibold" className={titleClassName}>
								{item.title}
							</Paragraph>
							<Paragraph className={stepClassName} weight="regular">
								Progres {item.step}
							</Paragraph>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default SpTimeline;
