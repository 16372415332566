import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.7812 13.5C13.875 13.5 13.4688 14 12 14C10.5 14 10.0938 13.5 9.1875 13.5C6.875 13.5 5 15.4062 5 17.7188V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V17.7188C19 15.4062 17.0938 13.5 14.7812 13.5ZM17.5 18.5H6.5V17.7188C6.5 16.2188 7.6875 15 9.1875 15C9.65625 15 10.375 15.5 12 15.5C13.5938 15.5 14.3125 15 14.7812 15C16.2812 15 17.5 16.2188 17.5 17.7188V18.5ZM12 13C14.4688 13 16.5 11 16.5 8.5C16.5 6.03125 14.4688 4 12 4C9.5 4 7.5 6.03125 7.5 8.5C7.5 11 9.5 13 12 13ZM12 5.5C13.625 5.5 15 6.875 15 8.5C15 10.1562 13.625 11.5 12 11.5C10.3438 11.5 9 10.1562 9 8.5C9 6.875 10.3438 5.5 12 5.5Z"
			fill="#1E252E"
		/>
	</svg>
);

const UserIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} />
);

export default UserIcon;
