import '@squantumengine/horizon/lib/index.css';
import './index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { datadogRum } from '@datadog/browser-rum';
import { ConfigProvider, notification } from 'antd';
import antdThemeConfig from './antd.theme';
import App from './app';
import { ModalProvider } from './common/context/shared-modal';
import reportWebVitals from './reportWebVitals';

const { version } = require('../package.json');

datadogRum.init({
	applicationId: process.env.REACT_APP_DD_APP_ID as string,
	clientToken: process.env.REACT_APP_DD_TOKEN as string,
	site: 'datadoghq.com',
	service: process.env.REACT_APP_DD_SERVICE,
	env: process.env.REACT_APP_ENV,
	version: version,
	sessionSampleRate: 100,
	sessionReplaySampleRate: 20,
	trackUserInteractions: true,
	trackResources: true,
	trackLongTasks: true,
	defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

notification.config({
	placement: 'top',
	duration: 3,
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
			retry: 0,
		},
	},
});

const basename = process.env.REACT_APP_BASENAME;

root.render(
	<QueryClientProvider client={queryClient}>
		<BrowserRouter basename={basename}>
			<ConfigProvider theme={antdThemeConfig}>
				<ModalProvider>
					<App />
				</ModalProvider>
			</ConfigProvider>
		</BrowserRouter>
	</QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
