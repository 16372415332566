import type {
	NotificationType,
	NotificationIcon,
} from './notification-center.interfaces';

export const MESSAGE_VARIANTS = {
	alert: 'ALERT',
	muted: 'MUTED',
	default: 'DEFAULT',
	link: 'LINK',
} as const;
export const MESSAGE_TAGS = {
	hover: 'HOVER',
} as const;
export const NOTIFICATION_STATUS = {
	all: '',
	unread: 'UNREAD',
	read: 'READ',
} as const;
export const NOTIFICATION_ACTION_ID = {
	openSignerList: 'OPEN_SIGNER_LIST',
	openContractDetail: 'OPEN_CONTRACT_DETAIL',
	downloadEvidenceAttachment: 'DOWNLOAD_EVIDENCE_ATTACHMENT',
	failedDownloadEvidence: 'FAILED_TO_DOWNLOAD_BULK_EVIDENCES',
} as const;
export const NOTIFICATION_CATEGORY = {
	general: 'GENERAL',
	attachment: 'ATTACHMENT',
} as const;
export const EVENT_TYPES = {
	today: 'Hari ini',
	yesterday: 'Kemarin',
	lastWeek: '7 hari terakhir',
	lastMonth: '1 bulan terakhir',
	older: 'Terdahulu',
} as { [key: string]: string };
export const NOTIFICATION_ICON = {
	exclamationCircle: 'exclamation-circle',
	paperPlane: 'paper-plane',
	fileAlt: 'file-alt',
	folderDownload: 'folder-download',
} as const;
export const NOTIFICATION_TYPE_MAP = {
	REMINDER_FAILED: NOTIFICATION_ICON.exclamationCircle,
	REMINDER_SUCCESS: NOTIFICATION_ICON.paperPlane,
	CONTRACT_FAILED: NOTIFICATION_ICON.exclamationCircle,
	CONTRACT_SUCCESS: NOTIFICATION_ICON.fileAlt,
	READY_TO_DOWNLOAD_BULK_EVIDENCES: NOTIFICATION_ICON.folderDownload,
	FAILED_TO_DOWNLOAD_BULK_EVIDENCES: NOTIFICATION_ICON.exclamationCircle,
} as { [key in NotificationType]: NotificationIcon };
