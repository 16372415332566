import { Typography, Icon } from '@squantumengine/horizon';
import { Popover } from 'antd';
import type { HeaderProps } from './header.interfaces';

const { Paragraph } = Typography;

const Header = ({
	notifCount,
	unreadCount,
	isError,
	isHeaderActionOpen,
	showDeleteAction = false,
	onReadNotifications,
	onToggleHeaderAction,
	onDeleteAction,
}: HeaderProps) => {
	const isShowUnread = !isError && unreadCount > 0;
	const isShowMarker = !isError && notifCount > 0;
	const unreadText = unreadCount > 99 ? '99+' : unreadCount.toString();

	return (
		<div className="flex p-4 h-[56px] justify-between">
			<div className="flex items-center">
				<Paragraph size="l" weight="bold">
					Notifikasi
				</Paragraph>
				{isShowUnread && (
					<Paragraph className="ml-1 text-secondary" size="l" weight="regular">
						({unreadText})
					</Paragraph>
				)}
			</div>
			{isShowMarker && (
				<Popover
					getPopupContainer={(triggerNode) =>
						triggerNode.parentElement as HTMLElement
					}
					content={
						<div className="w-[212px] py-1">
							<div className="py-1 px-4 hover:bg-blue-50 cursor-pointer">
								<Paragraph
									weight="regular"
									size="r"
									onClick={onReadNotifications}
								>
									Mark as Read
								</Paragraph>
							</div>
							{showDeleteAction && onDeleteAction && (
								<div className="py-1 px-4 hover:bg-blue-50 cursor-pointer">
									<Paragraph
										weight="regular"
										size="r"
										onClick={() => onDeleteAction(true)}
									>
										Delete Message
									</Paragraph>
								</div>
							)}
						</div>
					}
					open={isHeaderActionOpen}
					onOpenChange={onToggleHeaderAction}
					trigger="click"
					placement="bottomRight"
				>
					<div className="cursor-pointer" data-testid="headerAction">
						<Icon name="kebab-horizontal" />
					</div>
				</Popover>
			)}
		</div>
	);
};

export default Header;
