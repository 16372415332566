import SpSkeleton from 'common/components/sp-skeleton';

const Skeleton = () => {
	return (
		<div className="py-2 px-4 flex flex-col relative">
			<SpSkeleton className="h-5 w-[186px]" />
			<SpSkeleton className="mt-1 h-4 w-[300px]" />
			<SpSkeleton className="mt-1 h-4 w-[200px]" />
			<SpSkeleton className="mt-1 h-4 w-[100px]" />
		</div>
	);
};

export default Skeleton;
