import SpSkeleton from 'common/components/sp-skeleton';

const Skeleton = () => {
	const firstSkeletonArray = new Array(3).fill(null).map((_, index) => index);
	const secondSkeletonArray = new Array(4).fill(null).map((_, index) => index);

	return (
		<>
			<div className="flex flex-wrap gap-6 mt-6">
				{firstSkeletonArray.map((key) => (
					<div className="w-[calc((100%-48px)/3)]" key={key}>
						<div className="flex flex-col bg-neutral-0 shadow-base rounded-lg p-4">
							<SpSkeleton className="h-5 w-[100px]" />
							<SpSkeleton className="h-8 mt-2 w-[200px]" />
							<SpSkeleton className="h-[196px] mt-6 w-full" />
							<div className="flex justify-end mt-6">
								<SpSkeleton className="h-4 w-[100px]" />
							</div>
						</div>
					</div>
				))}
			</div>
			<SpSkeleton className="h-6 w-[100px] mt-8" />
			<div className="flex flex-wrap gap-6 mt-6">
				{secondSkeletonArray.map((key) => (
					<div className="w-[calc((100%-48px)/3)]" key={key}>
						<div className="flex flex-col bg-neutral-0 shadow-base rounded-lg p-4">
							<SpSkeleton className="h-5 w-[100px]" />
							<SpSkeleton className="h-8 mt-2 w-[200px]" />
							<SpSkeleton className="h-[196px] mt-6 w-full" />
							<div className="flex justify-end mt-6">
								<SpSkeleton className="h-4 w-[100px]" />
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default Skeleton;
