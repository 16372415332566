import { Fragment } from 'react';
import { Divider } from 'antd';
import FieldRows from 'features/invoice-preview/field-rows';
import RowTitle from 'features/invoice-preview/row-title';
import { convertToThousandsSeparator } from 'common/utils/transformer';
import { IGNORE_SERVICES_QTY } from './product.constants';
import type { ProductProps } from './product.interfaces';

const Product = ({ services, gross }: ProductProps) => {
	return (
		<div className="rounded-lg border border-stroke-secondary flex flex-col bg-neutral-0 overflow-hidden">
			<FieldRows
				rowClassName="py-3 px-4"
				textClassName="text-primary text-body-r-semibold"
				values={['Product/Service', 'Unit Price IDR', 'Usage', 'Amount IDR']}
			/>
			<Divider className="m-0 border-stroke-secondary" />
			{services.map((service) => (
				<Fragment key={service.serviceId}>
					<RowTitle
						title={service.serviceName}
						useDivider
						rowClassName="p-4 bg-neutral-50"
					/>
					{service.serviceTypes.map((type, index) => {
						const price = type.isFree
							? 'Gratis'
							: `${convertToThousandsSeparator(type.unitPrice.toString())} ${
									type.unitType
							  }`;

						let usage;

						if (IGNORE_SERVICES_QTY.includes(type.name.toLowerCase())) {
							usage = type.unitTypeUsage;
						} else {
							const qtyCount = convertToThousandsSeparator(String(type.qty));
							usage = type.isFree
								? 'Gratis'
								: `${qtyCount} ${type.unitTypeUsage}`;
						}

						const total = type.isFree
							? '0'
							: convertToThousandsSeparator(type.totalPrice.toString());
						const name = type.nonTaxableGoods ? `${type.name} *` : type.name;

						return (
							<FieldRows
								key={`${service.serviceId}-${type.id}-${type.unitPrice}-${type.qty}`}
								rowClassName="p-4"
								textClassName="text-primary text-body-r-regular"
								values={[name, price, usage, total]}
								useDivider
							/>
						);
					})}
					<RowTitle
						title={`TOTAL ${service.serviceName}`}
						useDivider
						rowClassName="p-4"
						value={convertToThousandsSeparator(service.total.toString()) || '0'}
					/>
				</Fragment>
			))}
			<RowTitle
				title="TOTAL PRODUCT/SERVICE COST"
				rowClassName="p-4 bg-neutral-50"
				value={convertToThousandsSeparator(gross.toString()) || '0'}
			/>
		</div>
	);
};

export default Product;
