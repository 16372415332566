import { useNavigate } from 'react-router-dom';
import { Button } from '@squantumengine/horizon';
import { useBusinessUnitStore } from 'features/business-unit/business-unit.store';
import SectionHeader from 'common/components/section-header';
import OrganisationServiceTable from './organisation-service-table';

const InvoiceManagement = () => {
	const navigate = useNavigate();

	const setIsLoadingDetails = useBusinessUnitStore(
		(action) => action.setIsLoadingDetails
	);

	const goToAddBusinessUnit = () => {
		setIsLoadingDetails(true);
		navigate('/business-unit/add');
	};

	const goToGeneralPriceManagement = () => {
		navigate('/price/general');
	};

	return (
		<div className="py-8">
			<SectionHeader containerClass="mb-6" title="Invoice Management">
				<div className="flex">
					<Button
						variant="secondary"
						size="sm"
						onClick={goToGeneralPriceManagement}
					>
						Pengaturan Harga Standar
					</Button>
					<span className="ml-4">
						<Button size="sm" onClick={goToAddBusinessUnit}>
							Tambah Business Unit
						</Button>
					</span>
				</div>
			</SectionHeader>
			<div className="p-6 bg-white rounded-lg shadow-base">
				<OrganisationServiceTable />
			</div>
		</div>
	);
};

export default InvoiceManagement;
