import type {
	GetApprovalListParams,
	GetApprovalLogParams,
	GetRecipientsParams,
	PostReportPayload,
	PostConfirmPayload,
} from 'features/billing-approval/billing-approval.interfaces';
import axios from './axios-instance';

const baseUrl = '/v1/report';

export const addReport = async (payload: PostReportPayload) => {
	const response = await axios.post(baseUrl, {
		data: payload,
	});

	return response.data;
};
export const confirmReport = async (payload: PostConfirmPayload) => {
	const response = await axios.patch(`${baseUrl}/confirmation`, payload);

	return response.data;
};
export const deletePic = async ({
	ids,
	reportId,
}: {
	ids: string[];
	reportId: string;
}) => {
	const response = await axios.delete(`${baseUrl}/recipient`, {
		data: {
			ids,
			report_id: reportId,
		},
	});

	return response.data;
};
export const getApprovalList = async (params: GetApprovalListParams) => {
	const response = await axios.get(baseUrl, {
		params,
	});

	return response.data;
};
export const getDetail = async (id: string) => {
	const response = await axios.get(`${baseUrl}/${id}/state`);

	return response.data;
};
export const getLogs = async (params: GetApprovalLogParams) => {
	const response = await axios.get(`${baseUrl}/journey`, {
		params,
	});

	return response.data;
};
export const getJourneys = async (id: string) => {
	const response = await axios.get(`${baseUrl}/${id}/state/detail`);

	return response.data;
};
export const getRecipients = async (params: GetRecipientsParams) => {
	const response = await axios.get(`${baseUrl}/recipient`, {
		params,
	});

	return response.data;
};
