import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Paragraph } from '@squantumengine/horizon';
import { Modal, Switch, TimePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import SectionHeader from 'common/components/section-header';
import SpSkeleton from 'common/components/sp-skeleton';
import ToastMessage from 'common/utils/toast-message';
import { getAutoReminder, updateAutoReminder } from 'services/usage';
import style from './index.module.scss';
import { REMINDER_STATUS } from './send-reminder.constants';

export default function SendReminderSettings() {
	const navigate = useNavigate();
	const [isEdit, setIsEdit] = useState(false);
	const [time, setTime] = useState<Dayjs | null>();
	const [isAuto, setIsAuto] = useState(false);
	const [modal, contextHolder] = Modal.useModal();
	const queryClient = useQueryClient();

	const { isFetching, refetch } = useQuery({
		queryKey: 'getReminderSettings',
		queryFn: getAutoReminder,
		onSuccess: (response) => {
			setIsAuto(response?.data?.status === REMINDER_STATUS.ACTIVE);
			setTime(dayjs(response?.data?.reminder_time, 'HH:mm'));
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: 'Gagal mengambil data pengaturan pengiriman otomatis.',
			});
		},
	});

	const updateReminderMutation = useMutation(updateAutoReminder, {
		onSuccess: () => {
			setIsEdit(false);
			queryClient.invalidateQueries('getReminderSettings');

			ToastMessage({
				type: 'info',
				label: 'Pengingat otomatis berhasil diperbarui',
			});
		},
	});

	const onEdit = () => {
		setIsEdit(true);
	};

	const onCancelEdit = () => {
		setIsEdit(false);
		refetch();
	};

	const onSubmit = () => {
		updateReminderMutation.mutate({
			reminder_time: time?.format('HH:mm') as string,
			status: isAuto ? REMINDER_STATUS.ACTIVE : REMINDER_STATUS.INACTIVE,
		});
	};

	const handleSwitch = (checked: boolean) => {
		if (!checked) {
			modal.confirm({
				title: `Apakah anda yakin untuk menonaktifkan pengiriman otomatis?`,
				cancelText: 'Batalkan',
				okText: 'Ya',
				className: 'confirm-modal',
				centered: true,
				zIndex: 1041,
				onOk: () => {
					setIsAuto(false);
					updateReminderMutation.mutate({
						status: REMINDER_STATUS.INACTIVE,
						reminder_time: time?.format('HH:mm') as string,
					});
				},
			});
			return;
		}
		updateReminderMutation.mutate({
			status: REMINDER_STATUS.ACTIVE,
			reminder_time: time?.format('HH:mm') as string,
		});
		setIsAuto(checked);
	};

	return (
		<div className="py-8">
			<SectionHeader
				containerClass="mb-6"
				title="Pengaturan Pengiriman Otomatis"
				backHandler={() => navigate(-1)}
			/>
			<div className={`grid grid-cols-2 ${style['reminder-settings']}`}>
				<div className="col-span-1 p-6 rounded-lg bg-neutral-0 shadow-base">
					<Paragraph size="l" weight="bold" className="mb-1">
						Pilih kapan pengingat dikirim
					</Paragraph>
					<Paragraph className="text-secondary mb-6">
						Dikirim H+1 setelah penandatangan menerima weblink pertama.
					</Paragraph>
					{!isEdit ? (
						<div className="mb-6 border-b pb-5 flex justify-between items-center border-neutral-100">
							<div className="flex gap-2 items-center">
								<Icon name="bell-on" />
								{isFetching ? (
									<SpSkeleton className="w-20 h-6" />
								) : (
									<Paragraph>{`${dayjs(time).format('HH:mm')} WIB`}</Paragraph>
								)}
							</div>
							{isFetching ? (
								<SpSkeleton className="w-20 h-6" />
							) : (
								<Switch onChange={handleSwitch} checked={isAuto} />
							)}
						</div>
					) : (
						<div className="mb-6">
							<Paragraph weight="semibold" className="mb-1">
								Jam
							</Paragraph>
							<TimePicker
								onChange={(value) => setTime(value)}
								value={time}
								size="large"
								format={'HH:mm'}
								placeholder="Pilih waktu"
								showNow={false}
								className="w-full"
								getPopupContainer={(trigger) =>
									trigger.parentElement as HTMLElement
								}
								status={time ? undefined : 'error'}
								inputReadOnly
								allowClear
							/>
						</div>
					)}

					{isEdit ? (
						<div className="flex justify-end gap-4">
							<Button variant="secondary" onClick={onCancelEdit}>
								Batalkan
							</Button>
							<Button
								onClick={onSubmit}
								loading={updateReminderMutation.isLoading}
								disabled={!time}
							>
								Terapkan
							</Button>
						</div>
					) : (
						<div className="flex justify-end">
							<Button variant="secondary" onClick={onEdit}>
								Edit Pengingat
							</Button>
						</div>
					)}
				</div>
			</div>
			{contextHolder}
		</div>
	);
}
