export const downloadFile = (blob: Blob, name: string) => {
	const url = window.URL.createObjectURL(blob);
	const downloader = document.createElement('a');

	downloader.href = url;
	downloader.download = name;
	document.body.appendChild(downloader);
	downloader.click();
	window.URL.revokeObjectURL(url);
};
