import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const renderIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12 16.875C12.4688 16.875 12.875 16.5 12.875 16V14C12.875 13.5312 12.4688 13.125 12 13.125C11.5 13.125 11.125 13.5312 11.125 14V16C11.125 16.5 11.5 16.875 12 16.875ZM19 11.5C19 10.6875 18.3125 10 17.5 10H16.5V8.5625C16.5 6.0625 14.5 4.03125 12 4C9.5 4 7.5 6.03125 7.5 8.5V10H6.5C5.65625 10 5 10.6875 5 11.5V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V11.5ZM9 10V8.5C9 6.875 10.3438 5.5 12 5.5C13.625 5.5 15 6.875 15 8.5V10H9ZM17.5 11.5V18.5H6.5V11.5H17.5Z"
			fill="currentColor"
		/>
	</svg>
);

const LockIcon = (props: Partial<CustomIconComponentProps>) => (
	<Icon component={renderIcon} {...props} aria-label="Lock icon" />
);

export default LockIcon;
