import { Fragment } from 'react';
import { Typography } from '@squantumengine/horizon';
import { Divider } from 'antd';
import type { TooltipProps } from './tooltip.interfaces';

const { Paragraph } = Typography;

const Tooltip = ({ usages, total, totalText, unit }: TooltipProps) => {
	return (
		<div
			className="flex flex-col bg-neutral-0 rounded-lg overflow-hidden"
			data-testid="tooltip"
		>
			<div className="flex flex-col px-4 pt-4 pb-2">
				{usages.map((usage, index) => {
					return (
						<Fragment key={usage.date}>
							<div className="flex justify-between">
								<Paragraph
									size="r"
									weight="regular"
									className="text-secondary mr-4"
								>
									{usage.date}
								</Paragraph>
								<Paragraph size="r" weight="regular" className="text-primary">
									{usage.value} {unit}
								</Paragraph>
							</div>
							{index + 1 < usages.length ? <Divider className="my-2" /> : null}
						</Fragment>
					);
				})}
			</div>
			<div className="p-4 flex justify-between bg-blue-50">
				<Paragraph size="r" weight="regular" className="text-primary mr-4">
					{totalText}
				</Paragraph>
				<Paragraph size="r" weight="semibold" className="text-primary">
					{total} {unit}
				</Paragraph>
			</div>
		</div>
	);
};

export default Tooltip;
