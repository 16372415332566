import { Fragment } from 'react';
import { Divider } from 'antd';
import SpSkeleton from 'common/components/sp-skeleton';

const ListSkeleton = () => {
	const skeletonArray = new Array(4).fill(null).map((_, index) => index);

	return (
		<div className="w-full flex flex-col">
			<div className="mt-6 flex flex-col">
				{skeletonArray.map((index) => {
					return (
						<Fragment key={`service-skeleton-${index}`}>
							<div className="flex justify-between py-2 mt">
								<SpSkeleton className="w-[60px] h-6" />
								<SpSkeleton className="w-[60px] h-6" />
							</div>
							{index < 3 ? <Divider className="my-3"></Divider> : null}
						</Fragment>
					);
				})}
			</div>
		</div>
	);
};

export default ListSkeleton;
