import { useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { REDIRECT_TO_HOME_ROUTES } from 'common/constants/routes';

const Redirect = () => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const { pathname } = location;

		if (REDIRECT_TO_HOME_ROUTES.includes(pathname)) {
			navigate('/', { replace: true });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Outlet />;
};

export default Redirect;
