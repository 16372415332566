import { Fragment } from 'react';
import { Divider } from 'antd';
import SpSkeleton from 'common/components/sp-skeleton';

const Skeleton = () => {
	const skeletonArray = new Array(4).fill(null).map((_, index) => index);

	return (
		<div className="w-full bg-neutral-0 shadow-base rounded-lg p-6 flex flex-col">
			<SpSkeleton className="w-[245px] h-8 mb-8" />
			<div className="flex justify-between items-center mb-8">
				<SpSkeleton className="w-[200px] h-8" />
				<SpSkeleton className="w-[200px] h-8" />
			</div>

			{skeletonArray.map((index) => (
				<Fragment key={index}>
					<div className="flex gap-2 justify-evenly">
						{skeletonArray.map((index) => (
							<SpSkeleton key={index} className="w-full h-8 mb-2" />
						))}
					</div>
				</Fragment>
			))}

			<Divider className="my-6" />

			<div className="flex justify-between items-center mb-8">
				<SpSkeleton className="w-[200px] h-8" />
				<SpSkeleton className="w-[200px] h-8" />
			</div>

			{skeletonArray.map((index) => (
				<Fragment key={index}>
					<div className="flex gap-2 justify-evenly">
						{skeletonArray.map((index) => (
							<SpSkeleton key={index} className="w-full h-8 mb-2" />
						))}
					</div>
				</Fragment>
			))}
		</div>
	);
};

export default Skeleton;
