import { useEffect } from 'react';

const usePageVisibility = (callback: (isVisible: boolean) => void) => {
	useEffect(() => {
		const onVisibilityChange = () => {
			callback(document.visibilityState === 'visible');
		};

		document.addEventListener('visibilitychange', onVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', onVisibilityChange);
		};
	}, [callback]);
};

export default usePageVisibility;
