import { Fragment } from 'react';
import { Typography } from '@squantumengine/horizon';
import { Divider } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { ACTIVITY_LOG_SORT_KEYS } from 'features/activity-log/activity-log.constants';
import type {
	ActivityLogSortKey,
	Log,
} from 'features/activity-log/activity-log.interfaces';
import SpPagination from 'common/components/sp-pagination';
import SpTable from 'common/components/sp-table';
import type { SortBy } from 'common/types/global';
import style from './index.module.scss';
import type { TableProps } from './table.interfaces';
import Value from './value';

const { Paragraph } = Typography;

const ActivityLogTable = ({
	data: activityLogs,
	isLoading,
	limit,
	page,
	totalCount,
	onChangeCurrentPage,
	onChangePageSize,
	onSort,
}: TableProps) => {
	const onTableChange = (sorter: SorterResult<Log>) => {
		const by =
			sorter.order && sorter.columnKey
				? (sorter.columnKey as ActivityLogSortKey)
				: ('created_at' as ActivityLogSortKey);
		let order = 'desc' as SortBy;

		if (sorter.order) order = sorter.order === 'ascend' ? 'asc' : order;

		onSort({
			by,
			order,
		});
	};

	const valueTitle = () => {
		return (
			<div className="m-[-16px] flex flex-col h-full">
				<div className="flex items-center justify-center h-11">
					<Paragraph size="r" weight="semibold">
						Value
					</Paragraph>
				</div>
				<Divider className="m-0" />
				<div className="flex h-11">
					<div className="flex w-[300px] items-center justify-center">
						<Paragraph size="r" weight="semibold">
							Old
						</Paragraph>
					</div>
					<Divider className="m-0 h-full" type="vertical" />
					<div className="flex w-[300px] items-center justify-center">
						<Paragraph size="r" weight="semibold">
							New
						</Paragraph>
					</div>
				</div>
			</div>
		);
	};

	const activityLogsColumns: ColumnsType<Log> = [
		{
			title: 'Update terakhir',
			dataIndex: 'createdAt',
			fixed: 'left',
			key: ACTIVITY_LOG_SORT_KEYS.createdAt,
			sorter: true,
			render: (_, { updatedAt }) => (
				<>{dayjs(updatedAt).format('DD MMM YYYY - HH:mm:ss')}</>
			),
		},
		{
			title: 'Email pengguna',
			dataIndex: 'actorEmail',
			key: ACTIVITY_LOG_SORT_KEYS.actorEmail,
			fixed: 'left',
			sorter: true,
		},
		{
			title: 'Aktivitas',
			dataIndex: 'activityTypeDescription',
		},
		{
			title: 'Subject',
			dataIndex: 'activitySubjectDescription',
		},
		{
			title: 'Perubahan',
			dataIndex: 'changePath',
			render: (_, { changePath }) => (
				<div>
					{changePath.map((path, index) => (
						<Fragment key={path}>
							<span>{path}</span>
							{index + 1 < changePath.length ? ' > ' : null}
						</Fragment>
					))}
				</div>
			),
		},
		{
			colSpan: 2,
			className: 'w-[600px] min-w-[600px]',
			title: valueTitle,
			render: (_, { changeLogs, activityType }) => (
				<div className="flex flex-col m-[-16px]">
					{changeLogs?.map((log) => (
						<Value
							key={log.id}
							title={log.title}
							level={1}
							activityType={activityType}
							oldValue={log.oldValue}
							newValue={log.newValue}
							childs={log.childs}
						/>
					))}
				</div>
			),
			onCell: () => ({
				colSpan: 2,
			}),
		},
		{
			title: 'New',
			colSpan: 0,
			onCell: () => ({
				colSpan: 0,
			}),
		},
	];

	return (
		<>
			<SpTable
				columns={activityLogsColumns}
				dataSource={activityLogs}
				bordered
				defaultTable
				scroll={{
					x: 1600,
				}}
				className={style.table}
				isLoading={isLoading}
				pagination={false}
				onChange={(_pagination, _filters, sorter) =>
					onTableChange(sorter as SorterResult<Log>)
				}
			/>
			{activityLogs.length && !isLoading ? (
				<div className="px-6">
					<SpPagination
						currentPage={page}
						pageSize={limit}
						totalCount={totalCount}
						onChangePageSize={onChangePageSize}
						onChangeCurrentPage={onChangeCurrentPage}
					/>
				</div>
			) : null}
		</>
	);
};

export default ActivityLogTable;
