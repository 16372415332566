import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useBusinessUnitStore } from './business-unit.store';
import Form from './form';
import useBusinessUnitForm from './form/use-business-unit-form';

const EditBusinessUnit = () => {
	const { organisationId } = useParams();
	const navigate = useNavigate();
	const { getDetails, setFormStep } = useBusinessUnitForm();

	const setIsUseStepperGuard = useBusinessUnitStore(
		(action) => action.setIsUseStepperGuard
	);

	useEffect(() => {
		if (organisationId) {
			setFormStep(1);
			setIsUseStepperGuard(false);
			getDetails();
			return;
		}

		navigate('/404', { replace: true });
		// eslint-disable-next-line
	}, []);

	return <Form />;
};

export default EditBusinessUnit;
