import type { DocumentType } from 'features/invoice-management/invoice-management.interfaces';

export const FILE_TYPE: {
	text: string;
	value: DocumentType;
}[] = [
	{
		text: 'Excel (.xlsx)',
		value: 'XLSX',
	},
	{
		text: 'Document (.pdf)',
		value: 'PDF',
	},
	{
		text: 'Document (.docx)',
		value: 'DOCX',
	},
];
