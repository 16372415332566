import { lazy, Suspense, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import {
	CALCULATION_TYPES,
	CALCULATION_TYPES_KEY,
} from 'features/price/price.constants';
import type {
	GetPriceApiResponse,
	GetPriceApiPriceResponse,
	PatchUpsertPriceApiPayload,
} from 'features/price/price.interfaces';
import EmptyData from 'common/components/empty-data';
import SectionHeader from 'common/components/section-header';
import ToastMessage from 'common/utils/toast-message';
import {
	camelize,
	convertToDecimalPrice,
	convertToThousandsSeparator,
} from 'common/utils/transformer';
import useModal from 'common/hooks/use-modal';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { getPrices, updatePrices } from 'services/price';
import {
	getUnitTypes,
	deleteUnitType,
	updateUnitType,
} from 'services/unit-type';
import type {
	EditFormProps,
	GetUnitTypesApiResponse,
	UpdateUnitTypeApiPayload,
	ServicePricesList,
	SelectedUnitType,
	UnitTypes,
} from './edit-form.interfaces';
import List from './list';
import Skeleton from './skeleton';

const UnitTypeUpsertModal = lazy(
	() => import(/* webpackChunkName: "unitTypeUpsertModal" */ './upsert-modal')
);

const EditForm = ({ breadcrumbTitle }: EditFormProps) => {
	const queryClient = useQueryClient();
	const { organisationId } = useParams();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const unitTypeUpsertModal = useModal();
	const [modal, contextHolder] = Modal.useModal();

	const [isUpdatingNextPeriod, setIsUpdatingNextPeriod] = useState(false);
	const [isPricesLoading, setIsPricesLoading] = useState(true);
	const [priceList, setPriceList] = useState<ServicePricesList[]>([]);
	const [selectedUnitType, setSelectedUnitType] = useState<SelectedUnitType>();
	const [unitTypes, setUnitTypes] = useState<UnitTypes[]>([]);
	const [unitTypePage, setUnitTypesPage] = useState(1);
	const [isUnitTypeLoadMore, setIsUnitTypeLoadMore] = useState(false);
	const [unitTypeHasNext, setUnitTypeHasNext] = useState(false);

	const { refetch: getServicePrices } = useQuery({
		queryKey: 'getPrices',
		queryFn: () =>
			getPrices({
				service_id: currentServiceId,
				organisation_id: organisationId,
			}),
		enabled: false,
		onSuccess: (response) => {
			const data = response?.data ?? null;
			const sanitizedResponse: GetPriceApiResponse | null = data
				? camelize(data)
				: data;
			const activePrices = sanitizedResponse?.activePrices ?? [];
			const nextPrices = sanitizedResponse?.nextPeriodPrices ?? [];
			const dataSource = nextPrices.length ? nextPrices : activePrices;

			const priceList = dataSource.map((data) => {
				const prices = toSorted(data);
				const isFree = prices?.[0]?.isFree;

				return {
					calculationType: isFree
						? CALCULATION_TYPES_KEY.FREE
						: data.calculationType,
					serviceTypeId: data.serviceTypeId,
					serviceTypeName: data.serviceTypeName,
					serviceTypeInfo: data.serviceTypeInfo,
					unitTypeId: data.unitTypeId,
					nonTaxableGoods: data.nonTaxableGoods,
					prices: prices.map((price) => {
						return {
							id: price.id,
							minQty: convertToThousandsSeparator(price.minQty.toString()),
							unitPrice: convertToDecimalPrice(price.unitPrice.toString()),
							unitType: data.unitType,
							unitTypeId: data.unitTypeId,
							freeText: CALCULATION_TYPES.FREE,
							isFree: price.isFree,
						};
					}),
				};
			});

			setIsUpdatingNextPeriod(Boolean(nextPrices.length));
			setPriceList(priceList);
			setIsPricesLoading(false);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
			setPriceList([]);
			setIsPricesLoading(false);
		},
	});

	const { isFetching: isUnitTypeLoading } = useQuery({
		queryKey: ['getUnitTypes', unitTypePage],
		queryFn: () =>
			getUnitTypes({
				limit: 5,
				page: unitTypePage,
			}),
		onSuccess: (response) => {
			const data = response?.data ?? null;
			const sanitizedResponse = camelize(data) as GetUnitTypesApiResponse;

			const items = isUnitTypeLoadMore
				? [...unitTypes, ...sanitizedResponse.items]
				: sanitizedResponse.items;

			setUnitTypes(items.filter((item) => item.eligibleToShow));
			setUnitTypeHasNext(sanitizedResponse.pagination.hasNext);
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
		onSettled: () => {
			setIsUnitTypeLoadMore(false);
		},
	});

	const removeUnitType = useMutation(deleteUnitType, {
		onSuccess: () => {
			unitTypePage === 1
				? queryClient.invalidateQueries('getUnitTypes')
				: setUnitTypesPage(1);
			ToastMessage({
				type: 'success',
				label: 'Data berhasil dihapus.',
			});
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: 'Data gagal dihapus.',
			});
		},
	});

	const upsertUnitType = useMutation(updateUnitType, {
		onSuccess: () => {
			unitTypePage === 1
				? queryClient.invalidateQueries('getUnitTypes')
				: setUnitTypesPage(1);
			ToastMessage({
				type: 'success',
				label: selectedUnitType?.id
					? 'Pembaruan data berhasil.'
					: 'Penambahan data berhasil.',
			});
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: selectedUnitType?.id
					? 'Pembaruan data gagal.'
					: 'Penambahan data gagal.',
			});
		},
	});

	const updatePrice = useMutation(updatePrices, {
		onSuccess: () => {
			setIsPricesLoading(true);
			goToPreviousPage();
			ToastMessage({
				type: 'success',
				label: 'Pembaruan data berhasil.',
			});
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: 'Pembaruan data gagal.',
			});
		},
	});

	const loadMoreUnitTypes = () => {
		setIsUnitTypeLoadMore(true);
		setUnitTypesPage(unitTypePage + 1);
	};

	const goToPreviousPage = () => {
		navigate(-1);
	};

	const onUpdatePrice = (payload: PatchUpsertPriceApiPayload) => {
		updatePrice.mutate(payload);
	};

	const onOpenUpsertUnitTypeModal = () => {
		setSelectedUnitType(undefined);
		unitTypeUpsertModal.openModal();
	};

	const onSubmitUnitTypeUpsert = (payload: string) => {
		const upsertPayload = {
			description: payload,
		} as UpdateUnitTypeApiPayload;

		if (selectedUnitType?.id) upsertPayload.id = selectedUnitType.id;

		upsertUnitType.mutate(upsertPayload);
	};

	const onCloseUnitTypeUpsert = () => {
		unitTypeUpsertModal.closeModal();
	};

	const onDeleteUnitType = (id: string) => {
		removeUnitType.mutate(id);
	};

	const onOpenDeleteUnitTypeModal = (id: string) => {
		setSelectedUnitType(undefined);
		modal.confirm({
			title: 'Anda ingin menghapus data?',
			cancelText: 'Batal',
			okText: 'Ya, Hapus',
			className: 'confirm-modal',
			centered: true,
			zIndex: 1041,
			onOk: () => onDeleteUnitType(id),
		});
	};

	const onOpenEditUnitType = (payload: SelectedUnitType) => {
		setSelectedUnitType(payload);
		unitTypeUpsertModal.openModal();
	};

	const toSorted = (data: GetPriceApiPriceResponse) => {
		return data.prices.sort((source, target) => source.minQty - target.minQty);
	};

	const currentServiceId = searchParams.get('sid') ?? '';

	useEffect(() => {
		if (!currentServiceId) {
			return navigate('/invoice-management', { replace: true });
		}

		getServicePrices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<SectionHeader
				containerClass="mb-6"
				isLoading={isPricesLoading}
				title={breadcrumbTitle}
				backHandler={() => navigate(-1)}
			/>
			{isPricesLoading && <Skeleton />}
			{!isPricesLoading && priceList.length === 0 && <EmptyData />}
			{!isPricesLoading && priceList.length > 0 && (
				<List
					priceList={priceList}
					organisationId={organisationId as string}
					goToPreviousPage={goToPreviousPage}
					isUpdatingNextPeriod={isUpdatingNextPeriod}
					isUnitTypeLoadMore={isUnitTypeLoadMore}
					isUnitTypeLoading={isUnitTypeLoading}
					isUpdatePriceLoading={updatePrice.isLoading}
					loadMoreUnitTypes={loadMoreUnitTypes}
					unitTypes={unitTypes}
					unitTypeHasNext={unitTypeHasNext}
					onUpdatePrice={onUpdatePrice}
					onOpenUpsertUnitTypeModal={onOpenUpsertUnitTypeModal}
					onOpenDeleteUnitTypeModal={onOpenDeleteUnitTypeModal}
					onOpenEditUnitTypeModal={onOpenEditUnitType}
					onSetUnitTypesPage={setUnitTypesPage}
					onSetIsUnitTypeLoadMore={setIsUnitTypeLoadMore}
				/>
			)}
			<Suspense>
				{unitTypeUpsertModal.visible && (
					<UnitTypeUpsertModal
						isVisible={unitTypeUpsertModal.visible}
						selectedData={selectedUnitType}
						closeModal={onCloseUnitTypeUpsert}
						onSubmit={onSubmitUnitTypeUpsert}
					></UnitTypeUpsertModal>
				)}
			</Suspense>
			{contextHolder}
		</>
	);
};

export default EditForm;
