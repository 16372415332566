import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { AxiosError } from 'axios';
import Loader from 'common/components/loader';
import { local } from 'common/utils/storage';
import ToastMessage from 'common/utils/toast-message';
import { GENERAL_ERROR_MESSAGE, ROLES } from 'common/constants/index';
import { login, getInfo } from 'services/auth';

const LoginCallback = () => {
	const [params] = useSearchParams();
	const navigate = useNavigate();
	const code = params.get('code') ?? '';
	const code_verifier = local.getItem('oa_code_verifier') ?? '';
	const redirect_uri = process.env.REACT_APP_SQE_ID_REDIRECT_URI as string;

	const { refetch } = useQuery({
		queryKey: ['getProfile'],
		queryFn: getInfo,
		enabled: false,
		onSuccess: (response) => {
			const role = response?.data?.user_role ?? '';

			local.removeItem('oa_code_verifier');
			local.removeItem('oa_state');

			const initialPage = role === ROLES.ADMIN ? '/admin' : '/';
			navigate(initialPage);
		},
		onError: (error: AxiosError) => {
			const status = error.response?.status;

			if (status === 404) {
				return navigate('/unregistered-account');
			}

			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	const loginMutation = useMutation(login, {
		onSuccess: (data) => {
			const token = data?.data?.access_token ?? '';
			const refreshToken = data?.data?.refresh_token;

			if (token) {
				local.setItem('access_token', token);
				local.setItem('refresh_token', refreshToken);
				refetch();
			}
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: GENERAL_ERROR_MESSAGE,
			});
		},
	});

	useEffect(() => {
		loginMutation.mutate({
			code,
			code_verifier,
			redirect_uri,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="w-full h-full bg-neutral-100">
			<Loader loading={true} overlay={false} />
		</div>
	);
};

export default LoginCallback;
