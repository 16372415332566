import {
	CALCULATION_TYPES,
	CALCULATION_TYPES_KEY,
} from 'features/price/price.constants';
import type {
	CalculationType,
	GetPriceApiPriceResponse,
	PriceServiceList,
	PriceServiceText,
} from 'features/price/price.interfaces';
import { convertToThousandsSeparator } from 'common/utils/transformer';
import type { PriceDictionary } from './dashboard.interfaces';

export const generateCurrentPeriodPrices = (
	activePrices: GetPriceApiPriceResponse[]
): PriceServiceText[] => {
	let newData = [] as PriceServiceText[];

	activePrices.forEach((currentServiceData) => {
		const {
			calculationType,
			serviceTypeId,
			serviceTypeInfo,
			prices,
			unitType,
			nonTaxableGoods,
		} = currentServiceData;

		const dataSet = [] as PriceServiceText[];
		const isMultiplierType =
			calculationType === CALCULATION_TYPES_KEY.MULTIPLIER;

		prices.forEach((currentPeriod, index) => {
			const { minQty, unitPrice, isFree, isCustom } = currentPeriod;
			const nextMinQty = prices?.[index + 1]?.minQty ?? 0;
			const name = getNameText(isMultiplierType, minQty, serviceTypeInfo);
			const type = getTypeText(calculationType, minQty, isFree);
			const isNonTaxable = index === 0 ? nonTaxableGoods : false;
			const qtyText = getQtyText(minQty, nextMinQty);

			dataSet.push({
				key: `${serviceTypeId}-${minQty}-${unitPrice}`,
				name,
				minQty,
				price: unitPrice,
				unit: unitType,
				textClassName: '',
				qtyText,
				type,
				isCustom,
				nonTaxableGoods: isNonTaxable,
			});
		});

		const sortedData = toSort(dataSet) as PriceServiceText[];

		newData = [...newData, ...sortedData];
	});

	return newData;
};

const getNameText = (
	isMultiplierType: boolean,
	minQty: number,
	serviceTypeInfo: string
): string => {
	return minQty === 1 || isMultiplierType ? serviceTypeInfo : '';
};

const getQtyText = (minQty: number, nextMinQty: number): string => {
	let qtyText = '';

	if (nextMinQty && typeof nextMinQty === 'number') {
		qtyText =
			nextMinQty - 1 > minQty
				? `${convertToThousandsSeparator(
						minQty.toString()
				  )} - ${convertToThousandsSeparator((nextMinQty - 1).toString())}`
				: convertToThousandsSeparator(minQty.toString());
	}

	return qtyText || convertToThousandsSeparator(minQty.toString());
};

const getTypeText = (
	calculationType: CalculationType,
	minQty: number,
	isFree: boolean
) => {
	let type = CALCULATION_TYPES[calculationType];

	if (calculationType === CALCULATION_TYPES_KEY.MULTIPLIER) return type;
	if (calculationType === CALCULATION_TYPES_KEY.UNIT && isFree) {
		return CALCULATION_TYPES.FREE;
	}

	type = minQty === 1 ? type : '';

	return type;
};

export const generateNextPeriodPrices = (
	activePrices: GetPriceApiPriceResponse[],
	nextPrices: GetPriceApiPriceResponse[]
): PriceServiceText[] => {
	let newData = [] as PriceServiceText[];

	nextPrices.forEach((nextServiceData) => {
		const {
			calculationType,
			serviceTypeId,
			serviceTypeInfo,
			serviceTypeName,
			prices: nextPeriodPrices,
			unitType,
			nonTaxableGoods,
		} = nextServiceData;

		const prevServicesData = activePrices.find(
			(currentServiceType) =>
				currentServiceType.serviceTypeName.toLowerCase() ===
				serviceTypeName.toLowerCase()
		);

		const prevPeriodPrices = prevServicesData?.prices ?? [];
		const prevPeriodInfo = prevServicesData?.serviceTypeInfo;
		const prevPeriodPricesDictionary = {} as PriceDictionary;

		prevPeriodPrices.forEach((prevPrice) => {
			prevPeriodPricesDictionary[prevPrice.minQty] = prevPrice.unitPrice;
		});

		const dataSet = [] as PriceServiceList[];
		const isMultiplierType =
			calculationType === CALCULATION_TYPES_KEY.MULTIPLIER;

		nextPeriodPrices.forEach((nextPeriod, index) => {
			const { minQty, unitPrice, isFree, isCustom } = nextPeriod;

			const prevPeriodPrice = prevPeriodPricesDictionary[minQty];
			const isSamePrice =
				prevPeriodPrice !== undefined ? prevPeriodPrice === unitPrice : false;
			const isSameInfo = prevPeriodInfo === serviceTypeInfo;

			const name = getNameText(isMultiplierType, minQty, serviceTypeInfo);
			const isNonTaxable = index === 0 ? nonTaxableGoods : false;
			const type = getTypeText(calculationType, minQty, isFree);

			const payload = {
				key: `${serviceTypeId}-${minQty}-${unitPrice}`,
				name,
				minQty: minQty,
				price: unitPrice,
				unit: unitType,
				textClassName: '',
				type,
				isCustom,
				nonTaxableGoods: isNonTaxable,
			};

			if (!isSamePrice || !isSameInfo) payload.textClassName = 'text-blue-500';

			dataSet.push(payload);
		});

		const sortedData = toSort(dataSet);

		const rangeData = sortedData.map(
			(data: PriceServiceList, index: number) => {
				const currentMinQty = data.minQty;
				const nextQty = sortedData?.[index + 1]?.minQty ?? 0;
				const qtyText = getQtyText(currentMinQty, nextQty);

				return {
					...data,
					qtyText,
				};
			}
		) as PriceServiceText[];

		newData = [...newData, ...rangeData];
	});

	return newData;
};

const toSort = (dataSet: PriceServiceText[] | PriceServiceList[]) => {
	return dataSet.sort(
		(
			source: PriceServiceText | PriceServiceList,
			target: PriceServiceText | PriceServiceList
		) => source.minQty - target.minQty
	);
};
