import { useEffect } from 'react';
import { useBusinessUnitStore } from './business-unit.store';
import Form from './form';

const AddBusinessUnit = () => {
	const setIsLoadingDetails = useBusinessUnitStore(
		(action) => action.setIsLoadingDetails
	);
	const setIsUseStepperGuard = useBusinessUnitStore(
		(action) => action.setIsUseStepperGuard
	);
	const setFormStep = useBusinessUnitStore((action) => action.setFormStep);

	useEffect(() => {
		setFormStep(1);
		setIsUseStepperGuard(true);
		setIsLoadingDetails(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Form />;
};

export default AddBusinessUnit;
