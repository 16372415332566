import { Icon, Paragraph } from '@squantumengine/horizon';
import type { ColumnsType } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import { useModalContext } from 'common/context/shared-modal';
import type { SortBy } from 'common/types';
import dayjs from 'dayjs';
import type { WeblinkSortKeys } from 'features/dashboard/know-your-customer/detail/signer-detail.interfaces';
import FieldColumn from 'features/dashboard/know-your-customer/field-column';
import { SIGNER_STATUS } from 'features/dashboard/know-your-customer/kyc-dashboard.constants';
import style from 'features/dashboard/know-your-customer/table/index.module.scss';
import SpPagination from 'common/components/sp-pagination';
import SpProgress from 'common/components/sp-progress';
import SpTable from 'common/components/sp-table';
import type { WeblinkList, WeblinkTableProps } from './weblink.interfaces';

const WeblinkTable = ({
	data,
	isLoading,
	limit,
	page,
	refUserId,
	totalCount,
	onChangePageSize,
	onChangeCurrentPage,
	onSort,
}: WeblinkTableProps) => {
	const columns: ColumnsType<WeblinkList> = [
		{
			title: 'Tanggal dikirim',
			dataIndex: 'weblinkCreatedAt',
			key: 'weblink_created_at',
			sorter: true,
			width: 200,
			className: 'min-w-[200px]',
			render: (_, { weblinkCreatedAt }) => (
				<FieldColumn
					title={dayjs(weblinkCreatedAt).format('DD MMM YYYY')}
					description={dayjs(weblinkCreatedAt).format('HH:mm:ss')}
				/>
			),
		},
		{
			title: 'Tanggal diperbarui',
			dataIndex: 'weblinkUpdatedAt',
			key: 'weblink_updated_at',
			sorter: true,
			width: 200,
			className: 'min-w-[200px]',
			render: (_, { weblinkUpdatedAt }) => (
				<FieldColumn
					title={dayjs(weblinkUpdatedAt).format('DD MMM YYYY')}
					description={dayjs(weblinkUpdatedAt).format('HH:mm:ss')}
				/>
			),
		},
		{
			title: 'Weblink ID',
			dataIndex: 'weblinkId',
			width: 200,
			className: 'min-w-[200px]',
			render: (_, { weblinkId }) => <FieldColumn title={weblinkId} />,
		},
		{
			title: 'Progress tandatangan',
			dataIndex: 'signingStatuses',
			width: 200,
			className: 'min-w-[200px]',
			render: (_, { signingStatuses }) => {
				const totalSigning = signingStatuses.length;
				let successCount = 0;
				let failedCount = 0;
				let pendingCount = 0;

				signingStatuses.forEach((status) => {
					if (status === SIGNER_STATUS.success) {
						successCount += 1;
					} else if (status === SIGNER_STATUS.failed) {
						failedCount += 1;
					} else {
						pendingCount += 1;
					}
				});

				const percentage = (successCount * 100) / totalSigning;
				const isShowProgress = successCount < totalSigning;
				const title = `${totalSigning} dokumen ${
					totalSigning === successCount ? 'selesai' : ''
				}`;

				return (
					<div className="flex flex-col">
						<div className="flex items-center justify-between w-[200px]">
							<div className="flex items-center">
								<Icon name="file" />
								<Paragraph size="s" className="ml-0.5">
									{title}
								</Paragraph>
							</div>
							{failedCount > 0 && pendingCount === 0 && (
								<Paragraph className="text-red-500" size="s">
									{failedCount} Gagal
								</Paragraph>
							)}
						</div>
						{isShowProgress ? (
							<div className="mt-1">
								<SpProgress
									progress={pendingCount > 0 ? 0 : percentage}
									width={200}
									progressColor="#006ceb"
									trailColor={
										failedCount > 0 && pendingCount === 0
											? '#f44336'
											: '#d5dbe5'
									}
								/>
							</div>
						) : null}
					</div>
				);
			},
		},
	];

	const onTableChange = (sorter: SorterResult<WeblinkList>) => {
		const by =
			sorter.order && sorter.columnKey
				? (sorter.columnKey as WeblinkSortKeys)
				: ('weblink_created_at' as WeblinkSortKeys);

		let order = 'desc' as SortBy;

		if (sorter.order) order = sorter.order === 'ascend' ? 'asc' : order;

		onSort({
			by,
			order,
		});
	};

	const { toggleSharedModal } = useModalContext();

	const openStatusModal = (weblinkId: string) => {
		toggleSharedModal({
			name: 'signerStatus',
			payload: {
				isOpen: true,
				data: {
					weblinkId,
					contractId: '',
					refUserId,
				},
			},
		});
	};

	return (
		<>
			<SpTable
				className={style.table}
				columns={columns}
				dataSource={data}
				isLoading={isLoading}
				bordered
				defaultTable
				scroll={{
					x: 900,
				}}
				rowClassName="cursor-pointer"
				onRow={({ weblinkId }) => {
					return {
						onClick: () => openStatusModal(weblinkId),
					};
				}}
				onChange={
					/* istanbul ignore next */
					(_pagination, _filters, sorter) =>
						onTableChange(sorter as SorterResult<WeblinkList>)
				}
				pagination={false}
			/>
			{data.length && !isLoading ? (
				<div className="px-6">
					<SpPagination
						currentPage={page}
						pageSize={limit}
						totalCount={totalCount}
						onChangePageSize={onChangePageSize}
						onChangeCurrentPage={onChangeCurrentPage}
					/>
				</div>
			) : null}
		</>
	);
};

export default WeblinkTable;
