import type { PostDownloadInvoiceApiPayload } from 'features/invoice-management/invoice-management.interfaces';
import type { GetInvoiceDetailParams } from 'features/invoice-preview/invoice-preview.interfaces';
import axios from './axios-instance';

const baseUrl = '/v1/invoice';

export const download = async (payload: PostDownloadInvoiceApiPayload) => {
	const response = await axios.post(`${baseUrl}/download`, payload, {
		responseType: 'blob',
	});

	return response.data;
};
export const getDetail = async (params: GetInvoiceDetailParams) => {
	const response = await axios.get(`${baseUrl}`, { params });

	return response.data;
};
