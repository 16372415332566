import { Navigate, useLocation, matchPath } from 'react-router-dom';
import { ROLE_ALLOWED_PATHS } from 'layouts/dashboard/dashboard.constants';
import { useProfileStore } from 'common/stores/profile';
import { ROLES } from 'common/constants/global';
import type { PrivateRouteProps } from './private-route.interfaces';

const PrivateRoute = ({
	allowedRoles,
	component: Component,
}: PrivateRouteProps) => {
	const { pathname } = useLocation();

	const role = useProfileStore((state) => state.role);
	const isInActiveUser = useProfileStore((state) => state.isInActiveUser);
	const isUnregisteredUser = useProfileStore(
		(state) => state.isUnregisteredUser
	);
	const featureFlags = useProfileStore((state) => state.featureFlags);

	const checkAllowedPaths = () => {
		let paths = ROLE_ALLOWED_PATHS[role] ?? [];
		let isAllowed = false;

		if (
			ROLES.PENGELOLA.toLowerCase() === role &&
			((featureFlags && !featureFlags['ekyc_dashboard_enabled']) ||
				!featureFlags)
		) {
			paths = paths.filter(
				(nav) => nav.path !== '/dashboard/know-your-customer'
			);
		}
		if (
			ROLES.MANAGER.toLowerCase() === role &&
			((featureFlags &&
				/* istanbul ignore next */ !featureFlags[
					'approval_dashboard_enabled'
				]) ||
				!featureFlags)
		) {
			paths = paths.filter(
				(nav) =>
					nav.path !== '/invoice-management/approval' &&
					nav.path !== '/invoice-management/approval/:reportId' &&
					nav.path !== '/invoice-management/approval/log/:reportId'
			);
		}

		for (const routeInfo of paths) {
			if (matchPath(routeInfo.path, pathname)) {
				isAllowed = true;
				break;
			}
		}

		return isAllowed;
	};

	const isAuthorized = allowedRoles.includes(role);
	const isAllowed = checkAllowedPaths();

	if (isInActiveUser) return <Navigate to={'/inactive'} />;
	if (isUnregisteredUser) return <Navigate to={'/unregistered-account'} />;
	if (role === ROLES.ADMIN.toLowerCase() && pathname === '/') {
		return <Navigate to={'/admin'} />;
	}
	if (!isAuthorized) return <Navigate to={'/unauthorized'} />;
	if (!isAllowed) return <Navigate to={'/404'} />;

	return Component;
};

export default PrivateRoute;
