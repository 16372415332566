import axios from './axios-instance';

const baseUrl = '/v1/file';

export const fileDownload = async (id: string) => {
	const response = await axios.get(`${baseUrl}/attachment`, {
		params: {
			id,
		},
		responseType: 'blob',
	});

	return {
		disposition: response.headers['content-disposition'],
		blob: response.data,
	};
};
export const fileUpload = async (payload: FormData) => {
	const response = await axios.post(`${baseUrl}/attachment`, payload, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	return response.data;
};
export const deleteFile = async (id: string) => {
	const response = await axios.delete(baseUrl, {
		data: {
			id,
		},
	});

	return response.data;
};
