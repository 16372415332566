export const generateInitial = (name: string) => {
	const uppercaseValue = name?.toUpperCase();

	if (!uppercaseValue) return '';

	const splittedValue = uppercaseValue.split(' ');

	if (splittedValue.length === 1) {
		return uppercaseValue.length === 1
			? uppercaseValue
			: `${uppercaseValue[0]}${uppercaseValue[1]}`;
	}

	return `${splittedValue[0][0]}${splittedValue[1][0]}`;
};
