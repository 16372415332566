import SpSkeleton from 'common/components/sp-skeleton';
import type { UnitTypeSkeletonprops } from './unit-type-skeleton.interfaces';

const UnitTypeSkeleton = ({ count }: UnitTypeSkeletonprops) => {
	const skeletonArray = new Array(count).fill(null).map((_, index) => index);

	return (
		<div className="flex flex-col">
			{skeletonArray.map((index) => (
				<div key={index} className="px-4 h-[36px] flex items-center">
					<SpSkeleton className="w-20 h-4" />
				</div>
			))}
		</div>
	);
};

export default UnitTypeSkeleton;
