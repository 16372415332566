import { Typography } from '@squantumengine/horizon';
import classNames from 'classnames';
import type { EmptyDocumentProps } from './empty-document.interfaces';

const { Paragraph, Title } = Typography;

const EmptyDocument = ({
	title,
	description,
	stroke = false,
}: EmptyDocumentProps) => {
	const containerClassName = classNames(
		'bg-neutral-0 shadow-base rounded-lg py-[76px] flex flex-col items-center',
		{
			'border border-stroke-secondary': stroke,
		}
	);

	return (
		<div className={containerClassName}>
			<div className="h-[128px]">
				<img
					src="/assets/illustrations/empty-document.svg"
					alt="Empty document"
					className="h-full w-auto"
				/>
			</div>
			<Title level={4} className="mt-4">
				{title}
			</Title>
			<Paragraph className="mt-2 text-secondary" size="r" weight="regular">
				{description}
			</Paragraph>
		</div>
	);
};

export default EmptyDocument;
