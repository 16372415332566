import type {
	PostLoginApiPayload,
	PostLogoutApiPayload,
} from 'common/types/auth';
import axios from './axios-instance';

const baseUrl = '/v1/oidc';

export const login = async (payload: PostLoginApiPayload) => {
	const response = await axios.post(`${baseUrl}/authorization-code`, payload);

	return response.data;
};
export const logout = async (payload: PostLogoutApiPayload) => {
	const response = await axios.post(`${baseUrl}/logout`, payload);

	return response.data;
};
export const getInfo = async () => {
	const response = await axios.get(`${baseUrl}/userinfo`);

	return response.data;
};
