import { Paragraph } from '@squantumengine/horizon';
import type { RecapProps } from './recap.interfaces';

export default function RecapWidget({
	icon,
	iconClassColor,
	content,
	value,
}: RecapProps) {
	return (
		<div className="relative p-4 bg-neutral-0 rounded-lg shadow-base h-[146px]">
			<div className="flex flex-col h-full">
				<div className={`${iconClassColor} w-8 h-8 mb-1`}>{icon}</div>
				{content}
				{value && (
					<Paragraph size="xl" weight="bold" className="mt-auto">
						{value}
					</Paragraph>
				)}
			</div>
		</div>
	);
}
