import SpSkeleton from 'common/components/sp-skeleton';
import ServiceListSkeleton from './list-skeleton';

const Skeleton = () => {
	return (
		<div className="w-full bg-neutral-0 shadow-base rounded-lg p-6 flex flex-col">
			<SpSkeleton className="w-[80px] h-6 mb-4" />
			<ServiceListSkeleton />
		</div>
	);
};

export default Skeleton;
