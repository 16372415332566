import {
	createContext,
	useContext,
	useState,
	useMemo,
	useCallback,
} from 'react';
import type { ProfileOrganisations } from 'common/types';
import type {
	SharedModalProps,
	TogglePayload,
	ModalState,
} from './shared-modal.interfaces';

const ModalContext = createContext({
	modalState: {
		signerList: {
			isOpen: false,
			data: {
				documentId: '',
			},
		},
		signerStatus: {
			isOpen: false,
			data: {
				weblinkId: '',
				contractId: '',
				refUserId: '',
			},
		},
		downloadEvidence: {
			isOpen: false,
			data: {
				organisations: [] as ProfileOrganisations[],
			},
		},
	},

	toggleSharedModal: /* istanbul ignore next */ ({
		name,
		payload,
	}: // eslint-disable-next-line @typescript-eslint/no-empty-function
	TogglePayload) => {},
});

export const ModalProvider = ({ children }: SharedModalProps) => {
	const [modalState, setModalState] = useState<ModalState>({
		signerList: {
			isOpen: false,
			data: {
				documentId: '',
			},
		},
		signerStatus: {
			isOpen: false,
			data: {
				weblinkId: '',
				contractId: '',
				refUserId: '',
			},
		},
		downloadEvidence: {
			isOpen: false,
			data: {
				organisations: [] as ProfileOrganisations[],
			},
		},
	});

	const toggleSharedModal = useCallback(({ name, payload }: TogglePayload) => {
		setModalState((prevState) => ({
			...prevState,
			[name]: payload,
		}));
	}, []);

	const contextValue = useMemo(
		() => ({
			modalState,
			toggleSharedModal,
		}),
		[modalState, toggleSharedModal]
	);

	return (
		<ModalContext.Provider value={contextValue}>
			{children}
		</ModalContext.Provider>
	);
};

export const useModalContext = () => useContext(ModalContext);
